import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import UserNavbar from "./UserNavbar";
import { getprofile, editprofile } from "../store/slices/profile";
import Moment from "moment";
const EditProfile = () => {
  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [gender, setGender] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [message, setMessage] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [dob, setDob] = useState("");
  React.useEffect(() => {
    dispatch(getprofile())
      .unwrap()
      .then((data) => {
        setFirstName(data.user.data[0].firstName);
        setLastName(data.user.data[0].lastName);
        setEmail(data.user.data[0].email);
        setAddress(data.user.data[0].address);
        setCity(data.user.data[0].city);
        setGender(data.user.data[0].gender);
        setimageUrl(data.user.data[0].imageUrl);
        setmobileNo(data.user.data[0].mobileNo);
        setDob(data.user.data[0].dob);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function upload(e) {
    e.preventDefault();
    let item = {
      set: {
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        country,
        pinCode,
        dob,
        mobileNo,
        imageUrl,
        gender,
      },
    };
    dispatch(editprofile(item))
      .unwrap()
      .then(() => {
        alert("Profile updated succesfully");
        navigate("/MyProfile");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setimageUrl(data.data.location);
    setMessage(data.message);
  }
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container">
              <div className="pt-5 mt-5">
                <div className="car">
                  <div className="a" align="center">
                    <img
                      src={imageUrl}
                      alt=""
                      style={{
                        borderRadius: "100px",
                        width: "100px",
                        height: "100px",
                      }}
                    />
                    <br />
                    <div className="row mt-3">
                      <div className="col-md-4" />
                      <div className="col-md-4">
                        <input
                          type="file"
                          className="form-control"
                          Placeholder=""
                          name="imageUrl"
                          //
                          onChange={(e) => imageUpload(e)}
                        />
                      </div>
                    </div>

                    <form className="contact-form" onSubmit={upload}>
                      <div className="row">
                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>First Name</b>
                            <input
                              type="text"
                              name="First Name"
                              placeholder="First Name"
                              className="form-control mt-2"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              //  value={agentVal.firstName}
                            />
                          </div>
                        </div>

                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>Last Name</b>
                            <input
                              type="text"
                              name="Last name"
                              placeholder="Last name"
                              className="form-control mt-2"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>Email</b>
                            <input
                              type="text"
                              name="email"
                              placeholder="Email Address"
                              className="form-control mt-2"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>Mobile Number</b>
                            <input
                              type="number"
                              value={mobileNo}
                              name="Phone number"
                              placeholder="Phone number"
                              className="form-control mt-2"
                              onChange={(e) => setmobileNo(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>Date of Birth</b>
                            <input
                              type="date"
                              placeholder=""
                              className="form-control mt-2"
                              value={Moment(dob).format("YYYY-MM-DD")}
                              max={Moment().format("YYYY-MM-DD")}
                              onChange={(e) => setDob(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>Gender</b>
                            <select
                              className="form-control mt-2"
                              onChange={(e) => setGender(e.target.value)}
                              value={gender}
                            >
                              <option value="">Select</option>
                              <option>Male</option>
                              <option>Female</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12" align="left">
                          <div className="form-group">
                            <b>Address</b>
                            <textarea
                              name="Address"
                              placeholder="Address"
                              className="form-control mt-2"
                              value={address}
                              rows="2"
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>City</b>
                            <input
                              type="text"
                              name="city"
                              placeholder="city"
                              className="form-control mt-2"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>State</b>
                            <input
                              type="text"
                              name="State"
                              placeholder="State"
                              className="form-control mt-2"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>Pincode</b>
                            <input
                              type="text"
                              name="Pincode"
                              placeholder="Pincode"
                              className="form-control mt-2"
                              value={pinCode}
                              onChange={(e) => setPinCode(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6" align="left">
                          <div className="form-group">
                            <b>Country</b>
                            <input
                              type="text"
                              name="Country"
                              placeholder="Country"
                              className="form-control  mt-2"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                          </div>
                        </div>

                        <center
                          className="send-btn"
                          style={{ marginBottom: "20px" }}
                        >
                          <button className="btn btn-warning col-white submit-btn">
                            Save
                          </button>
                          &nbsp;
                          <button className="btn btn-blue col-white submit-btn">
                            Cancel
                          </button>
                        </center>
                      </div>
                    </form>
                    {/* ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
