import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  ButtonGroup,
  NavDropdown,
} from "react-bootstrap";

import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { MdWifiCalling } from "react-icons/md";

function Header() {
  //   useEffect(() => {

  //     var tawk = new TawkTo(propertyId, tawkId)

  //     tawk.onStatusChange((status) =>
  //     {
  //         // console.log(status)
  //     })

  // }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [navBackground, setNavBackground] = useState(false);
  const navigate = useNavigate();

  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 80;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [user, setUser] = useState({});
  useEffect(() => {
    {
    }
    setInterval(() => {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);

      setUser(user);
    }, []);
  }, 500000);

  function dropnav(e) {
    localStorage.clear();
    navigate("/HealthCare");
  }

  const logout = () => {
    return localStorage.clear();
  };
  const listStyleactive = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#f06900" : "white",
      borderRadius: isActive ? "5px" : "5px",
      color: isActive ? "white" : "gray",
      height: isActive ? "40px" : "",
    };
  };

  const dropStyleactive = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#f06900" : "white",
      borderRadius: isActive ? "5px" : "5px",
      color: isActive ? "white" : "gray",
      padding: isActive ? "10px 0px 6px 11px;" : "10px 0px 6px 11px;",
      // lineHeight: isActive ? '1.5' : '1.5',
    };
  };
  if (!user) {
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          fixed="top"
          style={{
            transition: "1s ease",
            color: "black",
            backgroundColor: navBackground ? "white" : "white",
          }}
        >
          <Container>
            <Navbar.Brand to="/">
              <Link to="/">
                <img
                  className="logo-3"
                  src={logo}
                  alt=""
                  style={{ height: "80px" }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavLink
                  to="/"
                  className="nav-link-item active"
                  style={listStyleactive}
                >
                  Home
                </NavLink>
                &nbsp; &nbsp;
                <Dropdown
                  as={ButtonGroup}
                  className="d-flex align-items-center"
                  style={{ width: "125px" }}
                >
                  <NavLink
                    to="/HealthCare"
                    style={dropStyleactive}
                    className="nav-link-item2"
                  >
                    Health Care
                    <NavLink to="">
                      <Dropdown.Toggle
                        id="dropdown-split-basic"
                        className="btn-toggle-down"
                      />
                    </NavLink>
                  </NavLink>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink to="/HealthCare-Ayurveda">Ayurveda</NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="">Health Plans</NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/Medicine"> Medicine</NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <NavLink
                  to="/weight-loss"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Weight Loss
                </NavLink>
                &nbsp; &nbsp;
                <Dropdown
                  as={ButtonGroup}
                  className="d-flex align-items-center"
                  style={{ width: "100px" }}
                >
                  <NavLink
                    to="/fitness"
                    style={dropStyleactive}
                    className="nav-link-item2"
                  >
                    Fitness
                    <NavLink to="">
                      <Dropdown.Toggle
                        id="dropdown-split-basic"
                        className="btn-toggle-down"
                      />
                    </NavLink>
                  </NavLink>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink to="/Gym-franchise">Gym Franchise</NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/Personal-training">
                        Personal Training
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <NavLink
                  to="/physiotherapy"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Physiotherapy
                </NavLink>
                <NavLink
                  to="/LiveWell"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Live Well
                </NavLink>
                <NavLink
                  to="/corporate"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Corporate
                </NavLink>
                <NavLink
                  to="/travelonify"
                  target="_blank"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Travelonify
                </NavLink>
                <NavLink
                  to="/signin"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Login
                </NavLink>
                {/* )} */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="call-btn">
          <a href="tel:+919773233030">
            <MdWifiCalling className="call-icon" />{" "}
          </a>
        </div>
      </div>
    );
  }
  if (user) {
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          fixed="top"
          style={{
            transition: "1s ease",
            color: "black",
            backgroundColor: navBackground ? "white" : "white",
          }}
        >
          <Container>
            <Navbar.Brand to="/">
              <Link to="/">
                <img
                  className="logo-3"
                  src={logo}
                  alt=""
                  style={{ height: "80px" }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavLink
                  to="/"
                  className="nav-link-item active"
                  style={listStyleactive}
                >
                  Home
                </NavLink>
                &nbsp; &nbsp;
                <Dropdown
                  as={ButtonGroup}
                  className="d-flex align-items-center"
                  style={{ width: "125px" }}
                >
                  <NavLink
                    to="/HealthCare"
                    style={dropStyleactive}
                    className="nav-link-item2"
                  >
                    Health Care
                    <NavLink to="">
                      <Dropdown.Toggle
                        id="dropdown-split-basic"
                        className="btn-toggle-down"
                      />
                    </NavLink>
                  </NavLink>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink to="/HealthCare-Ayurveda">Ayurveda</NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="">Health Plans</NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/Medicine"> Medicine</NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <NavLink
                  to="/weight-loss"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Weight Loss
                </NavLink>
                &nbsp; &nbsp;
                <Dropdown
                  as={ButtonGroup}
                  className="d-flex align-items-center"
                  style={{ width: "100px" }}
                >
                  <NavLink
                    to="/fitness"
                    style={dropStyleactive}
                    className="nav-link-item2"
                  >
                    Fitness
                    <NavLink to="">
                      <Dropdown.Toggle
                        id="dropdown-split-basic"
                        className="btn-toggle-down"
                      />
                    </NavLink>
                  </NavLink>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink to="/Gym-franchise">Gym Franchise</NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/Personal-training">
                        Personal Training
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <NavLink
                  to="/physiotherapy"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Physiotherapy
                </NavLink>
                <NavLink
                  to="/LiveWell"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Live Well
                </NavLink>
                <NavLink
                  to="/corporate"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Corporate
                </NavLink>
                <NavLink
                  to="/travelonify"
                  target="_blank"
                  className="nav-link-item"
                  style={listStyleactive}
                >
                  Travelonify
                </NavLink>
                <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <NavLink to="/MyProfile">My Profile</NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="call-btn">
          <a href="tel:+919773233030">
            <MdWifiCalling className="call-icon" />{" "}
          </a>
        </div>
      </div>
    );
  }
}

export default Header;
