import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "./navbar";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { getFitnessDetails } from "./../store/slices/fitnesscenter";
import { useNavigate } from "react-router-dom";
import Travelfooter from "./Travelfooter";

function Traveldetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("id", id);
  const [fcName, setfcName] = useState();
  const [imageUrl, setimageUrl] = useState([]);
  const [logo, setLogo] = useState("");
  const [centeraddress, setcenterAddress] = useState("");
  const [about, setAbout] = useState("");
  React.useEffect(() => {
    dispatch(getFitnessDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setfcName(data.user.data[0].fcName);
          setimageUrl(data.user.data[0].mediaLink);
          setcenterAddress(data.user.data[0].fcAddress);
          setAbout(data.user.data[0].about);
          setLogo(data.user.data[0].logo);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div id="_next">
      <Header />

      <section>
        <div className="carousel_cover position-relative">
          <Carousel className="length">
            {imageUrl.map((image, index) => (
              <Carousel.Item interval={2500}>
                <img className="d-block w-100 carimg " src={image} alt="" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
      <section
        className="container"
        style={{
          border: "solid 1px silver",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div className="d-flex justify-content-left">
          <div className="row pb-1">
            <div className="col-lg-3 col-md-3 col-sm-12 ">
              <img src={logo} alt="" />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 ">
              <h3 className="text-left  pb-2" style={{ color: "black" }}>
                {fcName}
              </h3>
              <p style={{ whiteSpace: "pre-wrap" }}>
                <i className="fa fa-map-marker"></i>&nbsp;
                {centeraddress.doorNoAndStreetName}, {centeraddress.city},&nbsp;
                {centeraddress.state} {centeraddress.pincode}
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section
        className="container"
        style={{
          border: "solid 1px silver",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div className="d-flex justify-content-left">
          <h3 className="text-center  pb-2" style={{ color: "black" }}>
            About {fcName}
          </h3>
        </div>

        <div className="row pb-1">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <p style={{ whiteSpace: "pre-wrap" }}>{about}</p>
          </div>
        </div>
      </section>

      <section
        className="container"
        style={{
          border: "solid 1px silver",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div className="d-flex justify-content-left">
          <div className="row pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <h3 className="text-left  pb-2" style={{ color: "black" }}>
                Amenities
              </h3>
              <p style={{ whiteSpace: "pre-wrap" }}>
                <i className="fa fa-map-marker"></i>&nbsp;
                {centeraddress.doorNoAndStreetName}, {centeraddress.city},&nbsp;
                {centeraddress.state} {centeraddress.pincode}
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <Travelfooter />
    </div>
  );
}

export default Traveldetails;
