import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class IndianPackageService {
   
    static indianpackage() {
        return axios
          .get( API_PATHS.indianPackage)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default IndianPackageService;