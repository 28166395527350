import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import frequentlyQuestion from "../../services/frequently.question";
import AuthService from "../../services/auth.service";

export const Frequently = createAsyncThunk(
    "frequently/get/faq",
    async(thunkAPI) => {
        try {
            const data = await frequentlyQuestion.frequently();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const frequentlySlice = createSlice({
    name: "frequently",
    initialState,
    extraReducers: {
        [Frequently.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [Frequently.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [Frequently.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = frequentlySlice;
export default reducer;