import React, { useEffect } from "react";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/blog/hb1.jpg";
import { Link } from "react-router-dom";
import Gapp from "./../images/project/Gapp.png";
import Iapp from "./../images/project/apple.png";
// slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "./navbar";

const Healthmeter = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  //enquiry form
  return (
    <div id="__next">
      <Header />

      <section>
        <div className="carousel_cover ">
          <Carousel className="">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner1}
                alt=""
              />
              <Carousel.Caption>
                <Link to="/HRA">
                  <button
                    type="button"
                    className="btn btn-healthonify  col-white"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      // width: "200px",
                      height: "50px",
                    }}
                  >
                    TAKE HEALTH RISK ASSESSMENT
                  </button>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Health Meter</h3>
        </div>

        <div className="pt-4">
          <p>
            Healthonify mission is simple. We’ve created an ecosystem of care
            starting with wellness screenings, risk prediction, recommended
            lifestyle, and clinical care with health coaching to guide you to
            better care. We provide complete solution for your healthcare needs.
            <br />
            <br />
            Upon the completion of your Health Risk Assessment, the Healthonify
            system will identifies chronic conditions and recommends Care
            Program, creating the ability for continued disease and lifestyle
            management. We offer a health coaching program through a virtual
            platform that allows patients to access to care and therefore reduce
            the unnecessary E/R visits. Our Programs offers a solution that is
            second to none. Our Goal is simple- To Educate,Motivate,Cure and
            Monitor your Health using our experts and technology.
          </p>
        </div>
      </section>
      <section className="d-flex justify-content-center pt-4 pb-2">
        <Link to="/HRA">
          <button
            type="button"
            className="btn btn-warning col-white btn-single"
            data-bs-toggle="modal"
          >
            TAKE A HEALTH RISK ASSESSMENT NOW
          </button>
        </Link>
      </section>
      <section className="container pt-5 pb-5">
        <div className="pt-4">
          <p>
            <b>How We Work</b>
            <br />
            <ul>
              <li> Take a Risk Assessment</li>
              <li> Get a Detailed Health Report</li>
              <li> Health Coach explains the risks</li>
              <li> Avail Doctors Consultation</li>
              <li> Get the Lab tests done if required.</li>
              <li> Avail a Care Plan</li>
              <li>
                {" "}
                Use Healthonify App to track and Monitor Health Parameters.
              </li>
              <li>
                {" "}
                Avail the Fitness, Weight Management & related Wellness
                Ecosystem.
              </li>
              <li> Turn Around your Lifestyle to be Fit and Healthy</li>
            </ul>
          </p>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <img src={Gapp} alt="" style={{ height: "80px" }} />
            &nbsp;
            <img src={Iapp} alt="" style={{ height: "80px" }} />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Healthmeter;
