import React from "react";
import Travelfooter from "./Travelfooter";
import Header from "./navbar";

const Cancellationpolicy = () => {
  return (
    <div>
      <Header />
      <div className="header mt-5 pt-5">
        <h2 className="header-text-section">Cancellation Policy</h2>
      </div>
      <div className="container">
        <h5 className="">PAYMENTS: </h5>
        <p className="para-t">
          For all the services contracted,50% advance payment should be made to
          hold the booking, on confirmed basis & the balance amount can be paid
          15 days before the commencement of the services. Management personnel
          hold the right to decide upon the amount to be paid as advance
          payment, based on the nature of the service & the time left for the
          commencement of the service.
        </p>

        <p>
          <b>
            Apart from above in some cases like Special/Seasonal Tours, Special
            Train Journeys, hotels or resorts bookings during the peak season
            (X-Mas, New Year), full payment in advance is mandatory.
          </b>
          <strick>required to be sent in advance.</strick>{" "}
        </p>

        <h5 className="pt-5">CANCELLATION POLICY: </h5>
        <p>
          In the event of cancellation of tour / travel services due to any
          avoidable / unavoidable reason/s we must be notified of the same in
          writing. Cancellation charges will be effective from the date we
          receive advice in writing, and cancellation charges would be as
          follows:
        </p>

        <p>
          60 days prior to the start date of tour arrival: 10% of the Tour /
          service cost​
        </p>
        <p>
          45 days prior to the start date of tour arrival: 25% of the Tour /
          service cost​
        </p>
        <p>
          15 days prior to the start date of tour arrival: 50% of the Tour /
          service cost​
        </p>
        <p>
          07 days prior to the start date of tour arrival: 100% of the Tour /
          service cost
        </p>

        <h5 className="pt-5">LESS THAN 7 DAYS OR NO SHOW: NO REFUND </h5>
        <p>
          In case of Special/Seasonal Tours, Special Trains Journeys and peak
          season hotel bookings company reserves the right to amend the a
          separate cancellation policy and the same will be intimated to
          Customer/Client at the time of booking. is applicable (which can be
          advised as and when required).{" "}
        </p>

        <h5 className="pt-5">
          SOME CANCELLATION AND REFUND POLICIES ARE OUT OF OUR TENDER:
        </h5>
        <p>
          Refund for hotel payment is based on will follow the hotel’s
          cancellation policy​ Train tickets refund is based on cancellations
          will follow the Railway’s cancellation policy​ Flight tickets refund
          is based on airlines companies’ cancellations policy will follow the
          airlines company policy ,In case you cancel the trip after
          commencement, then there will be no refund. However, company will try
          to get the refund for unused hotel accommodation and/or transportation
          services but company not give any assurances of getting refund from
          concern parties. Furthermore, it is not the company responsibility
          and/or liability to get the refund since it is already informed to you
          that any cancellation after commencement of the tour there will be no
          refund. would be restricted to a limited amount only which too would
          depend on the amount that we would be able to recover from the
          hoteliers/ contractors we patronize. For unused hotel accommodation,
          chartered transportation & missed meals etc. we do not bear any
          responsibility to refund.{" "}
        </p>

        <h5 className="pt-5">OUR LIABILITIES & LIMITATIONS: </h5>
        <p>
          Please note that after the finalization of the Tour/ service Cost, if
          there are any Hike in entrance fees of monuments / museums, Taxes,
          fuel cost or guide charges – by Govt of India, the same would be
          charged as extra.
        </p>

        <h5 className="pt-5">LAW & JUSDICTION </h5>
        <p>
          For all complaints, suits, claims or disputes of whatsoever nature
          relating to any products offered by The Travel Planners, will be under
          the exclusive jurisdiction of Court in Mumbai, of Maharashtra.
        </p>
      </div>
      <Travelfooter />
    </div>
  );
};

export default Cancellationpolicy;
