import React, { useState } from "react";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/fitness/banner1.png";
import banner2 from "./../images/fitness/banner2.png";
import banner3 from "./../images/fitness/banner3.png";
import banner4 from "./../images/fitness/banner4.png";
import banner5 from "./../images/fitness/banner5.jpg";
import banner6 from "./../images/fitness/banner6.jpg";
import banner7 from "./../images/fitness/banner7.jpg";
import Healthy from "./../images/fitness/Fitness - Get Healthy with our healthonify App.png";
import { useDispatch } from "react-redux";
import { getFitnessBlog } from "../store/slices/blog";
import home3 from "./../images/homepage/banner3.jpg";
import coach from "./../images/homepage/icons/coach.png";
import lab from "./../images/homepage/icons/laboratory.png";
import customized from "./../images/homepage/icons/customized.png";
import appease from "./../images/homepage/icons/app-ease.png";
import { useNavigate, Link } from "react-router-dom";
import bmis from "./../images/homepage/icons/bmi.png";
import bmrs from "./../images/homepage/icons/bmr.png";
import active from "./../images/homepage/icons/calorie.png";
import Ideal from "./../images/homepage/icons/weight.png";
import fat from "./../images/homepage/icons/body.png";
import mass from "./../images/homepage/icons/bodymass.png";
import macro from "./../images/homepage/icons/macrocalculation.png";
import rmr from "./../images/homepage/icons/rmr.png";
import diet from "./../images/fitness/diet track.jpg";
import training from "./../images/fitness/online training lady.jpg";
import fitnessanddiet from "./../images/fitness/fitness n diet.jpg";
import workfitness from "./../images/fitness/workfitness.jpg";
import personaltraining from "./../images/fitness/personal trainer.jpg";

import shop1 from "./../images/shop/shop1.jpg";
import shop2 from "./../images/shop/shop2.webp";
import shop3 from "./../images/shop/shop3.jpg";
import shop4 from "./../images/shop/shop4.jpg";
import shop5 from "./../images/shop/shop5.jpg";
// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//testimonial images
import Sid from "./../images/testimonial/sid.png";
import Shymala from "./../images/testimonial/Shymala.png";
import Kundesha from "./../images/testimonial/kundesha.png";
import Abhijit from "./../images/testimonial/abhijit.png";

import {
  bmi,
  bmr,
  rmrcalculator,
  IdealWeightcalculator,
  leanbodymasscalculator,
  macrocalculator,
  bodyfatcalculator,
  calorieintakecalculator,
} from "../store/slices/tools";

import { postfitness } from "../store/slices/fitnesscenter";
import { WmEnquiry } from "../store/slices/enquiry";
import { TravelEnquiry } from "../store/slices/enquiry";
import { getTrendingfitness } from "../store/slices/fitnesscenter";

import Header from "./navbar";

const Fitness = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [query, setQuery] = useState("");
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState("");
  const [gender, setGender] = useState("");
  const [profession, setProfession] = useState("");
  const [healthConditions, setHealthConditions] = useState("");
  const [prefferedLanguage, setPrefferedLanguage] = useState("");
  const [result, setResult] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [trendingFitness, settrendingFitness] = useState([]);

  const [alertname2, setAlertname2] = useState("");
  const [alertemail2, setAlertemail2] = useState("");
  const [alertprofession2, setAlertprofession2] = useState("");
  const [alerthealthcondition2, setAlerthealthcondition2] = useState("");
  const [alertprefferedlanguage2, setAlertprefferedlanguage2] = useState("");
  const [alertmessage2, setAlertmessage2] = useState("");
  const [alertcontactnumber2, setAlertcontactnumber2] = useState("");
  const [alertage2, setAlertage2] = useState("");
  const [alertgender2, setalertGender2] = useState("");

  const [bmiresult, setBmiResult] = useState("");
  const [bmrresult, setBmrResult] = useState("");
  const [rmrresult, setRmrResult] = useState("");
  const [bodyfatresult, setBodyfatResult] = useState("");
  const [IdealWeightresult, setIdealWeightresult] = useState("");
  const [leanbodymassresult, setLeanbodymassresult] = useState("");
  const [macroresult, setMacroresult] = useState("");
  const [calorieintakeresult, setCalorieintakeresult] = useState("");

  // BMI / BMR / RMR / IdealWeight / Lean Body Mass / Body Fat / Calorie in take
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [age, setAge] = useState("");
  const [alertweight, setAlertWeight] = useState("");
  const [alertheight, setAlertHeight] = useState("");
  const [alertage, setAlertAge] = useState("");

  // Macro Calculator
  const [calories, setCalories] = useState("");
  const [alertcalories, setAlertCalories] = useState("");

  // Body Fat
  const [neck, setNeck] = useState("");
  const [waist, setWaist] = useState("");
  const [hip, setHip] = useState("");

  const [alertneck, setAlertNeck] = useState("");
  const [alertwaist, setAlertWaist] = useState("");
  const [alerthip, setAlertHip] = useState("");

  // Calorie in take
  const [targetWeight, setTargetWeight] = useState("");
  const [goal, setGoal] = useState("");
  const [activityLevel, setActivityLevel] = useState("");

  const [alerttargetWeight, setAlertTargetWeight] = useState("");
  const [alertgoal, setAlertGoal] = useState("");
  const [alertactivityLevel, setAlertActivityLevel] = useState("");

  // Fitness Enquire form

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [fitnessCenterName, setFitnessCenterName] = useState("");
  const [messages, setMessages] = useState("");

  const [alertemail, setAlertEmail] = useState("");
  const [alertname, setAlertName] = useState("");
  const [alertcontactNumber, setAlertContactNumber] = useState("");
  const [alertmessage, setAlertMessage] = useState("");
  const [alertaddress, setAlertAddress] = useState("");
  const [alertfitnessCenterName, setAlertFitnessCenterName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [alertcompanyName, setAlertCompanyName] = useState("");
  const [alertmessages, setAlertMessages] = useState("");
  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setGender(target.value);
    }
  };

  async function upload(e) {
    e.preventDefault();
    let enquiryFor = "companyOnboarding";
    let item = {
      name,
      email,
      companyName,
      message,
      contactNumber,
      enquiryFor,
    };
    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setCompanyName("");
        setMessage("");
        setContactNumber("");

        document.getElementById("corporateEnquiry").style.display = "none";
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }

  React.useEffect(() => {
    dispatch(getFitnessBlog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  const join = (e) => {
    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setAlertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setAlertEmail("Please enter a valid email");
    }

    const textname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (textname.test(name)) {
      setAlertName("");
    } else if (!textname.test(name) && name === "") {
      setAlertName("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName("");
    }

    const testname2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (testname2.test(companyName)) {
      setAlertCompanyName("");
    } else if (!testname2.test(companyName) && companyName === "") {
      setAlertCompanyName("Please enter your company name");
      e.preventDefault();
    } else {
      setAlertCompanyName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setAlertMessage("");
    } else if (!vMessage.test(message) && message === "") {
      setAlertMessage("Please enter your message");
      e.preventDefault();
    } else {
      setAlertMessage("");
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setAlertContactNumber("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber("Please enter your mobile number");
      e.preventDefault();
    } else {
      setAlertContactNumber("Valid mobile numbers only");
      e.preventDefault();
    }
  };
  const weightform = (e) => {
    e.preventDefault();

    let enquiryFor = "Fitness";
    let role = "client";

    let item = {
      name,
      email,
      profession,
      healthConditions,
      prefferedLanguage,
      message,
      contactNumber,
      enquiryFor,
      gender,
      age,
      role,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(WmEnquiry(item))
      .unwrap()
      .then(() => {
        setEmail("");
        setName("");
        setContactNumber("");
        setMessage("");
        setProfession("");
        setAge("");
        setHealthConditions("");
        setPrefferedLanguage("");
        setGender("");
        setAge("");
        alert("Enquiry submitted succesfully! We will get back to you.");
        document.getElementById("freeconsultations").style.display = "none";
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const freeconsultation = (e) => {
    const regxname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxname.test(name)) {
      setAlertname2("");
    } else if (!regxname.test(name) && name === "") {
      setAlertname2("Please enter your name");
      e.preventDefault();
    } else {
      setAlertname2("");
    }

    if (gender === "") {
      setalertGender2("Please select");
      e.preventDefault();
    } else {
      setalertGender2("");
    }

    const regxCondition = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxCondition.test(healthConditions)) {
      setAlerthealthcondition2("");
    } else if (
      !regxCondition.test(healthConditions) &&
      healthConditions === ""
    ) {
      setAlerthealthcondition2("Please select ");
      e.preventDefault();
    } else {
      setAlerthealthcondition2("");
    }

    const regxProfession = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxProfession.test(profession)) {
      setAlertprofession2("");
    } else if (!regxProfession.test(profession) && profession === "") {
      setAlertprofession2("Please select ");
      e.preventDefault();
    } else {
      setAlertprofession2("");
    }

    const regxLanguage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxLanguage.test(prefferedLanguage)) {
      setAlertprefferedlanguage2("");
    } else if (
      !regxLanguage.test(prefferedLanguage) &&
      prefferedLanguage === ""
    ) {
      setAlertprefferedlanguage2("Please select");
      e.preventDefault();
    } else {
      setAlertprefferedlanguage2("");
    }

    const regxemail =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regxemail.test(email)) {
      setAlertemail2("");
    } else if (!regxemail.test(email) && email === "") {
      setAlertemail2("Please enter the email");
      e.preventDefault();
    } else {
      setAlertemail2("Please enter a valid email");
      e.preventDefault();
    }

    const regxmobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regxmobile.test(contactNumber)) {
      setAlertcontactnumber2("");
    } else if (!regxmobile.test(contactNumber) && contactNumber === "") {
      setAlertcontactnumber2("Please enter the contact number");
      e.preventDefault();
    } else {
      setAlertcontactnumber2("Please enter a valid contact number");
      e.preventDefault();
    }

    const regxMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxMessage.test(message)) {
      setAlertmessage2("");
    } else if (!regxMessage.test(message) && message === "") {
      setAlertmessage2("Please enter the message");
      e.preventDefault();
    } else {
      setAlertmessage2("");
    }

    const regxage = /^(1[89]|[2-9]\d)$/;
    if (regxage.test(age)) {
      setAlertage2("");
    } else if (!regxage.test(age) && age === "") {
      setAlertage2("Please enter  your age");
      e.preventDefault();
    } else {
      setAlertage2("");
    }
  };

  const formclear = () => {
    setName("");
    setEmail("");
    setAge("");
    setProfession("");
    setHealthConditions("");
    setPrefferedLanguage("");
    setMessage("");
    setContactNumber("");

    setAlertname2("");
    setAlertemail2("");
    setAlertprofession2("");
    setAlerthealthcondition2("");
    setAlertprefferedlanguage2("");
    setAlertmessage2("");
    setAlertcontactnumber2("");
    setAlertage2("");
  };

  async function bmical(e) {
    document.getElementById("bmiresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bmi())
      .unwrap()
      .then((data) => {
        setBmiResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function bmrcal(e) {
    document.getElementById("bmrresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bmr())
      .unwrap()
      .then((data) => {
        setBmrResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function rmrcal(e) {
    document.getElementById("rmrresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(rmrcalculator())
      .unwrap()
      .then((data) => {
        setRmrResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function Idealweightcal(e) {
    document.getElementById("Idealweightresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(IdealWeightcalculator())
      .unwrap()
      .then((data) => {
        setIdealWeightresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function macrocal(e) {
    document.getElementById("macroresults").style.height = "max-content";
    e.preventDefault();
    let item = { calories };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(macrocalculator())
      .unwrap()
      .then((data) => {
        setMacroresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function leanweightcal(e) {
    document.getElementById("leanbodymassresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(leanbodymasscalculator())
      .unwrap()
      .then((data) => {
        setLeanbodymassresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function bodyfatcal(e) {
    document.getElementById("bodyfatresults").style.height = "max-content";
    e.preventDefault();
    let item = { weight, age, height, gender, neck, waist, hip };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bodyfatcalculator())
      .unwrap()
      .then((data) => {
        setBodyfatResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function calorieintakecal(e) {
    document.getElementById("calorieintakeresults").style.height =
      "max-content";
    e.preventDefault();
    let item = {
      weight,
      age,
      height,
      gender,
      targetWeight,
      goal,
      activityLevel,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(calorieintakecalculator())
      .unwrap()
      .then((data) => {
        setCalorieintakeresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  const save = (e) => {
    const number1 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number1.test(age)) {
      setAlertAge("");
    } else if (!number1.test(age) && age === "0") {
      setAlertAge("Invalid number");
      e.preventDefault();
    } else if (!number1.test(age) && age === "") {
      setAlertAge("Please enter");
      e.preventDefault();
    } else {
      setAlertAge("");
      // e.preventDefault();
    }

    const number2 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number2.test(height)) {
      setAlertHeight("");
    } else if (!number2.test(height) && height === "0") {
      setAlertHeight("Invalid number");
      e.preventDefault();
    } else if (!number2.test(height) && height === "") {
      setAlertHeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const number3 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number3.test(weight)) {
      setAlertWeight("");
    } else if (!number3.test(weight) && weight === "0") {
      setAlertWeight("Invalid number");
      e.preventDefault();
    } else if (!number3.test(weight) && weight === "") {
      setAlertWeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }
  };

  const savecalories = (e) => {
    const number4 = /^[1-3][0-9][0-9][0-9]?$|^4000$/;
    if (number4.test(calories)) {
      setAlertCalories("");
    } else if (!number4.test(calories) && calories === "0") {
      setAlertCalories("Invalid number");
      e.preventDefault();
    } else if (!number4.test(calories) && calories === "") {
      setAlertCalories("Please enter");
      e.preventDefault();
    } else {
      setAlertCalories("");
      // e.preventDefault();
    }
  };

  const cancel = () => {
    setBmiResult("0");
    setBmrResult("0");
    setRmrResult("0");
    setIdealWeightresult("0");
    setLeanbodymassresult("0");
    setMacroresult("0");
    setWeight("");
    setHeight("");
    setAge("");
    setCalories("");
    setNeck("");
    setWaist("");
    setHip("");
    setAlertNeck("");
    setAlertWaist("");
    setAlertHip("");
    setTargetWeight("");
    setGoal("");
    setActivityLevel("");
    setAlertTargetWeight("");
    setAlertGoal("");
    setAlertActivityLevel("");
    setAlertWeight("");
    setAlertHeight("");
    setAlertAge("");
    setAlertCalories("");
    document.getElementById("leanbodymassresults").style.height = "0px";
    document.getElementById("macroresults").style.height = "0px";
    document.getElementById("Idealweightresults").style.height = "0px";
    document.getElementById("rmrresults").style.height = "0px";
    document.getElementById("bmrresults").style.height = "0px";
    document.getElementById("bmiresults").style.height = "0px";
    document.getElementById("bodyfatresults").style.height = "0px";
    document.getElementById("calorieintakeresults").style.height = "0px";
  };

  const close = () => {
    setBmiResult("");
    setBmrResult("");
    setRmrResult("");
    setIdealWeightresult("");
    setLeanbodymassresult("");
    setMacroresult("");
    setWeight("");
    setHeight("");
    setAge("");
    setAlertWeight("");
    setAlertHeight("");
    setAlertAge("");
    setAlertCalories("");
    setCalories("");
    setNeck("");
    setWaist("");
    setHip("");
    setAlertNeck("");
    setAlertWaist("");
    setAlertHip("");
    setTargetWeight("");
    setGoal("");
    setActivityLevel("");
    setAlertTargetWeight("");
    setAlertGoal("");
    setAlertActivityLevel("");
    setEmail("");
    setName("");
    setContactNumber("");
    setMessage("");
    setAddress("");
    setFitnessCenterName("");
    setAlertEmail("");
    setAlertName("");
    setAlertContactNumber("");
    setAlertMessage("");
    setAlertAddress("");
    setAlertFitnessCenterName("");
    document.getElementById("leanbodymassresults").style.height = "0px";
    document.getElementById("macroresults").style.height = "0px";
    document.getElementById("Idealweightresults").style.height = "0px";
    document.getElementById("rmrresults").style.height = "0px";
    document.getElementById("bmrresults").style.height = "0px";
    document.getElementById("bmiresults").style.height = "0px";
    document.getElementById("calorieintakeresults").style.height = "0px";
    document.getElementById("bodyfatresults").style.height = "0px";
    // document.getElementById("enquire").style.height = "0px";
  };
  const clear = () => {
    setName("");
    setEmail("");
    setCompanyName("");
    setMessage("");
    setContactNumber("");
    setAlertName("");
    setAlertEmail("");
    setAlertCompanyName("");
    setAlertMessage("");
    setAlertContactNumber("");
  };
  const savebodyfat = (e) => {
    const number4 = /^[1-9][0-9]?$|^100$/;
    if (number4.test(neck)) {
      setAlertNeck("");
    } else if (!number4.test(neck) && neck === "0") {
      setAlertNeck("Invalid number");
      e.preventDefault();
    } else if (!number4.test(neck) && neck === "") {
      setAlertNeck("Please enter");
      e.preventDefault();
    } else {
      setAlertNeck("");
      // e.preventDefault();
    }

    const number5 = /^[1-9][0-9]?$|^100$/;
    if (number5.test(waist)) {
      setAlertWaist("");
    } else if (!number5.test(waist) && waist === "0") {
      setAlertWaist("Invalid number");
      e.preventDefault();
    } else if (!number5.test(waist) && waist === "") {
      setAlertWaist("Please enter");
      e.preventDefault();
    } else {
      setAlertWaist("");
      // e.preventDefault();
    }

    const number6 = /^[1-9][0-9]?$|^100$/;
    if (number6.test(hip)) {
      setAlertHip("");
    } else if (!number6.test(hip) && hip === "0") {
      setAlertHip("Invalid number");
      e.preventDefault();
    } else if (!number6.test(hip) && hip === "") {
      setAlertHip("Please enter ");
      e.preventDefault();
    } else {
      setAlertHip("");
      // e.preventDefault();
    }

    const number1 = /^[1-9][0-9]?$|^100$/;
    if (number1.test(age)) {
      setAlertAge("");
    } else if (!number1.test(age) && age === "0") {
      setAlertAge("Invalid");
      e.preventDefault();
    } else if (!number1.test(age) && age === "") {
      setAlertAge("Please enter your age");
      e.preventDefault();
    } else {
      setAlertAge("");
      // e.preventDefault();
    }

    const number2 = /^[1-9][0-9]?$|^100$/;
    if (number2.test(height)) {
      setAlertHeight("");
    } else if (!number2.test(height) && height === "0") {
      setAlertHeight("Invalid number");
      e.preventDefault();
    } else if (!number2.test(height) && height === "") {
      setAlertHeight("Please enter");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const number3 = /^[1-9][0-9]?$|^100$/;
    if (number3.test(weight)) {
      setAlertWeight("");
    } else if (!number3.test(weight) && weight === "0") {
      setAlertWeight("Invalid number");
      e.preventDefault();
    } else if (!number3.test(weight) && weight === "") {
      setAlertWeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }
  };

  const savecalorietake = (e) => {
    const number4 = /^[1-9][0-9]?$|^100$/;
    if (number4.test(targetWeight)) {
      setAlertTargetWeight("");
    } else if (!number4.test(targetWeight) && targetWeight === "0") {
      setAlertTargetWeight("Invalid number");
      e.preventDefault();
    } else if (!number4.test(targetWeight) && targetWeight === "") {
      setAlertTargetWeight("Please enter");
      e.preventDefault();
    } else {
      setAlertTargetWeight("");
      // e.preventDefault();
    }

    const text2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (text2.test(goal)) {
      setAlertGoal("");
    } else if (!text2.test(goal) && goal === "0") {
      setAlertGoal("Invalid number");
      e.preventDefault();
    } else if (!text2.test(goal) && goal === "") {
      setAlertGoal("Please enter");
      e.preventDefault();
    } else {
      setAlertGoal("");
      // e.preventDefault();
    }

    const text1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (text1.test(activityLevel)) {
      setAlertActivityLevel("");
    } else if (!text1.test(activityLevel) && activityLevel === "0") {
      setAlertActivityLevel("Invalid number");
      e.preventDefault();
    } else if (!text1.test(activityLevel) && activityLevel === "") {
      setAlertActivityLevel("Please enter");
      e.preventDefault();
    } else {
      setAlertActivityLevel("");
      // e.preventDefault();
    }

    const number1 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number1.test(age)) {
      setAlertAge("");
    } else if (!number1.test(age) && age === "0") {
      setAlertAge("Invalid number");
      e.preventDefault();
    } else if (!number1.test(age) && age === "") {
      setAlertAge("Please enter");
      e.preventDefault();
    } else {
      setAlertAge("");
      // e.preventDefault();
    }

    const number2 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number2.test(height)) {
      setAlertHeight("");
    } else if (!number2.test(height) && height === "0") {
      setAlertHeight("Invalid number");
      e.preventDefault();
    } else if (!number2.test(height) && height === "") {
      setAlertHeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const number3 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number3.test(weight)) {
      setAlertWeight("");
    } else if (!number3.test(weight) && weight === "0") {
      setAlertWeight("Invalid number");
      e.preventDefault();
    } else if (!number3.test(weight) && weight === "") {
      setAlertWeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  async function fitenqiure(e) {
    let enquiryFor = "fitnessCenter";
    let category = "Gym Franchise";
    const city = address;
    const message = messages + ". Wants to invest " + fitnessCenterName;
    e.preventDefault();
    let item = {
      email,
      name,
      contactNumber,
      message,
      city,
      category,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        document.getElementById("enquire").style.display = "none";
        setEmail("");
        setName("");
        setContactNumber("");
        setMessage("");
        setAddress("");
        setFitnessCenterName("");
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }

  async function fitenqiury(e) {
    let enquiryFor = "fitnessCenter";

    e.preventDefault();
    let item = {
      email,
      name,
      contactNumber,
      message,
      address,
      fitnessCenterName,
      enquiryFor,
    };

    dispatch(postfitness(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        document.getElementById("gymenquire").style.display = "none";
        setEmail("");
        setName("");
        setContactNumber("");
        setMessage("");
        setAddress("");
        setFitnessCenterName("");
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }

  const fitenq = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setAlertName("");
    } else if (!vName.test(name) && name === "") {
      setAlertName("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName("Please enter a valid name");
      e.preventDefault();
    }

    const fitname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fitname.test(fitnessCenterName)) {
      setAlertFitnessCenterName("");
    } else if (!fitname.test(fitnessCenterName) && fitnessCenterName === "") {
      setAlertFitnessCenterName(
        "Please enter how much you are looking to invest"
      );
      e.preventDefault();
    } else {
      setAlertFitnessCenterName("");
      // e.preventDefault();
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setAlertContactNumber("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber("Please enter your mobile number");
    } else {
      setAlertContactNumber("Valid mobile numbers only");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setAlertEmail("Please enter your email");
    } else {
      setAlertEmail("Please enter a valid email");
    }

    const vMessage = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage.test(messages)) {
      setAlertMessages("");
    } else if (!vMessage.test(messages) && messages === "") {
      setAlertMessages("Please enter your message");
      e.preventDefault();
    } else {
      setAlertMessages("");
      e.preventDefault();
    }

    const textaddress = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (textaddress.test(address)) {
      setAlertAddress("");
    } else if (!textaddress.test(address) && address === "") {
      setAlertAddress("Please enter the address");
      e.preventDefault();
    } else {
      setAlertAddress("");
      e.preventDefault();
    }
  };

  const fitenqy = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setAlertName("");
    } else if (!vName.test(name) && name === "") {
      setAlertName("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName("Please enter a valid name");
      e.preventDefault();
    }

    const fitname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fitname.test(fitnessCenterName)) {
      setAlertFitnessCenterName("");
    } else if (!fitname.test(fitnessCenterName) && fitnessCenterName === "") {
      setAlertFitnessCenterName("Please enter your business name");
      e.preventDefault();
    } else {
      setAlertFitnessCenterName("");
      // e.preventDefault();
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setAlertContactNumber("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber("Please enter your mobile number");
    } else {
      setAlertContactNumber("Valid mobile numbers only");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setAlertEmail("Please enter your email");
    } else {
      setAlertEmail("Please enter a valid email");
    }

    const vMessage = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage.test(message)) {
      setAlertMessage("");
    } else if (!vMessage.test(message) && message === "") {
      setAlertMessage("Please enter your message");
      e.preventDefault();
    } else {
      setAlertMessage("");
      e.preventDefault();
    }

    const textaddress = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (textaddress.test(address)) {
      setAlertAddress("");
    } else if (!textaddress.test(address) && address === "") {
      setAlertAddress("Please enter the address");
      e.preventDefault();
    } else {
      setAlertAddress("");
      e.preventDefault();
    }
  };

  React.useEffect(() => {
    dispatch(getTrendingfitness())
      .unwrap()
      .then((data) => {
        settrendingFitness(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  const myDatas = {
    fitnessServiceName: query,
    cityName: city,
    locationName: locality,
  };

  async function searchFitnessCenters(e) {
    e.preventDefault();
    navigate("/Fitness-center/", { state: myDatas });
  }

  return (
    <div id="__next">
      <Header />

      <section>
        <div className="carousel_cover position-relative">
          <Carousel>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner5}
                alt=""
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner6}
                alt=""
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner7}
                alt=""
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <div className="col-lg-12 d-flex justify-content-center section-title">
        <h3>Fitness Centers</h3>
      </div>

      <h3 className="text-center pb-2">
        Avail Gym/Fitness Center Membership At Best Rates
      </h3>

      <section className="cover2 mb-5 ">
        <h3 className="text-center col-white">Search Gym/Fitness Center</h3>
        <form onSubmit={searchFitnessCenters}>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <input
                    type="text"
                    className="form-control"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="City"
                    style={{
                      lineHeight: "2.6",
                    }}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <input
                    type="text"
                    value={locality}
                    onChange={(e) => setLocality(e.target.value)}
                    className="form-control"
                    placeholder="Location"
                    style={{
                      lineHeight: "2.6",
                    }}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <select
                    name="health"
                    id="fit"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="select-opt"
                    style={{
                      lineHeight: "2.6",
                    }}
                  >
                    <option value="">Select Fitness Format</option>
                    <option>Gyms</option>
                    <option>Zumba</option>
                    <option>Cross Functional Training</option>
                    <option>Yoga</option>
                    <option>Fitness Studio</option>
                    <option>MMA</option>
                    <option>Kick Boxing</option>
                    <option>Dance</option>
                    <option>Pilates</option>
                  </select>
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 d-flex justify-content-center">
                  <button
                    className="btn btn-warning col-white inside-btn"
                    style={{ lineHeight: "2", border: "none" }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      <section className="row pt-3 pb-3 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <Carousel>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100  container-scroll-size"
                    src={diet}
                    alt=""
                  />
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                  <img
                    className="d-block w-100   container-scroll-size"
                    src={training}
                    alt=""
                  />
                </Carousel.Item>

                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100  container-scroll-size"
                    src={fitnessanddiet}
                    alt=""
                  />
                </Carousel.Item>
              </Carousel>
            </div>

            <div className="col-md-6">
              <h3 className="text-underline text-center">
                Why buy from Healthonify
              </h3>
              <br />
              <h4 className="text-center">
                Discover Best Brands @ Best Offers
              </h4>
              <div className="row">
                <div className="col-md-2" />
                <div className="col-md-8">
                  <p style={{ fontSize: "17px" }}>
                    &bull; Discover best brands at best offers
                    <br /> &bull; Great offers and best deals
                    <br />
                    &bull; Benefits of Healthonify app
                    <br />
                    &bull; No cost EMI
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container pt-5 pb-3">
        <div className="col-lg-12">
          <p className="wel-text text-center pb-2">
            Trending Fitness Centers Near You
          </p>
        </div>
        <div className="container">
          <Slider {...settings}>
            {trendingFitness.map((trendingFitnessVal, index) => (
              <div className="slide slick-space">
                <div className="card_slicks">
                  <img
                    src={trendingFitnessVal.fitnessCenterId.logo}
                    alt=""
                    className="lie-img-slide"
                  />
                  <h4
                    className="card-title slick_text"
                    style={{
                      fontSize: "15px",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    {trendingFitnessVal.fitnessCenterId.fcName}
                  </h4>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className="section-card pt-5 pb-3">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h2>Work Fitness</h2>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <p style={{ fontWeight: "500" }}>
                &bull; Let us make Fitness a part of your Company culture
                <br />
                &bull; Let us motivate all employees to be fit and Healthy
                <br />
                &bull; Let us create fitness Groups within the company and
                create challenges and rewards and motivate maximum participation
                <br />
                &bull; Let us Educate, engage, motivate and monitor all
                employees in the Fitness revolution
              </p>

              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#corporateEnquiry"
                className="btn btn-warning btn-w-full-sm col-white adjust-btn mb-2 "
              >
                Explore
              </button>
              {/* Link this to corporate enquiry form */}
            </div>

            <div className="col-md-6">
              <img src={workfitness} style={{ borderRadius: "10px" }} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section-card pt-3 pb-3">
        <h2 className="text-center pt-5 pb-5">Online Fitness Training </h2>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={training} alt="" style={{ borderRadius: "10px" }} />
            </div>

            <div className="col-md-6">
              <p style={{ fontWeight: "500" }}>
                &bull; Healthonify’s Online Fitness Training program
                <br />
                &bull; Qualified and Certified Trainers
                <br />
                &bull; Achieve your Fitness goals training with our experts
                <br />
                &bull; Enjoy the benefit of Group Classes
                <br />
                &bull; Consult with our fitness experts and get required
                guidance
              </p>

              <button
                data-bs-toggle="modal"
                data-bs-target="#freeconsultations"
                className="btn btn-warning  btn-w-full-sm  col-white adjust-btn"
              >
                Explore
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="section-card pt-3 pb-3">
        <h2 className="text-center pt-5 pb-5">Personal Training </h2>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <p style={{ fontWeight: "500" }}>
                &bull; Healthonify’s Online Personal Training
                <br />
                &bull; ACSM / ACE certified Personal Trainers <br />
                &bull; Result oriented trainers
                <br />
                &bull; Transformations – transformation records and case studies
                <br />
                &bull; Specialization in Online Personal training
              </p>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#freeconsultations"
                className="btn btn-warning col-white btn-w-full-sm   adjust-btn mb-2"
              >
                Search
              </button>
            </div>

            <div className="col-md-6">
              <img
                src={personaltraining}
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="freeconsultations"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Online Training Consultation</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={formclear}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={weightform}>
                <p className="bmi_text">
                  Gender:
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="male"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Male
                  </span>
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="Female"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Female
                  </span>
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="Other"
                      value="Other"
                      checked={gender === "Other"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Other
                  </span>
                </p>
                <p className="alert-message">{alertgender2}</p>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      <p className="alert-message">{alertname2}</p>
                      <br />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        placeholder="Enter your Age"
                      />
                      <p className="alert-message">{alertage2}</p>

                      <br />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Enter your contact number"
                      />
                      <p className="alert-message">{alertcontactnumber2}</p>

                      <br />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="alert-message">{alertemail2}</p>
                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <select
                        name="health"
                        id="health"
                        className="select-mod"
                        value={healthConditions}
                        onChange={(e) => setHealthConditions(e.target.value)}
                      >
                        <option value="" className="">
                          --Select health condition--
                        </option>
                        <option value="Thyriod">Thyriod</option>
                        <option value="Acidity">Acidity</option>
                        <option value="Cholesterol">Cholesterol</option>
                        <option value="Kneepain">Knee Pain</option>
                        <option value="Backpain">Back Pain</option>
                        <option value="Diabetes">Diabetes(T2)</option>
                        <option value="BP">BP</option>
                        <option value="Prediabetes">Pre Diabetes</option>
                        <option value=" pcod">PCOD</option>
                        <option value="None">None</option>
                        <option value="Other">Other</option>
                      </select>
                      <p className="alert-message">{alerthealthcondition2}</p>
                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <select
                        name="language"
                        id="profession"
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        className="select-mod"
                      >
                        <option value="" className="">
                          --Select your profession--
                        </option>
                        <option value="Homemaker">Homemaker</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Business">Business</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Service">Service</option>
                        <option value="Retired">Retired</option>
                        <option value="Student">Student</option>
                        <option value="Unemployed">Unemployed</option>
                        <option value="Others">Others</option>
                      </select>
                      <p className="alert-message">{alertprofession2}</p>

                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 pb-1">
                      <select
                        name="language"
                        id="language"
                        value={prefferedLanguage}
                        onChange={(e) => setPrefferedLanguage(e.target.value)}
                        className="select-mod"
                      >
                        <option value="" className="">
                          --Select your language--
                        </option>
                        <option value="hindi">Hindi</option>
                        <option value="english">English</option>
                        <option value="kannada">Kannada</option>
                        <option value="Marathi">Marathi</option>
                        <option value="Telugu">Telugu</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Malayalam">Malayalam</option>
                        <option value="Punjabi">Punjabi</option>
                        <option value="Gujarati">Gujarati</option>
                        <option value="Bengali">Bengali</option>
                        <option value="Urdu">Urdu</option>
                      </select>
                      <p className="alert-message">{alertprefferedlanguage2}</p>

                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <textarea
                        col="50"
                        row="50"
                        type="text"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Describe your requirement"
                      ></textarea>
                      <p className="alert-message">{alertmessage2}</p>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={freeconsultation}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={formclear}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
      <section className="container pt-3 pb-3">
        <div className="col-lg-12">
          <p className="wel-text pb-2 text-center">Blogs</p>
        </div>
        <div className="container">
          <Slider {...settings}>
            <div className="d-flex justify-content-center">
              <img src={banner1} alt="live-well" className="lie-img-slide" />
            </div>

            <div className="d-flex justify-content-center">
              <img src={banner2} alt="live-well" className="lie-img-slide" />
            </div>

            <div className="d-flex justify-content-center">
              <img src={banner3} alt="live-well" className="lie-img-slide" />
            </div>

            <div className="d-flex justify-content-center">
              <img src={banner4} alt="live-well" className="lie-img-slide" />
            </div>

            <div className="d-flex justify-content-center">
              <img src={banner1} alt="live-well" className="lie-img-slide" />
            </div>

            <div className="d-flex justify-content-center">
              <img src={banner2} alt="live-well" className="lie-img-slide" />
            </div>
          </Slider>
        </div>
      </section>

      <section className="section-card pt-3 pb-3">
        <h2 className="text-center pt-4 pb-4">
          Get Healthy With Our Healthonify App
        </h2>

        <div className="row align-items-center">
          <div className="col-md-12">
            <img
              src={Healthy}
              alt="banner-sigin"
              style={{ borderRadius: "10px" }}
            />
          </div>
        </div>
      </section>

      <section className="section-card pt-3 pb-3">
        <h2 className="text-center pt-5 pb-5">Become Our Fitness Partner</h2>

        <div className="row align-items-center">
          <div className="col-md-1" />
          <div className="col-md-10">
            <p>
              Enroll your Gym for Daily Fitness sessions for our Time share
              partner
              <br />
              Process -- Please share <br />
              <b>
                &bull; Your Gym Name and Address/ Google location/ PAN Number/
                Bank Account Details/ Single point of Contact (SPOC) from your
                end
              </b>
            </p>
            <p>
              <b>&bull; Become an Healthonify Franchise </b>
              <br />
              Healthonify – We are different. We do not offer standard gym
              models to the Franchisee. We discuss with the franchisee,
              understand their mindset and experience, look into their Budget
              and we decide and suggest the best business module to make the
              best and most profitable Fitness Center.
            </p>
            <p>
              <b>&bull; Rebrand your gym to Healthonify</b>
              <br />
              You can rebrand your existing gym to Healthonify brand and reap
              the benefits of Healthonify’s Gym Franchise at a very special
              rate.
            </p>
          </div>
        </div>
      </section>
      <section className="section-card pt-3 pb-3">
        <img src={home3} alt="banner" className="phy_img_carousel" />
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-warning col-white"
            data-bs-toggle="modal"
            data-bs-target="#enquire"
            style={{
              color: "box",
              lineHeight: "2 !important",
              width: "200px",
              height: "50px",
              fontSize: "19px",
            }}
          >
            Enquire
          </button>
        </div>
        <div className="position-relative text-top mt-5">
          <p className="text-center">List your business on Healthonify</p>
          <br />
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10">
              <p style={{ fontSize: "16px" }}>
                Onboard your gym on Healthonify Website and grow your business
                <br />
                Process :<br />
                &bull; Register your gym on Healthonify Website
                <br />
                Details required
                <br />
                &bull; Gym name, address, Google location, Gym photos, Gym
                facilities, packages which you wish to promote <br />
                &bull; Promotion of your gym on healthonify website
                <br />
                &bull; Usage of Healthonify App by your members
                <br />
                &bull; Enjoy commission on sales of other services
              </p>
            </div>
          </div>
          <img src={banner6} alt="banner" className="phy_img_carousel mt-5" />
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-warning col-white"
            data-bs-toggle="modal"
            data-bs-target="#gymenquire"
            style={{
              color: "box",
              lineHeight: "2 !important",
              width: "200px",
              height: "50px",
              fontSize: "19px",
            }}
          >
            Enquire
          </button>
        </div>
      </section>

      <div className="container">
        <section className="section-card pt-3 pb-3">
          <h2 className="text-center pt-3 pb-3">Why Healthonify</h2>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-xs-6 pt-2 pb-2">
              <div className="box-white-bg">
                <div className="d-flex justify-content-center">
                  <img src={coach} className="icon-f" alt="" />
                </div>

                <p className="text-center text-mark-2">
                  Certified personal trainer
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-xs-6 pt-2 pb-2">
              <div className="box-white-bg">
                <div className="d-flex justify-content-center">
                  <img src={lab} className="icon-f" alt="" />
                </div>

                <p className="text-center text-mark-2">Scientific approach</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-xs-6 pt-2 pb-2">
              <div className="box-white-bg">
                <div className="d-flex justify-content-center">
                  <img src={customized} className="icon-f" alt="" />
                </div>

                <p className="text-center text-mark-2">
                  Customized training plans
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-xs-6 pt-2 pb-2">
              <div className="box-white-bg">
                <div className="d-flex justify-content-center">
                  <img src={appease} className="icon-f" alt="" />
                </div>

                <p className="text-center text-mark-2">App for ease</p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-card pt-3 pb-3">
          <h2 className="text-center pt-3 pb-3">Fitness Tools</h2>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-xs-6 pt-2 pb-2">
              <div
                className="tools-section box-white-bg"
                data-bs-toggle="modal"
                data-bs-target="#myModalBMI"
              >
                <div className="d-flex justify-content-center">
                  <img src={bmis} className="icon-f" alt="" />
                </div>
                <p className="text-mark-2 col-white text-center">BMI</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 pt-2 pb-2">
              <div
                className="tools-section box-white-bg"
                data-bs-toggle="modal"
                data-bs-target="#myModalBMR"
              >
                <div className="d-flex justify-content-center">
                  <img src={bmrs} className="icon-f" alt="" />
                </div>
                <p className="text-mark-2 col-white text-center">BMR</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 pt-2 pb-2">
              <div
                className="tools-section box-white-bg"
                data-bs-toggle="modal"
                data-bs-target="#myModalcalorieintake"
              >
                <div className="d-flex justify-content-center">
                  <img src={active} className="icon-f" alt="" />
                </div>
                <p className="text-mark-2 col-white text-center">
                  Calorie Intake
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 pt-2 pb-2">
              <div
                className="tools-section box-white-bg"
                data-bs-toggle="modal"
                data-bs-target="#myModalIdealweight"
              >
                <div className="d-flex justify-content-center">
                  <img src={Ideal} className="icon-f" alt="" />
                </div>
                <p className="text-mark-2 col-white text-center">
                  Ideal weight
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 pt-2 pb-2">
              <div
                className="tools-section box-white-bg"
                data-bs-toggle="modal"
                data-bs-target="#myModalBodyfat"
              >
                <div className="d-flex justify-content-center">
                  <img src={fat} className="icon-f" alt="" />
                </div>
                <p className="text-mark-2 col-white text-center">Body fat</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 pt-2 pb-2">
              <div
                className="tools-section box-white-bg"
                data-bs-toggle="modal"
                data-bs-target="#myModalleanbody"
              >
                <div className="d-flex justify-content-center">
                  <img src={mass} className="icon-f" alt="" />
                </div>
                <p className="text-mark-2 col-white text-center">
                  Lean body mass
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 pt-2 pb-2">
              <div
                className="tools-section box-white-bg"
                data-bs-toggle="modal"
                data-bs-target="#myModalmacro"
              >
                <div className="d-flex justify-content-center">
                  <img src={macro} className="icon-f" alt="" />
                </div>
                <p className="text-mark-2 col-white text-center">
                  Macro calculator
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 pt-2 pb-2">
              <div
                className="tools-section box-white-bg"
                data-bs-toggle="modal"
                data-bs-target="#myModalRMR"
              >
                <div className="d-flex justify-content-center">
                  <img src={rmr} className="icon-f" alt="" />
                </div>
                <p className="text-mark-2 col-white text-center">RMR</p>
              </div>
            </div>
          </div>
        </section>
        <section className="livewell container pt-2 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Blog</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              {blogs.map((blogsVal, index) => (
                <div className="d-flex justify-content-center">
                  <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                    <div
                      className="col-12"
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      align="center"
                    >
                      <img
                        src={blogsVal.mediaLink}
                        alt=""
                        className="lie-img-slide"
                      />
                      <p>{blogsVal.blogTitle}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </section>

        <section className="section-card pt-4 pb-3">
          <section className="container pt-5 pb-5">
            <div className="col-lg-12">
              <p className="wel-text  text-center pb-2">Testimonial</p>
            </div>
            <div className="container">
              <Slider {...settings}>
                <div className="d-flex justify-content-center">
                  <div className="single-features-item bg-orange-card fix_space">
                    <div className="circle-profile d-flex justify-content-center">
                      <img src={Sid} alt="" />
                    </div>
                    <h4 className="text-center">Sid</h4>

                    <p className="col-white">
                      I was a regular gymmer but from past 6 months I skipped my
                      workouts due to some knee problem. I downloaded the
                      healthonify app, had a free consultation with the Fitness
                      expert and the Physiotherapist who have really given me
                      the confidence to get in shape. Following the fitness
                      section of the app and slowly getting into shape
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="single-features-item bg-orange-card fix_space">
                    <div className="circle-profile d-flex justify-content-center">
                      <img src={Shymala} alt="" />
                    </div>
                    <h4 className="text-center">Shymala</h4>

                    <p className="col-white">
                      Healthonify App has all the required tools and information
                      to keep one fit and Healthy. I follow their free workout
                      plans, I make my own workout plan from the Fitness library
                      available on the app, I also try and make my healthy diet
                      plan, track calories burn, consult experts whenever
                      needed…What more do I need?
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="single-features-item bg-orange-card fix_space">
                    <div className="circle-profile d-flex justify-content-center">
                      <img src={Kundesha} alt="" />
                    </div>
                    <h4 className="text-center">Kundesha</h4>

                    <p className="col-white">
                      There are so many things on the Healthonify app, I being a
                      fitness freak, I follow the fitness and diet sections very
                      closely. The trackers are very handy, the fitness tools,
                      the exercise library, the food data base, the challenges
                      and the community section and not last but the least, the
                      ease of using the App, I love it.
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="single-features-item bg-orange-card fix_space">
                    <div className="circle-profile d-flex justify-content-center">
                      <img src={Abhijit} alt="" />
                    </div>
                    <h4 className="text-center">Abhijit</h4>

                    <p className="col-white">
                      I have so many fitness related things on my mobile phone,
                      but do not feel like using it as I see no motivation and
                      inclination. I downloaded the Healthonify App and I am
                      really happy to use it as it us user friendly, with very
                      interesting sections. I thought I was very fit; I took the
                      Health Risk assessment test followed by a Health coach
                      consultation and Doctor consultation. Based on their
                      advice I am aligning myself
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </section>
        </section>

        <section className="section-card">
          <section className="container pt-5 pb-5">
            <div className="col-lg-12">
              <p className="wel-text  text-center pb-2">Shop</p>
            </div>
            <div className="container">
              <Slider {...settings}>
                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop1} alt="" className="lie-img-slide" />
                  </div>
                </Link>
                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop2} alt="" className="lie-img-slide" />
                  </div>
                </Link>

                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop3} alt="" className="lie-img-slide" />
                  </div>
                </Link>

                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop4} alt="" className="lie-img-slide" />
                  </div>
                </Link>

                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop5} alt="" className="lie-img-slide" />
                  </div>
                </Link>
              </Slider>
            </div>
          </section>
        </section>
      </div>

      <Footer />

      {/*calculator modal*/}

      <div className="modal fade" id="myModalBMI" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">BMI Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bmical}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div className="result-bg" id="bmiresults">
                  <div className="d-flex justify-content-between">
                    <p>BMI :</p>
                    <span>{bmiresult.bmi}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Suggestion:</p>
                    <span>{bmiresult.category}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="myModalBMR" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents modal-resize">
            <div className="modal-header">
              <h4 className="modal-title">BMR Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bmrcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div
                  className="result-bg d-flex justify-content-between"
                  id="bmrresults"
                >
                  <p>BMR :</p>
                  <span>{bmrresult.bmr}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="myModalRMR" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center "
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">RMR Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={rmrcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="aalert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="aalert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="aalert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>

                <div className="result-bg" id="rmrresults">
                  <div className="d-flex justify-content-between">
                    <p>RMR :</p>
                    <span>{rmrresult.rmr}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalIdealweight"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Ideal Weight Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={Idealweightcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>

                <div className="result-bg" id="Idealweightresults">
                  <div className="d-flex justify-content-between">
                    <p>Ideal Weight :</p>
                    <span>{IdealWeightresult.idealWeight}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalleanbody"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Lean Body Mass</h4>
              <button
                type="button"
                className="btn btn-close"
                onClick={close}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={leanweightcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>

                <div className="result-bg" id="leanbodymassresults">
                  <div className="d-flex justify-content-between">
                    <p>Lean Body Mass :</p>
                    <span>{leanbodymassresult.lbm}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="myModalmacro" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Macro Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                onClick={close}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={macrocal}>
                <div className="row">
                  <div className="col-md-2 col-4">Calories:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={calories}
                      onChange={(e) => setCalories(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter the calories"
                      min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertcalories}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={savecalories}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>

                <div className="result-bg" id="macroresults">
                  <div className="d-flex justify-content-between">
                    <p>Protein In Grams :</p>
                    <span>{macroresult.proteinInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Carb In Grams :</p>
                    <span>{macroresult.carbInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Fat In Grams :</p>
                    <span>{macroresult.fatInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Fiber In Grams:</p>
                    <span>{macroresult.fiberInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Total Calories :</p>
                    <span>{macroresult.totalCalories}</span>{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalBodyfat"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Body Fat </h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bodyfatcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="female"
                        checked={gender === "female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>

                  <div className="col-md-2 col-4">Neck:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={neck}
                      onChange={(e) => setNeck(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your neck"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertneck}</p>
                  </div>

                  <div className="col-md-2 col-4">Waist:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={waist}
                      onChange={(e) => setWaist(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your waist"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertwaist}</p>
                  </div>

                  <div className="col-md-2 col-4">Hip:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={hip}
                      onChange={(e) => setHip(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your hip"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alerthip}</p>
                  </div>
                </div>

                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={savebodyfat}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div className="result-bg" id="bodyfatresults">
                  <div className="d-flex justify-content-between">
                    <p>BFP :</p>
                    <span>{bodyfatresult.bfp}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalcalorieintake"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Calorie In Take </h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={calorieintakecal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="female"
                        checked={gender === "female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>

                  <div className="col-md-2 col-4">Target Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={targetWeight}
                      onChange={(e) => setTargetWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your neck"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alerttargetWeight}</p>
                  </div>

                  <div className="col-md-5 col-5" style={{ textAlign: "left" }}>
                    Select Goal:
                  </div>
                  <div
                    className="col-md-7 col-7 pt-2"
                    style={{ padding: "1%" }}
                  >
                    <div className="d-flex justify-content-start">
                      <select
                        name="fitness"
                        id="select"
                        value={goal}
                        onChange={(e) => setGoal(e.target.value)}
                        className="form-control select-width"
                        style={{ border: "1px solid black" }}
                      >
                        <option value="">Select</option>
                        <option value="loseWeight">Lose Weight</option>
                        <option value="gainWeight">Gain Weight</option>
                        <option value="maintainWeight">Maintain Weight</option>
                      </select>
                    </div>
                    <p className="alert-message" style={{ textAlign: "left" }}>
                      {alertgoal}
                    </p>
                  </div>

                  <div className="col-md-5 col-5" style={{ textAlign: "left" }}>
                    Select Activity Level:
                  </div>
                  <div
                    className="col-md-7 col-7 pt-2"
                    style={{ padding: "1%" }}
                  >
                    <div className="d-flex justify-content-start">
                      <select
                        name="fitness"
                        id="select"
                        value={activityLevel}
                        onChange={(e) => setActivityLevel(e.target.value)}
                        className="form-control select-width"
                        style={{ border: "1px solid black" }}
                      >
                        <option value="">Select</option>
                        <option value="sedentary">Sedentary</option>
                        <option value="lightlyActive">Lightly Active</option>
                        <option value="moderatelyActive">
                          Moderately Active
                        </option>
                        <option value="veryActive">Very Active</option>
                        <option value="extraActive">Extra Active</option>
                      </select>
                    </div>
                    <p className="alert-message" style={{ textAlign: "left" }}>
                      {alertactivityLevel}
                    </p>
                  </div>
                </div>

                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={savecalorietake}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div className="result-bg" id="calorieintakeresults">
                  <div className="d-flex justify-content-between">
                    <p>Current calorie per day :</p>
                    <span>{calorieintakeresult.currentCaloriesPerDay}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Calories goal per day :</p>
                    <span>{calorieintakeresult.caloriesGoalPerDay}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Current weight :</p>
                    <span>{calorieintakeresult.currentWeight}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Target weight :</p>
                    <span>{calorieintakeresult.targetWeight}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Goal :</p>
                    <span>{calorieintakeresult.goal}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Activity level :</p>
                    <span>{calorieintakeresult.activityLevel}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
      <div className="modal fade" id="gymenquire" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Business Enquiry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={fitenqiury}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      <p className="alert-message">{alertname}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter the email"
                      />
                      <p className="alert-message">{alertemail}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Enter the address"
                      />
                      <p className="alert-message">{alertaddress}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={fitnessCenterName}
                        onChange={(e) => setFitnessCenterName(e.target.value)}
                        placeholder="Name of your business entity"
                      />
                      <p className="alert-message">{alertfitnessCenterName}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Enter the contact number"
                      />
                      <p className="alert-message">{alertcontactNumber}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        type="text"
                        row="50"
                        col="50"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter the message"
                      ></textarea>
                      <p className="alert-message">{alertmessage}</p>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={fitenqy}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={close}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
      <div className="modal fade" id="enquire" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Franchise Enquiry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={fitenqiure}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      <p className="alert-message">{alertname}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter the email"
                      />
                      <p className="alert-message">{alertemail}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Enter the address"
                      />
                      <p className="alert-message">{alertaddress}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={fitnessCenterName}
                        onChange={(e) => setFitnessCenterName(e.target.value)}
                        placeholder="How much are you looking to invest"
                      />
                      <p className="alert-message">{alertfitnessCenterName}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Enter the contact number"
                      />
                      <p className="alert-message">{alertcontactNumber}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        type="text"
                        row="50"
                        col="50"
                        className="form-control"
                        value={messages}
                        onChange={(e) => setMessages(e.target.value)}
                        placeholder="Enter the message"
                      ></textarea>
                      <p className="alert-message">{alertmessages}</p>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={fitenq}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={close}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
      <div
        className="modal fade"
        id="corporateEnquiry"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Corporate Enquiry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={clear}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={upload}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      <p className="alert-message">{alertname}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                      />
                      <p className="alert-message">{alertemail}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Enter your company name"
                      />
                      <p className="alert-message">{alertcompanyName}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Enter your contact number"
                      />
                      <p className="alert-message">{alertcontactNumber}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        type="text"
                        row="50"
                        col="50"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter message"
                      ></textarea>
                      <p className="alert-message">{alertmessage}</p>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={join}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={clear}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
    </div>
  );
};

export default Fitness;
