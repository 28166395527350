import React, { useState } from "react";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import shop from "./../images/blog/shop.jpg";
import target from "./../images/blog/target.gif";
import calculator from "./../images/blog/calculator.gif";
import balance from "./../images/blog/balance.gif";
import encryption from "./../images/blog/encryption.gif";
import weights from "./../images/blog/weight.gif";
import banner2 from "./../images/weight-loss/banner2.png";
import banner3 from "./../images/weight-loss/banner22.png";
import banner4 from "./../images/weight-loss/banner4.png";
import banner5 from "./../images/weight-loss/banner5.png";
import health from "./../images/weight-loss/Healthonify Technology-01.png";
import aciditymanagement from "./../images/weight-loss/icons/wespecial/aciditymanagement.png";
import detox from "./../images/weight-loss/icons/wespecial/detox.png";
import diabetes from "./../images/weight-loss/icons/wespecial/diabetes.png";
import dietconsultation from "./../images/weight-loss/icons/wespecial/dietconsultation.png";
import natal from "./../images/weight-loss/icons/wespecial/natal.png";
import pcod from "./../images/weight-loss/icons/wespecial/pcod.png";
import specialtratment from "./../images/weight-loss/icons/wespecial/specialtratment.png";
import thyroid from "./../images/weight-loss/icons/wespecial/thyroid.png";
import weightloss from "./../images/weight-loss/icons/wespecial/weightloss.png";
import weightmanagement from "./../images/weight-loss/icons/wespecial/weightmanagement.png";
import water from "./../images/weight-loss/icons/water.png";
import macro from "./../images/weight-loss/icons/macro.png";
import report from "./../images/weight-loss/icons/report.png";
import calorie from "./../images/weight-loss/icons/calories.png";
import device from "./../images/weight-loss/icons/device.png";
import measuring from "./../images/weight-loss/icons/measuring.png";
import monitoring from "./../images/weight-loss/icons/monitoring.png";
import shop1 from "./../images/shop/shop1.jpg";
import shop2 from "./../images/shop/shop2.webp";
import shop3 from "./../images/shop/shop3.jpg";
import shop4 from "./../images/shop/shop4.jpg";
import shop5 from "./../images/shop/shop5.jpg";

import bodyfat from "./../images/weight-loss/icons/bodyfat.png";
import ideal from "./../images/weight-loss/icons/ideal.png";

import { Link } from "react-router-dom";

import Header from "./navbar";

import weightimg from "./../images/weight-loss/icons/weightloss.png";
import grouth from "./../images/weight-loss/icons/successrate.png";
import quality from "./../images/weight-loss/icons/expert.png";
import plan from "./../images/weight-loss/icons/customplans.png";

import bmiicon from "./../images/weight-loss/icons/BMI.png";
import bmricon from "./../images/weight-loss/icons/BMR.png";

import Slider from "react-slick";
import { useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { WmEnquiry } from "../store/slices/enquiry";
import { getWmblog } from "../store/slices/blog";

//testimonial images
import Prashant from "./../images/testimonial/Prashant.png";
import Ankit from "./../images/testimonial/ankit.png";
import Vijaydeep from "./../images/testimonial/Vijaydeep.png";
import Deepak from "./../images/testimonial/Deepak.png";

import {
  bmi,
  bmr,
  IdealWeightcalculator,
  macrocalculator,
  bodyfatcalculator,
} from "../store/slices/tools";

const Weight = () => {
  const dispatch = useDispatch();

  const [blogs, setBlogs] = useState([]);

  const [gender, setGender] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [age, setAge] = useState("");
  const [result, setResult] = useState("");

  // form Validatoin UseSate(){

  let role = "client";
  const [goal, setGoal] = useState("");
  const [enquiryFor, setenquiryFor] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profession, setProfession] = useState("");
  const [healthConditions, setHealthConditions] = useState("");
  const [prefferedLanguage, setPrefferedLanguage] = useState("");
  const [messages, setMessages] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  // BMI / BMR / RMR / IdealWeight / Lean Body Mass / Body Fat / Calorie in take

  const [alertweight, setAlertWeight] = useState("");
  const [alertheight, setAlertHeight] = useState("");
  const [alertnecks, setAlertNecks] = useState("");
  const [alertwaists, setAlertWaists] = useState("");
  const [alerthips, setAlertHips] = useState("");
  const [alertgender2, setalertGender2] = useState("");
  const [alertenquiryFor, setalertenquiryFor] = useState("");
  // Calorie in take
  const [alertCalories, setAlertCalories] = useState("");
  const [alerttargetWeight, setAlertTargetWeight] = useState("");
  const [alertgoal, setAlertGoal] = useState("");
  const [alertactivityLevel, setAlertActivityLevel] = useState("");

  //alert message{

  const [alertage, setAlertage] = useState("");
  const [alertname2, setAlertname2] = useState("");
  const [alertemail2, setAlertemail2] = useState("");
  const [alertprofession2, setAlertprofession2] = useState("");
  const [alerthealthcondition2, setAlerthealthcondition2] = useState("");
  const [alertprefferedlanguage2, setAlertprefferedlanguage2] = useState("");
  const [alertmessage2, setAlertmessage2] = useState("");
  const [alertcontactnumber2, setAlertcontactnumber2] = useState("");
  const [alertage2, setAlertage2] = useState("");
  const [bodyfatresult, setBodyfatResult] = useState("");
  const [IdealWeightresult, setIdealWeightresult] = useState("");
  const [macroresult, setMacroresult] = useState("");

  // Body Fat
  const [necks, setNecks] = useState("");
  const [waists, setWaists] = useState("");
  const [hips, setHips] = useState("");
  const [calories, setCalories] = useState("");
  const [cm, setCM] = useState("");
  const [feet, setFeet] = useState("");
  const [inch, setInch] = useState("");

  const [visible, setVisible] = React.useState(false);
  //alert message}
  const close = () => {
    setIdealWeightresult("");
    setMacroresult("");
    setWeight("");
    setHeight("");
    setAge("");
    setCalories("");
    setNecks("");
    setWaists("");
    setHips("");
    document.getElementById("macroresults").style.height = "0px";
    document.getElementById("Idealweightresults").style.height = "0px";
    document.getElementById("bmrresults").style.height = "0px";
    document.getElementById("bmiresults").style.height = "0px";
    document.getElementById("bodyfatresults").style.height = "0px";
  };
  // form Validatoin UseSate()}

  const [results, setResults] = useState("");

  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setGender(target.value);
    }
  };

  // form Validation start

  const cancel = () => {
    setGender("");
    setWeight("");
    setHeight("");
    setAge("");
    setResult("");
    setAlertWeight("");
    setAlertHeight("");
    setAlertNecks("");
    setAlertWaists("");
    setAlertHips("");
    setAlertCalories("");
    setAlertTargetWeight("");
    setAlertGoal("");
    setAlertActivityLevel("");
    setAlertage("");
    setBodyfatResult("");
    setIdealWeightresult("");
    setMacroresult("");
    setNecks("");
    setWaists("");
    setHips("");
    setCalories("");
    setalertGender2("");
    document.getElementById("macroresults").style.height = "0px";
    document.getElementById("Idealweightresults").style.height = "0px";
    document.getElementById("bmrresults").style.height = "0px";
    document.getElementById("bmiresults").style.height = "0px";
    document.getElementById("bodyfatresults").style.height = "0px";
  };

  async function weightform(e) {
    e.preventDefault();
    let enquiryFor = "WeightLoss";
    const message = messages + ". Goal is  " + goal;
    let item = {
      name,
      email,
      profession,
      healthConditions,
      prefferedLanguage,
      message,
      contactNumber,
      enquiryFor,
      gender,
      age,
      role,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(WmEnquiry(item))
      .unwrap()
      .then((data) => {
        setName("");
        setEmail("");
        setProfession("");
        setHealthConditions("");
        setPrefferedLanguage("");
        setMessages("");
        setContactNumber("");
        setenquiryFor("");
        setGender("");
        setAge("");
        setalertenquiryFor("");
        alert("Enquiry submitted succesfully! We will get back to you.");
        document.getElementById("freeconsultation").style.display = "none";
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const freeconsultation = (e) => {
    const regxname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxname.test(name)) {
      setAlertname2("");
    } else if (!regxname.test(name) && name === "") {
      setAlertname2("Please enter your name");
      e.preventDefault();
    } else {
      setAlertname2("");
    }

    const regxCondition = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxCondition.test(healthConditions)) {
      setAlerthealthcondition2("");
    } else if (
      !regxCondition.test(healthConditions) &&
      healthConditions === ""
    ) {
      setAlerthealthcondition2("Please select ");
      e.preventDefault();
    } else {
      setAlerthealthcondition2("");
    }

    const regxProfession = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxProfession.test(profession)) {
      setAlertprofession2("");
    } else if (!regxProfession.test(profession) && profession === "") {
      setAlertprofession2("Please select ");
      e.preventDefault();
    } else {
      setAlertprofession2("");
    }

    const regxLanguage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxLanguage.test(prefferedLanguage)) {
      setAlertprefferedlanguage2("");
    } else if (
      !regxLanguage.test(prefferedLanguage) &&
      prefferedLanguage === ""
    ) {
      setAlertprefferedlanguage2("Please select");
      e.preventDefault();
    } else {
      setAlertprefferedlanguage2("");
    }

    const regxemail =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regxemail.test(email)) {
      setAlertemail2("");
    } else if (!regxemail.test(email) && email === "") {
      setAlertemail2("Please enter the email");
      e.preventDefault();
    } else {
      setAlertemail2("Please enter a valid email");
      e.preventDefault();
    }

    const regxmobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regxmobile.test(contactNumber)) {
      setAlertcontactnumber2("");
    } else if (!regxmobile.test(contactNumber) && contactNumber === "") {
      setAlertcontactnumber2("Please enter the contact number");
      e.preventDefault();
    } else {
      setAlertcontactnumber2("Please enter a valid contact number");
      e.preventDefault();
    }

    const regxMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxMessage.test(messages)) {
      setAlertmessage2("");
    } else if (!regxMessage.test(messages) && messages === "") {
      setAlertmessage2("Please enter the message");
      e.preventDefault();
    } else {
      setAlertmessage2("");
    }

    const regxage = /^(1[89]|[2-9]\d)$/;
    if (regxage.test(age)) {
      setAlertage2("");
    } else if (!regxage.test(age) && age === "") {
      setAlertage2("Please enter  your age");
      e.preventDefault();
    } else {
      setAlertage2("");
    }
    if (gender === "") {
      setalertGender2("Please select");
      e.preventDefault();
    } else {
      setalertGender2("");
    }

    if (goal === "") {
      setalertenquiryFor("Please enter your goal");
      e.preventDefault();
    }
  };
  // form Validation end

  async function bmical(e) {
    document.getElementById("book").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bmi())
      .unwrap()
      .then((data) => {
        setResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function bmrcal(e) {
    document.getElementById("books").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bmr())
      .unwrap()
      .then((data) => {
        setResults(data.user.data);
        // setcountVal(data.user.dataCount);
        // console.log(countVal);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function Idealweightcal(e) {
    document.getElementById("Idealweightresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(IdealWeightcalculator())
      .unwrap()
      .then((data) => {
        setIdealWeightresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function macrocal(e) {
    document.getElementById("macroresults").style.height = "max-content";
    e.preventDefault();
    let item = { calories };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(macrocalculator())
      .unwrap()
      .then((data) => {
        setMacroresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }
  async function bodyfatcal(e) {
    document.getElementById("bodyfatresults").style.height = "max-content";
    e.preventDefault();
    let item = { weight, age, height, gender, necks, waists, hips };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bodyfatcalculator())
      .unwrap()
      .then((data) => {
        setBodyfatResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  const formclear = () => {
    setName("");
    setEmail("");
    setAge("");
    setProfession("");
    setHealthConditions("");
    setPrefferedLanguage("");
    setMessages("");
    setContactNumber("");

    setAlertage("");

    setAlertname2("");
    setAlertemail2("");
    setAlertprofession2("");
    setAlerthealthcondition2("");
    setAlertprefferedlanguage2("");
    setAlertmessage2("");
    setAlertcontactnumber2("");
    setAlertage2("");
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",

          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          // background: "orange",
          // color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  React.useEffect(() => {
    dispatch(getWmblog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const save = (e) => {
    const number1 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number1.test(age)) {
      setAlertage("");
    } else if (!number1.test(age) && age === "0") {
      setAlertage("Invalid number");
      e.preventDefault();
    } else if (!number1.test(age) && age === "") {
      setAlertage("Please enter");
      e.preventDefault();
    } else {
      setAlertage("");
      // e.preventDefault();
    }

    const number2 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number2.test(height)) {
      setAlertHeight("");
    } else if (!number2.test(height) && height === "0") {
      setAlertHeight("Invalid number");
      e.preventDefault();
    } else if (!number2.test(height) && height === "") {
      setAlertHeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const number3 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number3.test(weight)) {
      setAlertWeight("");
    } else if (!number3.test(weight) && weight === "0") {
      setAlertWeight("Invalid number");
      e.preventDefault();
    } else if (!number3.test(weight) && weight === "") {
      setAlertWeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }
  };

  const savecalories = (e) => {
    const number4 = /^[1-3][0-9][0-9][0-9]?$|^4000$/;
    if (number4.test(calories)) {
      setAlertCalories("");
    } else if (!number4.test(calories) && calories === "0") {
      setAlertCalories("Invalid number");
      e.preventDefault();
    } else if (!number4.test(calories) && calories === "") {
      setAlertCalories("Please enter");
      e.preventDefault();
    } else {
      setAlertCalories("");
      // e.preventDefault();
    }
  };

  const savebodyfat = (e) => {
    const number4 = /^[1-9][0-9]?$|^100$/;
    if (number4.test(necks)) {
      setAlertNecks("");
    } else if (!number4.test(necks) && necks === "0") {
      setAlertNecks("Invalid number");
      e.preventDefault();
    } else if (!number4.test(necks) && necks === "") {
      setAlertNecks("Please enter");
      e.preventDefault();
    } else {
      setAlertNecks("");
      // e.preventDefault();
    }

    const number5 = /^[1-9][0-9]?$|^100$/;
    if (number5.test(waists)) {
      setAlertWaists("");
    } else if (!number5.test(waists) && waists === "0") {
      setAlertWaists("Invalid number");
      e.preventDefault();
    } else if (!number5.test(waists) && waists === "") {
      setAlertWaists("Please enter");
      e.preventDefault();
    } else {
      setAlertWaists("");
      // e.preventDefault();
    }

    const number6 = /^[1-9][0-9]?$|^100$/;
    if (number6.test(hips)) {
      setAlertHips("");
    } else if (!number6.test(hips) && hips === "0") {
      setAlertHips("Invalid number");
      e.preventDefault();
    } else if (!number6.test(hips) && hips === "") {
      setAlertHips("Please enter ");
      e.preventDefault();
    } else {
      setAlertHips("");
      // e.preventDefault();
    }

    const number1 = /^[1-9][0-9]?$|^100$/;
    if (number1.test(age)) {
      setAlertage("");
    } else if (!number1.test(age) && age === "0") {
      setAlertage("Invalid");
      e.preventDefault();
    } else if (!number1.test(age) && age === "") {
      setAlertage("Please enter your age");
      e.preventDefault();
    } else {
      setAlertage("");
      // e.preventDefault();
    }

    const number2 = /^[1-9][0-9]?$|^100$/;
    if (number2.test(height)) {
      setAlertHeight("");
    } else if (!number2.test(height) && height === "0") {
      setAlertHeight("Invalid number");
      e.preventDefault();
    } else if (!number2.test(height) && height === "") {
      setAlertHeight("Please enter");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const number3 = /^[1-9][0-9]?$|^100$/;
    if (number3.test(weight)) {
      setAlertWeight("");
    } else if (!number3.test(weight) && weight === "0") {
      setAlertWeight("Invalid number");
      e.preventDefault();
    } else if (!number3.test(weight) && weight === "") {
      setAlertWeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }
  };

  // const savecalorietake = (e) => {

  //   const number1 = /^[1-9][0-9]?$|^100$/;
  //   if (number1.test(age)) {
  //     setAlertage("");
  //   } else if (!number1.test(age) && age === "") {
  //     setAlertage("Please enter ");
  //     e.preventDefault();
  //   } else {
  //     setAlertage("Min value should be 1");
  //     e.preventDefault();
  //   }

  //   const number2 = /^[1-9][0-9]?$|^100$/;
  //   if (number2.test(height)) {
  //     setAlertHeight("");
  //   } else if (!number2.test(height) && height === "") {
  //     setAlertHeight("Please enter");
  //     e.preventDefault();
  //   } else {
  //     setAlertHeight("Min value should be 1");
  //     e.preventDefault();
  //   }

  //   const number3 = /^[1-9][0-9]?$|^100$/;
  //   if (number3.test(weight)) {
  //     setAlertWeight("");
  //   } else if (!number3.test(weight) && weight === "") {
  //     setAlertWeight("Please enter");
  //     e.preventDefault();
  //   } else {
  //     setAlertWeight("Min value should be 1");
  //     e.preventDefault();
  //   }
  // };

  return (
    <div id="_next">
      <button
        type="button"
        className="btn btn-warning verticalButton buttonLink"
        data-bs-toggle="modal"
        data-bs-target="#freeconsultation"
      >
        FREE CONSULTATION
      </button>
      <Header />
      <section>
        <div className="carousel_cover">
          <Carousel fade>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner3}
                alt=""
              />
              <Carousel.Caption>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#freeconsultation"
                  className="btn btn-warning col-white btn-warning-mod"
                  style={{ width: "150px" }}
                >
                  Lose Now
                </button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner5}
                alt=""
              />
              <Carousel.Caption>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#freeconsultation"
                  className="btn btn-warning col-white btn-warning-mod"
                  style={{ width: "150px" }}
                >
                  Lose Now
                </button>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner2}
                alt=""
              />
              <Carousel.Caption>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#freeconsultation"
                  className="btn btn-warning col-white btn-warning-mod"
                  style={{ width: "150px" }}
                >
                  Lose Now
                </button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <div className="col-lg-12 section-title pt-5">
        <h3 className="text-center">Weight Loss</h3>
      </div>

      <section className="bmi_cal  container">
        <p>
          Achieving healthy weight loss isn’t about a “diet” or “program” but a
          lifestyle with healthy eating patterns, regular physical activity, and
          stress management. Medications taken for other conditions may also
          make it harder to lose weight. If you are concerned about your weight
          or have questions about your medications, talk with our health care
          provider. When you’re trying to lose weight, it’s natural to want it
          to happen very quickly. But people with gradual and steady weight loss
          (about 1 to 2 pounds per week) are more likely to keep the weight off.
        </p>

        <div className="col-lg-12 section-title pt-5">
          <h3 className="text-center">Healthonify Weight Loss Programs</h3>
        </div>
        <h5 className="text-center pt-1 pb-2">
          We Are The Most Trusted, Result Oriented Weight Loss Programs
        </h5>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-xs-6 pt-2">
            <div className="col-lg-12 col-md-12 col-sm-12 box-bmi pt-4 pb-2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={grouth} className="flat-icon" alt="" />
              </div>
              <p className="bmi-text2">95% Success rate</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-xs-6 pt-2">
            <div className="col-lg-12 col-md-12 col-sm-12 box-bmi pt-2 pb-2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={weightimg} className="flat-icon" alt="" />
              </div>
              <p className="bmi-text2">100,000+ Total kgs lost by clients</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6 pt-2">
            <div className="col-lg-12 col-md-12 col-sm-12 box-bmi pt-2 pb-2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={quality} className="flat-icon" alt="" />
              </div>
              <p className="bmi-text2">Certified expert nutritionists</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6 pt-2">
            <div className="col-lg-12 col-md-12 col-sm-12 box-bmi pt-2 pb-2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={plan} className="flat-icon" alt="" />
              </div>
              <p className="bmi-text2">Customized plans that suit you</p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mt-4 ">
          <button
            type="button"
            className="btn btn-warning col_white edit-btns2"
            data-bs-toggle="modal"
            data-bs-target="#freeconsultation"
            style={{ width: "300px", fontSize: "19px" }}
          >
            Yes I want to lose weight now
          </button>
        </div>
      </section>
      <section className="container pt-3 pb-3">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="pt-5">Weight Loss Made Easy</h3>
        </div>
        <h5 className="text-center pb-2">
          Healthonify Has Everything To Help You Achieve Your Weight Loss Goals
        </h5>
        <div className="row">
          <div className="col-row-5 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole">
              <p className="plan-text1" style={{ fontWeight: "bold" }}>
                Set Your Goals - Get Your Plan
              </p>

              <div className="col-lg-12 d-flex justify-content-center">
                <img src={target} className="cards-img" alt="" />
              </div>
              <p className="plan-text2 pt-3">
                Tell us what you want to achieve and avail personalize goals and
                weight loss plan.
              </p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole">
              <p className="plan-text1 " style={{ fontWeight: "bold" }}>
                Track Your Diet
              </p>

              <div className="col-lg-12 d-flex justify-content-center">
                <img src={calculator} className="cards-img" alt="" />
              </div>
              <p className="plan-text2 pt-3">
                Learn about food you are eating and calculate your daily
                calories.
              </p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole">
              <p className="plan-text1 " style={{ fontWeight: "bold" }}>
                Calorie Balance
              </p>

              <div className="col-lg-12 d-flex justify-content-center">
                <img src={balance} className="cards-img" alt="" />
              </div>
              <p className="plan-text2 pt-3">
                Check your daily calorie balance
              </p>
            </div>
          </div>
          <div className="col-row-5 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole">
              <p className="plan-text1" style={{ fontWeight: "bold" }}>
                Expert Monitoring
              </p>

              <div className="col-lg-12 d-flex justify-content-center">
                <img src={encryption} className="cards-img" alt="" />
              </div>
              <p className="plan-text2 pt-3">
                Certified experts watch daily progress and advice corrective
                action.
              </p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole">
              <p className="plan-text1 " style={{ fontWeight: "bold" }}>
                Lose Weight
              </p>

              <div className="col-lg-12 d-flex justify-content-center">
                <img src={weights} className="cards-img" alt="" />
              </div>
              <p className="plan-text2 pt-3">
                Reach your goals and set new once for healthier you.
              </p>
            </div>
          </div>

          <div className="col-lg-12 d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-warning edit-btns2 col-white"
              data-bs-toggle="modal"
              data-bs-target="#freeconsultation"
              style={{ weight: "500px", fontSize: "19px" }}
            >
              Free Weight Loss Consultation
            </button>
          </div>
        </div>
      </section>

      <div className="col-lg-12 d-flex justify-content-center section-title">
        <h3 className="pt-5">Healthonify Technology</h3>
      </div>

      <img src={health} alt="" style={{ width: "100%" }} />
      <div className="container">
        <ul className="arrow-point pt-5">
          <li>
            <b>Do you know </b>– Overweight and Obesity are the two common
            lifestyle problem that plague nearly 17% of India’s population.
          </li>
          <li>
            <b>Do you know</b> - There are 28 diseases identified in the Global
            Burden of Disease database that are linked to obesity or High BMI
          </li>
          <li>
            <b>Are You Overweight / Obese </b>
          </li>
          <li>What is your BMI</li>
          <li>How much you are overweight</li>
          <li>Do you follow a healthy diet</li>
          <li>Are you interested in Weight Loss / management</li>
          <li>Try Healthonify’s Weight Management solutions and get Fit</li>
        </ul>

        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-warning col-white"
            data-bs-toggle="modal"
            data-bs-target="#freeconsultation"
            style={{ fontSize: "19px", width: "300px" }}
          >
            First Free Consultation
          </button>
        </div>
      </div>

      <section className="container pt-5 pb-5">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={water} alt="" className="cards-img mt-2" />
              </div>
              <p className="plan-text1 pt-3" style={{ color: "black" }}>
                Water Tracking
              </p>
              <p className="plan-text2 pt-1" style={{ color: "black" }}>
                Track your water intake to make sure that you are getting the
                recommended amount daily
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={macro} alt="" className="cards-img mt-2" />
              </div>
              <p className="plan-text1 pt-3" style={{ color: "black" }}>
                Food macro Tracking
              </p>
              <p className="plan-text2 pt-1" style={{ color: "black" }}>
                Monitor your food calories and macronutirient goals
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={report} alt="" className="cards-img mt-2" />
              </div>
              <p className="plan-text1 pt-3" style={{ color: "black" }}>
                Patterns & Report
              </p>
              <p className="plan-text2 pt-1" style={{ color: "black" }}>
                Get report on your progress and behaviour
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={calorie} alt="" className="cards-img mt-2" />
              </div>
              <p className="plan-text1 pt-3" style={{ color: "black" }}>
                Dailty Calorie Balance
              </p>
              <p className="plan-text2 pt-1" style={{ color: "black" }}>
                Track your daily calorie in and out
              </p>
            </div>
          </div>
          <div className="col-lg-2" style={{ width: "12%" }} />
          <div className="col-lg-3 col-md-12 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={device} alt="" className="cards-img mt-2" />
              </div>
              <p className="plan-text1 pt-3" style={{ color: "black" }}>
                Device Integration
              </p>
              <p className="plan-text2 pt-1" style={{ color: "black" }}>
                Connect Google Fit, Apple Health and other gadgets for easy
                tracking
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={measuring} alt="" className="cards-img mt-2" />
              </div>
              <p className="plan-text1 pt-3" style={{ color: "black" }}>
                Measurement
              </p>
              <p className="plan-text2 pt-1" style={{ color: "black" }}>
                Use Camera to track a newer you
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-xs-12 pt-3 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 cards-whole2">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={monitoring} alt="" className="cards-img mt-2" />
              </div>
              <p className="plan-text1 pt-3" style={{ color: "black" }}>
                Expert Monitoring{" "}
              </p>
              <p className="plan-text2 pt-1" style={{ color: "black" }}>
                Access to expert and their online guidance
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container weight-details">
        <div className="row">
          <div className="col-md-2 col-xs-6 mt-1 mb-1">
            <div className="calculator">
              <div className="d-flex justify-content-center  pb-2">
                <img src={bmiicon} alt="" className="cards-img" />
              </div>
              <div className="d-flex justify-content-center pb-2">
                <button
                  type="button"
                  className="btn btn-warning col_white rotate"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalBMI"
                >
                  BMI Calculator
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-2 col-xs-6 mt-1 mb-1">
            <div className="calculator">
              <div className="d-flex justify-content-center  pb-2">
                <img src={bmricon} alt="" className="cards-img" />
              </div>
              <div className="d-flex justify-content-center pb-2">
                <button
                  type="button"
                  className="btn btn-warning col_white rotate"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalBMR"
                >
                  BMR Calculator
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-2 col-xs-6 mt-1 mb-1">
            <div className="calculator">
              <div className="d-flex justify-content-center  pb-2">
                <img src={ideal} alt="" className="cards-img" />
              </div>
              <div className="d-flex justify-content-center pb-2">
                <button
                  type="button"
                  className="btn btn-warning col_white rotate"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalIdealweight"
                >
                  Ideal Weight
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-2 col-xs-6 mt-1 mb-1">
            <div className="calculator">
              <div className="d-flex justify-content-center  pb-2">
                <img src={macro} alt="" className="cards-img" />
              </div>
              <div className="d-flex justify-content-center pb-2">
                <button
                  type="button"
                  className="btn btn-warning col_white rotate"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalmacro"
                >
                  Macro Calculator
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-2 col-xs-6 mt-1 mb-1">
            <div className="calculator">
              <div className="d-flex justify-content-center  pb-2">
                <img src={bodyfat} alt="" className="cards-img" />
              </div>
              <div className="d-flex justify-content-center pb-2">
                <button
                  type="button"
                  className="btn btn-warning col_white rotate"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalBodyfat"
                >
                  Body Fat Calculator
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-xs-6 mt-1 mb-1">
            <div className="calculator">
              <div className="d-flex justify-content-center  pb-2">
                <img src={bodyfat} alt="" className="cards-img" />
              </div>
              <div className="d-flex justify-content-center pb-2">
                <button
                  type="button"
                  className="btn btn-warning col_white rotate"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalBodyfat"
                >
                  Calorie Intake
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="pt-5">Healthonify Weight Loss Plans</h3>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 mt-1 mb-1">
            <div className="lie-img-slide-with-text position-relative bg-b-o-grandient">
              <p className="text-transform col-white position-absolute">
                Weight Loss Plans
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mt-1 mb-1">
            <div className="lie-img-slide-with-text position-relative bg-b-o-grandient">
              <p className="text-transform col-white position-absolute">
                Weight Loss Plans
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mt-1 mb-1">
            <div className="lie-img-slide-with-text position-relative bg-b-o-grandient">
              <p className="text-transform col-white position-absolute">
                Weight Loss Plans
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mt-1 mb-1">
            <div className="lie-img-slide-with-text position-relative bg-b-o-grandient">
              <p className="text-transform col-white position-absolute">
                Weight Loss Plans
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="pt-5">We Specialize In</h3>
        </div>

        <div className="row">
          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={dietconsultation}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Diet Consultation</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={weightloss}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Weight Loss</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={weightmanagement}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Weight Maintainance</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={diabetes}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Diabates Management</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={thyroid}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Thyroid Management</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={aciditymanagement}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Acidity Management</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={natal}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Pre and Post Natal</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={detox}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Detox Cleansing</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img
                  src={specialtratment}
                  alt=""
                  className="box-image small-sm-img"
                ></img>
              </div>
              <p className="col-style-text pt-2 pb-2">Special Cases</p>
            </div>
          </div>

          <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-xs-12 col-background-white">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={pcod} alt="" className="box-image small-sm-img"></img>
              </div>
              <p className="col-style-text pt-2 pb-2">PCOD / PCOS</p>
            </div>
          </div>
        </div>

        <div className="col-lg-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-warning edit-btns2 col-white mt-3"
            data-bs-toggle="modal"
            data-bs-target="#freeconsultation"
            style={{ fontSize: "19px", width: "300px" }}
          >
            Book Your Free Consultation
          </button>
        </div>
      </section>

      <section className="livewell container pt-5 pb-5">
        <div className="col-lg-12">
          <p className="wel-text text-center pb-2">Shop</p>
        </div>
        <div className="container">
          <Slider {...settings}>
            <Link to="/Shop">
              <div className="d-flex justify-content-center">
                <img src={shop1} alt="" className="lie-img-slide" />
              </div>
            </Link>
            <Link to="/Shop">
              <div className="d-flex justify-content-center">
                <img src={shop2} alt="" className="lie-img-slide" />
              </div>
            </Link>

            <Link to="/Shop">
              <div className="d-flex justify-content-center">
                <img src={shop3} alt="" className="lie-img-slide" />
              </div>
            </Link>

            <Link to="/Shop">
              <div className="d-flex justify-content-center">
                <img src={shop4} alt="" className="lie-img-slide" />
              </div>
            </Link>

            <Link to="/Shop">
              <div className="d-flex justify-content-center">
                <img src={shop5} alt="" className="lie-img-slide" />
              </div>
            </Link>
          </Slider>
        </div>
      </section>

      <section className="livewell container pt-2 pb-5">
        <div className="col-lg-12">
          <p className="wel-text text-center pb-2">Blog</p>
        </div>
        <div className="container">
          <Slider {...settings}>
            {blogs.map((blogsVal, index) => (
              <div className="d-flex justify-content-center">
                <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                  <div
                    className="col-12"
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    align="center"
                  >
                    <img
                      src={blogsVal.mediaLink}
                      alt=""
                      className="lie-img-slide"
                    />
                    <p>{blogsVal.blogTitle}</p>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className="section-card pt-4 pb-3">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text  text-center pb-2">Testimonial</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Prashant} alt="" />
                  </div>
                  <h4 className="text-center">Prashant</h4>

                  <p className="col-white">
                    I always wanted to lose weight, particularly my tummy fat. I
                    made several resolutions on my birthday and anniversary, but
                    it never worked. Finally, I downloaded this Healthonify App
                    which has all required things which motivated me to lose
                    weight. Priyanka Dietitian is very knowledgeable and
                    friendly, She takes regular follow-ups.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Ankit} alt="" />
                  </div>
                  <h4 className="text-center">Ankit</h4>

                  <p className="col-white">
                    I am a Gujarati and I love eating. During the pandemic, I
                    have put on too much weight and everyday in the morning when
                    I see myself in the mirror, I feel I need to go on strict
                    diet, do some physical activity and get into shape. I am
                    using the Healthonify App now from past 2 weeks which is
                    motivating me to get healthier and Fitter.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Vijaydeep} alt="" />
                  </div>
                  <h4 className="text-center">Vijaydeep</h4>

                  <p className="col-white">
                    I go for regular morning walks, that was the fitness regime.
                    I am overweight and I always wanted to lose weight.
                    Healthonify app gave me so many insights. The fitness
                    trackers and amazing, I try and make my own diet plan and
                    fitness plan. It is real fun. I took consultation from the
                    Fitness and Weight loss experts and I am going ahead as per
                    their guidance.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Deepak} alt="" />
                  </div>
                  <h4 className="text-center">Deepak</h4>

                  <p className="col-white">
                    Healthonify App has made my mobile and me lighter. I have
                    lost 8 kilos in last two months. Can’t tell you how good I
                    feel after so long. Its fun to use the app and chk the
                    progress. My friends are there in the community and their
                    likes really motivate me. Initially I did not like the
                    constant calls of the coach but now I can appreciate that as
                    it has made me bend the line and fall in a disciplined
                    routine.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <Footer />

      <div className="modal fade" id="myModalBMI" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">BMI Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bmical}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                    <p className="alert-message">{alertgender2}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4 mt-3">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4 mt-3">Height:</div>
                  <div className="col-md-10 col-8">
                    <div
                      className={
                        visible ? "element-invisible" : "element-display"
                      }
                    >
                      <input
                        type="number"
                        value={cm}
                        onChange={(e) => setCM(e.target.value)}
                        className="edit_text_form"
                        placeholder="CM"
                        min="1"
                        style={{ marginTop: "3%", width: "180px" }}
                      />
                    </div>
                    <div
                      className={visible ? "element-visible" : "element-hidden"}
                    >
                      <input
                        type="number"
                        value={feet}
                        onChange={(e) => setFeet(e.target.value)}
                        className="edit_text_form"
                        placeholder="FT"
                        min="1"
                        style={{ marginTop: "3%", width: "90px" }}
                      />
                      <input
                        type="number"
                        className="edit_text_form"
                        value={inch}
                        onChange={(e) => setInch(e.target.value)}
                        placeholder="In"
                        min="0"
                        max="11"
                        style={{ marginTop: "3%", width: "90px" }}
                      />
                    </div>
                    <p className="alert-message">{alertheight}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4"></div>
                  <div className="col-md-10 col-8">
                    <a
                      style={{ color: "blue", fontWeight: "500" }}
                      onClick={() => setVisible(!visible)}
                    >
                      {visible ? "In cm" : "In Feet"}
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4 mt-3">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight in Kg"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div className="result-bg" id="bmiresults">
                  <div className="d-flex justify-content-between">
                    <p>BMI :</p>
                    <span>{result.bmi}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Suggestion:</p>
                    <span>{result.category}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="myModalBMR" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents modal-resize">
            <div className="modal-header">
              <h4 className="modal-title">BMR Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bmrcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
                <div
                  className="result-bg d-flex justify-content-between"
                  id="books"
                >
                  <p>BMR :</p>
                  <span>{results.bmr}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalIdealweight"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Ideal Weight Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={Idealweightcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>

                <div className="result-bg" id="Idealweightresults">
                  <div className="d-flex justify-content-between">
                    <p>Ideal Weight :</p>
                    <span>{IdealWeightresult.idealWeight}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="myModalmacro" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Macro Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                onClick={close}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={macrocal}>
                <div className="row">
                  <div className="col-md-2 col-4">Calories:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={calories}
                      onChange={(e) => setCalories(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter the calories"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertCalories}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={savecalories}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>

                <div className="result-bg" id="macroresults">
                  <div className="d-flex justify-content-between">
                    <p>Protein In Grams :</p>
                    <span>{macroresult.proteinInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Carb In Grams :</p>
                    <span>{macroresult.carbInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Fat In Grams :</p>
                    <span>{macroresult.fatInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Fiber In Grams:</p>
                    <span>{macroresult.fiberInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Total Calories :</p>
                    <span>{macroresult.totalCalories}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalBodyfat"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Body Fat </h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bodyfatcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="female"
                        checked={gender === "female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>

                  <div className="col-md-2 col-4">Necks:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={necks}
                      onChange={(e) => setNecks(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your necks"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertnecks}</p>
                  </div>

                  <div className="col-md-2 col-4">Waists:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={waists}
                      onChange={(e) => setWaists(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your waists"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertwaists}</p>
                  </div>

                  <div className="col-md-2 col-4">Hips:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={hips}
                      onChange={(e) => setHips(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your hips"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alerthips}</p>
                  </div>
                </div>

                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={savebodyfat}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div className="result-bg" id="bodyfatresults">
                  <div className="d-flex justify-content-between">
                    <p>BFP :</p>
                    <span>{bodyfatresult.bfp}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="freeconsultation"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Weight Management Consultation</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={formclear}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={weightform}>
                <p className="bmi_text">
                  Gender:
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="male"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Male
                  </span>
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="Female"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Female
                  </span>
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="Female"
                      value="Other"
                      checked={gender === "Other"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Other
                  </span>
                </p>
                <p className="alert-message">{alertgender2}</p>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      <p className="alert-message">{alertname2}</p>
                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="alert-message">{alertemail2}</p>
                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Enter your contact number"
                      />
                      <p className="alert-message">{alertcontactnumber2}</p>

                      <br />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        placeholder="Enter your Age"
                      />
                      <p className="alert-message">{alertage2}</p>

                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <select
                        name="health"
                        id="health"
                        className="select-mod"
                        value={healthConditions}
                        onChange={(e) => setHealthConditions(e.target.value)}
                      >
                        <option value="" className="">
                          --Select health condition--
                        </option>
                        <option value="Thyriod">Thyriod</option>
                        <option value="Acidity">Acidity</option>
                        <option value="Cholesterol">Cholesterol</option>
                        <option value="Kneepain">Knee Pain</option>
                        <option value="Backpain">Back Pain</option>
                        <option value="Diabetes">Diabetes(T2)</option>
                        <option value="BP">BP</option>
                        <option value="Prediabetes">Pre Diabetes</option>
                        <option value=" pcod">PCOD</option>
                        <option value="None">None</option>
                        <option value="Other">Other</option>
                      </select>
                      <p className="alert-message">{alerthealthcondition2}</p>
                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <select
                        name="language"
                        id="profession"
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        className="select-mod"
                      >
                        <option value="" className="">
                          --Select your profession--
                        </option>
                        <option value="Homemaker">Homemaker</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Business">Business</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Service">Service</option>
                        <option value="Retired">Retired</option>
                        <option value="Student">Student</option>
                        <option value="Unemployed">Unemployed</option>
                        <option value="Others">Others</option>
                      </select>
                      <p className="alert-message">{alertprofession2}</p>

                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 pb-1">
                      <select
                        name="language"
                        id="language"
                        value={prefferedLanguage}
                        onChange={(e) => setPrefferedLanguage(e.target.value)}
                        className="select-mod"
                      >
                        <option value="" className="">
                          --Select your language--
                        </option>
                        <option value="hindi">Hindi</option>
                        <option value="english">English</option>
                        <option value="kannada">Kannada</option>
                        <option value="Marathi">Marathi</option>
                        <option value="Telugu">Telugu</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Malayalam">Malayalam</option>
                        <option value="Punjabi">Punjabi</option>
                        <option value="Gujarati">Gujarati</option>
                        <option value="Bangali">Bangali</option>
                        <option value="Urdu">Urdu</option>
                      </select>
                      <p className="alert-message">{alertprefferedlanguage2}</p>

                      <br />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={goal}
                        onChange={(e) => setGoal(e.target.value)}
                        placeholder="Weight Loss/ Gain Goal"
                      />
                      <p className="alert-message">{alertenquiryFor}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        col="50"
                        row="50"
                        type="text"
                        className="form-control"
                        value={messages}
                        onChange={(e) => setMessages(e.target.value)}
                        placeholder="Enter your message"
                      ></textarea>
                      <p className="alert-message">{alertmessage2}</p>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={freeconsultation}
                  >
                    Book Now
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={formclear}
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
    </div>
  );
};
export default Weight;
