import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class GoalService {
   
   
    static setStepGoal(item) {
        let api='';
        let user = JSON.parse(localStorage.getItem("user"));
        let id = user.data.id;
        api = API_PATHS.postStepGoal+ '?id=' + id;
        return axios
          .put(api,item)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static getStepGoal() {
        let api='';
        let user = JSON.parse(localStorage.getItem("user"));
        let id = user.data.id;
        api = API_PATHS.getStepGoal+ '?id=' + id;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default GoalService;