import React, { useState } from "react";
import Header from "./navbar";
import Footer from "./Footer";
import Moment from "moment";
import { getHcBlog } from "../store/slices/blog";
import Carousel from "react-bootstrap/Carousel";
import Gapp from "./../images/project/Gapp.png";
import Iapp from "./../images/project/apple.png";
import transform from "./../images/healthcare/Healthcare - Download the Healthonify app.png";
import Approved from "./../images/homepage/icons/approved.png";
import documents from "./../images/homepage/icons/documents.png";
import lab from "./../images/homepage/icons/lab.png";
import prescription from "./../images/healthcare/prescription.png";
import followup from "./../images/healthcare/schedule.png";
import Gynacology from "./../images/homepage/icons/gynecology.png";
import Physician from "./../images/homepage/icons/physician.png";
import Dermatology from "./../images/homepage/icons/dermatology.png";
import Digesition from "./../images/homepage/icons/stomatch.png";
import Pediatric from "./../images/homepage/icons/pediatrics.png";
import Urology from "./../images/homepage/icons/urology.png";
import Cardiology from "./../images/homepage/icons/cardiology.png";
import Psychiatry from "./../images/homepage/icons/psychiatry.png";
import Diabetes from "./../images/homepage/icons/diabetes.png";
import Management from "./../images/homepage/icons/weight.png";
import request from "./../images/healthcare/conversation.png";
import consultation from "./../images/healthcare/personal.png";
import video from "./../images/healthcare/live-streaming.png";
import offer1 from "./../images/healthcare/hcoffer1.jpg";
import offer2 from "./../images/healthcare/hcoffer2.jpg";
import offer3 from "./../images/healthcare/hcoffer3.jpg";
import labtests from "./../images/healthcare/labtest.jpg";

import hcpackage1 from "./../images/healthcare/hcpackage1.png";
import hcpackage2 from "./../images/healthcare/hcpackage2.png";
import hcpackage3 from "./../images/healthcare/hcpackage3.png";
import hcpackage4 from "./../images/healthcare/hcpackage4.jpg";

import babycare from "./../images/healthcare/medicine/Medicine_ Baby Care.png";
import alternate from "./../images/healthcare/medicine/Medicine_ alternate medicine.png";
import fitness from "./../images/healthcare/medicine/Medicine_ Fitness and wellness.png";
import personalcare from "./../images/healthcare/medicine/Medicine_ Personal Care.png";
import healthneeds from "./../images/healthcare/medicine/Medicine_ Health needs.png";
import womencare from "./../images/healthcare/medicine/WOMEN CARE.jpg";
import pain from "./../images/healthcare/medicine/PAIN.jpg";
import skincare from "./../images/healthcare/medicine/SKIN CARE.jpg";

import { API_PATHS } from "../utils/constants/api.constants";
import { Postlabs } from "../store/slices/lab";
import { getexpert } from "../store/slices/consult";
import { TravelEnquiry } from "../store/slices/enquiry";

import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import home1 from "./../images/homepage/banner1.png";
import home2 from "./../images/homepage/banner2.jpg";
import home3 from "./../images/healthcare/medicine.jpg";
import home4 from "./../images/homepage/banner4.jpg";

//testimonial images
import Atulya from "./../images/testimonial/Atulya.png";
import Amrutha from "./../images/testimonial/Amrutha.png";
import Salil from "./../images/testimonial/salil.png";
import Asha from "./../images/testimonial/asha.png";

import onlineconsult from "./../images/healthcare/Inventory Artboards 500by300 Ai ghaa-01.jpg";
import hra from "./../images/healthcare/NEW take a health risk assessment image.png";
import { getTrendingfitness } from "../store/slices/fitnesscenter";
const HealthCare = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [trendingFitness, settrendingFitness] = useState([]);
  //lab vendor enquiry
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [labName, setLabName] = useState("");
  const [nablCertificate, setNablCertificate] = useState("");

  const [alertemail, setAlertEmail] = useState("");
  const [alertname, setAlertName] = useState("");
  const [alertcontactNumber, setAlertContactNumber] = useState("");
  const [alertmessage, setAlertMessage] = useState("");
  const [alertaddress, setAlertAddress] = useState("");
  const [alertlabName, setAlertLabName] = useState("");
  const [messagecertificate, setMessagecertificate] = useState("");
  const [alertnablCertificate, setAlertNablCertificate] = useState("");

  const [pexpertid, setPexpertid] = useState([]);
  const [expertname, setExpertname] = useState("");
  const [expertiseId, setExpertiseId] = useState("");

  //doctor consultation enquiry
  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [alertCity, setAlertCity] = useState("");
  const [alertdate, setAlertdate] = useState("");
  const [alertCategory, setAlertCategory] = useState("");
  const [alertemail1, setAlertEmail1] = useState("");
  const [alertname1, setAlertName1] = useState("");
  const [alertcontactNumber1, setAlertContactNumber1] = useState("");
  const [alertmessage1, setAlertMessage1] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [alertCity1, setAlertCity1] = useState("");
  const [alertdate1, setAlertdate1] = useState("");
  const [alertemail2, setAlertEmail2] = useState("");
  const [alertname2, setAlertName2] = useState("");
  const [alertcontactNumber2, setAlertContactNumber2] = useState("");
  const [alertmessage2, setAlertMessage2] = useState("");

  const save = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setAlertName("");
    } else if (!vName.test(name) && name === "") {
      setAlertName("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName("Please enter a valid name");
      e.preventDefault();
    }

    const labname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (labname.test(labName)) {
      setAlertLabName("");
    } else if (!labname.test(labName) && labName === "") {
      setAlertLabName("Please enter the lab name");
      e.preventDefault();
    } else {
      setAlertLabName("Please enter a valid name");
    }

    if (nablCertificate === "") {
      setAlertNablCertificate("Please upload NABL certificate");
      e.preventDefault();
    } else {
      setAlertNablCertificate("");
    }

    const vMessage = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage.test(message)) {
      setAlertMessage("");
    } else if (!vMessage.test(message) && message === "") {
      setAlertMessage("Please enter the message");
      e.preventDefault();
    } else {
      setAlertMessage("");
    }

    const vemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vemail.test(email)) {
      setAlertEmail("");
    } else if (!vemail.test(email) && email === "") {
      setAlertEmail("Please enter the  email");
      e.preventDefault();
    } else {
      setAlertEmail("Please enter a valid email");
      e.preventDefault();
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile.test(contactNumber)) {
      setAlertContactNumber("");
    } else if (!mobile.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setAlertContactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const addresstext = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (addresstext.test(address)) {
      setAlertAddress("");
    } else if (!addresstext.test(address) && address === "") {
      setAlertAddress("Please enter the address");
      e.preventDefault();
    } else {
      setAlertAddress("");
    }
  };

  const reqConsult = (e) => {
    const vName1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName1.test(name)) {
      setAlertName1("");
    } else if (!vName1.test(name) && name === "") {
      setAlertName1("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName1("Please enter a valid name");
      e.preventDefault();
    }

    const vCity1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vCity1.test(city)) {
      setAlertCity("");
    } else if (!vCity1.test(city) && city === "") {
      setAlertCity("Please enter your city name");
      e.preventDefault();
    } else {
      setAlertCity("Please enter a valid city name");
      e.preventDefault();
    }

    const vemail1 = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vemail1.test(email)) {
      setAlertEmail1("");
    } else if (!vemail1.test(email) && email === "") {
      setAlertEmail1("Please enter your  email");
      e.preventDefault();
    } else {
      setAlertEmail1("Please enter a valid email");
      e.preventDefault();
    }

    const mobile1 = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile1.test(contactNumber)) {
      setAlertContactNumber1("");
    } else if (!mobile1.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber1("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setAlertContactNumber1("Please enter a valid mobile number");
      e.preventDefault();
    }

    if (category === "") {
      setAlertCategory("Please select expertise");
      e.preventDefault();
    } else {
      setAlertCategory("");
    }

    const vDate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate.test(date)) {
      setAlertdate("");
    } else if (!vDate.test(date) && date === "") {
      setAlertdate("Please enter your date of consultation");
      e.preventDefault();
    } else {
      setAlertdate("");
    }

    const vMessage1 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage1.test(message)) {
      setAlertMessage1("");
    } else if (!vMessage1.test(message) && message === "") {
      setAlertMessage1("Please enter your issue");
      e.preventDefault();
    } else {
      setAlertMessage1("");
    }
  };

  const reqLabtest = (e) => {
    const vName2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName2.test(name)) {
      setAlertName2("");
    } else if (!vName2.test(name) && name === "") {
      setAlertName2("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName2("Please enter a valid name");
      e.preventDefault();
    }

    const vCity2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vCity2.test(city)) {
      setAlertCity1("");
    } else if (!vCity2.test(city) && city === "") {
      setAlertCity1("Please enter your city name");
      e.preventDefault();
    } else {
      setAlertCity1("Please enter a valid city name");
      e.preventDefault();
    }

    const vemail2 = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vemail2.test(email)) {
      setAlertEmail2("");
    } else if (!vemail2.test(email) && email === "") {
      setAlertEmail2("Please enter your  email");
      e.preventDefault();
    } else {
      setAlertEmail2("Please enter a valid email");
      e.preventDefault();
    }

    const mobile2 = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile2.test(contactNumber)) {
      setAlertContactNumber2("");
    } else if (!mobile2.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber2("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setAlertContactNumber2("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vDate1 =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate1.test(date)) {
      setAlertdate1("");
    } else if (!vDate1.test(date) && date === "") {
      setAlertdate1("Please enter your date of consultation");
      e.preventDefault();
    } else {
      setAlertdate1("");
    }

    const vMessage2 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage2.test(message)) {
      setAlertMessage2("");
    } else if (!vMessage2.test(message) && message === "") {
      setAlertMessage2("Please enter your issue");
      e.preventDefault();
    } else {
      setAlertMessage2("");
    }
  };

  React.useEffect(() => {
    dispatch(getHcBlog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  const cancel = () => {
    setEmail("");
    setName("");
    setContactNumber("");
    setMessage("");
    setAddress("");
    setLabName("");
    setNablCertificate("");
    setAlertEmail("");
    setAlertName("");
    setAlertContactNumber("");
    setAlertMessage("");
    setAlertAddress("");
    setAlertLabName("");
    setMessagecertificate("");
    setAlertNablCertificate("");
    setExpertiseId("");
    setCity("");
    setDate("");
    setCategory("");
    setAlertCity("");
    setAlertdate("");
    setAlertCategory("");
  };

  React.useEffect(() => {
    dispatch(getexpert())
      .unwrap()
      .then((data) => {
        setPexpertid(data.user.data);
        setExpertiseId(data.user.data[0]._id);
        setExpertname(data.user.data[0].name);
        console.log(data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function upload(e) {
    let enquiryFor = "labVendor";
    e.preventDefault();
    let item = {
      email,
      name,
      contactNumber,
      message,
      address,
      labName,
      nablCertificate,
      enquiryFor,
    };

    dispatch(Postlabs(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setEmail("");
        setName("");
        setContactNumber("");
        setMessage("");
        setAddress("");
        setMessagecertificate("");
        setLabName("");
        setNablCertificate("");
        document.getElementById("labvendor").style.display = "none";
      })

      .catch(({ emessage }) => {
        alert(emessage);
      });
  }

  async function uploadconsult(e) {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let category = "Healthcare";
    let item = {
      name,
      email,
      contactNumber,
      city,
      date,
      message,
      category,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setContactNumber("");
        setCity("");
        setDate("");
        setMessage("");
        setCategory("");
        document.getElementById("consult").style.display = "none";
      })

      .catch(({ emessage }) => {
        alert(emessage);
      });
  }

  async function labtest(e) {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let category = "Lab Test";
    let item = {
      name,
      email,
      contactNumber,
      city,
      date,
      message,
      category,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setContactNumber("");
        setCity("");
        setDate("");
        setMessage("");
        document.getElementById("labtestEnquiry").style.display = "none";
      })

      .catch(({ emessage }) => {
        alert(emessage);
      });
  }

  async function certificate(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setNablCertificate(data.data.location);
    setMessagecertificate(data.message);
  }

  React.useEffect(() => {
    dispatch(getTrendingfitness())
      .unwrap()
      .then((data) => {
        settrendingFitness(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      <Header />

      <section>
        <div className="carousel_cover pb-5 ">
          <Carousel className="">
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3 "
                src={home1}
                alt=""
              />
              <Carousel.Caption>
                <h2 style={{ color: "white" }}>
                  Avoid Hassles of Visiting a Clinic & Waiting in Queue
                </h2>
                <Link to="/HRA">
                  <button
                    type="button"
                    className="btn btn-healthonify btn-single  col-white"
                    data-bs-toggle="modal"
                  >
                    Take a Health Risk Assessment
                  </button>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3 "
                src={home3}
                alt=""
              />
              <Carousel.Caption>
                <h2 style={{ color: "black" }}>
                  Check Your Health Meter and work on Preventive measures to
                  save Pain & Cost
                </h2>
                <Link to="/HRA">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    className="btn btn-healthonify btn-single  col-white"
                  >
                    Take a Health Risk Assessment
                  </button>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      <div className="container">
        <section className="section-card pt-4 pb-3">
          <h3 className="text-center wel-text">
            Take Online Doctor Consultation
          </h3>
          <div className="row mt-5">
            <div className="d-flex justify-content-center col-md-4 col-sm-12 pt-1 pb-1">
              <div className="box-white-bg2">
                <div className="d-flex justify-content-center">
                  <img src={Physician} className="icon-f" alt="flat-icon" />
                </div>
                <p className="text-center text-mark-2 mt-3">
                  <b>Verified Doctor</b>
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-center col-md-4 col-sm-12 pt-1 pb-1">
              <div className="box-white-bg2">
                <div className="d-flex justify-content-center">
                  <img src={prescription} className="icon-f" alt="flat-icon" />
                </div>
                <p className="text-center text-mark-2 mt-3">
                  <b>Digital Prescription</b>
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-center col-md-4 col-sm-12 pt-1 pb-1">
              <div className="box-white-bg2">
                <div className="d-flex justify-content-center">
                  <img src={followup} className="icon-f" alt="flat-icon" />
                </div>
                <p className="text-center text-mark-2 mt-3">
                  <b>Free follow Up</b>
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center pt-5 pb-1">
            <button
              type="button"
              className="btn  btn-warning col-white"
              data-bs-toggle="modal"
              data-bs-target="#consult"
              style={{ fontSize: "19px", width: "300px", height: "50px" }}
            >
              Consult Now
            </button>
          </div>
        </section>

        <section className="section-card pt-4 pb-3">
          <h3 className="wel-text text-center pb-3">Online Consultation</h3>

          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <h5 className="text-underline text-center">
                Benefits of online consultations
              </h5>
              <ul>
                <li className="point-text">Consult top doctors</li>
                <p>Live video consultation from verified top doctors.</p>
              </ul>

              <ul>
                <li className="point-text">Convinient & easy</li>
                <p>
                  {" "}
                  100% safe & secure online consultation through mobile app
                  interface for convienince.
                </p>
              </ul>

              <ul>
                <li className="point-text">Clinic like experience</li>
                <p>
                  Upload reports on Healthonify mobile app, get online
                  prescriptions and medicines through Healthonify e-commerce
                  shop.
                </p>
              </ul>

              <ul>
                <li className="point-text">Free follow up</li>
                <p>
                  Coordination with Health Coaches on all aspects of Healthcare.
                </p>
              </ul>
            </div>

            <div className="col-md-6 col-sm-12">
              <img
                src={onlineconsult}
                className="image-box-sec"
                alt="image-box"
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-warning col-white"
                data-bs-toggle="modal"
                data-bs-target="#consult"
                style={{ fontSize: "19px" }}
              >
                Request Appointment Now{" "}
              </button>
            </div>
          </div>
        </section>

        <section className="section-card pt-4 pb-3">
          <h3 className="wel-text text-center pb-3">
            Take A Health Risk Assessment
          </h3>

          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <img
                src={hra}
                alt=""
                className="image-box-sec"
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-2">
              <p style={{ fontWeight: "500" }}>
                What is your current health score. Are you healthy now. Do you
                want to know what health risks you may foresee in the coming
                months / years.
                <br />
                Get a Healthonify Health Risk Assessment done based on which we
                can do the risk prediction and recommend you the required care
                plan with our panel of experts.
                <br />
                Based on the result of your Health Risk Assessment, our team
                will guide you to the required Health experts – Doctor,
                Physiotherapists, Dieticians, Yoga Expert, Fitness trainers,
                Meditation experts, Sound therapists, Sleep experts, healers..
                to name a few. who will handhold you throughout your journey
                with Healthonify.
              </p>

              <div className="d-flex justify-content-center">
                <Link to="/HRA">
                  <button
                    type="button"
                    className="btn btn-warning col-white"
                    data-bs-toggle="modal"
                    data-bs-target="#consult"
                    style={{ fontSize: "19px" }}
                  >
                    Take Health Risk Assessment Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="section-card pt-4 pb-3">
          <h3 className="wel-text text-center pb-2">
            How Healthonify Online Consultation Works
          </h3>
          <div className="row">
            <div className="col-md-4 col-sm-12 pt-1 pb-1">
              <div className="position-relative round-border text-center">
                <img src={request} alt="" className="icon-f mt-2" />

                <p className="text-transform position-absolute col-white mt-5">
                  Request Appointment
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 pt-1 pb-1">
              <div className="position-relative round-border text-center">
                <img src={consultation} alt="" className="icon-f mt-2" />
                <p className="text-transform position-absolute col-white mt-5">
                  Consult Health Expert
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 pt-1 pb-1">
              <div className="position-relative round-border text-center">
                <img src={video} alt="" className="icon-f mt-2" />
                <p className="text-transform position-absolute col-white mt-5">
                  Live Video Consultation
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-card pt-4 pb-3">
          <h3 className="wel-text text-center pb-3">Consultation Offers</h3>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-xs-6 pt-2">
              <img
                src={offer1}
                alt=""
                className="lie-img-slide lie-img-slide-sm"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="col-lg-4 col-md-4 col-xs-6  pt-2">
              <img
                src={offer2}
                alt=""
                className="lie-img-slide lie-img-slide-sm"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="col-lg-4 col-md-4 col-xs-6 pt-2">
              <img
                src={offer3}
                alt=""
                className="lie-img-slide lie-img-slide-sm"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </section>

        <section className="section-card pt-4 pb-3">
          <h3 className="wel-text text-center pb-3">
            Lab Test And Diagnostics
          </h3>

          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                src={labtests}
                alt="image-box"
                className="image-box-sec"
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <h5 className="text-underline text-center">How It Works</h5>

              <ul>
                <li className="point-text">Book your test online</li>
                <p>
                  Fill in the prefered time slots and request lab/home
                  collection of samples
                </p>
              </ul>

              <ul>
                <li className="point-text">Health expert co-ordination</li>
                <p>
                  Helath expert to co-ordination an facilitate collection of
                  samples
                </p>
              </ul>

              <ul>
                <li className="point-text">Collection of samples</li>
                <p>
                  Samples are collected at requested time by a qualified e-medic
                </p>
              </ul>

              <ul>
                <li className="point-text">Reports</li>
                <p>
                  Quick turn around and timely production of report and, sent on
                  email or registered number
                </p>
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-center pb-2 pt-4">
            <button
              type="button"
              className="btn btn-warning col-white"
              data-bs-toggle="modal"
              data-bs-target="#labtestEnquiry"
              style={{ fontSize: "20px" }}
            >
              Book Lab Test Appointment Now
            </button>
          </div>
        </section>

        <section className="section-card pt-4 pb-3">
          <h3 className="wel-text text-center pb-3">
            High Quality Labs And Partners
          </h3>

          <p className="point-text text-center">
            Healthonify's team of qualified pathologists and lab technicians
            ensure that your samples are processed with highest safety protocol
            so that all reports are absolutely accurate
          </p>
        </section>

        <section className="section-card pt-4 pb-3">
          <h3 className="wel-text text-center pb-3">Labs</h3>
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <div className="d-flex justify-content-center">
                <div className="card-all">
                  <div className="d-flex justify-content-center align-items-center pt-2 pb-3">
                    <p className="point-text">NABL Accreditation</p>&nbsp;&nbsp;
                    <img src={documents} alt="approved" className="icon-f" />
                  </div>

                  <div className="d-flex justify-content-center align-items-center pt-2 pb-3">
                    <p className="point-text">ICMR Approved</p>&nbsp;&nbsp;
                    <img src={Approved} alt="approved" className="icon-f" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="row">
                {trendingFitness.map((trendingFitnessVal, index) => (
                  <div className="col-6 mt-2 mb-2 d-flex justify-content-center">
                    <div className="card-all-2">
                      <div className="d-flex justify-content-center">
                        <img
                          src={trendingFitnessVal.labId.logo}
                          alt="lab"
                          className="icon-f"
                          style={{ height: "100px", width: "100px" }}
                        />
                      </div>

                      <p className="point-text text-center col-black">
                        {trendingFitnessVal.labId.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-12 d-flex justify-content-center pt-4 pb-2 ">
            <button
              type="button"
              className="btn btn-warning col-white d-none-sm"
              style={{ fontSize: "22px" }}
              data-bs-toggle="modal"
              data-bs-target="#labtestEnquiry"
            >
              Request Sample Collection
            </button>
            &nbsp; &nbsp;
            <button
              type="button"
              className="btn btn-healthonify btn-single  col-white"
              data-bs-toggle="modal"
              data-bs-target="#labvendor"
              style={{ fontSize: "22px" }}
            >
              Lab Vendor Enquiry
            </button>
          </div>

          <div className="row ">
            <div className="col-sm-12 d-flex justify-content-center mt-1 mb-1">
              <button
                type="button"
                className="btn btn-warning col-white d-none-lg d-none-md"
                style={{ fontSize: "22px" }}
              >
                Request Sample Collection
              </button>
            </div>
            <div className="col-sm-12 d-flex justify-content-center mt-1 mb-1">
              <button
                type="button"
                className="btn btn-warning col-white d-none-lg d-none-md"
                data-bs-toggle="modal"
                data-bs-target="#labvendor"
                style={{ fontSize: "22px" }}
              >
                Lab vendor enquiry
              </button>
            </div>
          </div>
        </section>
        <div className="row mt-5">
          <h3 className="wel-text text-center pb-3">
            Download Healthonify Application
          </h3>

          <div className="col-md-9">
            <img src={transform} alt="" />
          </div>
          <div className="col-md-3 text-center">
            <img
              src={Gapp}
              alt=""
              style={{ height: "80px" }}
              className="mt-10"
            />
            <img src={Iapp} alt="" style={{ height: "80px" }} />
          </div>
        </div>
        <section className="section-card pt-4 pb-3">
          <section className="container pt-5 pb-5">
            <div className="col-lg-12">
              <p className="wel-text  text-center pb-2">
                Healthonify Health Packages
              </p>
            </div>
            <div className="container">
              <Slider {...settings}>
                <div className="d-flex justify-content-center">
                  <img
                    src={hcpackage1}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <img
                    src={hcpackage2}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <img
                    src={hcpackage3}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <img
                    src={hcpackage4}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                {/* <div className="d-flex justify-content-center">
                  <img src={home1} alt="live-well" className="lie-img-slide" />
                </div>

                <div className="d-flex justify-content-center">
                  <img src={home2} alt="live-well" className="lie-img-slide" />
                </div> */}
              </Slider>
            </div>
          </section>
        </section>

        <section className="section-card pt-4 pb-3">
          <h3 className="wel-text text-center pb-2">Treatment Specialities</h3>

          <div className="row card-all-3">
            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Gynacology} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">Gynecology</p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Physician} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">
                General Physician
              </p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Dermatology} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">Dermatology</p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Digesition} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">
                Stomach & Digestion
              </p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Pediatric} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">Pediatric</p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Urology} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">Urology</p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Cardiology} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">Cardiology</p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Psychiatry} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">Psychiatry</p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Diabetes} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">Diabetes</p>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-4 mb-4">
              <div className="d-flex justify-content-center">
                <img src={Management} alt="" className="icon-f" />
              </div>

              <p className="point-text col-black text-center">
                Weight Management
              </p>
            </div>
          </div>
        </section>

        <section className="livewell container pt-2 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Blog</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              {blogs.map((blogsVal, index) => (
                <div className="d-flex justify-content-center">
                  <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                    <div
                      className="col-12"
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      align="center"
                    >
                      <img
                        src={blogsVal.mediaLink}
                        alt=""
                        className="lie-img-slide"
                      />
                      <p>{blogsVal.blogTitle}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </section>

        <section className="section-card container pt-2 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Shop for medicines</p>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img src={skincare} alt="" style={{ borderRadius: "10px" }} />
                  <p className="text-transform col-white position-absolute">
                    Skin Care
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative ">
                  <img src={babycare} alt="" style={{ borderRadius: "10px" }} />
                  <p className="text-transform col-white position-absolute">
                    Baby Care
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img src={pain} alt="" style={{ borderRadius: "10px" }} />
                  <p className="text-transform col-white position-absolute">
                    Pain Relief
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img
                    src={personalcare}
                    alt=""
                    style={{ borderRadius: "10px" }}
                  />
                  <p className="text-transform col-white position-absolute">
                    Personal Care
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img
                    src={alternate}
                    alt=""
                    style={{ borderRadius: "10px" }}
                  />
                  <p className="text-transform col-white position-absolute">
                    Alternate Medicines
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img
                    src={womencare}
                    alt=""
                    style={{ borderRadius: "10px" }}
                  />
                  <p className="text-transform col-white position-absolute">
                    Women Care
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img
                    src={healthneeds}
                    alt=""
                    style={{ borderRadius: "10px" }}
                  />
                  <p className="text-transform col-white position-absolute">
                    Daily Health Needs
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img src={fitness} alt="" style={{ borderRadius: "10px" }} />
                  <p className="text-transform col-white position-absolute">
                    Fitness & Wellness
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </section>

        <section className="section-card pt-4 pb-3">
          <section className="container pt-5 pb-5">
            <div className="col-lg-12">
              <p className="wel-text  text-center pb-2">
                What Our Users Say About Online Consultation Experience
              </p>
            </div>
            <div className="container">
              <Slider {...settings}>
                <div className="d-flex justify-content-center">
                  <div className="single-features-item bg-orange-card fix_space">
                    <div className="circle-profile d-flex justify-content-center">
                      <img src={Atulya} alt="" />
                    </div>
                    <h4 className="text-center">Atulya</h4>

                    <p className="col-white">
                      I like this new concept of Healthonify in India, Its
                      convenient and easy, check on your current health
                      condition and they will guide to the required expert based
                      on your condition. Only by using the app, I was motivated
                      to adopt a healthy lifestyle. I recommend everyone to
                      download this app.
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="single-features-item bg-orange-card fix_space">
                    <div className="circle-profile d-flex justify-content-center">
                      <img src={Amrutha} alt="" />
                    </div>
                    <h4 className="text-center">Amrutha</h4>

                    <p className="col-white">
                      I am 34 years old, I down loaded Healthonify app thru
                      which I have changed my lifestyle. I create my own workout
                      plan, diet plan and monitor my calorie intake, I use the
                      trackers and check on my calorie burn, I am an active
                      participant in their community wherein I interact with
                      likeminded people. Honestly, I am hooked to this app.
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="single-features-item bg-orange-card fix_space">
                    <div className="circle-profile d-flex justify-content-center">
                      <img src={Salil} alt="" />
                    </div>
                    <h4 className="text-center">Salil</h4>

                    <p className="col-white">
                      I am a diabetic and I was always advised by my doctor to
                      check and keep record of my BP and Blood sugar levels. I
                      always use to nod my head and tell the doctor that I will
                      do it from tomorrow, but that tomorrow never came. I
                      downloaded the Healthonify app and started exploring. It
                      is so simple and easy to use, I took a health risk
                      assessment, post which the health coach guided me to the
                      required expert panel. Now I use their Healthcare, Fitness
                      and mindfulness services.
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="single-features-item bg-orange-card fix_space">
                    <div className="circle-profile d-flex justify-content-center">
                      <img src={Asha} alt="" />
                    </div>
                    <h4 className="text-center">Asha</h4>

                    <p className="col-white">
                      Doctor Rahul was really helpful. His attention to our
                      history and connecting it to our current pain was very
                      scientific. My son is feeling lot better. Follow ups of
                      Sanjana is very engaging and helped me to monitor the
                      progress with Healthonify App
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </section>
        </section>
      </div>
      <Footer />

      <div className="modal fade" id="labvendor" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header">
              <h4 className="modal-title">Lab Vendor Enquiry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={cancel}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={upload}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={name}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <p className="alert-message">{alertname}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={labName}
                      placeholder=" Enter the lab name"
                      onChange={(e) => setLabName(e.target.value)}
                    />
                    <p className="alert-message">{alertlabName}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={email}
                      placeholder="Enter the email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="alert-message">{alertemail}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={contactNumber}
                      placeholder="Enter your contact number"
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                    <p className="alert-message">{alertcontactNumber}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={address}
                      placeholder="Enter the lab address/ Location"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <p className="alert-message">{alertaddress}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="file"
                      id="img"
                      style={{ display: "none" }}
                      className="form-control-file"
                      placeholder="NABL Certificate"
                      onChange={(e) => certificate(e)}
                    />
                    <label
                      for="img"
                      style={{ border: "solid 1px silver" }}
                      className="form-control  mt-1 mb-2 pt-2 pb-2"
                    >
                      Upload NABL Certificate
                    </label>
                    <p className="alert-message">{alertnablCertificate}</p>
                    <p style={{ fontWeight: "500", color: "green" }}>
                      {messagecertificate}
                    </p>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={message}
                      placeholder="Describe in brief"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <p className="alert-message">{alertmessage}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-center pt-5 ">
                  <button
                    type="submit"
                    className="btn btn-warning col-white adjust-btn"
                    onClick={save}
                  >
                    Apply
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white adjust-btn"
                    onClick={cancel}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="consult" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header">
              <h4 className="modal-title">Doctor Consultation</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={cancel}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={uploadconsult}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={name}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <p className="alert-message">{alertname1}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={email}
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="alert-message">{alertemail1}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={contactNumber}
                      placeholder="Enter your mobile number"
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                    <p className="alert-message">{alertcontactNumber1}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="date"
                      min={Moment().add(0, "d").format("YYYY-MM-DD")}
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                    <p className="alert-message">{alertdate}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={city}
                      placeholder="Enter your city name"
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <p className="alert-message">{alertCity}</p>
                  </div>
                  <div className="col-md-6 col-sm-12 pt-1">
                    <select
                      name="cars"
                      id="cars"
                      className="select-opt"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Select Expertise</option>
                      <option>Endocrinologist/Diabetes</option>
                      <option>Dermatologist</option>
                      <option>Cardiologist</option>
                      <option>Ayurveda</option>
                      <option>Gynaecologist</option>
                      <option>Paedetrics</option>
                    </select>
                    <p className="alert-message">{alertCategory}</p>
                  </div>

                  <div className="col-12 pt-2">
                    <textarea
                      row="50"
                      col="50"
                      className="form-control"
                      placeholder="Brief your issue"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>

                    <p className="alert-message">{alertmessage1}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-center pt-4">
                  <button
                    type="submit"
                    className="btn btn-warning col-white adjust-btn"
                    onClick={reqConsult}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white adjust-btn"
                    onClick={cancel}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="labtestEnquiry"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header">
              <h4 className="modal-title">Lab Test Request</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={cancel}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={labtest}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={name}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <p className="alert-message">{alertname1}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={email}
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="alert-message">{alertemail1}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={contactNumber}
                      placeholder="Enter your mobile number"
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                    <p className="alert-message">{alertcontactNumber1}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="date"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={date}
                      min={Moment().add(0, "d").format("YYYY-MM-DD")}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                    <p className="alert-message">{alertdate}</p>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={city}
                      placeholder="Enter your city name"
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <p className="alert-message">{alertCity}</p>
                  </div>

                  <div className="col-12 pt-2">
                    <textarea
                      row="50"
                      col="50"
                      className="form-control"
                      placeholder="Brief your issue"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>

                    <p className="alert-message">{alertmessage1}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-center pt-4">
                  <button
                    type="submit"
                    className="btn btn-warning col-white adjust-btn"
                    onClick={reqLabtest}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white adjust-btn"
                    onClick={cancel}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
    </div>
  );
};

export default HealthCare;
