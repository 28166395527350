import React, { useState } from "react";
import { Link } from "react-router-dom";
import aimg1 from "./../images/travel-login.png";
import { useNavigate } from "react-router-dom";
import TravelHeader from "./travel-navbar";
import { login } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import Travelfooter from "./Travelfooter";
import { VscEye } from "react-icons/vsc";

function TravelSignin() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [mobileNo, setmobileNo] = useState("");
  const [password, setPassword] = useState("");

  const [alertmobileNo, setAlertmobileNo] = useState("");
  const [alertpass, setAlertpass] = useState("");
  const navigate = useNavigate("");
  const { loading, error, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  async function signIn(event) {
    event.preventDefault();
    dispatch(login({ mobileNo, password }))
      .unwrap()
      .then(() => {
        // navigate("/travelonify");
        navigate(-1);
      });
  }

  const save = (e) => {
    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(mobileNo)) {
      setAlertmobileNo("");
    } else if (!regNum.test(mobileNo) && mobileNo === "") {
      setAlertmobileNo("Please enter the mobile number or email");
      e.preventDefault();
    }

    const regPass = /^(?=.*?[A-Za-z]).{6,9}$/;
    if (regPass.test(password)) {
      setAlertpass("");
    } else if (!regPass.test(password) && password === "") {
      setAlertpass("Please enter the password");
      e.preventDefault();
    } else {
      setAlertpass("");
      e.preventDefault();
    }
  };
  return (
    <div id="__next">
      <TravelHeader />
      <section className="login-bg-image">
        <div className="login-form">
          {/*error && <Alert variant="danger">{error}</Alert>*/}

          <p className="login-main-head text-center">Login</p>

          <form onSubmit={signIn}>
            <input
              type="text"
              value={mobileNo}
              onChange={(e) => setmobileNo(e.target.value)}
              className="form-control text-black-ph"
              placeholder="Enter mobile number or email"
              style={{ lineHeight: "2" }}
            />
            <p className="alert-message">{alertmobileNo}</p>
            <br />

            <div className="position-r">
              <input
                type={passwordShown ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control text-black-ph"
                placeholder="Enter password"
                style={{ lineHeight: "2" }}
              />
              <VscEye className="eye_icon_login" onClick={togglePassword} />
            </div>
            <p className="alert-message">{alertpass}</p>

            <div className="col-md-12 d-flex justify-content-center mt-5 mb-2">
              <button
                type="submit"
                className="btn btn-primary login-btn-travel col-white"
                onClick={save}
              >
                Sign in
              </button>
            </div>
            <p className=" text-center login-text-2 pt-1">
              Don't have an account?&nbsp;
              <Link
                to="/travelonify/TravelRegister"
                className="heightlight-black"
              >
                Sign Up
              </Link>
            </p>

            <p className="text-center">
              <a href="#" className="heightlight-black text-center">
                Forgot Password?
              </a>
            </p>
          </form>
        </div>
      </section>
      <Travelfooter />
    </div>
  );
}
export default TravelSignin;
