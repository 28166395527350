import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ExpertService {
    static getExperts() {
        let api='';
        let id = localStorage.getItem("topExpertise");
        api = API_PATHS.userprofile+ '?topExpertise=' + id;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                  //  localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    static getAllExpert() {
        let api='';
       
        api = API_PATHS.userprofile+'?isAdminApproved=1';
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                  //  localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default ExpertService  ;