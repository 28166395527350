import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import GoalService from './../../services/goal.service';

export const SetStepsGoal = createAsyncThunk(
    "goal/put/stepgoal",
    async(item,thunkAPI) => {
        try {
            const data = await GoalService.setStepGoal(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const GetStepsGoal = createAsyncThunk(
    "goal/get/stepsgoal",
    async(item,thunkAPI) => {
        try {
            const data = await GoalService.getStepGoal(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);


// export const logout = createAsyncThunk("auth/logout", async () => {
//   EnquiryService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const bookingSlice = createSlice({
    name: "booking",
    initialState,
    extraReducers: {
        [SetStepsGoal.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [SetStepsGoal.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [SetStepsGoal.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = bookingSlice;
export default reducer;