import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import UserNavbar from "./UserNavbar";
import { postmealreminder } from "../store/slices/reminder";
// import Moment from "moment";
const AddMeal = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [breakfast, setBreakfast] = useState("");
  const [lunch, setLunch] = useState("");
  const [dinner, setDinner] = useState("");
  const [morningSnack, setMorningSnack] = useState("");
  const [eveningSnack, setEveningSnack] = useState("");
  const [remindmeEverydayAt, setRemindmeEverydayAt] = useState("");
  const [remindmeEveryweekAt, setRemindmeEveryweekAt] = useState("");

  const [alertremindmeEveryweekAt, setAlertRemindmeEveryweekAt] = useState("");

 

  async function upload(e) {
    e.preventDefault();
    let userId = "628e07c3295cbb2a64996d2d";

    let item = {
        userId,
        breakfast,
        lunch,
        dinner,
        morningSnack,
        eveningSnack,
        remindmeEverydayAt,
        remindmeEveryweekAt,
    
}
    dispatch(postmealreminder(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        // navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save= (e) =>{

    const textweek = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (textweek.test(remindmeEveryweekAt)) {
      setAlertRemindmeEveryweekAt("");
    } else if (!textweek.test(remindmeEveryweekAt) && remindmeEveryweekAt === "") {
      setAlertRemindmeEveryweekAt("Please enter");
      e.preventDefault();
    } else {
      setAlertRemindmeEveryweekAt("");
      e.preventDefault();
    }
  }

  const cancel =()=>{
    setBreakfast("");
    setLunch("");
    setDinner("");
    setMorningSnack("");
    setEveningSnack("");
    setRemindmeEverydayAt("");
    setRemindmeEveryweekAt("");
    setAlertRemindmeEveryweekAt("");

  }
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container">
              <div className="pt-5 mt-5">
            <h3 className="pt-4 pb-2">Add Meal Reminder</h3>

                <div className="car">
                  <div className="a" align="center">

                    <form className="contact-form" onSubmit={upload}>
                      <div className="row">

                        <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Break Fast</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={breakfast}
                            required
                            onChange={(e) => setBreakfast(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Lunch</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={lunch}
                            required

                            onChange={(e) => setLunch(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Dinner</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={dinner}
                            required

                            onChange={(e) => setDinner(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Morning Snack</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={morningSnack}
                            required

                            onChange={(e) => setMorningSnack(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Evening Snack</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={eveningSnack}
                            required

                            onChange={(e) => setEveningSnack(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Remind Evert Day</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            required

                            value={remindmeEverydayAt}
                            onChange={(e) => setRemindmeEverydayAt(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Remind Every Week</b>
                          <input
                            type="text"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"

                            value={remindmeEveryweekAt}
                            onChange={(e) => setRemindmeEveryweekAt(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                          <p className="alert-message">{alertremindmeEveryweekAt}</p>
                        </div>
                      </div>

                      

                       

                        <center
                          className="send-btn"
                          style={{ marginBottom: "20px" }}
                        >
                          <button className="btn btn-warning col-white submit-btn" onClick={save}>
                            Save
                          </button>
                          &nbsp;
                          <button className="btn btn-blue col-white submit-btn" onClick={cancel}>
                            Cancel
                          </button>
                        </center>
                      </div>
                    </form>
                    {/* ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMeal;
