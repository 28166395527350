import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import UserNavbar from "./UserNavbar";
import { postsleepreminder } from "../store/slices/reminder";
// import Moment from "moment";
const AddSleep = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [sleepTime, setSleepTime] = useState("");
  const [wakeupTime, setWakeupTime] = useState("");


 

  async function upload(e) {
    e.preventDefault();
    let userId = "628e07c3295cbb2a64996d2d";
    let sleepTimeReminderEnabled = true ;
    let wakeupTimeReminderEnabled = true ;

    let item = {
        userId,
sleepTimeReminderEnabled,
wakeupTimeReminderEnabled,
sleepTime,
wakeupTime,
    
}
    dispatch(postsleepreminder(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");

        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container">
              <div className="pt-5 mt-5">
            <h3 className="pt-4 pb-2">Add Sleep Reminder</h3>

                <div className="car">
                  <div className="a" align="center">

                    <form className="contact-form" onSubmit={upload}>
                      <div className="row">

                        <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Sleep Time</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={sleepTime}
                            required
                            onChange={(e) => setSleepTime(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Wakeup Time</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={wakeupTime}
                            required
                            onChange={(e) => setWakeupTime(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      

                       

                        <center
                          className="send-btn"
                          style={{ marginBottom: "20px" }}
                        >
                          <button className="btn btn-warning col-white submit-btn">
                            Save
                          </button>
                          &nbsp;
                          <button className="btn btn-blue col-white submit-btn">
                            Cancel
                          </button>
                        </center>
                      </div>
                    </form>
                    {/* ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSleep;
