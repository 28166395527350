import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class getRoomGroupService {
   
   
    static roomgroup() {
      let api = '';
        let id = localStorage.getItem("hotelId"); 
        api = API_PATHS.getroomGroup + '?hotelId=' + id ;
        localStorage.removeItem("id");
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default getRoomGroupService;