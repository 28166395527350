import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import TravelHeader from "./travel-navbar";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { PackageDetails } from "./../store/slices/package";
import { Accordion } from "react-bootstrap";
import Moment from "moment";
import {
  CalculatePrice,
  PackageBooking,
  PaymentStore,
} from "./../store/slices/booking";
import { useNavigate } from "react-router-dom";
import Travelfooter from "./Travelfooter";
import { BsPlusCircleFill } from "react-icons/bs";
import { AiFillMinusCircle } from "react-icons/ai";

function Traveldetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
  };
  const [adultCount, setadultCount] = useState(1);
  const [childCount, setchildCount] = useState(0);
  const [childWithoutBedCount, setchildWithoutBedCount] = useState(0);
  const [checkinDate, setcheckinDate] = useState("");
  const [bookingIds, setbookingId] = useState("");
  const navigate = useNavigate("");
  const [imgeUrlfirst, setimgeUrlfirst] = useState("");
  const [imgeUrlsecond, setimgeUrlsecond] = useState("");

  const [grossAmount, setgrossAmount] = useState("");
  const [facilitatingFees, setfacilitatingFees] = useState("");
  const [reversalOnFf, setreversalOnFf] = useState("");
  const [basicRoomRate, setbasicRoomRate] = useState("");
  const [gstOnEffectivePrice, setgstOnEffectivePrice] = useState("");
  const [gstOnFf, setgstOnFf] = useState("");

  const [city, setCity] = useState("");
  const [packageId, setpackageId] = useState("");
  const [packageName, setpackageName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [ratings, setRatings] = useState("");
  const [departureLocation, setdepartureLocation] = useState("");
  const [inclusive, setInclusive] = useState("");
  const [exclusive, setExclusive] = useState("");
  const [agentId, setagentId] = useState("");
  const [rzpOrderId, setrzpOrderId] = useState("");
  const [pricingDetails, setpricingDetails] = useState("");
  const [termsCondition, settermsCondition] = useState("");
  const [importantNotes, setimportantNotes] = useState("");
  const [bookingProcedure, setbookingProcedure] = useState("");
  const [twinShareRate, settwinShareRate] = useState("");
  const [imageUrl, setimageUrl] = useState([{ mediaLink: "" }]);
  const [pricing, setPricing] = useState("");
  const [tourIternary, settourIternary] = useState([
    { day: "", description: "", meal: "" },
  ]);
  const [hotelDescription, sethotelDescription] = useState([
    {
      destination: "",
      HotelName: "",
      address: "",
      duration: "",
    },
  ]);

  const dispatch = useDispatch();

  const { id } = useParams();
  localStorage.setItem("id", id);
  localStorage.setItem("packageId", id);
  React.useEffect(() => {
    dispatch(PackageDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          // setimgeUrlfirst(data.user.data[0].imageUrl[1].mediaLink);
          // setimgeUrlsecond(data.user.data[0].imageUrl[2].mediaLink);
          setDuration(data.user.data[0].duration);
          setCity(data.user.data[0].city);
          setdepartureLocation(data.user.data[0].departureLocation);
          setInclusive(data.user.data[0].inclusive);
          setExclusive(data.user.data[0].exclusive);
          settermsCondition(data.user.data[0].termsCondition);
          setimageUrl(data.user.data[0].imageUrl);
          setimportantNotes(data.user.data[0].importantNotes);
          setbookingProcedure(data.user.data[0].bookingProcedure);
          setpackageName(data.user.data[0].packageName);
          setDescription(data.user.data[0].description);
          setRatings(data.user.data[0].ratings);
          settwinShareRate(data.user.data[0].twinShareRate);
          setPricing(data.user.data[0].pricing);
          settourIternary(data.user.data[0].tourIternary);
          sethotelDescription(data.user.data[0].hotelDescription);
          setpackageId(data.user.data[0]._id);
          setagentId(data.user.data[0].agentId.agencyId._id);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  // const { id } = useParams();

  const back = () => {
    document.getElementById("book").classList.toggle("shown");
  };

  const IncNum_A = () => {
    setadultCount(adultCount + 1);
  };

  const DecNum_A = () => {
    setadultCount((adultCount) => Math.max(adultCount - 1, 1));
  };

  const IncNum_B = () => {
    setchildCount(childCount + 1);
  };

  const DecNum_B = () => {
    setchildCount((childCount) => Math.max(childCount - 1, 0));
  };

  const IncNum_C = () => {
    setchildWithoutBedCount(childWithoutBedCount + 1);
  };

  const DecNum_C = () => {
    setchildWithoutBedCount((childWithoutBedCount) =>
      Math.max(childWithoutBedCount - 1, 0)
    );
  };

  var serviceFee = +facilitatingFees + +gstOnFf;
  async function save(e) {
    document.getElementById("book").style.height = "270px";
    e.preventDefault();
    let item = {
      packageId,
      adultCount,
      childCount,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(CalculatePrice(item))
      .unwrap()
      .then((data) => {
        setgrossAmount(data.user.data.totalCharge);
        setfacilitatingFees(data.user.data.facilitatingFees);
        setreversalOnFf(data.user.data.reversalOnFf);
        setbasicRoomRate(data.user.data.basicRoomRate);
        setpricingDetails(data.user.data);
        setgstOnEffectivePrice(data.user.data.gstOnEffectivePrice);
        setgstOnFf(data.user.data.gstOnFf);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }

  async function payNow(e) {
    const user = localStorage.getItem("user");

    if (user) {
      let data = JSON.parse(localStorage.getItem("user"));
      let userId = data.data.id;
      let userName = data.data.firstName + " " + data.data.lastName;
      let userType = "loggedin";
      let userEmail = data.data.email;
      let userMobileNo = data.data.mobileNo;
      let startTime = "13:30:00";
      let startDate = checkinDate;
      let endDate = "12/15/2022";
      e.preventDefault();
      let item = {
        packageId,
        userId,
        agentId,
        userName,
        userType,
        userEmail,
        userMobileNo,
        startTime,
        startDate,
        endDate,
        adultCount,
        childCount,
        pricingDetails,
        childWithoutBedCount,
      };
      localStorage.setItem("item", JSON.stringify(item));

      dispatch(PackageBooking(item))
        .unwrap()
        .then((data) => {
          setrzpOrderId(data.user.data.paymentData.rzpOrderId);
          setbookingId(data.user.data.bookingId);
        })
        .catch(({ message }) => {
          // alert(message);
        });

      let options = {
        key: "rzp_test_ZyEGUT3SkQbtE6",
        amount: grossAmount * 100, // 2000 paise = INR 20, amount in paisa
        name: "Helathonify",
        description: "Travel Package",
        image: "https://healthonify.com/uploads/frontend/images/logo.png",
        order_id: rzpOrderId,
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          let item = {
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: rzpOrderId,
            razorpay_payment_id: response.razorpay_payment_id,
            bookingId: bookingIds,
          };
          localStorage.setItem("item", JSON.stringify(item));
          dispatch(PaymentStore(item))
            .unwrap()
            .then((data) => {
              if (data.user.status === 0) {
                alert(data.user.message);
              } else if (data.user.status === 1) {
                alert(data.user.message);
                navigate("/travelonify/Thanks");
              }
            })
            .catch(({ message }) => {
              alert(message);
            });
        },

        prefill: {
          name: userName,
          email: userEmail,
          mobile: userMobileNo,
        },
        notes: {
          address: "Bangalore",
        },
        theme: {
          color: "#F37254",
        },
      };

      let rzp1 = new window.Razorpay(options);
      rzp1.open();
    } else {
      navigate("/travelonify/TravelSignin");
    }
  }

  return (
    <div id="_next">
      <TravelHeader />

      <section>
        <div className="carousel_cover position-relative">
          <Carousel className="length">
            {imageUrl.map((image, index) => (
              <Carousel.Item interval={2500}>
                <img
                  className="d-block w-100 carimg "
                  src={image.mediaLink}
                  alt=""
                />
                <div className="topText">
                  <h5>
                    <span
                      style={{
                        color: "#90EE90",
                        fontWeight: "700",
                        fontSize: "20px",
                        textDecoration: "line-through",
                        textDecorationColor: "red",
                        width: "50px",
                      }}
                    >
                      &#x20b9; {pricing / 2}
                    </span>
                    <br />
                    <span className="glow">
                      Offer Price <br />
                      &#x20b9; {twinShareRate / 2}
                    </span>
                    <br />
                    <span style={{ fontSize: "10px", fontWeight: "400" }}>
                      Per person on twin sharing basis*
                    </span>
                  </h5>
                </div>
               
              </Carousel.Item>
            ))}
          </Carousel>

          <div className="position-center-btn">
           <button
                    onClick={handleClick}
                    className="btn btn-blue col-white"
                    style={{ lineHeight:"2" }}
                  >
                    Book Now
                  </button>
          </div>
        </div>
      </section>
  <div className="d-flex justify-content-center pt-4">
      <h1 className="text-center pt-5 pb-2" style={{ color: "black" }}>
        {packageName}
      </h1>
      </div>
      <section className="container pt-4">

    

        <div className="row pb-1 pt-5 ">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div>
              <h3 style={{ color: "#001E79" }}>Tour Description</h3>
            </div>

            <p style={{ whiteSpace: "pre-wrap" }}>{description}</p>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div style={{ borderBottomStyle: "groove" }}>
              <h3 style={{ color: "#001E79" }}>Itiernary</h3>
            </div>
            <br />
            <div className="col-lg-12">
              <Accordion>
                {tourIternary.map((itiernary, index) => (
                  <Accordion.Item eventKey={itiernary.day}>
                    <Accordion.Header>Day - {itiernary.day}</Accordion.Header>

                    <Accordion.Body style={{ whiteSpace: "pre-wrap" }}>
                      {itiernary.description}
                      <br />
                      <br />
                      <i className="fa fa-food"></i>Food Type: &nbsp;
                      {itiernary.meal}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
        <br />
        <div className="row   pt-3 pb-1">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{ color: "#001E79" }}>Inclusion/ Exclusion</h3>
              <h6 style={{ color: "black" }}> Inclusion</h6>

              <p style={{ whiteSpace: "pre-wrap" }}>{inclusive}</p>
              <br />
              <h6 style={{ color: "black" }}> Exclusion</h6>

              <p style={{ whiteSpace: "pre-wrap" }}>{exclusive}</p>
            </div>
          </div>
        </div>
        <div className="row pt-3 pb-1">
          <h3 style={{ color: "#001E79", textAlign: "left" }}>Hotel Details</h3>
          <div
            className="col-lg-12 col-md-12 col-sm-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th
                          style={{
                            width: "100px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#6a6c72",
                          }}
                        >
                          Location
                        </th>
                        <th
                          style={{
                            width: "600px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#6a6c72",
                          }}
                        >
                          Hotel Name
                        </th>
                        <th
                          style={{
                            width: "200px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#6a6c72",
                          }}
                        >
                          No.of Days
                        </th>
                        <th
                          style={{
                            width: "300px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#6a6c72",
                          }}
                        >
                          No.of Person Per Room
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {hotelDescription.map((hotel, index) => (
                        <tr>
                          <td
                            style={{
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              color: "#6a6c72",
                            }}
                          >
                            {hotel.destination}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              color: "#6a6c72",
                            }}
                          >
                            {hotel.HotelName}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              color: "#6a6c72",
                            }}
                          >
                            {hotel.Duration}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              color: "#6a6c72",
                            }}
                          >
                            {hotel.capacityPerRoom}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <br />
        <div className="row  pt-3 pb-1">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{ color: "#001E79" }}>Terms and Conditions</h3>

              <p style={{ whiteSpace: "pre-wrap" }}>{termsCondition}</p>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <img
                src={imageUrl[1]?.mediaLink ?? imageUrl[0].mediaLink}
                alt="travel"
                className="slide-img"
              />
            </div>
          </div>
        </div>
        <div className="row pt-3 pb-1">
          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{ alignItems: "center" }}
          >
            <h2 style={{ color: "#001E79" }}>Important Notes</h2>

            {/* {!imageUrl[2].mediaLink && ( */}
            <img
              src={imageUrl[2]?.mediaLink ?? imageUrl[0].mediaLink}
              alt="travel"
              className="slide-img"
            />
            {/* )} */}
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <br />
              <br />

              <p style={{ whiteSpace: "pre-wrap" }}>{importantNotes}</p>
            </div>
          </div>
        </div>
        <div className="row pt-3 pb-1">
          <h3 style={{ textAlign: "left", color: "#001E79" }}>
            Cancellation Policy
          </h3>
          <div
            className="col-lg-12 col-md-12 col-sm-12"
            style={{ alignItems: "center" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p style={{ whiteSpace: "pre-wrap" }}>{bookingProcedure}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container mt-5" ref={ref}>
        <form onSubmit={save}>
          <div className="col-lg-12 ">
            <h2 style={{ color: "#001E79" }}>Calculate Price</h2>
          </div>
          <div className="row booking">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">

              <div className="overflow-control">
              <div
                className="d-flex justify-content-between align-items-center"

              >
                <div className="sec-1">
                  <p className="" style={{ width: "max-content" }}>
                    No. of travellers
                  </p>
                </div>

                <div className="sec-1">
                  <p className="sec-text">Adult</p>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="">
                      <AiFillMinusCircle
                      style={{borderRadius:"5px 0px 0px 5px"}}

                        className="logic-icon"
                        onClick={DecNum_A}
                      />
                    </div>
                    <input
                      type="text"
                      className="num-form-input"
                      value={adultCount}
                      onChange={(e) => setadultCount(e.target.value)}
                    />
                    <div className="">
                      <BsPlusCircleFill
                      style={{borderRadius:"0px 5px 5px 0px"}}

                        className="logic-icon"
                        onClick={IncNum_A}
                      />
                    </div>
                    <input
  value={packageId}
  onChange={(e) => setpackageId(e.target.value)}
  type="hidden"
/>
                  </div>
                </div>

                <div className="sec-1">
                  <p className="sec-text">Child (With bed)</p>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="">
                      <AiFillMinusCircle
                      style={{borderRadius:"5px 0px 0px 5px"}}

                        className="logic-icon"
                        onClick={DecNum_B}
                      />
                    </div>
                    <input
                      type="text"
                      className="num-form-input"
                      value={childCount}
                      onChange={(e) => setchildCount(e.target.value)}
                    />

                    <div className="">
                      <BsPlusCircleFill
                      style={{borderRadius:"0px 5px 5px 0px"}}

                        className="logic-icon"
                        onClick={IncNum_B}
                      />
                    </div>
                  </div>
                </div>

                <div className="sec-1">
                  <p className="sec-text">Child (Without bed)</p>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="">
                      <AiFillMinusCircle
                      style={{borderRadius:"5px 0px 0px 5px"}}

                        className="logic-icon"
                        onClick={DecNum_C}
                      />
                    </div>
                    <input
                      type="text"
                      className="num-form-input"
                      value={childWithoutBedCount}
                      onChange={(e) =>
                        setchildWithoutBedCount(e.target.value)
                      }
                    />
                    <div className="">
                      <BsPlusCircleFill
                      style={{borderRadius:"0px 5px 5px 0px"}}

                        className="logic-icon"
                        onClick={IncNum_C}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
                <br />

                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                    <p className="inline bok-text">Date of Travel</p>
                  </div>

                  <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
                    <input
                      type="date"
                      min={Moment().add(7, "d").format("YYYY-MM-DD")}
                      max={Moment().add(30, "d").format("YYYY-MM-DD")}
                      className="form-control"
                      onChange={(e) => setcheckinDate(e.target.value)}
                      vaue={checkinDate}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 pt-5 d-flex justify-content-center">
                    <button type="submit" className="btn btn-blue col-white">
                      Calculate Package Price
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center">
                <div
                  className="col-lg-12 col-10 book-now"
                  id="book"
                  align="left"
                >
                  <div className="space-t">
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <p style={{ color: "white" }}>Package Base Price:</p>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{basicRoomRate}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <p style={{ color: "white" }}>GST:</p>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{gstOnEffectivePrice}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <p style={{ color: "white" }}>Service Fee:</p>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{serviceFee.toFixed(2)}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <p style={{ color: "white" }}>Rev. of service fee:</p>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">-{reversalOnFf}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <b style={{ color: "white" }}>Total price:</b>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{grossAmount}</span>
                      </div>
                    </div>
                  </div>
                  <div align="center">
                    {" "}
                    <button
                      onClick={payNow}
                      type="button"
                      style={{ background: "white", width: "80%" }}
                      className="btn btn-default"
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      <br />
      <br />
      <Travelfooter />
    </div>
  );
}

export default Traveldetails;
