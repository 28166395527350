import React, { useState } from "react";
import Footer from "./Footer";
import UserNavbar from "./UserNavbar";
import { SetStepsGoal, GetStepsGoal } from "../store/slices/goal";
import { useDispatch } from "react-redux";
import Steps from "../images/dashboard/step.png";
import sleep from "../images/dashboard/sleep.png";
import water from "../images/dashboard/water.png";

const MyGoals = () => {
  const dispatch = useDispatch();

  const [dailyStepCountGoal, setdailyStepCountGoal] = useState("");
  const [dailyWaterGoal, setdailyWaterGoal] = useState("");
  const [dailySleepGoalInSeconds, setdailySleepGoalInSeconds] = useState("");
  const [sleepTime, setsleepTime] = useState("");
  const [wakeupTime, setwakeupTime] = useState("");

  const [alertdailyStepCountGoal, setAlertdailyStepCountGoal] = useState("");
  const [alertdailyWaterGoal, setAlertdailyWaterGoal] = useState("");
  const [alertsleepTime, setAlertsleepTime] = useState("");
  const [alertwakeupTime, setAlertwakeupTime] = useState("");

  React.useEffect(() => {
    dispatch(GetStepsGoal())
      .unwrap()
      .then((data) => {
        setdailyStepCountGoal(data.user.data[0].dailyStepCountGoal);
        setdailyWaterGoal(data.user.data[0].dailyWaterGoal);
        setdailySleepGoalInSeconds(data.user.data[0].dailySleepGoalInSeconds);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  
  async function stepGoal(e) {
    e.preventDefault();
    let item = { set: { dailyStepCountGoal } };
    dispatch(SetStepsGoal(item))
      .unwrap()
      .then(() => {
        alert("Saved successfully");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function waterGoal(e) {
    e.preventDefault();
    let item = { set: { dailyWaterGoal } };
    dispatch(SetStepsGoal(item))
      .unwrap()
      .then(() => {
        alert("Saved successfully");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function stepSleepTime(e) {
    e.preventDefault();
    let item = { set: { sleepTime, wakeupTime } };
    dispatch(SetStepsGoal(item))
      .unwrap()
      .then(() => {
        alert("Saved successfully");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const savestep = (e) =>{

      const number4 = /^[1-3][0-9][0-9][0-9]?$|^10000$/;
      if (number4.test(dailyStepCountGoal)) {
        setAlertdailyStepCountGoal("");
      } else if (!number4.test(dailyStepCountGoal) && dailyStepCountGoal === "") {
        setAlertdailyStepCountGoal("Please enter");
        e.preventDefault();
      } else {
        setAlertdailyStepCountGoal("Value should be more then 1 less then 10000");
        e.preventDefault();
      }

  }


  const savewater = (e)=>{


    const number4 = /^[1-3][0-9][0-9][0-9]?$|^150$/;
    if (number4.test(dailyWaterGoal)) {
      setAlertdailyWaterGoal("");
    } else if (!number4.test(dailyWaterGoal) && dailyWaterGoal === "") {
      setAlertdailyWaterGoal("Please enter");
      e.preventDefault();
    } else {
      setAlertdailyWaterGoal("Value should be more then 1 less then 50");
      e.preventDefault();
    }
  }

  const clear = (e)=>{
    // setdailyStepCountGoal("");
    // setdailyWaterGoal("");
    // setsleepTime("");
    // setwakeupTime("");
    setAlertdailyStepCountGoal("");
    setAlertdailyWaterGoal("");
    setAlertsleepTime("");
    setAlertwakeupTime("");

  }


  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <div className="col-md-12">
                  <h3 className="mb-2">My Goals</h3>
                </div>
                <div className="row">
                  <div className="col-md-4 col-xs-6 mt-1 mb-1">
                    <div className="calculator">
                      <div className="d-flex justify-content-center  pb-2">
                        <img
                          src={Steps}
                          alt=""
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                      <div className="row pb-2">
                        <div className="col-md-12" align="center">
                          <h5>{dailyStepCountGoal} Steps</h5>

                          <button
                            type="button"
                            className="btn btn-warning col_white rotate mt-3"
                            data-bs-toggle="modal"
                            data-bs-target="#myModalsteps"
                          >
                            Set Steps Goal
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-6 mt-1 mb-1">
                    <div className="calculator">
                      <div className="d-flex justify-content-center  pb-2">
                        <img
                          src={water}
                          alt=""
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                      <div className="row pb-2" align="center">
                        <div className="col-md-12" align="center">
                          <h5>{dailyWaterGoal} Glasses of Water</h5>

                          <button
                            type="button"
                            className="btn btn-warning col_white rotate mt-4"
                            data-bs-toggle="modal"
                            data-bs-target="#myModalWater"
                          >
                            Set Water Intake Goal
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-6 mt-1 mb-1">
                    <div className="calculator">
                      <div
                        className="d-flex justify-content-center  pb-2"
                        align="center"
                      >
                        <img
                          src={sleep}
                          alt=""
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                      <div className="row pb-2" align="center">
                        <div className="col-md-12" align="center">
                          <h5>{dailySleepGoalInSeconds}</h5>

                          <button
                            type="button"
                            className="btn btn-warning col_white rotate mt-4"
                            data-bs-toggle="modal"
                            data-bs-target="#myModalSleep"
                          >
                            Set Sleep Goal
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="myModalWater" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{
            position: "relative",
            height: "100%",
            marginTop: "0%",
          }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Set Water Intake Goal</h4>
              <button
                type="button"
                className="btn btn-close"
                // onClick={close}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={waterGoal}>
                <div className="row">
                  <div className="col-md-2 col-4">No.of Glasses:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={dailyWaterGoal}
                      onChange={(e) => setdailyWaterGoal(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter the calories"
                      min="1"
                      style={{ marginBottom: "2%" }}
                    />
                  <p className="alert-message">{alertdailyWaterGoal}</p>

                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    onClick={savewater}
                    className="btn btn-warning col-white"
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    onClick={clear}
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background"></div>
      </div>
      <div className="modal fade" id="myModalsteps" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{
            position: "relative",
            height: "100%",
            marginTop: "0%",
          }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Set Steps Goal</h4>
              <button
                type="button"
                className="btn btn-close"
                // onClick={close}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body" align="center">
        <form action="" onSubmit={stepGoal}>
                <div className="row">
                  <div className="col-md-2 col-4">Steps:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={dailyStepCountGoal}
                      onChange={(e) => setdailyStepCountGoal(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter the calories"
                      min="1"
                      style={{ marginBottom: "2%" }}
                    />
                  <p className="alert-message">{alertdailyStepCountGoal}</p>

                  </div>

                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    onClick={savestep}
                    className="btn btn-warning col-white"
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    onClick={clear}
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background"></div>
      </div>
      <div className="modal fade" id="myModalSleep" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{
            position: "relative",
            height: "100%",
            marginTop: "0%",
          }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Set Sleep Goal</h4>
              <button
                type="button"
                className="btn btn-close"
                // onClick={close}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={stepSleepTime}>
                <div className="row">
                  <div className="col-md-3 col-4">Sleep Time:</div>
                  <div className="col-md-9 col-8">
                    <input
                      value={sleepTime}
                      step="1"
                      onChange={(e) => setsleepTime(e.target.value)}
                      type="time"
                      className="edit_text_form"
                      style={{ marginBottom: "2%", width: "50%" }}
                      required
                    />
                  </div>
                  <div className="col-md-3 col-4">Wakeup Time:</div>
                  <div className="col-md-9 col-8">
                    <input
                      value={wakeupTime}
                      step="1"
                      onChange={(e) => setwakeupTime(e.target.value)}
                      type="time"
                      className="edit_text_form"
                      placeholder="Enter the calories"
                      min="1"
                      style={{ marginBottom: "2%", width: "50%" }}
                      required
                    />
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    // onClick={save}
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};
export default MyGoals;
