import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import Footer from "./Footer";
import phyi from "./../images/tab/phyi.jpg";
import Meter from "./../images/tab/meter.png";
import Bed from "./../images/tab/well treated.png";
import Doctor from "./../images/tab/doctors.png";
import planner from "./../images/tab/shedule.png";
import user from "./../images/user.png";
import call from "./../images/tab/call back.png";
import man2 from "./../images/tab/doctor2.png";
import plus1 from "./../images/tab/notepad.png";
import Monitor from "./../images/tab/monitor.png";
import Home from "./../images/tab/home.png";
import Covid from "./../images/tab/covid.png";
import four from "./../images/blog/four.png";
import four2 from "./../images/blog/four2.png";
import orthopedics from "./../images/blog/orthopedics.png";
import sports from "./../images/blog/Sports.png";
import neurological from "./../images/blog/Neurological.png";
import paediatrics from "./../images/blog/Paediatrics.png";
import geriatric from "./../images/blog/Geriatric.png";
import chiropractic from "./../images/blog/chiropractic.png";
import physical from "./../images/blog/physical-therapy.png";
import android from "./../images/blog/screen1.jpg";
import ios from "./../images/physiotherapy/Physiotheraphy - Download the App.png";
import therapeutic from "./../images/blog/Therapeutic Fitness.png";
import workplace from "./../images/blog/workplace.png";
import "./services.css";
import banner1 from "./../images/physiotherapy/banner1.jpg";
import banner2 from "./../images/physiotherapy/banner2.jpg";
import banner4 from "./../images/physiotherapy/banner4.jpg";
import physiotherapy2 from "./../images/physiotherapy/physiotherapy2.png";
import physiotherapy3 from "./../images/physiotherapy/physiotherapy3.png";
import Navbar from "./navbar";
import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Frequently } from "../store/slices/frequently";
import { getPhysioBlog } from "../store/slices/blog";
import { TravelEnquiry } from "../store/slices/enquiry";
import { getExperts } from "../store/slices/experts";
import Gapp from "./../images/project/Gapp.png";
import Iapp from "./../images/project/apple.png";

//testimonial images
import Madhav from "./../images/testimonial/madhav.png";
import Omkar from "./../images/testimonial/omkar.png";
import Harshal from "./../images/testimonial/harshal.png";
import Bobby from "./../images/testimonial/bobby.png";
function Physiotherapy() {
  let enquiryFor = "Physiotherapy";
  let topExpertise = "6229a980305897106867f787";
  localStorage.setItem("topExpertise", topExpertise);
  const [faq, setFaq] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [experts, setExperts] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("");

  const dispatch = useDispatch();

  //form validation
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const [alertEmail1, setalertEmail1] = useState("");
  const [alertcontactNumber1, setalertcontactNumber1] = useState("");
  const [alertName1, setalertName1] = useState("");
  const [alertCity1, setalertCity1] = useState("");

  const [alertEmail2, setalertEmail2] = useState("");
  const [alertcontactNumber2, setalertcontactNumber2] = useState("");
  const [alertName2, setalertName2] = useState("");
  const [alertCity2, setalertCity2] = useState("");

  const [alertEmail3, setalertEmail3] = useState("");
  const [alertcontactNumber3, setalertcontactNumber3] = useState("");
  const [alertName3, setalertName3] = useState("");
  const [alertCity3, setalertCity3] = useState("");

  //side btn enquiry
  const phyEnquiry = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };
  async function enquiry(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myenquiry").style.display = "none";
      })
      .catch(({ emessage }) => {
        alert(emessage);
      });
  }

  //form enquiry
  const phyEnquiry1 = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName1("");
    } else if (!vName.test(name) && name === "") {
      setalertName1("Please enter your name");
      e.preventDefault();
    } else {
      setalertName1("Please enter a valid name");
      e.preventDefault();
    }

    const vCity = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vCity.test(city)) {
      setalertCity1("");
    } else if (!vCity.test(city) && city === "") {
      setalertCity1("Please enter your city");
      e.preventDefault();
    } else {
      setalertCity1("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber1("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber1("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber1("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail1("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail1("Please enter your  email");
      e.preventDefault();
    } else {
      setalertEmail1("Please enter a valid email");
      e.preventDefault();
    }
  };
  async function enquiry1(e) {
    let enquiryFor = "generalEnquiry";
    let category = "Physiotherapy";
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
      category,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        setCity("");
      })
      .catch(({ emessage }) => {
        alert(emessage);
      });
  }

  const phyEnquiry2 = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName2("");
    } else if (!vName.test(name) && name === "") {
      setalertName2("Please enter your name");
      e.preventDefault();
    } else {
      setalertName2("Please enter a valid name");
      e.preventDefault();
    }

    const vCity = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vCity.test(city)) {
      setalertCity2("");
    } else if (!vCity.test(city) && city === "") {
      setalertCity2("Please enter your city");
      e.preventDefault();
    } else {
      setalertCity2("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber2("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber2("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber2("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail2("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail2("Please enter your  email");
      e.preventDefault();
    } else {
      setalertEmail2("Please enter a valid email");
      e.preventDefault();
    }
  };

  const phyEnquiry3 = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName3("");
    } else if (!vName.test(name) && name === "") {
      setalertName3("Please enter your name");
      e.preventDefault();
    } else {
      setalertName3("Please enter a valid name");
      e.preventDefault();
    }

    const vCity = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vCity.test(city)) {
      setalertCity3("");
    } else if (!vCity.test(city) && city === "") {
      setalertCity3("Please enter your city");
      e.preventDefault();
    } else {
      setalertCity3("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber3("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber3("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber3("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail3("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail3("Please enter your  email");
      e.preventDefault();
    } else {
      setalertEmail3("Please enter a valid email");
      e.preventDefault();
    }
  };

  const formReset = (e) => {
    setEmail("");
    setMessage("");
    setName("");
    setCity("");
    setcontactNumber("");
    setalertName("");
    setalertMesssage("");
    setalertcontactNumber("");
    setalertEmail("");
  };

  React.useEffect(() => {
    dispatch(Frequently())
      .unwrap()
      .then((data) => {
        setFaq(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getPhysioBlog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getExperts())
      .unwrap()
      .then((data) => {
        setExperts(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          // background: "orange",
          // color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          // background: "orange",
          // color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow style={{ background: "black" }} />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertName("");
    setalertMesssage("");
    setalertcontactNumber("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />
      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myenquiry"
          style={{ marginRight: "45px" }}
        >
          Enquire Now
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="">
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner1}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top"></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner2}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top"></Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner4}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top"></Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <div className="col-lg-12 d-flex justify-content-center section-title pt-5 pb-5">
        <h3>Physiotherapy</h3>
      </div>

      <div className="bg-grad pt-1">
        <form className="container contact-form" onSubmit={enquiry1}>
          <div className="section-title">
            <h3 align="center" style={{ fontWeight: "400", color: "white" }}>
              Schedule a free appointment with our expert
            </h3>
          </div>
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName1}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber1}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="City"
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <p className="alert-message">{alertCity1}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail1}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <p className="col-white">
                By clicking Request Now, you agree to our&nbsp;
                <a
                  href="https://healthonify.com/Terms-condition"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Terms and Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://healthonify.com/Privacy-policy"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>

            <div className="col-md-12 pt-3 pb-2">
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white submit-btn"
                  onClick={phyEnquiry1}
                >
                  Request Now
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-gray col-white submit-btn"
                  onClick={formReset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <h2 className="text-center pt-4">Physiotherapy Services</h2>

      <section className="project-details-area mt-1 mb-3  features-section">
        <div className="container mt-1 mb-2">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 pt-1 ">
              <Link to="/orthopedic-physiotherapy">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_left_1 move-right">
                  <p className=" met_text">
                    <img src={orthopedics} className=" phys_icon" alt="" />
                    Orthopaedic Physiotherapy
                  </p>
                </div>
              </Link>
              <Link to="/nurological-physiotherapy">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_left_2 move-right">
                  <p className=" met_text">
                    <img src={neurological} className="phys_icon" alt="" />
                    Neurological Physiotherapy
                  </p>
                </div>
              </Link>
              <Link to="/cardiothoracic-physiotherapy">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_left_3 move-right">
                  <p className=" met_text">
                    <img src={chiropractic} className="phys_icon" alt="" />
                    Cardiothoracic Physiotherapy
                  </p>
                </div>
              </Link>
              <Link to="/women-physiotherapy">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_left_4 move-right">
                  <p className=" met_text">
                    <img src={chiropractic} className="phys_icon" alt="" />
                    Women Physiotherapy
                  </p>
                </div>
              </Link>
              <Link to="/workplace-ergonomics">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_left_5 move-right">
                  <p className=" met_text">
                    <img src={workplace} className="phys_icon" alt="" />
                    WorkPlace Ergonomics
                  </p>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center"
              style={{ alignItems: "center" }}
            >
              <img
                src={phyi}
                alt="phy_img"
                className="ovel_img_sh fade_img_round_up"
              />
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 pt-1">
              <Link to="/sports-physiotherapy">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_right_1 move-left">
                  <p className="met_text">
                    <img src={sports} className="phys_icon" alt="" />
                    Sports Physiotherapy
                  </p>
                </div>
              </Link>
              <Link to="/paediatrics-physiothepary">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_right_2 move-left">
                  <p className=" met_text">
                    <img src={paediatrics} className="phys_icon" alt="" />
                    Paediatrics Physiotherapy
                  </p>
                </div>
              </Link>
              <Link to="/geriatric-physiotherapy">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_right_3 move-left">
                  <p className=" met_text">
                    <img src={geriatric} className="phys_icon" alt="" />
                    Geriatric Physiotherapy
                  </p>
                </div>
              </Link>
              <Link to="/therapeutic-physiotherapy">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_right_4 move-left">
                  <p className=" met_text">
                    <img src={therapeutic} className="phys_icon" alt="" />
                    Therapeutic Fitness/Rehab
                  </p>
                </div>
              </Link>
              <Link to="/rehab">
                <div className="col-lg-12 bg-sh3 mt-1 mb-3 animate_box_right_5 move-left">
                  <p className=" met_text">
                    <img src={physical} className="phys_icon" alt="" />
                    Physio/ Rehab E-com
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section  features-area pt-4">
        <div className="container">
          <div className="col-lg-12 d-flex justify-content-center section-title">
            <h3>Healthonify’s Physiotherapy at home</h3>
          </div>

          <div className="pt-1 mt-1">
            <p className="col-box">
              Healthonify is delighted to offer Physiotherapy at the comfort of
              your home. Patients feel more comfortable and relaxed avoiding the
              hassles of reaching out to a clinic in pain, thereby availing a
              therapy which will be cost effective, convenient, saving time and
              energy and trouble to the patients. Our qualified Physiotherapists
              will fix a prior appointment with you and will be there at your
              home with all required equipment’s for the therapy. Relax – you
              will be taken care.
            </p>
          </div>
          <br />
          <br />
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-lg-7 col-md-12 ">
              <div className="col-lg-12 d-flex justify-content-center">
                <p className="phy_text3 ">How it works</p>
              </div>
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={four} alt="imag2" className="phy_img2" />
              </div>
            </div>

            <div className="col-lg-5 col-md-12 d-flex justify-content-center">
              <img src={physiotherapy3} alt="img" className=" phy_img" />
            </div>
          </div>
        </div>
        <div className="bg-grad">
          <form className="container contact-form mt-5" onSubmit={enquiry1}>
            <div className="section-title">
              <h3 align="center" style={{ fontWeight: "400", color: "white" }}>
                <br />
                Want Home visit! Book Now
                <span style={{ fontSize: "30px", fontWeight: "600" }}></span>
              </h3>
            </div>
            <div className="row mt-5">
              <div className="col-md-3">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p className="alert-message">{alertName2}</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Mobile number"
                    className="form-control"
                    value={contactNumber}
                    onChange={(e) => setcontactNumber(e.target.value)}
                  />
                  <p className="alert-message">{alertcontactNumber2}</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="City"
                    className="form-control"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <p className="alert-message">{alertCity2}</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="alert-message">{alertEmail2}</p>
                </div>
              </div>

              <div className="d-flex justify-content-start">
                <p className="col-white">
                  By clicking Request Now, you agree to our&nbsp;
                  <a
                    href="https://healthonify.com/Terms-condition"
                    target="_blank"
                    style={{ color: "#ffc107" }}
                  >
                    Terms and Conditions
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href="https://healthonify.com/Privacy-policy"
                    target="_blank"
                    style={{ color: "#ffc107" }}
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>

              <div className="col-md-12 pt-3 pb-2">
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white submit-btn"
                    onClick={phyEnquiry2}
                  >
                    Request Now
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-gray col-white submit-btn"
                    onClick={formReset}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      <section className="container features-section">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Healthonify’s Online Physiotherapy </h3>
        </div>

        <div className="pt-2 mt-2">
          <p className="col-box">
            You may have a physiotherapy condition, but you may not be fully
            aware of the same. It may be difficult at times to take out time
            from your busy schedule to go and meet the Physiotherapist.
            Healthonify offers you the solution - Healthonify’s Online
            Physiotherapy provides you the convenience to connect with the
            Physiotherapist online from your home or workplace and get the
            required guidance and therapy. Expert Chief Physiotherapist designs
            the entire treatment plan and under his guidance a team will aid you
            online for the treatment doing guided exercises online, on mobile or
            laptop or on your TV. Periodic counselling is done online and
            progress is also monitored online and if any equipment-based
            treatment is required then it can be meted out at our nearest
            partner clinic or we can schedule a Home visit to complete the
            service.
          </p>
        </div>

        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12 d-flex justify-content-center pb-2">
              <div className="features-content-area">
                <img
                  src={physiotherapy2}
                  className="phy_img"
                  style={{ width: "400px", height: "400px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-12 pb-2">
              <div className="col-lg-12 d-flex justify-content-center">
                <p className="phy_text3">How it works</p>
              </div>
              <div className="d-flex justify-content-center">
                <img src={four2} alt="imag2" className="phy_img2" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="consultation bg-grad">
        <form className=" container contact-form mt-5" onSubmit={enquiry1}>
          <h3 align="center " className="col-white pt-3">
            Book Free Consultation
          </h3>

          <div className="row mt-5">
            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName3}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber3}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="City"
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <p className="alert-message">{alertCity3}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail3}</p>
              </div>
            </div>

            <div className="d-flex justify-content-start">
              <p className="col-white">
                By clicking Request Now, you agree to our&nbsp;
                <a
                  href="https://healthonify.com/Terms-condition"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Terms and Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://healthonify.com/Privacy-policy"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>

            <div className="col-md-12 pt-2 pb-2">
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white submit-btn"
                  onClick={phyEnquiry3}
                >
                  Request Now
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-gray col-white submit-btn"
                  onClick={formReset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <section className="features-area pt-100 ">
        <div className="container">
          <div className="col-lg-12 d-flex justify-content-center section-title">
            <h3>Healthonify Physiotherapy </h3>
          </div>

          <div className=" pb-5">
            <p className="col-box">
              We are different…We have a team of qualified expert counsellors,
              experienced Physiotherapists for different Physiotherapy
              conditions and technology to guide and help you. Our main USP –
              Healthonify Technology which is a combination of online and
              offline services with help of App and Home visit experts which
              provides you all the required solutions to lead a healthy and
              disease-free life.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Back-pain">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Back Pain </h4>
                    </div>
                  </div>
                  <p className="col-white">
                    80% of all healthcare expenses are due to low back pain.
                    Schedule an appointment now for physiotherapy.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Knee-pain">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Knee Pain </h4>
                    </div>
                  </div>
                  <p className="col-white">
                    Suffering from Knee pain? We can help you. Schedule an
                    appointment for physiotherapy.
                    <br />
                    &nbsp;
                    <br />
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Neck-pain">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Neck Pain </h4>
                    </div>
                  </div>
                  <p className="col-white">
                    Want to get rid of neck pain? Physiotherapy can help you
                    relieve the pain and relax your muscles. <br />
                    &nbsp;
                    <br />
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Arthitis">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Arthritis </h4>
                    </div>
                  </div>
                  <p className="col-white">
                    Having arthritis? Physiotherapy can help you overcome the
                    pain and discomfort. Book your appointment now.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Neuro-disorders">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Neuro Disorder</h4>
                    </div>
                  </div>
                  <p className="col-white">
                    Physiotherapy can help you get your life back for patients
                    suffering from neuro disorder. Schedule an appointment now.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Post-surgery">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Post Surgery</h4>
                    </div>
                  </div>
                  <p className="col-white">
                    Had a surgery? You need the best possible physio care to get
                    back to normal. Book an appointment. <br />
                    &nbsp;
                    <br />
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Slipped-disc">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Slipped Disc </h4>
                    </div>
                  </div>
                  <p className="col-white">
                    Suffering from slipped disc? Healthonify can help you
                    recover faster.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Shoulder-pain">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Shoulder Pain</h4>
                    </div>
                  </div>
                  <p className="col-white">
                    Suffering from shoulder pain? Physiotherapy can help you
                    overcome strained, torn shoulder.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <Link to="/Sports-injury">
                <div className="single-features-item bg-orange-card">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h4>Sports Injury </h4>
                    </div>
                  </div>
                  <p className="col-white">
                    Healthonify is the best place for treatment of sports
                    injuries. Schedule and appointment.
                  </p>
                </div>
              </Link>
            </div>

            <center>
              <button
                className="btn col-box btn-warning col-white edit-btns2 mb-5"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#myenquiry"
                style={{ fontSize: "19px" }}
              >
                Schedule A Free Appointment Now
              </button>
            </center>
          </div>
        </div>
      </section>

      <section className="choose-section">
        <div className="container">
          <div className="col-lg-12 d-flex justify-content-center section-title">
            <h3>Our treatment policy</h3>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-features-item bg-white2">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <h4 style={{ fontSize: "18px", color: "white" }}>
                      Access Advance
                    </h4>
                  </div>
                </div>

                <p className="col-white">
                  At Healthonify, we just don’t treat the injury or condition we
                  focus on treating the root cause and provide a healthy
                  lifestyle. We consider you as an opportunity to serve, not as
                  a problem to be solved.
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-features-item bg-white2">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <h4 style={{ fontSize: "18px", color: "white" }}>
                      Treat The Cause
                    </h4>
                  </div>
                </div>
                <p className="col-white">
                  At Healthonify, we just don’t treat the injury or condition;
                  we focus on treating the root cause and provide a healthy
                  lifestyle. We consider you as an opportunity to serve, not as
                  a problem to be solved.
                  <br />
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-features-item bg-white2">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <h4 style={{ fontSize: "18px", color: "white" }}>
                      Consult Often
                    </h4>
                  </div>
                </div>

                <p className="col-white">
                  Healthonify offers highly effective realistic, quality and
                  evidence based treatment protocols to ensure swift &
                  individualised recovery performance with as many as sessions
                  <br />
                  &nbsp;
                  <br />
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-features-item bg-white2">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <h4 style={{ fontSize: "18px", color: "white" }}>
                      Unique & Effective
                    </h4>
                  </div>
                </div>

                <p className="col-white">
                  Healthonify understands your demand for immediate recovery to
                  step back to your normal life. Our qualified team will help
                  you serve the best with our leading edge treatment protocols
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-area pt-100 ">
        <div className="container">
          <div className="col-lg-12 d-flex justify-content-center section-title">
            <h3>How we work </h3>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-white3">
                <center>
                  <img
                    src={call}
                    alt=""
                    style={{ height: "50px", width: "50px" }}
                    className="head_animation"
                  />
                </center>

                <h3 className="text-center">Request A Call Back</h3>

                <p>Book an appointment or request a call back on website</p>

                <br />
                <br />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-white3">
                <center>
                  <img
                    src={planner}
                    alt=""
                    style={{ height: "50px", width: "50px" }}
                    className="flip_animation"
                  />
                </center>

                <h3 className="text-center">Get Scheduled</h3>

                <p>
                  Our executive gets in touch with you & schedules an
                  appointment with our expert
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-white3">
                <center>
                  <img
                    src={Doctor}
                    alt=""
                    style={{ height: "50px", width: "50px" }}
                  />
                </center>

                <h3 className="text-center">Get Assessed</h3>

                <p>
                  Quality assessment of the condition will be done by our
                  experts either virtually or at your home
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-white3">
                <center>
                  <img
                    src={Bed}
                    alt=""
                    style={{ height: "50px", width: "50px" }}
                    className="border_animate_round_move"
                  />
                </center>

                <h3 className="text-center">Get Treated & Monitored</h3>

                <p>
                  With personalised treatment plans, get treated at the comfort
                  of your home with continuous progress monitoring
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="choose-section" style={{ background: "#E9610C" }}>
        <div className="container">
          <div className="features-title">
            <h3 align="center">Why Choose Us</h3>
          </div>

          <div className="row mt-5" align="center">
            <div className="col-lg-2 col-md-6 col-sm-6 ">
              <div
                className="icons move-up"
                style={{
                  height: "100px",
                  width: "100px",
                  background: "#C04F09",
                }}
              >
                <img
                  src={plus1}
                  alt=""
                  style={{ height: "70px", width: "70px" }}
                />
              </div>
              <br />
              <br />
              <br />
              <h6 style={{ color: "white" }}>
                Evidence based treatment protocols
              </h6>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 ">
              <div
                className="icons move-up"
                style={{
                  height: "100px",
                  width: "100px",
                  background: "#C04F09",
                }}
              >
                <img
                  src={man2}
                  alt=""
                  style={{ height: "70px", width: "70px" }}
                />
              </div>
              <br />
              <br />
              <br />
              <h6 style={{ color: "white" }}>
                Professional mannerisms & reliable methods of approach
              </h6>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 ">
              <div
                className="icons move-up"
                style={{
                  height: "100px",
                  width: "100px",
                  background: "#C04F09",
                }}
              >
                <img
                  src={Monitor}
                  alt=""
                  style={{ height: "70px", width: "70px" }}
                />
              </div>
              <br />
              <br />
              <br />
              <h6 style={{ color: "white" }}>
                Technology based progress monitoring and Quality service
              </h6>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 ">
              <div
                className="icons move-up"
                style={{
                  height: "100px",
                  width: "100px",
                  background: "#C04F09",
                }}
              >
                <img
                  src={Home}
                  alt=""
                  style={{ height: "70px", width: "70px" }}
                />
              </div>
              <br />
              <br />
              <br />
              <h6 style={{ color: "white" }}>
                Quality service at comfort of your home
              </h6>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 ">
              <div
                className="icons move-up"
                style={{
                  height: "100px",
                  width: "100px",
                  background: "#C04F09",
                }}
              >
                <img
                  src={Meter}
                  alt=""
                  style={{ height: "70px", width: "70px" }}
                />
              </div>
              <br />
              <br />
              <br />
              <h6 style={{ color: "white" }}>
                Realistic goal setting & outcome measuring tools
              </h6>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 ">
              <div
                className="icons move-up"
                style={{
                  height: "100px",
                  width: "100px",
                  background: "#C04F09",
                }}
              >
                <img
                  src={Covid}
                  alt=""
                  style={{ height: "70px", width: "70px" }}
                />
              </div>
              <br />
              <br />
              <br />
              <h6 style={{ color: "white" }}>Covid - 19 safety guidelines</h6>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial pt-5">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <p className="wel-text pb-2">Our Experts</p>
          </div>
          <div className="row">
            {experts.map((expertVal, index) => (
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-features-item ">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={user} alt="" />
                  </div>

                  <h4 className="text-center">
                    {expertVal.firstName} {expertVal.lastName}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="livewell container pt-5 pb-5">
        <div className="col-lg-12">
          <p className="wel-text pb-2">Blog</p>
        </div>
        <div className="container">
          <Slider {...settings}>
            {blogs.map((blogsVal, index) => (
              <div className="d-flex justify-content-center">
                <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                  <img
                    src={blogsVal.mediaLink}
                    alt=""
                    className="lie-img-slide"
                  />
                  <p>{blogsVal.blogTitle}</p>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className="services-section12 pt-100 pb-60 ">
        <div className="container">
          <div className="section-title">
            <h3>Frequently Asked Questions</h3>
          </div>

          <br />
          <div className="row">
            <div className="col-lg-12">
              <Accordion>
                {faq.map((FrequentlyVal, index) => (
                  <Accordion.Item eventKey={FrequentlyVal._id}>
                    <Accordion.Header>
                      {FrequentlyVal.question}
                    </Accordion.Header>

                    <Accordion.Body>{FrequentlyVal.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <section className="project-details-area mt-5 mb-3">
        <h3 align="center">Download The App</h3>

        <div className="container mb-2">
          <center>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <img src={ios} alt="iphone" />
              </div>
              <div className="col-md-12 text-center">
                <img src={Gapp} alt="" style={{ height: "80px" }} />
                &nbsp;
                <img src={Iapp} alt="" style={{ height: "80px" }} />
              </div>
            </div>
          </center>
        </div>
      </section>

      <section className="section-card pt-4 pb-3">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text  text-center pb-2">Testimonial</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Madhav} alt="" />
                  </div>
                  <h4 className="text-center">Madhav</h4>

                  <p className="col-white">
                    Work from home took on toll on my health. I had severe
                    backache and stiff neck issues. Healthonify’s Physio Dr
                    Vidya helped me to overcome my issues. Their online
                    physiotherapy is worth trying. I could easily follow the
                    exercises recommended from the ease of my home. I am using
                    their App as it has so many interesting features and
                    content.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Omkar} alt="" />
                  </div>
                  <h4 className="text-center">Omkar</h4>

                  <p className="col-white">
                    My mother had a fall and had a fracture in her right leg. I
                    got in touch with the Healthonify online. Physiotherapist
                    Shravan has done a wonderful job to get my mom stand on her
                    own feet. This all happened in a span of 2 months. Thanks to
                    the Healthonify team.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Harshal} alt="" />
                  </div>
                  <h4 className="text-center">Harshal</h4>

                  <p className="col-white">
                    I strained my shoulder while pulling my bag from closet in
                    my cellar last year. I didn't pay any attention and it
                    turned into Frozen shoulder. Doctor Shravan was very
                    encouraging. In 12 sessions I could see the change. Now I
                    feel absolutely good. The recovery was very fast and the App
                    is so easy and convenient to use and exercise.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Bobby} alt="" />
                  </div>
                  <h4 className="text-center">Bobby</h4>

                  <p className="col-white">
                    Got a ligament tear two months ago while running on trek.
                    Thought that that was the last run. I found Healthonify App
                    online. The Doctor Pradeep was very encouraging and
                    persistent. His Home visits and constant online mentoring
                    has helped me progress well. Feeling active again. Iam sure
                    that in one more month I will be able to run my morning jogs
                    for sure.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <Footer />
      <div className="modal fade" id="myenquiry" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header">
              <h4 className="modal-title">Physiotherapy Enquiry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={clearall}
              ></button>
            </div>

            <form onSubmit={enquiry}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Message"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={phyEnquiry}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
    </div>
  );
}

export default Physiotherapy;
