import React, { useState, useEffect, useRef } from "react";
import jammu from "./../images/trip/hotel.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import TravelHeader from "./travel-navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";
import travel3 from "./../images/travel3.jpg";
import travel1 from "./../images/travel2.jpg";
import travel2 from "./../images/travel1.jpg";
import Travelfooter from "./Travelfooter";
import { GethotelSearch } from "../store/slices/hotel";
import Sticky from "react-stickynode";
import { useDispatch, useSelector } from "react-redux";

function HotelSearch() {
  const [hotels, setHotels] = useState([]);
  const { id } = useParams();
  localStorage.setItem("id", id);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(GethotelSearch())
      .unwrap()
      .then((data) => {
        setHotels(data.user.data);
      })
      .catch(({ message }) => {
        //alert(message);
      });
  }, [dispatch]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate("");
  const [count_A, setCount_A] = useState(1);
  const [count_C, setCount_C] = useState(0);
  const [count_R, setCount_R] = useState(1);

  const IncNum_A = () => {
    setCount_A(count_A + 1);
  };

  const IncNum_C = () => {
    setCount_C(count_C + 1);
    // setCount_C(document.getElementById("hidden").classList.toggle("showhere")+1);
  };

  const IncNum_R = () => {
    setCount_R(count_R + 1);
  };
  const DecNum_A = () => {
    setCount_A((count_A) => Math.max(count_A - 1, 1));
  };

  const DecNum_C = () => {
    setCount_C((count_C) => Math.max(count_C - 1, 0));
  };

  const DecNum_R = () => {
    setCount_R((count_R) => Math.max(count_R - 1, 1));
  };

  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 10;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const myDatas = {
    destination: query,
  };
  async function searchHotels(e) {
    e.preventDefault();
    navigate("/travelonify/HotelSearch/", { state: myDatas });
  }
  return (
    <div id="_next">
      <TravelHeader />
      <section>
        <div className="carousel_cover-sticky" style={{ position: "relative" }}>
          <Carousel fade style={{ zIndex: "-10" }}>
            <Carousel.Item interval={2500} style={{ zIndex: "-20 !important" }}>
              <img
                className="d-block w-100 carimg-fixed"
                src={travel3}
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item interval={2500} style={{ zIndex: "-20 !important" }}>
              <img
                className="d-block w-100 carimg-fixed"
                src={travel1}
                alt=""
              />
            </Carousel.Item>

            <Carousel.Item interval={2500} style={{ zIndex: "-20 !important" }}>
              <img
                className="d-block w-100 carimg-fixed"
                src={travel2}
                alt=""
              />
            </Carousel.Item>
          </Carousel>

          <Sticky enabled={true} top={268} bottomBoundary={3000}>
            <div
              className="col-lg-12 col-md-12 fixed-content pb-2"
              style={{
                transition: "1s ease",
                color: "black",
                backgroundColor: navBackground ? " #001E79 " : "transparent",
              }}
            >
              <form className="container" onSubmit={searchHotels}>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-2 col-sm-12 col-md-6">
                    <p className="col-white">Destination</p>
                    <input
                      type="text"
                      placeholder="Destination"
                      className="hotel_form"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      style={{ height: "47px" }}
                    />
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12">
                    <p className="col-white">From</p>
                    <input
                      type="date"
                      className="hotel_form"
                      style={{ height: "47px" }}
                    />
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12">
                    <p className="col-white">To</p>
                    <input
                      type="date"
                      className="hotel_form"
                      style={{ height: "47px" }}
                    />
                  </div>

                  <div
                    className="col-lg-3 col-md-6 col-sm-12"
                    style={{ height: "50px" }}
                  >
                    <p className="col-white">No . of Person</p>
                    <Dropdown className="">
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        className="drop_downs sub_names"
                      >
                        {count_A} Adults , {count_C} Children , {count_R} Rooms
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="droper">
                        <Dropdown className="sub_dropers">
                          <span className="sub_names">Adults</span>{" "}
                          <span className="math">
                            <span className="clickmath" onClick={DecNum_A}>
                              <AiOutlineMinusCircle />
                            </span>
                            <span className="count"> {count_A}</span>
                            <span className="clickmath" onClick={IncNum_A}>
                              <AiOutlinePlusCircle />
                            </span>
                          </span>
                        </Dropdown>
                        <Dropdown className="sub_dropers">
                          <span className="sub_names">Children</span>{" "}
                          <span className="math">
                            <span className="clickmath" onClick={DecNum_C}>
                              <AiOutlineMinusCircle />
                            </span>
                            <span className="count"> {count_C}</span>
                            <span className="clickmath" onClick={IncNum_C}>
                              <AiOutlinePlusCircle />
                            </span>
                          </span>
                        </Dropdown>
                        <div className="row child_count" id="hidden">
                          <div className="col-lg-6 col-md-6 col-sm-12">age</div>
                        </div>
                        <Dropdown className="sub_dropers">
                          <span className="sub_names">Rooms</span>{" "}
                          <span className="math">
                            <span className="clickmath" onClick={DecNum_R}>
                              <AiOutlineMinusCircle />
                            </span>
                            <span className="count"> {count_R}</span>
                            <span className="clickmath" onClick={IncNum_R}>
                              <AiOutlinePlusCircle />
                            </span>
                          </span>
                        </Dropdown>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-lg-2 col-md-12 col-sm-12 ">
                    <button
                      type="button"
                      className="btn btn-warning btn_hotel btn_hotel-mod"
                      style={{
                        marginTop: "1%",
                        lineHeight: "2",
                        width: "100%",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Sticky>
        </div>
      </section>

      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <h3 className="widget-title" style={{ color: "#001E79" }}>
              15 Hotels Found
            </h3>
            <div
              className="col-lg-2 col-md-6"
              style={{ border: "solid 1px silver", borderRadius: "7px" }}
            >
              <aside className="widget-area" id="secondary">
                <b>Star rating</b>
                <div
                  className="widget widget_zash_posts_thumb"
                  style={{ marginTop: "10px" }}
                >
                  <div className="item">
                    <input type="checkbox" style={{ marginRight: "20px" }} />
                    5 Stars
                    <br />
                    <input
                      type="checkbox"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                    />
                    4 Stars
                    <br />
                    <input
                      type="checkbox"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                    />
                    3 Stars
                    <br />
                    <input
                      type="checkbox"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                    />
                    2 Stars
                    <br />
                    <input
                      type="checkbox"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                    />
                    1 Star
                    <br />
                    <br />
                    <b>Review Score</b>
                    <br />
                    <input
                      type="checkbox"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                    />
                    Exceptional 9+
                    <br />
                    <input
                      type="checkbox"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                    />
                    Very Good 8+
                    <br />
                    <input
                      type="checkbox"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                    />
                    Good 7+
                    <br />
                    <input
                      type="checkbox"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                    />
                    Pleasant 6+
                  </div>
                </div>
              </aside>
            </div>

            <div
              className="col-lg-9 col-md-12"
              style={{
                marginLeft: "10px",
              }}
            >
              {hotels.map((hotelVal, index) => (
                <div
                  className="row"
                  style={{
                    padding: "10px",
                    border: "solid 1px silver",
                    borderRadius: "7px",
                  }}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row bg-sh mt-2 mb-3">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <img
                          src={jammu}
                          alt="Card image"
                          className="card-img-top"
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className="col-lg-6  col-md-6 col-sm-6 bor_left">
                        <div className="card-body">
                          <h4
                            className="card-title"
                            style={{ color: "#001E79" }}
                          >
                            {hotelVal.name}
                          </h4>
                          <i className="fa fa-star" style={{ color: "orange" }}></i>
                          <i className="fa fa-star" style={{ color: "orange" }}></i>
                          <i className="fa fa-star" style={{ color: "orange" }}></i>
                          <i className="fa fa-star" style={{ color: "orange" }}></i>
                          <p style={{ marginTop: "20px" }}>
                            <i
                              className="fa fa-map-marker"
                              style={{ color: "darkblue" }}
                            ></i>
                            &nbsp; Bangalore, Karnataka
                          </p>
                          <span style={{ color: "#00239D" }}>Breakfast</span>
                          &nbsp;
                          <span style={{ color: "#00239D" }}>
                            Welcome Drink
                          </span>
                          &nbsp;
                          <span style={{ color: "#00239D" }}>Lunch</span>
                          <p style={{ color: "black" }}>Rs.2,750</p>
                          <Link
                            className="btn btn-blue"
                            style={{ color: "white" }}
                            to={`/travelonify/HotelDetails/${
                              hotelVal._id ? hotelVal._id : null
                            }`}
                          >
                            Book Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Travelfooter />
    </div>
  );
}

export default HotelSearch;
