import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Header from "./navbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getquestions, postAnswer } from "../store/slices/questionnaire";

const Questionnaire = () => {
  const location = useLocation();
  const values = location.state;

  const [qna, setQna] = useState([]);
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState([
    { points: "", questionId: "", answer: "" },
  ]);

  React.useEffect(() => {
    dispatch(getquestions())
      .unwrap()
      .then((data) => {
        setQna(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  const handleAddForms = () => {
    let questions = [...answers];
    questions.push({ points: "", questionId: "", answer: "" });
    setAnswers(questions);
  };

  const handleDeleteForms = (e) => {
    if (answers.length > 1) {
      let questions = [...answers];
      questions.pop();
      setAnswers(questions);
    }
  };

  const handleRowChange = (e, index) => {
    const questions = [...answers];
    questions[index][e.currentTarget.name] = e.currentTarget.value;
    setAnswers(questions);
    // console.log(tourIternary);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <div id="__next">
      <Header />
      <section className="container  pt-5 mt-5">
        <div className="row">
          <div className="col-lg-3" />
          <div className="col-lg-6 mt-5">
            <Carousel responsive={responsive} style={{ marginBottom: "2%" }}>
              {qna.map((QuestionVal, index) => (
                <div style={{ width: "95%" }} key={index}>
                  <h4 className="text-center qna-text">
                    {QuestionVal.question}
                  </h4>
                  <br />

                  {QuestionVal.options.map((ansVal, index) => (
                    <h5 className="col-black ans-text">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="radio1"
                        name="optradio"
                        value="option1"
                      />
                      &nbsp; {ansVal.optionValue}
                    </h5>
                  ))}
                </div>
              ))}
            </Carousel>
            <div className="col-lg-12 d-flex justify-content-end b-btn-b">
              <button type="button" className="btn btn-pink ">
                <Link to="/">Next</Link>
              </button>
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </section>
    </div>
  );
};

export default Questionnaire;
