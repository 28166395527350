import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Navbar from "./navbar";
const Therapeutic = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />
      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Therapeutic Fitness/Rehab</h3>
        </div>

        <div className="pt-4">
          <p>
            Therapeutic exercise involves movement prescribed to correct
            impairments, restore muscular and skeletal function and/or maintain
            a state of well-being. The scientific evidence demonstrating the
            beneficial effects of exercise is indisputable, and the benefits of
            exercise far outweigh the risks in most adults. For most adults, an
            exercise program including aerobic, resistance, flexibility, and
            neuromotor exercise training is indispensable to improve and
            maintain physical fitness and health.
            <br />
            Therapeutic exercise a core skill upon which the profession of
            physiotherapy is based. How does exercise differ from physical
            activity?
            <br />
            Physical activity refers to the contraction of skeletal muscle that
            produces bodily movement and requires energy.
            <br />
            Exercise is a physical activity that is planned and is performed
            with the goal of attaining or maintaining physical fitness. Physical
            fitness is a set of traits that allows an individual to perform
            physical activity.
            <br />
            Therapeutic exercise may include:
            <br />
            <ul>
              <li>aerobic and endurance conditioning and reconditioning</li>
              <li> agility training;</li>
              <li> body mechanics training;</li>
              <li> breathing exercises;</li>
              <li> coordination exercises;</li>
              <li> developmental activities training;</li>
              <li> movement pattern training;</li>
              <li> neuromotor development activities training;</li>
              <li> neuromuscular education or re-education;</li>
              <li> perceptual training;</li>
              <li> range of motion exercises and soft tissue stretching;</li>
              <li> relaxation exercises;</li>
              <li> strength, power, and endurance exercises.</li>
            </ul>
          </p>
          <p>
            The most commonly included exercises fall into 4 groups
            <br />
            <ul>
              <li>
                Strengthening exercises, usually performed with heavy resistance
                and fewer repetitions.
              </li>
              <li>
                Endurance exercises that engage large muscle groups over a
                longer period of time, in the area of 50 to 60% VO2Max to
                achieve greater cardiovascular endurance.
              </li>
              <li>
                Flexibility exercises achieved through stretching and movement.
              </li>
              <li>
                Balance and coordination exercises that focus on maintaining an
                individual's centre of gravity.
              </li>
            </ul>
          </p>
          <p>
            All of these are combinable into exercise programs that work for
            many different types of patients. Different subtypes of exercises
            can help to increase effectiveness or allow participation of
            individuals with special needs based on comorbidities. eg. aquatic
            therapy for stroke patients with balance difficulties.
            <br />
            Basic exercise prescriptions should follow the FITT mnemonic.
            <br />
            F- frequency: number of days per week
            <br />
            I- Intensity: low, moderate or vigorous
            <br />
            T- Time: minutes per session for endurance exercise
            <br />
            T- Type: endurance, strength, flexibility or some combination
          </p>
          <p>
            <b>Endurance Exercises</b>
            <br />
            The dose of exercise can be described using the so-called FITT
            factors, where FITT stands for Frequency, Intensity, Time, and Type
            of activity.
            <br />
            <ul>
              <li>
                Frequency (how often)-Exercise should be carried out 3-5 days a
                week.{" "}
              </li>
              <li>
                {" "}
                Intensity (how hard)-Intensity refers to the rate at which the
                activity is being performed or the magnitude of the effort
                required to perform an activity or exercise.{" "}
              </li>
              <li>
                {" "}
                Time (duration or how long)-A total of 20-60 minutes of
                continuous or intermittent aerobic activity a day should be
                performed.{" "}
              </li>
              <li>
                Type-Rhythmic, aerobic exercise of at least moderate intensity
                that involves large muscle groups and requires little skill to
                perform is recommended for all adults to improve health and CRF.
              </li>
            </ul>
          </p>
          <p>
            <b>Strengthening Exercises</b>
            <br />
            Strength training is done to build up muscle tissue; it typically
            consists of graded exercises involving resistance training. Strength
            training may be high or moderate in intensity.
            <br />
            This modality increases overall strength and comes in three forms:
            isotonic, isometric, and isokinetic.
            <br />
            <ul>
              <li>
                Isotonic consists of dynamic movements with a constant load.{" "}
              </li>
              <li>
                Isokinetic involves a constant velocity with variable load.{" "}
              </li>
              <li>
                Isometric involves muscle contraction that is static with no
                change in muscle length.
              </li>
            </ul>
          </p>
          <p>
            <b>Flexibility Exercises</b>
            <br />
            Flexibility training: slowed, controlled exercises performed in a
            gradual manner (usually in the increment of fifteen to thirty
            seconds) that aid in the range of motion at joints or series of
            joints. Three main types exist: static, dynamic, and PNF
            (proprioceptive neuromuscular facilitation).
            <br />
            Static involves held positions that are effective with little
            required in terms of assistance and time.
            <br /> Dynamic involves repetitive bouncing movements that produce
            muscle stretch over time. <br />
            PNF involves alternating contraction and relaxation of
            agonist/antagonist muscles at specific joints that generally produce
            the largest increase in flexibility.
          </p>
          <p>
            <b>Balance and Co-ordination</b>
            <br />
            Optimal control of balance in an upright stance is an essential
            requirement for sport, daily activities, and prevention of injury.
            For example, impaired postural control is associated with an
            increased risk of ankle sprain. Because of this strong association,
            balance and coordination training are common components of
            prophylactic and therapeutic intervention programs used by athletic
            trainers and other health care providers to treat patients with a
            variety of musculoskeletal conditions. Moreover, mounting evidence
            demonstrates that various balance-training programs improve postural
            control and reduce the recurrence of musculoskeletal injuries.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Therapeutic;
