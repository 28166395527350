import React, { useState } from "react";
import UserNavbar from "./UserNavbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MyBooking } from "./../store/slices/booking";
import Moment from "moment";
const Booking =()=> {
  const navigate = useNavigate("");
  const [bookings, setBookings] = useState([]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(MyBooking())
      .unwrap()
      .then((data) => {
        setBookings(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div className="bg-light">
      <UserNavbar />
      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <div className="bg-white-ptbrl-2 mb-5 mt-1">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th className="wd-15p">Package Name </th>
                          <th className="wd-15p">Booking Number </th>
                          <th className="wd-20p">Date of Booking </th>
                          <th className="wd-15p">Price </th>
                          <th className="wd-10p">Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookings.map((BookingVal, index) => (
                          <tr>
                            <td className="wd-20p">
                              {BookingVal.packageId.packageName}
                            </td>
                            <td className="wd-20p">
                              {BookingVal.bookingNumber}
                            </td>
                            <td className="wd-20p">
                              {Moment(BookingVal.startDate).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td className="wd-20p">
                              {BookingVal.pricingDetails.totalCharge}
                            </td>
                            <td className="wd-20p">{BookingVal.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Booking;
