import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import Carousel from "react-bootstrap/Carousel";
import img from "./../images/fitness/img.jpg";
import reception from "./../images/fitness/reception.jpeg";
import facade from "./../images/fitness/facade.jpeg";
import studio from "./../images/fitness/studio.png";
import floor from "./../images/fitness/floor.png";
import floor1 from "./../images/fitness/floor.jpeg";
import brochure from "./../brochure/Healthonify-Franchise-Brochure.pdf";
import gym from "./../images/homepage/icons/gym.png";
import interest from "./../images/homepage/icons/interest.png";
import investment from "./../images/homepage/icons/investment.png";
import profit from "./../images/homepage/icons/profit.png";
import streams from "./../images/homepage/icons/streams.png";

import business from "./../images/homepage/icons/business.png";
import users from "./../images/homepage/icons/users.png";
import market from "./../images/homepage/icons/market.png";

import gymfranchise from "./../images/fitness/gymfranchise.jpg";

// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Footer from "./Footer";
import Header from "./navbar";

import { TravelEnquiry } from "../store/slices/enquiry";
import banner6 from "./../images/fitness/banner6.png";

const Gymfranchise = () => {
  const dispatch = useDispatch();

  // Fitness Enquire form

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [messages, setMessages] = useState("");
  const [city, setcity] = useState("");
  const [age, setage] = useState("");

  const [alertemail, setAlertEmail] = useState("");
  const [alertname, setAlertName] = useState("");
  const [alertcontactNumber, setAlertContactNumber] = useState("");
  const [alertmessage, setAlertMessage] = useState("");
  const [alertcity, setAlertcity] = useState("");
  const [alertage, setAlertage] = useState("");

  async function fitenqiure(e) {
    let enquiryFor = "generalEnquiry";
    let category = "Gym Franchise";
    const message = messages + ". Wants to invest, " + age;
    e.preventDefault();
    let item = {
      email,
      name,
      contactNumber,
      message,
      city,
      enquiryFor,
      category,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setEmail("");
        setName("");
        setContactNumber("");
        setMessages("");
        setcity("");
        setage("");
        document.getElementById("enquire").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }

  const close = () => {
    setEmail("");
    setName("");
    setContactNumber("");
    setMessages("");
    setcity("");
    setage("");
    setAlertEmail("");
    setAlertName("");
    setAlertContactNumber("");
    setAlertMessage("");
    setAlertcity("");
    setAlertage("");
  };

  const fitenq = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setAlertName("");
    } else if (!vName.test(name) && name === "") {
      setAlertName("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName("Please enter a valid name");
      e.preventDefault();
    }

    const fitname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fitname.test(age)) {
      setAlertage("");
    } else if (!fitname.test(age) && age === "") {
      setAlertage("Please enter how much you are looking to invest");
      e.preventDefault();
    } else {
      setAlertage("");
      // e.preventDefault();
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setAlertContactNumber("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber("Please enter your mobile number");
    } else {
      setAlertContactNumber("Please enter a valid mobile number");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setAlertEmail("Please enter your email");
    } else {
      setAlertEmail("Please enter a valid email");
    }

    const vMessage = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage.test(messages)) {
      setAlertMessage("");
    } else if (!vMessage.test(messages) && messages === "") {
      setAlertMessage("Please enter your interest");
      e.preventDefault();
    } else {
      setAlertMessage("");
      e.preventDefault();
    }

    const textcity = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (textcity.test(city)) {
      setAlertcity("");
    } else if (!textcity.test(city) && city === "") {
      setAlertcity("Please enter city");
      e.preventDefault();
    } else {
      setAlertcity("");
      e.preventDefault();
    }
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      <Header />

      <section>
        <div className="carousel_cover position-relative">
          <Carousel className="">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={gymfranchise}
              />
              <Carousel.Caption>
                <button
                  type="button"
                  className="btn btn-warning col-white adjust-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#enquire"
                >
                  Enquire Now
                </button>
              </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel "
                src={banner2}
                alt=""
              />
              <Carousel.Caption>
                <h2>healthonify gym</h2>
                <button
                  type="button"
                  className="btn btn-warning col-white adjust-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#enquire"
                >
                  Enquire Now
                </button>
              </Carousel.Caption>
            </Carousel.Item> */}

            {/* <Carousel.Item interval={2500}>
              <img className="d-block w-100 phy_img_carousel" src={banner3} />
              <Carousel.Caption>
                <h2>healthonify gym</h2>
                <button
                  type="button"
                  className="btn btn-warning col-white adjust-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#enquire"
                >
                  Enquire Now
                </button>
              </Carousel.Caption>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </section>

      <div className="container pt-5 pb-5">
        <div className=" d-flex justify-content-center section-title pt-5">
          <h3>Healthonify - Fastest Growing Fitness Franchise</h3>
        </div>

        <section className="section-card mt-3 mb-5">
          <div className="row">
            <div className="col-row-5 col-md-4 col-xs-6 mt-2 mb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={investment} alt="" className="icon-f" />
                </div>

                <p
                  className="text-center text-mark-2"
                  style={{ fontWeight: "bold" }}
                >
                  Customizable investment plan from 45L to 2CR{" "}
                </p>
              </div>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-2 mb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={profit} alt="" className="icon-f" />
                </div>
                <p
                  className="text-center text-mark-2 mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  30% to 40% profit
                </p>
              </div>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-2 mb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={interest} alt="" className="icon-f" />
                </div>
                <p
                  className="text-center text-mark-2 mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  2 years to 2.5 years ROI
                </p>
              </div>
            </div>

            <div className="col-row-5 col-md-4 col-xs-6 mt-2 mb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={gym} alt="" className="icon-f" />
                </div>
                <p
                  className="text-center text-mark-2 mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  2500 sq.ft to 8000 sq.ft gyms
                </p>
              </div>
            </div>

            <div className="col-row-5 col-md-4 col-sm-12 mt-2 mb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={streams} alt="" className="icon-f" />
                </div>
                <p
                  className="text-center text-mark-2"
                  style={{ fontWeight: "bold" }}
                >
                  Innovative new revenue streams
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center pt-2 mt-5">
            <a
              href={brochure}
              download
              className="btn btn-warning col-white"
              style={{ fontSize: "20px" }}
            >
              Download Brochure
            </a>
          </div>
        </section>

        <section className="section-card pt-3 pb-5">
          <p className="wel-text text-center pb-2">
            Health & Fitness - An Attractive Business Model
          </p>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 mt-2 mb-4">
              <div className="col-lg-12 col-md-12 col-sm-12 banner-card">
                <div className="col-lg-12 d-flex justify-content-center">
                  <img src={users} alt="" className="flat-icon-mod" />
                </div>
                <p
                  className="text-center col-black mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  6.5 million active users & growing steadily
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 mt-2 mb-4">
              <div className="col-lg-12 col-md-12 col-sm-12 banner-card">
                <div className="col-lg-12 d-flex justify-content-center">
                  <img src={business} alt="" className="flat-icon-mod" />
                </div>
                <p
                  className="text-center col-black mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Fitness business growing at 23% CAGR
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm- mt-2 mb-4">
              <div className="col-lg-12 col-md-12 col-sm-12 banner-card">
                <div className="col-lg-12 d-flex justify-content-center">
                  <img src={market} alt="" className="flat-icon-mod" />
                </div>
                <p
                  className="text-center col-black mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Market size set to become $7 Billion by 2023
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="section-card pt-2 pb-5">
        <p className="wel-text text-center pb-2">Healthonify Franchise</p>
        <div className="position-relative text-top">
          <img
            src={banner6}
            alt="banner"
            className="d-block w-100 phy_img_carousel3"
          />
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-warning col-white"
            data-bs-toggle="modal"
            data-bs-target="#enquire"
            style={{
              color: "box",
              lineHeight: "2 !important",
              width: "200px",
              height: "50px",
              fontSize: "20px",
            }}
          >
            Enquire
          </button>
        </div>
      </section>
      <section className="container ">
        <h3 className="text-center pb-2">Our Story</h3>

        <p className="text-paragraph">
          Healthonify Team has decades of collective experience in setting up
          and operating gyms across India. Healthonify team has the leading edge
          and expertise in setting up a successful franchise. Our team comprises
          of experts in Gym setup, Gym operations, making strategies, MIS,
          Technology to make the Franchisee successful and profitable.
        </p>
        <br />

        <h3 className="text-center pb-2">The Future of the Fitness Industry</h3>

        <p className="text-paragraph">
          Since the time Covid 19 pandemic hit across the world, people have
          realised the value of Health and Fitness. We have seen the trend on
          increasing footfall in the gyms post the lockdown. With the Covid 19
          Pandemic reaching the Epidemic stage, the future of the Fitness
          industry is bright and promising. This is the best time to enter into
          the Fitness Industry which is recession-proof. Always remember – the
          penetration of Indians who are engaged with some sort of Fitness is a
          mere 0.32%, just imagine the potential and opportunity in the Fitness
          industry.
        </p>
        <br />

        <h3 className="text-center pb-2">Why Healthonify Franchisee</h3>

        <p className="text-paragraph">
          We have seen the Ups and Downs in the Fitness Industry, We understand
          the pain points of the Industry and what it needs to run a successful
          and profitable Fitness Center.We know the reasons why gyms fail and we
          have made detailed case studies and worked out on the strategies and
          plans for running a successful and profitable Fitness Centre. We
          participate with the Franchisee from day go, help them in every stage
          and do the handholding to make the Franchisee profitable and
          successful. We have different franchisee module to suit every Fitness
          Owner’s needs. Given below are some of the differentiators of
          Healthonify Franchise business module:
          <br />
          <ul>
            <li>
              Healthonify’s Site selection process – based on Demography,
              spending power, Local competition, Target audience, etc.
            </li>
            <li>
              Healthonify’s Gym set up – Design and Concept. We help the
              franchisee in making the layout plan for the gym based on site
              condition, procurement of Gym equipment, interior design etc. We
              also undertake turnkey contract in setting up the Gym.
            </li>
            <li>
              We follow the CPM/PERT method so that the project is completed on
              time.
            </li>
            <li>
              Healthonify’s pre-launch strategy – Basic launch, Pre sales and
              event support
            </li>
            <li>
              Healthonify’s staff Recruitment and training – We help the
              franchisee in staff recruitment and train them to run the gym
              professionally - KRA’s for the staff. We also conduct periodic
              training for the Gym staff to upgrade them with latest techniques
              in gym operations.
            </li>
            <li>Healthonify’s standard SOP’s for Gym operations</li>
            <li>
              Healthonify’s program design and support – we guide the franchisee
              in designing attractive packages based on competition and need of
              the particular Fitness Center
            </li>
            <li>Healthonify’s Gym management software - CRM</li>
            <li>
              Healthonify’s Digital media marketing support and promotion of the
              franchisee on Healthonify website
            </li>
            <li>Healthonify’s E Commerce Eco systems access to members</li>
            <li>
              Healthonify’s Management Information System (MIS) - Business plan
              for the centre and periodic review mechanism
            </li>
            <li>
              Healthonify’s on-going support program – We are there to handhold
              you
            </li>
            <li>Healthonify’s periodic audits and customer service scores</li>
          </ul>
          <b>
            Healthonify’s Aim – To make the franchisee run the gym
            professionally and profitably with high level of customer
            <br />
            Healthonify’s Reach – Pan India network, tie up with Training
            Academies across India, Tie up with Fitness equipment manufacturers
            and interior designers..to name a few
            <br />
            Healthonify’s Edge – Pan India network of experts and technicians.
            Exclusive special prices for branded gym equipment’s, interior
            designers and Architects
          </b>
        </p>
        <br />
        <div className="row mt-3">
          <h3 className="text-center pb-2">Our Model</h3>
          <div className="col-md-6">
            <h4 className="mt-10">
              <br />
              <br />
              Healthonify – We are different.
              <br /> We do not offer standard gym models to the Franchisee. We
              discuss with the franchisee, understand their mindset and
              experience, look into their Budget and we decide and suggest the
              best business module to make the best and most profitable Fitness
              Center.
            </h4>
          </div>
          <div className="col-md-6">
            <img src={img} alt="" />
          </div>
        </div>
      </section>
      <div className="container">
        <section className="section-card">
          <section className="container pt-5 pb-5">
            <div className="col-lg-12">
              <p className="wel-text text-center pb-2">Healthonify Gym</p>
            </div>
            <div className="container">
              <Slider {...settings}>
                <div className="slick-responsive">
                  <div className="bg-white-shadow">
                    <img
                      src={facade}
                      alt="live-well"
                      className="lie-img-slide-with-text"
                    />
                    <p
                      className="col-black slick-text text-center bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Gym Facade
                    </p>
                  </div>
                </div>

                <div className="slick-responsive">
                  <div className="bg-white-shadow">
                    <img
                      src={reception}
                      alt="live-well"
                      className="lie-img-slide-with-text"
                    />
                    <p
                      className="col-black slick-text text-center bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Reception
                    </p>
                  </div>
                </div>

                <div className="slick-responsive">
                  <div className="bg-white-shadow">
                    <img
                      src={studio}
                      alt="live-well"
                      className="lie-img-slide-with-text"
                    />
                    <p
                      className="col-black slick-text text-center bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Studio
                    </p>
                  </div>
                </div>

                <div className="slick-responsive">
                  <div className="bg-white-shadow">
                    <img
                      src={floor}
                      alt="live-well"
                      className="lie-img-slide-with-text"
                    />
                    <p
                      className="col-black slick-text text-center bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Floor
                    </p>
                  </div>
                </div>

                <div className="slick-responsive">
                  <div className="bg-white-shadow">
                    <img
                      src={floor1}
                      alt="live-well"
                      className="lie-img-slide-with-text"
                    />
                    <p
                      className="col-black slick-text text-center bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Floor
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </section>
        </section>

        <section className="container  mb-2 pt-5 card-mod">
          <p className="wel-text text-center pb-2">How To Proceed Ahead</p>

          <ul className="pt-4">
            <li className="col-black pb-3">Fill enquiry form or call us</li>

            <li className="col-black pb-3">
              Speak with our sales team to understand our business module &
              outcomes
            </li>

            <li className="col-black pb-3"> Sign up</li>

            <li className="col-black pb-3">
              Visit from our Project Team to finalise location
            </li>

            <li className="col-black pb-3">
              Due diligence for Project Team and finalise project cost
            </li>

            <li className="col-black pb-3">Franchise agreement</li>
            <li className="col-black pb-3">
              Finalisation of vendors - Architect, Equipment supplier, Interior
              etc{" "}
            </li>
            <li className="col-black pb-3">Team training and Pre-Launch </li>
            <li className="col-black pb-3">Gym Launch/ Opening </li>
            <li className="col-black pb-3">Continuous hand holding </li>
          </ul>

          <div className="d-flex justify-content-center pt-2 pb-1">
            <button
              type="button"
              className="btn btn-warning col-white adjust-btn"
              data-bs-toggle="modal"
              data-bs-target="#enquire"
            >
              Enquire Now
            </button>
          </div>
        </section>
      </div>

      <div className="modal fade" id="enquire" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Franchise Enquiry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={fitenqiure}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      <p className="alert-message">{alertname}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter  email"
                      />
                      <p className="alert-message">{alertemail}</p>

                      <br />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Enter  contact number"
                      />
                      <p className="alert-message">{alertcontactNumber}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                        placeholder="Enter the city"
                      />
                      <p className="alert-message">{alertcity}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={age}
                        onChange={(e) => setage(e.target.value)}
                        placeholder="How much are you looking to invest"
                      />
                      <p className="alert-message">{alertage}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        type="text"
                        row="50"
                        col="50"
                        className="form-control"
                        value={messages}
                        onChange={(e) => setMessages(e.target.value)}
                        placeholder="Describe your interest in brief"
                      ></textarea>
                      <p className="alert-message">{alertmessage}</p>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={fitenq}
                  >
                    Request a call back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={close}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <Footer />
    </div>
  );
};

export default Gymfranchise;
