import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HealthrecordService from "../../services/healthrecord.service";

export const healthrecords = createAsyncThunk(
    "healthrecord/post/healthrecord",
    async(item,thunkAPI) => {
        try {
            const data = await HealthrecordService.posthealthrecords(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const getrecord = createAsyncThunk(
    "record/get/Record",
    async(thunkAPI) => {
        try {
            const data = await HealthrecordService.getrecords();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   EnquiryService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: HealthrecordService.getUserDetails() || null,
    isLoggedIn: false,
};

const recordSlice = createSlice({
    name: "record",
    initialState,
    extraReducers: {
        [healthrecords.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [healthrecords.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [healthrecords.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        [getrecord.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getrecord.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getrecord.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = recordSlice;
export default reducer;