import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class BlogService {
    static getBlog() {
       let api='';
        let id = localStorage.getItem("id"); 
        api = id ? API_PATHS.getblog + '?_id=' + id : API_PATHS.getblog;
        localStorage.removeItem("id");
        return axios
        .get( api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getTravelBlog() {
        let api='';
         let id = localStorage.getItem("id"); 
         api = id ? API_PATHS.gettravelblog + '?_id=' + id : API_PATHS.gettravelblog;
         localStorage.removeItem("id");
         return axios
         .get( api)
             .then((response) => {
                 if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                 }
                 return response.data;
             });
     }
     static getWMBlog() {
        let api='';
         let id = localStorage.getItem("id"); 
         api = id ? API_PATHS.getwmblog + '?_id=' + id : API_PATHS.getwmblog;
         localStorage.removeItem("id");
         return axios
         .get( api)
             .then((response) => {
                 if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                 }
                 return response.data;
             });
     }

     static getPhysioBlog() {
        let api='';
         let id = localStorage.getItem("id"); 
         api = id ? API_PATHS.getphysioblog + '?_id=' + id : API_PATHS.getphysioblog;
         localStorage.removeItem("id");
         return axios
         .get( api)
             .then((response) => {
                 if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                 }
                 return response.data;
             });
     }

     static getFitnessBlog() {
        let api='';
         let id = localStorage.getItem("id"); 
         api = id ? API_PATHS.getfitnessblog + '?_id=' + id : API_PATHS.getfitnessblog;
         localStorage.removeItem("id");
         return axios
         .get( api)
             .then((response) => {
                 if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                 }
                 return response.data;
             });
     }

     static getHcBlog() {
        let api='';
         let id = localStorage.getItem("id"); 
         api = id ? API_PATHS.gethcblog + '?_id=' + id : API_PATHS.gethcblog;
         localStorage.removeItem("id");
         return axios
         .get( api)
             .then((response) => {
                 if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                 }
                 return response.data;
             });
     }


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default BlogService; 