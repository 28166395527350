import React from "react";
import logo from "../images/travelonify/travelonify-bw.png";
import { Link } from "react-router-dom";

const Travelfooter = () => {
  return (
    <div>
      <footer className="footer-section pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-12">
              <div className="footer-area">
                <img src={logo} alt="" style={{ width: "150px" }} />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-xs-6">
              <div className="footer-item-area">
                <h5 style={{ color: "white" }}>Locate Us</h5>
                <p className="col-white">
                  5046, 3rd floor, Rustomjee Eaze Zone Mall, Sunder Nagar, Malad West, Mumbai- 400064
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-xs-6">
              <div className="footer-item-area">
                <h5 style={{ color: "white" }}>Contact Us</h5>
                <p className="col-white">
                  alok@healthonify.com
                  <br />
                  info@travelonify.com
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-xs-6">
              <div className="footer-item-area">
                <h5 style={{ color: "white" }}>Quick Links</h5>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 setting-space">
                    <a
                      href="https://travel-admin.healthonify.com/agencyregister"
                      className="footer-items"
                      target="_blank"
                    >
                      Travel Agency Sign up
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 setting-space">
                    <a
                      href="https://travel-admin.healthonify.com/register"
                      className="footer-items"
                      target="_blank"
                    >
                      Travel Agent/ Hotel Owner Sign up
                    </a>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 setting-space">
                    <Link to="#" className="footer-items">
                      About Us
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 setting-space">
                    <Link to="/Privacy-policy" className="footer-items">
                      Privacy Policy
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 setting-space">
                    <Link to="/Cancellation-policy" className="footer-items">
                      Cancellation Policy{" "}
                    </Link>
                  </div>

                  <div className="col-lg-6 col-md- col-sm-12 setting-space">
                    <Link to="/Terms-condition" className="footer-items">
                      Terms & Conditions
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center" align="center">
            <div className="col-lg-12">
              <ul className="footer-social" style={{ textAlign: "center" }}>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/healthonify"
                    target="_blank"
                    className="bg-3955bc"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.facebook.com/healthonify/"
                    target="_blank"
                    className="bg-1da1f2"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.twitter.com/healthonify"
                    target="_blank"
                    className="bg-004dff"
                  >
                    <i className="fab fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/healthonify/"
                    target="_blank"
                    className="bg-0273af"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                </li>
              </ul>
              <p>
                © 2020-23 All Rights Reserved | Powered By &nbsp;
                <Link to="https://octaloptimum.com/" target="_blank">
                  Octal Optimum Technology
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Travelfooter;
