import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";

import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";

import Navbar from "./navbar";

const Paediatrics = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  //enquiry form
  return (
    <div id="__next">
      <Navbar />
      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="col-head-color-p">Paediatrics Physiotherapy</h3>
        </div>

        <div className="pt-4">
          <p>
            Pediatric physiotherapy is effective in the management of perinatal
            conditions, conditions diagnosed in early childhood, and injuries
            sustained throughout childhood and the transition to adult care.
            Pediatric physiotherapy improves physical function and quality of
            life.1,2 Its long-term benefits are significant and include reducing
            disability and the need for surgery or other more costly invasive
            interventions resulting in a decreased burden on the future use of
            health care services.
            <br />
            Pediatric physiotherapy services treat a wide variety of acute and
            chronic conditions in different settings, from acute care hospitals
            to the community and schools. Pediatric physiotherapists assess,
            diagnose and treat neurological, developmental, cardiorespiratory,
            and orthopedic conditions in children up to 18 years of age, with a
            focus on improving function and increasing independence.
            Physiotherapy has a positive and significant impact on pulmonary
            function, motor control, muscle strength, and physical endurance in
            pediatric patients with chronic conditions such as cerebral palsy,
            cystic fibrosis, and juvenile idiopathic arthritis.
          </p>
        </div>

        <div className="pt-4">
          <p>
            <b>How it works</b>
            <br />
            Pediatric physiotherapists help children to achieve optimal physical
            development. They have specialist knowledge in the movement,
            development, and conditions that are likely to affect the baby and
            growing child and treat from 1-day-old babies to adolescents.
            Treatment may involve soft tissue massage, mobilization, stretching,
            specific therapeutic exercises, and posture education. Because
            Children are not small adults these therapists encourage children to
            move to the best of their abilities through play and age-appropriate
            fun and instruction. The physiotherapist will inevitably work within
            the context of the child and his family and have the opportunity to
            work with the child in a range of situations extending into the
            home, preschool groups, education, and leisure activities.
          </p>
          <p>
            <b>PEDIATRIC CONDITIONS WE TREAT</b>
            <br />
            Pediatric physiotherapists have extensive experience in a variety of
            neurological pediatric conditions including:
            <br />
            <ul>
              <li>Gross motor delay </li>
              <li> Autism Spectrum Disorder </li>
              <li> Cerebral Palsy</li>
              <li> Developmental Coordination Disorder </li>
              <li> Down Syndrome </li>
              <li> Spina Bifida </li>
              <li> Acquired Brain Injury </li>
              <li> Hypotonia </li>
              <li> Muscular Dystrophy or other neuromuscular challenges </li>
              <li> Torticollis/Plagiocephaly </li>
              <li>Other genetic disorders </li>
              <li> Weight challenges </li>
              <li> Athlete</li>
            </ul>
            <br />
            Initial assessment of your child will include their development,
            movement, strength, and balance in order to produce a structured
            treatment program which may include:
            <br />
            Stretching
            <br />
            Strengthening
            <br />
            Re-educating normal movement patterns
            <br />
            Balance training
            <br />
            Improving standing and quality of walking
            <br />
            Advice and support for parents and or teachers
            <br />
            Milestone development
            <br />
            Working on fine and gross motor skills
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Paediatrics;
