import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "./logo.png";
import { useDispatch, useSelector } from "react-redux";
import TravelHeader from "./travel-navbar";
import { CityPackage } from "./../store/slices/city";
import travel3 from "./../images/travel3.jpg";
import { CityDetails } from "./../store/slices/city";
import styled from "styled-components";
import { ellipsis } from "polished";

import Travelfooter from "./Travelfooter";

function TravelCity(e) {
  const MainContainer = styled.div`
    padding: 10px;
  `;

  const TitleText = styled.div`
    font-size: 18px;
  `;

  const DescriptionText = styled.div`
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    ${({ showMore }) => showMore && ellipsis(undefined, 7)}
  `;

  const ShowMoreText = styled.div`
    cursor: pointer;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: blue;
  `;

  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [cityPackage, setcityPackage] = useState([]);

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { id } = useParams();
  localStorage.setItem("id", id);
  localStorage.setItem("cityid", id);
  React.useEffect(() => {
    dispatch(CityDetails())
      .unwrap()
      .then((data) => {
        setName(data.user.data[0].name);
        setState(data.user.data[0].state);
        setDescription(data.user.data[0].description);
        setImage(data.user.data[0].image);
        // setExclusive(data.user.data[0].exclusive);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(CityPackage())
      .unwrap()
      .then((data) => {
        setcityPackage(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const [isShowMore, setIsShowMore] = useState(true);
  const toggleReadMore = () => setIsShowMore((show) => !show);
  return (
    <div>
      <TravelHeader />
      <br />
      <br />
      <br />
    
      <seciton className="transparent-section pt-5">
      
      <div className="position-relative cover_box d-flex justify-content-end pt-5">
      <img src={image} alt="" className="trans_img"/>
      <div className="position-absolute transform-text">
      <h4>{name}</h4>
    <DescriptionText showMore={isShowMore}>
      {description}
    </DescriptionText>
    <ShowMoreText onClick={toggleReadMore}>
      {isShowMore ? "View more..." : "View less"}
    </ShowMoreText>
      </div>
      </div>

      </seciton>

      <section className="img_section container pt-5 pb-5">
        <div className="row">
          {cityPackage.map((PackageVal, index) => (
            <div
              className="col-lg-3 col-md-3 col-sm-12"
              style={{ padding: "5px" }}
            >
              <Link
                to={`/travelonify/Traveldetails/${
                  PackageVal._id ? PackageVal._id : null
                }`}
              >
                <div
                  className="col-lg-12 col-md-12 col-sm-12 white_card"
                  align="center"
                >
                  <img
                    src={PackageVal.imageUrl[0].mediaLink}
                    alt=""
                    className="trans_imgs"
                  />

                  <h4 className="card-title slick_text">
                    {PackageVal.packageName.substring(0, 25)}
                  </h4>
                  <p className="card-text">
                    {PackageVal.duration}
                    <br />
                    <b style={{ color: "blue" }}>
                      &#x20b9; {PackageVal.twinShareRate / 2}
                    </b>
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </section>
      <Travelfooter />
    </div>
  );
}

export default TravelCity;
