import React, { useState } from "react";
import Footer from "./Footer";
import logo from "./logo.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "./logo.png";

import { MdDashboard } from "react-icons/md";
import {
  BsBookFill,
  BsFillChatRightFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { BiCubeAlt } from "react-icons/bi";

import { MdNotifications } from "react-icons/md";
import { MdSettings } from "react-icons/md";
import user from "./../images/client-image/3.jpg";
import UserNavbar from "./UserNavbar";

import { getcommunitypost, enablecommmunity } from "../store/slices/community";

import { useDispatch } from "react-redux";


const Mycommunity = () => {
  const dispatch = useDispatch();

  const [communitypost, setCommunitypost]= useState([]);
 
  React.useEffect(() => {
    dispatch(getcommunitypost())
      .unwrap()
      .then((data) => {
        setCommunitypost(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  async function enableApprove(id) {
    let isApproved = true;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablecommmunity())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function disableApprove(id) {
    let isApproved = false;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablecommmunity())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  return (
    <div className="bg-light">
    <UserNavbar />

    <div className="container-fluid response-cover">
    <div className="row">
      <div className="col-lg-2 col-md-4" />
      <div className="col-lg-10 col-md-8">

      <div className="container pt-5">
      <div className="pt-5 mt-5">
      <div className="col-md-12">
      <h3 className="mb-2">My Community</h3>
      <Link to="/Mycommunity/AddCommunity" className="btn btn-warning adjust-btn col-white mt-3">Add</Link>
      </div>     
   
      

      <div className="row pt-5 pb-5">
{communitypost.map((communityVal, index) => (
        <div className="col-lg-4 col-md-6 pt-2 pb-2">
      <div className="post-card">
      <div className="d-flex justify-content-start">
  <div className="profile-icon"/> {/*you can use this classname to image div*/}
  &nbsp;
  &nbsp;
  
  <div className="pro-info">
    <p className="info-text" style={{color:"black", fontWeight:"500"}}>{communityVal.userId.firstName} {communityVal.userId.lastName}</p>
    <p className="info-text">{communityVal.date}</p>
  </div>    

      </div>

      <p style={{color:"black"}} className="pt-4 pb-2">{communityVal.description}</p>

      <div className="post-image">
      <img src={communityVal.mediaLink} alt="" className="post-image"/>
      </div>

      <div className="d-flex justify-content-end pt-2">

      {communityVal.isApproved === false && (
        <button
          className="btn btn-danger"
          style={{
            height: "25px",
            padding: "0 10px",
          }}
          onClick={() =>
            enableApprove(communityVal._id)
          }
        >
          No
        </button>
      )}
      {communityVal.isApproved === true && (
        <button
          className="btn btn-success"
          style={{
            height: "25px",
            padding: "0 10px",
          }}
          onClick={() =>
            disableApprove(communityVal._id)
          }
        >
          Yes
        </button>
      )}

<Link  to={`/Mycommunity/EditCommunity/${  communityVal._id ? communityVal._id : null }`} className="btn btn-primary">Edit</Link>
      </div>
      </div>
      </div>
))}
      </div>
      
    
        
      </div>
    </div>

      </div>
      </div>
      </div>
     
    </div>
  );
}
export default Mycommunity;
