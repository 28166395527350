import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";


import UserNavbar from "./UserNavbar";
import { getrecord } from "../store/slices/healthrecord";


const HealthRecords = () => {

    const [records, setRecords] = useState("");
const [reportName, setReportname] = useState("");

    const dispatch = useDispatch();

 const { id } = useParams();
    localStorage.setItem("id", id);

    React.useEffect(() => {
        dispatch(getrecord())
          .unwrap()
          .then((data) => {

            if (data.user.data[0].id === id) {
          // setReportname(data.user.data[0].imageUrl[1].mediaLink);

            }
          })
          .catch(({ message }) => {
            // alert(message);
          });
      }, [dispatch]);

{   /*   React.useEffect(() => {
        dispatch(getrecord())
          .unwrap()
          .then((data) => {
            setRecords(data.user.data);
          })
          .catch(({ message }) => {
            // alert(message);
          });
      }, [dispatch]);*/}

  return (
    <div>
    <UserNavbar/>

<div className="container pt-5">
<div className="row pt-5">

<div className="col-md-4">
<div className="col-md-12">
<p></p>
<p></p>
</div>
</div>


</div>
</div>

    </div>
  )
}

export default HealthRecords;