import React, { useState } from "react";
import UserNavbar from "./UserNavbar";
import { useDispatch, useSelector } from "react-redux";
import { getpayments } from "../store/slices/profile";
const MyPayment = () => {
  const [payments, setPayments] = useState([]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getpayments())
      .unwrap()
      .then((data) => {
        setPayments(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <section className="add-ui">
                  <div className="col-md-12">
                    <h2 className="mb-4">My Payments</h2>

                    <div className="bg-white-ptbrl-2 mb-5 mt-1">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead className="table-dark">
                            <tr>
                              <th className="wd-20p">Date &nbsp;</th>
                              <th className="wd-15p">Time &nbsp;</th>
                              <th className="wd-10p">Status &nbsp;</th>
                            </tr>
                          </thead>
                          {payments.map((paymentVal, index) => (
                            <tbody>
                              <tr key={index}>
                                <td>{paymentVal.netAmount} </td>
                                <td>{paymentVal.invoiceNumber}</td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPayment;
