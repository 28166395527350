import React, { useRef, useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import { Link, NavLink } from "react-router-dom";
import logo from "./logo_travelonify.png";

function Hotelnavbar() {
  let enquiryFor = "travelAgency";

  //   From Validation
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [agencyName, setagencyName] = useState("");
  const [message, setMessage] = useState("");

  // alert
  const [alertname, setAlertname] = useState("");
  const [alertmessage, setAlertmessage] = useState("");
  const [alertemail, setAlertemail] = useState("");
  const [alertcontactNumber, setAlertcontactNumber] = useState("");
  const [alertaddress, setAlertaddress] = useState("");
  const [alertagencyName, setAlertagencyName] = useState("");

  async function save(e) {
    e.preventDefault();
    let item = {
      name,
      email,
      contactNumber,
      address,
      agencyName,
      message,
      enquiryFor,
    };

    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setAlertname("");
    } else if (!vName.test(name) && name === "") {
      setAlertname("Please enter your name");
      e.preventDefault();
    } else {
      setAlertname("Please enter a valid name");
      e.preventDefault();
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setAlertcontactNumber("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setAlertcontactNumber("Please enter the contact number");
    } else {
      setAlertcontactNumber("Mobile number should not more/less then 10");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertemail("");
    } else if (!regemail.test(email) && email === "") {
      setAlertemail("Please enter the e-mail");
    } else {
      setAlertemail("Email is not valid");
    }

    const regaddress = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regaddress.test(address)) {
      setAlertaddress("");
    } else if (!regaddress.test(address) && address === "") {
      setAlertaddress("Please enter the address");
      e.preventDefault();
    } else {
      setAlertaddress("");
    }

    const regmessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regmessage.test(message)) {
      setAlertmessage("");
    } else if (!regmessage.test(message) && message === "") {
      setAlertmessage("Please enter the message");
      e.preventDefault();
    } else {
      setAlertmessage("");
    }

    const regagency = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regagency.test(agencyName)) {
      setAlertagencyName("");
    } else if (!regagency.test(agencyName) && agencyName === "") {
      setAlertagencyName("Please enter the agency name");
      e.preventDefault();
    } else {
      setAlertagencyName("");
      e.preventDefault();
    }

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setName("");
        setEmail("");
        setMessage("");
        setContactNumber("");
        setAddress("");
        setagencyName("");
        document.getElementById("myenquiry").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }

  const reset = () => {
    setName("");
    setEmail("");
    setContactNumber("");
    setAddress("");
    setagencyName("");
    setMessage("");

    // alert
    setAlertagencyName("");
    setAlertname("");
    setAlertmessage("");
    setAlertemail("");
    setAlertcontactNumber("");
    setAlertaddress("");
  };

  //   Form Validation
  const dispatch = useDispatch();

  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 80;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [user, setUser] = useState({});
  useEffect(() => {
  
    setInterval(() => {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);

      setUser(user);
    }, []);
  }, 500000);

  const travelLinks = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#001E79" : "white",
      borderRadius: isActive ? "0px" : "0px",
      color: isActive ? "white" : "gray",
    };
  };

  const logout = () => {
    return localStorage.clear();
  };
  if (!user) {
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          fixed="top"
          style={{
            transition: "1s ease",
            color: "black",
            backgroundColor: navBackground ? "white" : "white",
          }}
        >
          <Container>
            <Navbar.Brand to="/travelonify">
              <Link to="/travelonify">
                <img
                  className="logo-3"
                  src={logo}
                  alt=""
                  style={{ height: "80px" }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavLink
                  to="/travelonify"
                  className="nav-items-travel nav-link"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/travelonify/indian-holidays"
                  style={travelLinks}
                  className="nav-items-travel"
                >
                  Indian Holidays
                </NavLink>
                <NavLink
                  to="/travelonify/international-holidays"
                  style={travelLinks}
                  className="nav-items-travel"
                >
                  International Holidays
                </NavLink>

                <NavLink
                  to="/travelonify/Hotels"
                  style={travelLinks}
                  className="nav-items-travel nav-link"
                >
                  Hotels
                </NavLink>

                <NavLink
                  to="/travelonify/Hospitality"
                  style={travelLinks}
                  className="nav-items-travel nav-link"
                >
                  Hotel Management
                </NavLink>

                {/* <NavDropdown title="Login" id="basic-nav-dropdown">
                  <NavDropdown.Item
                    to="http://heal-admin.saketherp.in/"
                    target="_blank"
                  >
                    Partner Login
                  </NavDropdown.Item> */}
                <NavLink
                  to="/travelonify/TravelSignin"
                  className="nav-items-travel nav-link"
                >
                  Login
                </NavLink>
                {/* </NavDropdown> */}
                {/* )} */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="icon-bar">
          <button
            type="button"
            className=" btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#myenquiry"
          >
            Enquire Now
          </button>
        </div>

        <div className="modal fade" id="myenquiry">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Enquire Now</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={reset}

                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />

                      <p className="alert-message">{alertname}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="number"
                        placeholder="Mobile number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                      />

                      <p className="alert-message">{alertcontactNumber}</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <p className="alert-message">{alertemail}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Message"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />

                      <p className="alert-message">{alertmessage}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Agency name"
                        className="form-control"
                        value={agencyName}
                        onChange={(e) => setagencyName(e.target.value)}
                      />

                      <p className="alert-message">{alertagencyName}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <textarea
                        placeholder="Address"
                        className="form-control"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />

                      <p className="alert-message">{alertaddress}</p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer  d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white "
                    // data-bs-dismiss="modal"
                    onClick={save}
                  >
                    Submit
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"  
                    className="btn btn-blue col-white "
                    data-bs-dismiss="modal"
                    onClick={reset}
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="background"  data-bs-dismiss="modal"></div>
        </div>
      </div>
    )
  }
  if (user) {
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          fixed="top"
          style={{ backgroundColor: "white" }}
        >
          <Container>
            <Navbar.Brand to="/travelonify">
              <Link to="/travelonify">
                <img
                  className="logo-3"
                  src={logo}
                  alt=""
                  style={{ height: "60px" }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavLink
                  to="/travelonify"
                  className="nav-items-travel nav-link"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/travelonify/indian-holidays"
                  className="nav-items-travel nav-link"
                  style={travelLinks}
                >
                  Indian Holidays
                </NavLink>
                <NavLink
                  to="/travelonify/international-holidays"
                  className="nav-items-travel nav-link"
                  style={travelLinks}
                >
                  International Holidays
                </NavLink>
                <NavLink
                  to="/travelonify/Hotels"
                  className="nav-items-travel nav-link"
                  style={travelLinks}
                >
                  Hotels
                </NavLink>

                <NavLink
                  to="/travelonify/Hospitality"
                  className="nav-items-travel nav-link"
                  style={travelLinks}
                >
                  Hotel Management
                </NavLink>

                <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                  <NavDropdown.Item
                    to="http://heal-admin.saketherp.in/"
                    target="_blank"
                  >
                    Dashboard
                  </NavDropdown.Item>
                  <NavDropdown.Item to="/travelonify" onClick={logout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
                {/* )} */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="icon-bar">
          <button
            type="button"
            className="btn btn-warning"
            data-bs-toggle="modal"
            data-bs-target="#myenquiry"
          >
            Enquire Now
          </button>
        </div>

        <div className="modal fade" id="myenquiry" style={{height:"100%"}}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
              <div className="modal-header">
                <h4 className="modal-title">Enquire Now</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={reset}

                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />

                      <p className="alert-message">{alertname}</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="number"
                        placeholder="Mobile number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                      />

                      <p className="alert-message">{alertcontactNumber}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <p className="alert-message">{alertemail}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Message"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />

                      <p className="alert-message">{alertmessage}</p>
                    </div>
                  </div>
                  <br />

                  <input
                    type="text"
                    placeholder="Agency name"
                    className="form-control"
                    value={agencyName}
                    onChange={(e) => setagencyName(e.target.value)}
                  />

                  <p className="alert-message">{alertagencyName}</p>
                  <br />

                  <input
                    type="text"
                    placeholder="Address"
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />

                  <p className="alert-message">{alertaddress}</p>
                  <br />
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    // data-bs-dismiss="modal"
                    onClick={save}
                  >
                    Submit
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={reset}
                  data-bs-dismiss="modal"

                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="background"  data-bs-dismiss="modal"></div>
        </div>
      </div>
    );
  }
}

export default Hotelnavbar;
