import React, { useState } from "react";

import travel3 from "./../images/travel3.jpg";
import Carousel from "react-bootstrap/Carousel";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";
function HotelBooking() {
  const [count_A, setCount_A] = useState(1);
  const [count_C, setCount_C] = useState(0);
  const [count_R, setCount_R] = useState(1);

  const IncNum_A = () => {
    setCount_A(count_A + 1);
  };

  const IncNum_C = () => {
    setCount_C(count_C + 1);
  };

  const IncNum_R = () => {
    setCount_R(count_R + 1);
  };

  const DecNum_A = () => {
    setCount_A((count_A) => Math.max(count_A - 1, 1));
  };

  const DecNum_C = () => {
    setCount_C((count_C) => Math.max(count_C - 1, 0));
  };

  const DecNum_R = () => {
    setCount_R((count_R) => Math.max(count_R - 1, 1));
  };
  return (
    <section className="hotelbanner" style={{ padding: "80px" }}>
      <div className="col-lg-12 col-md-12">
        <p className="form_text-top" style={{ color: "white" }}>
          Hotel Booking
        </p>
        <form>
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <input
                type="text"
                placeholder="Destination"
                className="hotel_form"
                style={{ height: "50px" }}
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <input
                type="date"
                className="hotel_form"
                style={{ height: "50px" }}
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <input
                type="date"
                className="hotel_form"
                style={{ height: "50px" }}
              />
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-12 pt-4"
              style={{ height: "50px" }}
            >
              <Dropdown className="">
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className="drop_downs sub_names"
                >
                  {count_A} Adults , {count_C} Children , {count_R} Rooms
                </Dropdown.Toggle>

                <Dropdown.Menu className="droper">
                  <Dropdown className="sub_dropers">
                    <span className="sub_names">Adults</span>{" "}
                    <span className="math">
                      <span className="clickmath" onClick={DecNum_A}>
                        <AiOutlineMinusCircle />
                      </span>
                      <span className="count"> {count_A}</span>
                      <span className="clickmath" onClick={IncNum_A}>
                        <AiOutlinePlusCircle />
                      </span>
                    </span>
                  </Dropdown>
                  <Dropdown className="sub_dropers">
                    <span className="sub_names">Children</span>{" "}
                    <span className="math">
                      <span className="clickmath" onClick={DecNum_C}>
                        <AiOutlineMinusCircle />
                      </span>
                      <span className="count"> {count_C}</span>
                      <span className="clickmath" onClick={IncNum_C}>
                        <AiOutlinePlusCircle />
                      </span>
                    </span>
                  </Dropdown>
                  <Dropdown className="sub_dropers">
                    <span className="sub_names">Rooms</span>{" "}
                    <span className="math">
                      <span className="clickmath" onClick={DecNum_R}>
                        <AiOutlineMinusCircle />
                      </span>
                      <span className="count"> {count_R}</span>
                      <span className="clickmath" onClick={IncNum_R}>
                        <AiOutlinePlusCircle />
                      </span>
                    </span>
                  </Dropdown>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-5 col-md-4" />
              <div className="col-lg-1 col-md-2 col-sm-12 pt-3 pb-1">
                <button type="button" className="btn btn-success btn_hotel">
                  Submit
                </button>
              </div>
              <div className="col-lg-1 col-md-2 col-sm-12 pt-3 pb-1">
                <button type="reset" className="btn btn-danger btn_hotel">
                  Reset
                </button>
              </div>
              <div className="col-lg-5 col-md-4" />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default HotelBooking;
