import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import InsuranceService from "../../services/insurance.service";

export const postlockerinsurance = createAsyncThunk(
    "locker/post/locker",
    async(item,thunkAPI) => {
        try {
            const data = await InsuranceService.postinsurancelocker(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const getlockerinsurance = createAsyncThunk(
    "insurance/get/insurance",
    async(thunkAPI) => {
        try {
            const data = await InsuranceService.getinsurancelocker();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   EnquiryService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: InsuranceService.getUserDetails() || null,
    isLoggedIn: false,
};

const InsurnaceLockerSlice = createSlice({
    name: "record",
    initialState,
    extraReducers: {
        [postlockerinsurance.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postlockerinsurance.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postlockerinsurance.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        [getlockerinsurance.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getlockerinsurance.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getlockerinsurance.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = InsurnaceLockerSlice;
export default reducer;