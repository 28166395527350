import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class formsService {
   
   
    static fitnessForms() {
      let api = '';      
      let user = JSON.parse(localStorage.getItem("user"));
        let userId=user.data.id; 
      api = API_PATHS.fitnessform+ '?userId=' + userId;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static medicalHistory() {
        let api = '';      
        let user = JSON.parse(localStorage.getItem("user"));
          let userId=user.data.id; 
        api = API_PATHS.getmedicalHistory+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }


      static lifeStyleForm() {
        let api = '';      
        let user = JSON.parse(localStorage.getItem("user"));
          let userId=user.data.id; 
        api = API_PATHS.getlifestyle+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default formsService;