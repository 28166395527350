import React, { Component, useState } from "react";
import Footer from "./Footer";

import { useDispatch } from "react-redux";

import {
  bmi,
  bmr,
  rmrcalculator,
  IdealWeightcalculator,
  leanbodymasscalculator,
  macrocalculator,
  bodyfatcalculator,
  calorieintakecalculator,
} from "../store/slices/tools";

import UserNavbar from "./UserNavbar";

const MyTools = () => {
  const dispatch = useDispatch();
  const [gender, setGender] = useState("");

  const [bmiresult, setBmiResult] = useState("");
  const [bmrresult, setBmrResult] = useState("");
  const [rmrresult, setRmrResult] = useState("");
  const [bodyfatresult, setBodyfatResult] = useState("");
  const [IdealWeightresult, setIdealWeightresult] = useState("");
  const [leanbodymassresult, setLeanbodymassresult] = useState("");
  const [macroresult, setMacroresult] = useState("");
  const [calorieintakeresult, setCalorieintakeresult] = useState("");

  // BMI / BMR / RMR / IdealWeight / Lean Body Mass / Body Fat / Calorie in take
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [age, setAge] = useState("");

  const [alertweight, setAlertWeight] = useState("");
  const [alertheight, setAlertHeight] = useState("");
  const [alertage, setAlertAge] = useState("");
  const [alertgender, setalertGender] = useState("");
  // Macro Calculator
  const [calories, setCalories] = useState("");
  const [alertcalories, setAlertCalories] = useState("");

  // Body Fat
  const [neck, setNeck] = useState("");
  const [waist, setWaist] = useState("");
  const [hip, setHip] = useState("");

  const [alertneck, setAlertNeck] = useState("");
  const [alertwaist, setAlertWaist] = useState("");
  const [alerthip, setAlertHip] = useState("");

  // Calorie in take
  const [targetWeight, setTargetWeight] = useState("");
  const [goal, setGoal] = useState("");
  const [activityLevel, setActivityLevel] = useState("");

  const [alerttargetWeight, setAlertTargetWeight] = useState("");
  const [alertgoal, setAlertGoal] = useState("");
  const [alertactivityLevel, setAlertActivityLevel] = useState("");

  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setGender(target.value);
    }
  };

  async function bmical(e) {
    document.getElementById("bmiresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bmi())
      .unwrap()
      .then((data) => {
        setBmiResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function bmrcal(e) {
    document.getElementById("bmrresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bmr())
      .unwrap()
      .then((data) => {
        setBmrResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function rmrcal(e) {
    document.getElementById("rmrresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(rmrcalculator())
      .unwrap()
      .then((data) => {
        setRmrResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function Idealweightcal(e) {
    document.getElementById("Idealweightresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(IdealWeightcalculator())
      .unwrap()
      .then((data) => {
        setIdealWeightresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function macrocal(e) {
    document.getElementById("macroresults").style.height = "max-content";
    e.preventDefault();
    let item = { calories };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(macrocalculator())
      .unwrap()
      .then((data) => {
        setMacroresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function leanweightcal(e) {
    document.getElementById("leanbodymassresults").style.height = "max-content";
    e.preventDefault();
    let item = { gender, age, height, weight };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(leanbodymasscalculator())
      .unwrap()
      .then((data) => {
        setLeanbodymassresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function bodyfatcal(e) {
    document.getElementById("bodyfatresults").style.height = "max-content";
    e.preventDefault();
    let item = { weight, age, height, gender, neck, waist, hip };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(bodyfatcalculator())
      .unwrap()
      .then((data) => {
        setBodyfatResult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  async function calorieintakecal(e) {
    document.getElementById("calorieintakeresults").style.height =
      "max-content";
    e.preventDefault();
    let item = {
      weight,
      age,
      height,
      gender,
      targetWeight,
      goal,
      activityLevel,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(calorieintakecalculator())
      .unwrap()
      .then((data) => {
        setCalorieintakeresult(data.user.data);
        document.getElementById("join").style.display = "none";
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  const save = (e) => {
    const number1 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number1.test(age)) {
      setAlertAge("");
    } else if (!number1.test(age) && age === "0") {
      setAlertAge("Invalid number");
      e.preventDefault();
    } else if (!number1.test(age) && age === "") {
      setAlertAge("Please enter");
      e.preventDefault();
    } else {
      setAlertAge("");
      // e.preventDefault();
    }

    if (gender === "") {
      setalertGender("Please select");
      e.preventDefault();
    } else {
      setalertGender("");
    }

    const number2 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number2.test(height)) {
      setAlertHeight("");
    } else if (!number2.test(height) && height === "0") {
      setAlertHeight("Invalid number");
      e.preventDefault();
    } else if (!number2.test(height) && height === "") {
      setAlertHeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const number3 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number3.test(weight)) {
      setAlertWeight("");
    } else if (!number3.test(weight) && weight === "0") {
      setAlertWeight("Invalid number");
      e.preventDefault();
    } else if (!number3.test(weight) && weight === "") {
      setAlertWeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }
  };

  const savecalories = (e) => {
    const number4 = /^[1-3][0-9][0-9][0-9]?$|^4000$/;
    if (number4.test(calories)) {
      setAlertCalories("");
    } else if (!number4.test(calories) && calories === "0") {
      setAlertCalories("Invalid number");
      e.preventDefault();
    } else if (!number4.test(calories) && calories === "") {
      setAlertCalories("Please enter");
      e.preventDefault();
    } else {
      setAlertCalories("");
      // e.preventDefault();
    }
  };

  const cancel = () => {
    setBmiResult("0");
    setBmrResult("0");
    setRmrResult("0");
    setIdealWeightresult("0");
    setLeanbodymassresult("0");
    setMacroresult("0");
    setWeight("");
    setHeight("");
    setAge("");
    setCalories("");
    setNeck("");
    setWaist("");
    setHip("");
    setGender("");
    setAlertNeck("");
    setAlertWaist("");
    setAlertHip("");
    setTargetWeight("");
    setGoal("");
    setActivityLevel("");
    setAlertTargetWeight("");
    setAlertGoal("");
    setAlertActivityLevel("");
    setAlertWeight("");
    setAlertHeight("");
    setAlertAge("");
    setAlertCalories("");
    document.getElementById("leanbodymassresults").style.height = "0px";
    document.getElementById("macroresults").style.height = "0px";
    document.getElementById("Idealweightresults").style.height = "0px";
    document.getElementById("rmrresults").style.height = "0px";
    document.getElementById("bmrresults").style.height = "0px";
    document.getElementById("bmiresults").style.height = "0px";
    document.getElementById("bodyfatresults").style.height = "0px";
    document.getElementById("calorieintakeresults").style.height = "0px";
  };

  const close = () => {
    setBmiResult("");
    setBmrResult("");
    setRmrResult("");
    setIdealWeightresult("");
    setLeanbodymassresult("");
    setMacroresult("");
    setWeight("");
    setHeight("");
    setAge("");
    setAlertWeight("");
    setAlertHeight("");
    setAlertAge("");
    setAlertCalories("");
    setCalories("");
    setNeck("");
    setWaist("");
    setHip("");
    setAlertNeck("");
    setAlertWaist("");
    setAlertHip("");
    setTargetWeight("");
    setGoal("");
    setActivityLevel("");
    setAlertTargetWeight("");
    setAlertGoal("");
    setAlertActivityLevel("");
    document.getElementById("leanbodymassresults").style.height = "0px";
    document.getElementById("macroresults").style.height = "0px";
    document.getElementById("Idealweightresults").style.height = "0px";
    document.getElementById("rmrresults").style.height = "0px";
    document.getElementById("bmrresults").style.height = "0px";
    document.getElementById("bmiresults").style.height = "0px";
    document.getElementById("calorieintakeresults").style.height = "0px";
    document.getElementById("bodyfatresults").style.height = "0px";
  };

  const savebodyfat = (e) => {
    const number4 = /^[1-9][0-9]?$|^100$/;
    if (number4.test(neck)) {
      setAlertNeck("");
    } else if (!number4.test(neck) && neck === "0") {
      setAlertNeck("Invalid number");
      e.preventDefault();
    } else if (!number4.test(neck) && neck === "") {
      setAlertNeck("Please enter");
      e.preventDefault();
    } else {
      setAlertNeck("");
      // e.preventDefault();
    }

    if (gender === "") {
      setalertGender("Please select");
      e.preventDefault();
    } else {
      setalertGender("");
    }
    const number5 = /^[1-9][0-9]?$|^100$/;
    if (number5.test(waist)) {
      setAlertWaist("");
    } else if (!number5.test(waist) && waist === "0") {
      setAlertWaist("Invalid number");
      e.preventDefault();
    } else if (!number5.test(waist) && waist === "") {
      setAlertWaist("Please enter");
      e.preventDefault();
    } else {
      setAlertWaist("");
      // e.preventDefault();
    }

    const number6 = /^[1-9][0-9]?$|^100$/;
    if (number6.test(hip)) {
      setAlertHip("");
    } else if (!number6.test(hip) && hip === "0") {
      setAlertHip("Invalid number");
      e.preventDefault();
    } else if (!number6.test(hip) && hip === "") {
      setAlertHip("Please enter ");
      e.preventDefault();
    } else {
      setAlertHip("");
      // e.preventDefault();
    }

    const number1 = /^[1-9][0-9]?$|^100$/;
    if (number1.test(age)) {
      setAlertAge("");
    } else if (!number1.test(age) && age === "0") {
      setAlertAge("Invalid");
      e.preventDefault();
    } else if (!number1.test(age) && age === "") {
      setAlertAge("Please enter your age");
      e.preventDefault();
    } else {
      setAlertAge("");
      // e.preventDefault();
    }

    const number2 = /^[1-9][0-9]?$|^100$/;
    if (number2.test(height)) {
      setAlertHeight("");
    } else if (!number2.test(height) && height === "0") {
      setAlertHeight("Invalid number");
      e.preventDefault();
    } else if (!number2.test(height) && height === "") {
      setAlertHeight("Please enter");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const number3 = /^[1-9][0-9]?$|^100$/;
    if (number3.test(weight)) {
      setAlertWeight("");
    } else if (!number3.test(weight) && weight === "0") {
      setAlertWeight("Invalid number");
      e.preventDefault();
    } else if (!number3.test(weight) && weight === "") {
      setAlertWeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }
  };

  const savecalorietake = (e) => {
    const number4 = /^[1-9][0-9]?$|^100$/;
    if (number4.test(targetWeight)) {
      setAlertTargetWeight("");
    } else if (!number4.test(targetWeight) && targetWeight === "0") {
      setAlertTargetWeight("Invalid number");
      e.preventDefault();
    } else if (!number4.test(targetWeight) && targetWeight === "") {
      setAlertTargetWeight("Please enter");
      e.preventDefault();
    } else {
      setAlertTargetWeight("");
      // e.preventDefault();
    }

    const text2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (text2.test(goal)) {
      setAlertGoal("");
    } else if (!text2.test(goal) && goal === "0") {
      setAlertGoal("Invalid number");
      e.preventDefault();
    } else if (!text2.test(goal) && goal === "") {
      setAlertGoal("Please enter");
      e.preventDefault();
    } else {
      setAlertGoal("");
      // e.preventDefault();
    }

    const text1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (text1.test(activityLevel)) {
      setAlertActivityLevel("");
    } else if (!text1.test(activityLevel) && activityLevel === "0") {
      setAlertActivityLevel("Invalid number");
      e.preventDefault();
    } else if (!text1.test(activityLevel) && activityLevel === "") {
      setAlertActivityLevel("Please enter");
      e.preventDefault();
    } else {
      setAlertActivityLevel("");
      // e.preventDefault();
    }

    const number1 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number1.test(age)) {
      setAlertAge("");
    } else if (!number1.test(age) && age === "0") {
      setAlertAge("Invalid number");
      e.preventDefault();
    } else if (!number1.test(age) && age === "") {
      setAlertAge("Please enter");
      e.preventDefault();
    } else {
      setAlertAge("");
      // e.preventDefault();
    }

    const number2 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number2.test(height)) {
      setAlertHeight("");
    } else if (!number2.test(height) && height === "0") {
      setAlertHeight("Invalid number");
      e.preventDefault();
    } else if (!number2.test(height) && height === "") {
      setAlertHeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const number3 = /^[1-9][0-9][0-9]?$|^1000$/;
    if (number3.test(weight)) {
      setAlertWeight("");
    } else if (!number3.test(weight) && weight === "0") {
      setAlertWeight("Invalid number");
      e.preventDefault();
    } else if (!number3.test(weight) && weight === "") {
      setAlertWeight("Please enter ");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }
  };

  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container">
              <div className="pt-5 mt-5">
                <div className="col-md-12 pb-5">
                  <h3 className="mb-2">My Tools</h3>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalBMI"
                      >
                        BMI
                      </h5>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalBMR"
                      >
                        BMR
                      </h5>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalRMR"
                      >
                        RMR
                      </h5>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalBodyfat"
                      >
                        Body Fat
                      </h5>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalmacro"
                      >
                        Macro Calculator
                      </h5>
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalfood"
                      >
                        Food Calorie
                      </h5>
                    </div>
                  </div> */}

                  <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalIdealweight"
                      >
                        Ideal Weight
                      </h5>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalleanbody"
                      >
                        Lean Body Mass
                      </h5>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-1 mb-1">
                    <div className="bg-color">
                      <h5
                        className="text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#myModalcalorieintake"
                      >
                        Calorie Intake
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="myModalBMI" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">BMI Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bmical}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                    <p className="alert-message">{alertgender}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height in cm"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div className="result-bg" id="bmiresults">
                  <div className="d-flex justify-content-between">
                    <p>BMI :</p>
                    <span>{bmiresult.bmi}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Suggestion:</p>
                    <span>{bmiresult.category}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="myModalBMR" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents modal-resize">
            <div className="modal-header">
              <h4 className="modal-title">BMR Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bmrcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                    <p className="alert-message">{alertgender}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div
                  className="result-bg d-flex justify-content-between"
                  id="bmrresults"
                >
                  <p>BMR :</p>
                  <span>{bmrresult.bmr}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="myModalRMR" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center "
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">RMR Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={rmrcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                    <p className="alert-message">{alertgender}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="aalert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="aalert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="aalert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>

                <div className="result-bg" id="rmrresults">
                  <div className="d-flex justify-content-between">
                    <p>RMR :</p>
                    <span>{rmrresult.rmr}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalIdealweight"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Ideal Weight Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={Idealweightcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                    <p className="alert-message">{alertgender}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>

                <div className="result-bg" id="Idealweightresults">
                  <div className="d-flex justify-content-between">
                    <p>Ideal Weight :</p>
                    <span>{IdealWeightresult.idealWeight}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalleanbody"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Lean Body Mass</h4>
              <button
                type="button"
                className="btn btn-close"
                onClick={close}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={leanweightcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                    <p className="alert-message">{alertgender}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={save}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>

                <div className="result-bg" id="leanbodymassresults">
                  <div className="d-flex justify-content-between">
                    <p>Lean Body Mass :</p>
                    <span>{leanbodymassresult.lbm}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div className="modal fade" id="myModalmacro" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Macro Calculator</h4>
              <button
                type="button"
                className="btn btn-close"
                onClick={close}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={macrocal}>
                <div className="row">
                  <div className="col-md-2 col-4">Calories:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={calories}
                      onChange={(e) => setCalories(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter the calories"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertcalories}</p>
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={savecalories}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>

                <div className="result-bg" id="macroresults">
                  <div className="d-flex justify-content-between">
                    <p>Protein In Grams :</p>
                    <span>{macroresult.proteinInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Carb In Grams :</p>
                    <span>{macroresult.carbInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Fat In Grams :</p>
                    <span>{macroresult.fatInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Fiber In Grams:</p>
                    <span>{macroresult.fiberInGrams}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Total Calories :</p>
                    <span>{macroresult.totalCalories}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalBodyfat"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Body Fat </h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={bodyfatcal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="female"
                        checked={gender === "female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                    <p className="alert-message">{alertgender}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>

                  <div className="col-md-2 col-4">Neck:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={neck}
                      onChange={(e) => setNeck(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your neck"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertneck}</p>
                  </div>

                  <div className="col-md-2 col-4">Waist:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={waist}
                      onChange={(e) => setWaist(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your waist"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertwaist}</p>
                  </div>

                  <div className="col-md-2 col-4">Hip:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={hip}
                      onChange={(e) => setHip(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your hip"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alerthip}</p>
                  </div>
                </div>

                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={savebodyfat}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div className="result-bg" id="bodyfatresults">
                  <div className="d-flex justify-content-between">
                    <p>BFP :</p>
                    <span>{bodyfatresult.bfp}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      <div
        className="modal fade"
        id="myModalcalorieintake"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header">
              <h4 className="modal-title">Calorie Intake </h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body" align="center">
              <form action="" onSubmit={calorieintakecal}>
                <div className="row">
                  <div className="col-md-2 col-4">Gender:</div>
                  <div className="col-md-10 col-8">
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="female"
                        checked={gender === "female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                        required
                      />
                      Female
                    </span>
                    <p className="alert-message">{alertgender}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-4">Age:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Age"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertage}</p>
                  </div>

                  <div className="col-md-2 col-4">Height:</div>
                  <div className="col-md-10 col-8">
                    <input
                      type="number"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="edit_text_form"
                      placeholder="Enter Your Height"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertheight}</p>
                  </div>

                  <div className="col-md-2 col-4">Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter Your Weight"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alertweight}</p>
                  </div>

                  <div className="col-md-2 col-4">Target Weight:</div>
                  <div className="col-md-10 col-8">
                    <input
                      value={targetWeight}
                      onChange={(e) => setTargetWeight(e.target.value)}
                      type="number"
                      className="edit_text_form"
                      placeholder="Enter your neck"
                      // min="1"
                      style={{ marginTop: "3%" }}
                    />
                    <p className="alert-message">{alerttargetWeight}</p>
                  </div>

                  <div className="col-md-5 col-5" style={{ textAlign: "left" }}>
                    Select Goal:
                  </div>
                  <div
                    className="col-md-7 col-7 pt-2"
                    style={{ padding: "1%" }}
                  >
                    <div className="d-flex justify-content-start">
                      <select
                        name="fitness"
                        id="select"
                        value={goal}
                        onChange={(e) => setGoal(e.target.value)}
                        className="form-control select-width"
                        style={{ border: "1px solid black" }}
                      >
                        <option value="">Select</option>
                        <option value="loseWeight">Lose Weight</option>
                        <option value="gainWeight">Gain Weight</option>
                        <option value="maintainWeight">Maintain Weight</option>
                      </select>
                    </div>
                    <p className="alert-message" style={{ textAlign: "left" }}>
                      {alertgoal}
                    </p>
                  </div>

                  <div className="col-md-5 col-5" style={{ textAlign: "left" }}>
                    Select Activity Level:
                  </div>
                  <div
                    className="col-md-7 col-7 pt-2"
                    style={{ padding: "1%" }}
                  >
                    <div className="d-flex justify-content-start">
                      <select
                        name="fitness"
                        id="select"
                        value={activityLevel}
                        onChange={(e) => setActivityLevel(e.target.value)}
                        className="form-control select-width"
                        style={{ border: "1px solid black" }}
                      >
                        <option value="">Select</option>
                        <option value="sedentary">Sedentary</option>
                        <option value="lightlyActive">Lightly Active</option>
                        <option value="moderatelyActive">
                          Moderately Active
                        </option>
                        <option value="veryActive">Very Active</option>
                        <option value="extraActive">Extra Active</option>
                      </select>
                    </div>
                    <p className="alert-message" style={{ textAlign: "left" }}>
                      {alertactivityLevel}
                    </p>
                  </div>
                </div>

                <br />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-warning col-white"
                    onClick={savecalorietake}
                  >
                    Check
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    style={{ padding: "5px 20px" }}
                    className="btn btn-blue col-white"
                    onClick={cancel}
                  >
                    Reset
                  </button>
                </div>
                <div className="result-bg" id="calorieintakeresults">
                  <div className="d-flex justify-content-between">
                    <p>Current calorie per day :</p>
                    <span>{calorieintakeresult.currentCaloriesPerDay}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Calories goal per day :</p>
                    <span>{calorieintakeresult.caloriesGoalPerDay}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Current weight :</p>
                    <span>{calorieintakeresult.currentWeight}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Target weight :</p>
                    <span>{calorieintakeresult.targetWeight}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Goal :</p>
                    <span>{calorieintakeresult.goal}</span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Activity level :</p>
                    <span>{calorieintakeresult.activityLevel}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
    </div>
  );
};
export default MyTools;
