import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import consultService from "../../services/consult.service";
import AuthService from "../../services/auth.service";

export const getexpert = createAsyncThunk(
    "expertid/get/expertid",
    async(thunkAPI) => {
        try {
            const data = await consultService.getparentexpert();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const appointments = createAsyncThunk(
    "expertid/get/expertid",
    async(thunkAPI) => {
        try {
            const data = await consultService.getAppointments();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getConsultations = createAsyncThunk(
    "expertid/get/consultation",
    async(thunkAPI) => {
        try {
            const data = await consultService.getConsultations();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getPrescription = createAsyncThunk(
    "expertid/get/consultation",
    async(thunkAPI) => {
        try {
            const data = await consultService.getPrescriptions();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const postconsultation = createAsyncThunk(
    "enquiry/enquiry/saveEnquiry",
    async(item,thunkAPI) => {
        try {
            const data = await consultService.postconsult(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const blogSlice = createSlice({
    name: "consult",
    initialState,
    extraReducers: {
        [getexpert.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getexpert.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getexpert.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [postconsultation.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postconsultation.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postconsultation.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = blogSlice;
export default reducer;