import React, { useState } from "react";
import Footer from "./Footer";
import { medicalhistoryData } from "../store/slices/forms";
import { useDispatch, useSelector } from "react-redux";
import UserNavbar from "./UserNavbar";

const MedicalHistory = () => {
  const [medical, setMedical] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(medicalhistoryData())
      .unwrap()
      .then((data) => {
        setMedical(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <div className="col-md-12">
                  <h3 className="mb-2">Medical History</h3>
                </div>
                <div className="bg-white-ptbrl-2 mb-5 mt-1">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th className="wd-15p">Question</th>
                          <th>Answer</th>
                        </tr>
                      </thead>
                      {medical.map((medicalVal, index) => (
                        <tbody>
                          {medicalVal.conditions.map(
                            (conditationVal, index) => (
                              <tr key={index}>
                                <td>{conditationVal.questions[0].question} </td>
                                <td>{conditationVal.questions[0].answer}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MedicalHistory;
