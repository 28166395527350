import React from "react";
import TravelHeader from "./travel-navbar";
import tick from "./../images/tick.png";
import Travelfooter from "./Travelfooter";
const Thanks = () => {
  return (
    <div id="__next">
      <TravelHeader />

      <section className="about-section pt-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12 col-sm-12"
              style={{ marginTop: "100px" }}
            >
              <br />
              <br />
              <center>
                <img
                  src={tick}
                  alt="tick"
                  style={{
                    height: "60px",
                    width: "60px",
                    marginBottom: "30px",
                  }}
                />
                <h3>Thank you for your order !</h3>

                <p>
                  Your booking is complete. Please check your email for the
                  further details.
                </p>
                {/* <p>Your Booking Id is : #123456789</p> */}

                <a
                  className="default-btn-one"
                  href="/travelonify"
                  style={{
                    marginTop: "30px",
                    width: "350px",
                    borderRadius: "3px",
                  }}
                >
                  Continue Shopping
                </a>
              </center>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <Travelfooter />
    </div>
  );
};

export default Thanks;
