import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import UserNavbar from "./UserNavbar";
import { postwaterreminder } from "../store/slices/reminder";
// import Moment from "moment";
const AddWater = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [reminderFromTime, setReminderFromTime] = useState("");
  const [reminderToTime, setReminderToTime] = useState("");
  const [hourlyReminder, setHourlyReminder] = useState("");
  const [reminderTimes, setReminderTimes] = useState("");
 
  const [alerthourlyReminder, setAlertHourlyReminder] = useState("");
  const [alertreminderTimes, setAlertReminderTimes] = useState("");

  async function upload(e) {
    e.preventDefault();
    let userId = "628e07c3295cbb2a64996d2d";

    let item = {
        userId,
        reminderFromTime,
        reminderToTime,
        hourlyReminder,
        reminderTimes,
    
}
    dispatch(postwaterreminder(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save=(e)=>{
    const number4 = /^[1-3][0-9][0-9][0-9]?$|^100$/;
    if (number4.test(hourlyReminder)) {
      setAlertHourlyReminder("");
    } else if (!number4.test(hourlyReminder) && hourlyReminder === "") {
      setAlertHourlyReminder("Please enter");
      e.preventDefault();
    } else {
      setAlertHourlyReminder("");
      // e.preventDefault();
    }

    const number1 = /^[1-3][0-9][0-9][0-9]?$|^100$/;
    if (number1.test(reminderTimes)) {
      setAlertReminderTimes("");
    } else if (!number1.test(reminderTimes) && reminderTimes === "") {
      setAlertReminderTimes("Please enter");
      e.preventDefault();
    } else {
      setAlertReminderTimes("");
      // e.preventDefault();
    }

  }

  const cancel = () =>{
    setReminderFromTime("");
    setReminderToTime("");
    setHourlyReminder("");
    setReminderTimes("");
    setAlertHourlyReminder("");
    setAlertReminderTimes("");

  }

      return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container">
              <div className="pt-5 mt-5">
            <h3 className="pt-4 pb-2">Add Water Reminder</h3>

                <div className="car">
                  <div className="a" align="center">

                    <form className="contact-form" onSubmit={upload}>
                      <div className="row">

                        <div className="col-md-3 col-xs-6" align="left">
                        <div className="form-group">
                          <b>Reminder From </b>
                          <input
                            type="time"
                            required
                            className="form-control mt-2"
                            value={reminderFromTime}
                            onChange={(e) => setReminderFromTime(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-xs-6" align="left">
                        <div className="form-group">
                          <b>Reminder To </b>
                          <input
                            type="time"
                            required

                            className="form-control mt-2"
                            value={reminderToTime}
                            onChange={(e) => setReminderToTime(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Hour Reminder</b>
                          <input
                            type="number"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={hourlyReminder}
                            onChange={(e) => setHourlyReminder(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                          <p className="alert-message">{alerthourlyReminder}</p>
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Time Reminder</b>
                          <input
                            type="number"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={reminderTimes}
                            onChange={(e) => setReminderTimes(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                          <p className="alert-message">{alertreminderTimes}</p>

                        </div>
                      </div>


                        <center
                          className="send-btn"
                          style={{ marginBottom: "20px" }}
                        >
                          <button className="btn btn-warning col-white submit-btn" onClick={save}>
                            Save
                          </button>
                          &nbsp;
                          <button className="btn btn-blue col-white submit-btn" onClick={cancel}>
                            Cancel
                          </button>
                        </center>
                      </div>
                    </form>
                    {/* ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWater;
