import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import client1 from "./../images/travelonify/client/client1.jpeg";
import client2 from "./../images/travelonify/client/client2.jpeg";
import client3 from "./../images/travelonify/client/client3.png";
import client4 from "./../images/travelonify/client/client4.jpeg";
import mumbai from "../images/trip/Mumbai.jpg";
import Kerla from "../images/trip/Kerla.jpg";
import india from "../images/trip/india.jpg";
import USA from "../images/trip/USA.jpg";
import Japan from "../images/trip/Japan.jpg";
import tour1 from "../images/blog/temple-tour-banner.png";
import tour2 from "../images/blog/International-banner_1236.jpg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Carousel from "react-bootstrap/Carousel";

import Dropdown from "react-bootstrap/Dropdown";

import team1 from "./../images/team/pic1.jpg";
import team2 from "./../images/team/pic2.jpg";
import team3 from "./../images/team/pic3.jpg";
import team4 from "./../images/team/pic4.jpg";
import team5 from "./../images/team/pic5.jpg";

import travel1 from "./../images/travelonify/home-image/banner1.jpg";
import travel2 from "./../images/travelonify/home-image/banner2.jpg";
import travel3 from "./../images/travelonify/home-image/banner3.jpg";

import "./Travel.css";
import TravelHeader from "./travel-navbar";
import Travelfooter from "./Travelfooter";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import { City } from "../store/slices/city";
import {
  Package,
  SpecialPackage,
  BestCity,
  Themes,
  Honeymoon,
} from "../store/slices/package";

//testimonial images
import Akanksha from "./../images/testimonial/Akanksha.png";
import Usha from "./../images/testimonial/usha.png";
import Reva from "./../images/testimonial/reva.png";
import Asim from "./../images/testimonial/asim nath.png";

import { InternationalPackage } from "./../store/slices/internationalpackage";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { getTravelblog } from "../store/slices/blog";

const Travel = (props) => {
  const [blogs, setBlogs] = useState([]);

  let enquiryFor = "travelPackage";
  const [expertises, setexpertise] = useState([]);
  const [internationalPackage, setinternationalPackage] = useState([]);
  const [city, setCity] = useState([]);
  const [special, setSpecial] = useState([]);
  const [bestcitys, setBestCitys] = useState([]);
  const [themes, setThemes] = useState([]);
  const [honeymoon, setHoneymoon] = useState([]);
  const [childcount, setchildCount] = useState("");
  const [adultCount, setadultCount] = useState("");
  const [name, setName] = useState("");
  const [travelDate, settravelDate] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [alertmail, setAlertmail] = useState("");
  const [alertchild, setAlertchild] = useState("");
  const [alertdate, setalertDate] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertMobile, setAlertMobile] = useState("");
  const [alertdestination, setAlertDestination] = useState("");
  const [alertAdult, setAlertAdult] = useState("");
  const [alertMessage, setAlertmessage] = useState("");
  const peopleCount = adultCount + " Adult, " + childcount + " Children ";
  const [destination, setDestination] = useState("");
  const [hoteldestination, sethotelDestination] = useState("");
  const [query, setQuery] = useState("");
  const [querys, setQuerys] = useState("");
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(Themes())
      .unwrap()
      .then((data) => {
        setThemes(data.user.data);
        console.log(data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getTravelblog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(Honeymoon())
      .unwrap()
      .then((data) => {
        setHoneymoon(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(BestCity())
      .unwrap()
      .then((data) => {
        setBestCitys(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  // Increase and Decrease Numbers Hooks Start
  const [count_A, setCount_A] = useState(1);
  const [count_C, setCount_C] = useState(0);
  const [count_R, setCount_R] = useState(1);

  const IncNum_A = () => {
    setCount_A(count_A + 1);
  };

  const IncNum_C = () => {
    setCount_C(count_C + 1);
  };

  const IncNum_R = () => {
    setCount_R(count_R + 1);
  };

  const DecNum_A = () => {
    setCount_A((count_A) => Math.max(count_A - 1, 1));
  };

  const DecNum_C = () => {
    setCount_C((count_C) => Math.max(count_C - 1, 0));
  };

  const DecNum_R = () => {
    setCount_R((count_R) => Math.max(count_R - 1, 1));
  };
  // Increase and Decrease Numbers Hooks End

  const formSubmit = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("Please enter a valid name");
      e.preventDefault();
    }

    const vDate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate.test(travelDate)) {
      setalertDate("");
    } else if (!vDate.test(travelDate) && travelDate === "") {
      setalertDate("Please enter your date of travel");
      e.preventDefault();
    } else {
      setalertDate("");
    }

    const vDestination = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vDestination.test(destination)) {
      setAlertDestination("");
    } else if (!vDestination.test(destination) && destination === "") {
      setAlertDestination("Please enter the destination");
      e.preventDefault();
    } else {
      setAlertDestination("");
      e.preventDefault();
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setAlertmessage("");
    } else if (!vMessage.test(message) && message === "") {
      setAlertmessage("Please enter the message");
      e.preventDefault();
    } else {
      setAlertmessage("");
    }

    const vAdult = /^[1-9][0-9]?$|^100$/;
    if (vAdult.test(adultCount)) {
      setAlertAdult("");
    } else if (!vAdult.test(adultCount) && adultCount === "") {
      setAlertAdult("Please enter  the no.of adults");
      e.preventDefault();
    } else {
      setAlertAdult("Min value should be 1");
      e.preventDefault();
    }

    const child = /^[0-9][0-9]?$|^100$/;
    if (child.test(childcount)) {
      setAlertchild("");
    } else if (!child.test(childcount) && childcount === "") {
      setAlertchild("Please enter  the no.of child");
      e.preventDefault();
    } else {
      setAlertchild("");
      e.preventDefault();
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile.test(contactNumber)) {
      setAlertMobile("");
    } else if (!mobile.test(contactNumber) && contactNumber === "") {
      setAlertMobile("Please enter the mobile  number");
      e.preventDefault();
    } else {
      setAlertMobile("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vemail.test(email)) {
      setAlertmail("");
    } else if (!vemail.test(email) && email === "") {
      setAlertmail("Please enter the your email");
      e.preventDefault();
    } else {
      setAlertmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const formReset = (e) => {
    setDestination("");
    setMessage("");
    setchildCount("");
    setName("");
    setEmail("");
    setcontactNumber("");
    setadultCount("");
    settravelDate("");

    setAlertmail("");
    setAlertchild("");
    setalertDate("");
    setalertName("");
    setAlertMobile("");
    setAlertDestination("");
    setAlertAdult("");
    setAlertmessage("");
  };
  //submit enquiry
  async function upload(e) {
    e.preventDefault();
    let item = {
      name,
      email,
      contactNumber,
      message,
      enquiryFor,
      travelDate,
      destination,
      peopleCount,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setDestination("");
        setMessage("");
        setchildCount("");
        setName("");
        setEmail("");
        setcontactNumber("");
        setadultCount("");
        settravelDate("");
      })

      .catch(({ emessage }) => {
        alert("Error");
      });
  }

  //get packages
  React.useEffect(() => {
    dispatch(Package())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        //alert(message);
      });
  }, [dispatch]);

  //get internation package
  React.useEffect(() => {
    dispatch(InternationalPackage())
      .unwrap()
      .then((data) => {
        setinternationalPackage(data.user.data);
      })
      .catch(({ message }) => {
        //alert(message);
      });
  }, [dispatch]);

  //get city list
  React.useEffect(() => {
    dispatch(City())
      .unwrap()
      .then((data) => {
        setCity(data.user.data);
      })
      .catch(({ message }) => {
        //alert(message);
      });
  }, [dispatch]);

  //get travelonify special package

  React.useEffect(() => {
    dispatch(SpecialPackage())
      .unwrap()
      .then((data) => {
        setSpecial(data.user.data);
      })
      .catch(({ message }) => {
        //alert(message);
      });
  }, [dispatch]);

  //best of dubai

  const myData = {
    theme: querys,
  };

  const myDatas = {
    destination: query,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  async function searchDestination(e) {
    e.preventDefault();
    navigate("/travelonify/SearchByDestination/", { state: myDatas });
  }

  async function searchTheme(e) {
    e.preventDefault();
    navigate("/travelonify/SearchByTheme/", { state: myData });
  }

  const handleThemeChange = (e) => {
    var value = themes.filter(function (category) {
      return category.name === e.target.value;
    });
    setQuerys(value[0].name);
  };
  useEffect(() => {
    document.title = "Travelonify";
  }, []);

  const myDatass = {
    destination: hoteldestination,
  };
  async function searchHotels(e) {
    e.preventDefault();
    navigate("/travelonify/HotelList/", { state: myDatass });
  }
  return (
    <div id="_next">
      <TravelHeader />
      <section>
        <div className="carousel_cover">
          <Carousel fade>
            <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel1}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top">
                <div className="d-flex justify-content-center">
                  <h1 className="text1">
                    Create your dream memories with Travelonify
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="overlaywhite-text">
                    Search&gt;&gt; Select&gt;&gt; Book&gt;&gt; Pay
                    online&gt;&gt; Get Going
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel2}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top">
                <div className="d-flex justify-content-center">
                  <h1 className="text1">
                    Create your dream memories with Travelonify
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="overlaywhite-text">
                    Search&gt;&gt; Select&gt;&gt; Book&gt;&gt; Pay
                    Online&gt;&gt; Get Going
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel3}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top">
                <div className="d-flex justify-content-center">
                  <h1 className="text1">
                    Create your dream memories with Travelonify
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="overlaywhite-text">
                    Search&gt;&gt; Select&gt;&gt; Book&gt;&gt; Pay
                    online&gt;&gt; Get Going
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className="container">
            <div className="carousel-inner-content-overlay">
              <Tabs
                id="uncontrolled-tab-example"
                style={{ background: "white", borderRadius: "5px" }}
              >
                <Tab eventKey="home" title="Search by Destination">
                  <form onSubmit={searchDestination}>
                    <div className="row" style={{ padding: "5px 0" }}>
                      <div className="col-md-9 col-xs-6">
                        <input
                          type="text"
                          placeholder="Enter Destination"
                          className="search-mod border-active-none"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                      <div
                        className="col-md-3 col-xs-6"
                        align="left"
                        style={{ padding: "0%" }}
                      >
                        <button
                          className="btn btn-blue col-white"
                          style={{ width: "100%", height: "40px" }}
                          id="save"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab>
                <Tab eventKey="profile" title="Search by Theme">
                  <form onSubmit={searchTheme}>
                    <div className="row" style={{ padding: "5px 0" }}>
                      <div className="col-md-9 col-xs-6">
                        <select
                          className="form-control"
                          onChange={handleThemeChange}
                        >
                          <option value="">Select</option>
                          {themes.map((option) => (
                            <option value={option.name}>{option.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-3 col-xs-6" align="left">
                        <button
                          className="btn btn-blue col-white"
                          style={{ width: "100%" }}
                          id="save"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <form className="contact-form" onSubmit={upload} name="form" id="forms">
          <h2 align="center" style={{ color: "black" }}>
            Plan your holidays with our travel expert - Please fill in your
            details
          </h2>
          <br />
          <div className="row">
            <div className="col-md-3">
              <p className="ptext">Name</p>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Name"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                />

                <p className="alert-message">{alertName}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Mobile No</p>
                <input
                  maxlength="10"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                  id="num"
                />
                <p className="alert-message"> {alertMobile}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Email</p>
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="mail"
                />
                <p className="alert-message">{alertmail}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Travel Date</p>
                <input
                  type="date"
                  name="datemin"
                  placeholder="Travel Date"
                  value={travelDate}
                  onChange={(e) => settravelDate(e.target.value)}
                  className="form-control"
                  id="date"
                  required
                />

                <p className="alert-message">{alertdate}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Destination</p>
                <input
                  type="text"
                  placeholder="Destination"
                  className="form-control"
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                  id="des"
                />
                <p className="alert-message">{alertdestination}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">No.of Adult</p>
                <input
                  maxlength="2"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                  placeholder="No.of Adult"
                  className="form-control"
                  min="1"
                  value={adultCount}
                  id="adult"
                  onChange={(e) => setadultCount(e.target.value)}
                />
                <p className="alert-message">{alertAdult}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">No.of Children</p>
                <input
                  maxlength="2"
                  type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                  placeholder="No.of Children"
                  className="form-control"
                  value={childcount}
                  id="child"
                  onChange={(e) => setchildCount(e.target.value)}
                />
                <p className="alert-message">{alertchild}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Brief us on your requirement</p>
                <input
                  type="text"
                  placeholder="Brief us on your requirement"
                  className="form-control"
                  value={message}
                  id="message"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white submit-btn"
                  onClick={formSubmit}
                  id="save"
                >
                  Submit
                </button>
                &nbsp; &nbsp;
                <input
                  type="reset"
                  className="btn btn-blue col-white submit-btn"
                  value="Reset"
                  onClick={formReset}
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <br />

      <section className="banner2 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="pt-5 pb-4"
                style={{ color: "#001E79", fontSize: "25px" }}
                align="center"
              >
                Indian Holidays
              </h2>
            </div>
            <div className="container">
              <Slider {...settings}>
                {city.map((PackageVal, index) => (
                  <Link
                    to={`/travelonify/indian-holidays/TravelCity/${
                      PackageVal._id ? PackageVal._id : null
                    }`}
                  >
                    <div className="slide slick-space">
                      <div className="card_slicks">
                        <img
                          src={PackageVal.image}
                          alt=""
                          className="slick-img"
                        />
                        <h4
                          className="card-title slick_text pt-2"
                          style={{ textAlign: "center" }}
                        >
                          {PackageVal.name}
                        </h4>
                        <h6 className="pb-2" style={{ textAlign: "center" }}>
                          {PackageVal.state}
                        </h6>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 mb-5">
        <div className="container">
          <div className="row">
            <center>
              <h1
                style={{ fontSize: "25px", color: "#001E79" }}
                className="pt-4 pb-3"
              >
                International Holidays
              </h1>
            </center>

            <div className="container">
              <Slider {...settings}>
                {internationalPackage.map((PackageVal, index) => (
                  <Link
                    to={`/travelonify/Traveldetails/${
                      PackageVal._id ? PackageVal._id : null
                    }`}
                  >
                    <div className="slide slick-space">
                      <div className="card_slicks">
                        <img
                          src={PackageVal.imageUrl[0].mediaLink}
                          className="slick-img"
                          alt=""
                        />

                        <h4
                          className="card-title slick_text"
                          style={{
                            textAlign: "center",
                            paddingTop: "2%",
                            paddingBottom: "2%",
                          }}
                        >
                          {PackageVal.packageName.substring(0, 25)}
                        </h4>

                        <p
                          className="card-text"
                          style={{ textAlign: "center" }}
                        >
                          {PackageVal.duration}

                          <p
                            style={{
                              color: "#001E79",
                              fontSize: "20px",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            &#x20b9; {PackageVal.twinShareRate / 2}
                          </p>

                          <p
                            style={{
                              fontSize: "10px",
                              marginTop: "-20px",
                              textAlign: "center",
                            }}
                          >
                            Price per person on twin sharing basis*
                          </p>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className=" banner2 pb-5">
        <div className="container">
          <div className="row">
            <center>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="pt-5 pb-3"
                  style={{ color: "#001E79", fontSize: "25px" }}
                >
                  Travelonify Special
                </h2>
              </div>
            </center>

            <div className="container">
              <Slider {...settings}>
                {special.map((PackageVal, index) => (
                  <Link
                    to={`/travelonify/Traveldetails/${
                      PackageVal._id ? PackageVal._id : null
                    }`}
                  >
                    <div className="slide slick-space">
                      <div className="card_slicks-mod">
                        <img
                          src={PackageVal.imageUrl[0].mediaLink}
                          className="slick-img"
                          alt=""
                        />

                        <h4
                          className="card-title slick_text"
                          style={{
                            textAlign: "center",
                            paddingTop: "2%",
                            paddingBottom: "2%",
                          }}
                        >
                          {PackageVal.packageName}
                        </h4>
                        <p
                          className="card-text"
                          style={{ textAlign: "center" }}
                        >
                          {PackageVal.duration}

                          <p
                            style={{
                              color: "#001E79",
                              fontSize: "20px",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            &#x20b9; {PackageVal.twinShareRate / 2}
                          </p>

                          <p
                            style={{
                              fontSize: "10px",
                              marginTop: "-20px",
                              textALign: "center",
                            }}
                          >
                            Price per person on twin sharing basis*
                          </p>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section id="homepage pb-5 pt-1">
        <div className="container pb-5">
          <div className="row">
            <center>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="pt-5 pb-3"
                  style={{ color: "#001E79", fontSize: "25px" }}
                >
                  Adventures You will Love
                </h2>
              </div>
            </center>

            <div className="container">
              <Slider {...settings}>
                {expertises.map((PackageVal, index) => (
                  <Link
                    to={`/travelonify/Traveldetails/${
                      PackageVal._id ? PackageVal._id : null
                    }`}
                  >
                    <div className="slide slick-space">
                      <div className="card_slicks-mod">
                        <img
                          src={PackageVal.imageUrl[0].mediaLink}
                          className="slick-img"
                          alt=""
                        />

                        <h4
                          className="card-title slick_text"
                          style={{
                            fontSize: "15px",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          {PackageVal.packageName}
                        </h4>
                        <p
                          className="card-text"
                          style={{ textAlign: "center" }}
                        >
                          {PackageVal.duration}

                          <p
                            style={{
                              color: "#001E79",
                              fontSize: "20px",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            &#x20b9; {PackageVal.twinShareRate / 2}
                          </p>

                          <p
                            style={{
                              fontSize: "10px",
                              marginTop: "-20px",
                              textAlign: "center",
                            }}
                          >
                            Price per person on twin sharing basis*
                          </p>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="banner2 pb-4">
        <div className="container">
          <div className="row">
            <center>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="pt-5 pb-3"
                  style={{ color: "#001E79", fontSize: "25px" }}
                >
                  Honeymoon Packages
                </h2>
              </div>
            </center>

            <div className="container">
              <Slider {...settings}>
                {honeymoon.map((PackageVal, index) => (
                  <Link
                    to={`/travelonify/Traveldetails/${
                      PackageVal._id ? PackageVal._id : null
                    }`}
                  >
                    <div className="slide slick-space">
                      <div className="card_slicks-mod">
                        <img
                          src={PackageVal.imageUrl[0].mediaLink}
                          alt=""
                          className="slick-img"
                        />

                        <h4
                          className="card-title slick_text"
                          style={{
                            fontSize: "15px",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          {PackageVal.packageName}
                        </h4>
                        <p
                          className="card-text"
                          style={{ textAlign: "center" }}
                        >
                          {PackageVal.duration}

                          <p
                            style={{
                              color: "#001E79",
                              fontSize: "20px",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            &#x20b9; {PackageVal.twinShareRate / 2}
                          </p>

                          <p
                            style={{
                              fontSize: "10px",
                              marginTop: "-20px",
                              textAlign: "center",
                            }}
                          >
                            Price per person on twin sharing basis*
                          </p>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <center>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="pt-5 pb-3"
                style={{ color: "#001E79", fontSize: "25px" }}
              >
                Best of Dubai
              </h2>
            </div>
          </center>

          <div className="container">
            <Slider {...settings}>
              {bestcitys.map((PackageVal, index) => (
                <Link
                  to={`/travelonify/Traveldetails/${
                    PackageVal._id ? PackageVal._id : null
                  }`}
                >
                  <div className="slide">
                    <div
                      className="card_slik"
                      style={{
                        borderRadius: "20px",
                      }}
                      align="center"
                    >
                      <img
                        src={PackageVal.imageUrl[0].mediaLink}
                        className="card-img-top_slik"
                        alt=""
                      />

                      <h4
                        className="card-title slick_text"
                        style={{ fontSize: "15px", padding: "5px" }}
                      >
                        {PackageVal.packageName}
                      </h4>
                      <p className="card-text">
                        {PackageVal.duration}

                        <p
                          style={{
                            color: "#001E79",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}
                        >
                          &#x20b9; {PackageVal.twinShareRate / 2}
                        </p>

                        <p style={{ fontSize: "10px", marginTop: "-20px" }}>
                          Price per person on twin sharing basis*
                        </p>
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <section className="hotelbanner">
        <div className="container">
          <div className="col-lg-12 col-md-12">
            <p className="form_text-top" style={{ color: "white" }}>
              Hotel Booking
            </p>
            <form onSubmit={searchHotels}>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-md-12">
                  <p className="ptext" style={{ color: "white" }}>
                    Destination
                  </p>
                  <input
                    type="text"
                    placeholder="Destination"
                    className="hotel_form"
                    value={hoteldestination}
                    onChange={(e) => sethotelDestination(e.target.value)}
                    required
                    style={{ height: "50px" }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <p className="ptext" style={{ color: "white" }}>
                    From
                  </p>
                  <input
                    type="date"
                    required
                    className="hotel_form"
                    style={{ height: "50px" }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <p className="ptext" style={{ color: "white" }}>
                    To
                  </p>
                  <input
                    type="date"
                    required
                    className="hotel_form"
                    style={{ height: "50px" }}
                  />
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <p className="ptext" style={{ color: "white" }}>
                    No.of Pax
                  </p>
                  <Dropdown className="">
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                      className="drop_downs sub_names"
                    >
                      {count_A} Adults , {count_C} Children , {count_R} Rooms
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="droper">
                      <Dropdown className="sub_dropers">
                        <span className="sub_names">Adults</span>{" "}
                        <span className="math">
                          <span className="clickmath" onClick={DecNum_A}>
                            <AiOutlineMinusCircle />
                          </span>
                          <span className="count"> {count_A}</span>
                          <span className="clickmath" onClick={IncNum_A}>
                            <AiOutlinePlusCircle />
                          </span>
                        </span>
                      </Dropdown>
                      <Dropdown className="sub_dropers">
                        <span className="sub_names">Children</span>{" "}
                        <span className="math">
                          <span className="clickmath" onClick={DecNum_C}>
                            <AiOutlineMinusCircle />
                          </span>
                          <span className="count"> {count_C}</span>
                          <span className="clickmath" onClick={IncNum_C}>
                            <AiOutlinePlusCircle />
                          </span>
                        </span>
                      </Dropdown>
                      <Dropdown className="sub_dropers">
                        <span className="sub_names">Rooms</span>{" "}
                        <span className="math">
                          <span className="clickmath" onClick={DecNum_R}>
                            <AiOutlineMinusCircle />
                          </span>
                          <span className="count"> {count_R}</span>
                          <span className="clickmath" onClick={IncNum_R}>
                            <AiOutlinePlusCircle />
                          </span>
                        </span>
                      </Dropdown>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="d-flex justify-content-center pt-3 pb-4">
                <button className="btn btn-warning col-white submit-btn">
                  Submit
                </button>
                &nbsp; &nbsp;
                <button className="btn btn-blue col-white submit-btn">
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <div className="container">
        <h2 className="mt-5 mb-5" align="center" style={{ color: "#001E79" }}>
          Travelonify dream deals on hotel bookings
        </h2>
        <Slider {...settings}>
          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={india} className="slick-img" alt="" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Trident
                </h4>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Japan} className="slick-img" alt="" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Lemon Tree
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={USA} className="slick-img" alt="" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  OYO
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={mumbai} className="slick-img" alt="" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Country Club
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Kerla} className="slick-img" alt="" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Taj
                </h4>
              </div>
            </div>
          </Link>
        </Slider>
      </div>
      <section className="banner_carosel pt-5 pb-5">
        <Carousel className="carousel_small_mob">
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 carimg2" src={tour2} alt="" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 carimg2" src={tour1} alt="" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>

      <section className="about">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <p className="wel-text pt-2">About Travelonify</p>
            <p className="mt-2">
              Travelonify is the division of Healthonify Private Limited, a
              health and wellness aggregator which handles the entire Lifestyle
              of an individual. Travelonify is headed by Mr. Alok Varma, an IIM
              Alumni who have vast experience in the service Industry, in
              particular Indian and international holidays certified from Dubai
              and Russian tourism. Travelonify has partnered with major tour
              operators, Hotel and resorts across the Country and Globe.
            </p>
            <p className="wel-text pt-2">Why Travelonify</p>
            <p className="mt-2 ">
              We at Travelonify specialize in Domestic and International Tours,
              Adventure tourism, Wellness retreats, Luxury events like
              destination weddings, corporate events, corporate seminars & tours
              offering you the advantage of best pricing and experience. We help
              and guide you to make your itinerary memorable based on your
              preference and create customized tour plans. Our specialization:
              <br />
              <b>
                {" "}
                ** Around 100+ partnered Hotels and resorts with special mention
                on European, Dubai, Russia, Maldives and group tours **
                <br /> Around 45+ Corporate Clients We pledge to create a
                memorable holiday experience for our patrons which one will
                cherish during their lifetime and be a part of Healthonify and
                Travelonify family forever. Come -Experience Travelonify.
              </b>
            </p>
          </div>
        </div>
      </section>
      <br></br>

      <section className="section-card pt-4 pb-3">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text   pb-2">Testimonial</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Akanksha} alt="" />
                  </div>
                  <h4 className="text-center">Akanksha</h4>

                  <p className="col-white">
                    We really enjoyed the trip. Everything was well organized
                    and our tour guide / Cab Driver and hotel staff were very
                    nice.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Usha} alt="" />
                  </div>
                  <h4 className="text-center">Usha</h4>

                  <p className="col-white">
                    Never could I have imagined the wonderful experiences that
                    awaited me before we began our tour. I had an absolutely
                    FABULOUS time. Everything you arranged went smooth as silk.
                    Thanx Alok for creating such a memorable experience.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Reva} alt="" />
                  </div>
                  <h4 className="text-center">Reva</h4>

                  <p className="col-white">
                    Thanks team Travelonify for creating a wonderful travel
                    experience. Already planning my next holiday soon through
                    you.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Asim} alt="" />
                  </div>
                  <h4 className="text-center">Ashim Nath</h4>

                  <p className="col-white">
                    What an experience. Hotel, food, sightseeing, local travel..
                    Everything was taken care of well. Kudos to the Travelonify
                    Team
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <section className="livewell container pt-2 pb-5">
        <div className="col-lg-12">
          <p className="wel-text pt-2">Blog</p>
        </div>
        <div className="container">
          <Slider {...settings}>
            {blogs.map((blogsVal, index) => (
              <div className="d-flex justify-content-center">
                <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                  <div
                    className="col-12"
                    style={{
                      background: "rgba(128, 128, 128,0.2)",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    align="center"
                  >
                    <img
                      src={blogsVal.mediaLink}
                      alt=""
                      className="lie-img-slide"
                    />
                    <p>{blogsVal.blogTitle}</p>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className="container pt-2 pb-3">
        <p className="wel-text">Our Partners</p>

        <div className="row">
          <div
            className="col-lg-3 col-md-3"
            style={{
              padding: "5px",
            }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
              <img src={client1} alt="" className="pat_img" />
            </div>
          </div>

          <div
            className="col-lg-3 col-md-3"
            style={{
              padding: "5px",
            }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
              <img src={client2} alt="" className="pat_img" />
            </div>
          </div>

          <div
            className="col-lg-3 col-md-3"
            style={{
              padding: "5px",
            }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
              <img src={client3} alt="" className="pat_img" />
            </div>
          </div>

          <div
            className="col-lg-3 col-md-3"
            style={{
              padding: "5px",
            }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
              <img src={client4} alt="" className="pat_img" />
            </div>
          </div>
        </div>
      </section>

      <Travelfooter />
    </div>
  );
};
export default Travel;
