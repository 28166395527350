import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class CommunityService {
   
    static getcommunity() {
        let api='';
        api =  API_PATHS.getcommunitypost;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static getcommunitydetails() {
        let api='';
        let id = localStorage.getItem("id");
        api = API_PATHS.getcommunitypostdetails + '?_id=' + id ;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static enablecommunitypost() {
      let api='';
      let item = JSON.parse(localStorage.getItem("item"));
      let id = localStorage.getItem("id");
      console.log(item)
      api = API_PATHS.communitypostenable + '?id=' + id;
      return axios
        .put(api,item)
        .then((response) => {
          if (response.data) {
          
          }
          return response.data;
        });
    }
    
    static postcommunitys(item) {
      return axios
          .post(API_PATHS.postcommunitypost,
              item
          )
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          });
  }

  static editcommunity(item) {
    let api = '';
    let id = localStorage.getItem("id");
    api = API_PATHS.putcommunitypost + '?id=' + id;
    
    localStorage.removeItem("id");
    return axios
      .put(api,item)
      .then((response) => {
        if (response.data) {
        
        }
        return response.data;
      });
  }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default CommunityService;