import React, { useState } from "react";
import Footer from "./Footer";
import { getConsultations } from "../store/slices/consult";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Moment from "moment";

import UserNavbar from "./UserNavbar";

const MyConsultation = () => {
  const [physiobookings, setPhysioBookings] = useState([]);
  const [hcbookings, setHcBookings] = useState([]);
  const [wmbookings, setWmBookings] = useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getConsultations())
      .unwrap()
      .then((data) => {
        setPhysioBookings(data.user.data.physioConsultationsData);
        setHcBookings(data.user.data.hcConsultationsData);
        setWmBookings(data.user.data.wmConsultationsData);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-3 mt-3">
                <div className="col-md-12">
                  <h3 className="pb-3">My Consultation</h3>
                </div>
                <div className="bg-white-ptbrl-2 mb-5 mt-1">
                  <div className="table-responsive b-radius-10">
                    <h3 className="pb-3">Physiotherapy Consultation</h3>
                    <table className="table table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th className="text-center">Expert Name</th>
                          <th className="text-center">Consultation For</th>
                          <th className="text-center">Consultation Date</th>
                          <th className="text-center">Consultation Time</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {physiobookings.map((PhysiBookingVal, index) => (
                          <tr key={index}>
                            <td className="wd-20p">
                              {PhysiBookingVal.expertId
                                ? PhysiBookingVal.expertId[0].firstName
                                : null}
                            </td>
                            <td className="wd-20p">
                              {PhysiBookingVal.expertiseId
                                ? PhysiBookingVal.expertiseId[0].name
                                : null}
                            </td>
                            <td className="text-center">
                              {Moment(PhysiBookingVal.startDate).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td className="text-center">
                              {Moment(
                                PhysiBookingVal.startTime,
                                "HH:mm:ss"
                              ).format("hh:mm A")}
                            </td>

                            <td className="text-center">
                              {PhysiBookingVal.status === "expertAssigned" && (
                                <p>Expert Assigned</p>
                              )}
                              {PhysiBookingVal.status === "completed" && (
                                <p className="btn btn-success">Completed</p>
                              )}
                              {PhysiBookingVal.status === "initiated" && (
                                <p className="btn btn-warning">Initiated</p>
                              )}
                            </td>
                            <td className="text-center">
                              {PhysiBookingVal.status === "expertAssigned" && (
                                <a
                                  className="btn btn-success"
                                  href="https://meet.jit.si/{PhysiBookingVal.meetingLink}"
                                  targte="_blank"
                                  className="btn btn-primary"
                                >
                                  Join
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="bg-white-ptbrl-2 mb-5 mt-1">
                  <div className="table-responsive b-radius-10">
                    <h3 className="pb-3">Weight Management Consultation</h3>
                    <table className="table table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th className="wd-15p">Expert Name</th>

                          <th className="text-center">Consultation Date</th>
                          <th className="text-center">Consultation Time</th>
                          <th className="text-center">Action</th>
                          <th className="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wmbookings.map((wmBookingVal, index) => (
                          <tr key={index}>
                            <td className="wd-20p">
                              {wmBookingVal.expertId
                                ? wmBookingVal.expertId[0].firstName
                                : null}
                            </td>

                            <td className="text-center">
                              {Moment(wmBookingVal.startDate).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td className="text-center">
                              {" "}
                              {Moment(
                                wmBookingVal.startTime,
                                "HH:mm:ss"
                              ).format("hh:mm A")}
                            </td>
                            <td className="text-center">
                              {wmBookingVal.status === "expertAssigned" && (
                                <a
                                  href="https://meet.jit.si/{wmBookingVal.meetingLink}"
                                  targte="_blank"
                                  className="btn btn-primary"
                                >
                                  Join
                                </a>
                              )}
                            </td>
                            <td className="text-center">
                              {wmBookingVal.status === "initiated" && (
                                <p className="btn btn-warning">Initiated</p>
                              )}
                              {wmBookingVal.status === "completed" && (
                                <p className="btn btn-success">Completed</p>
                              )}
                              {wmBookingVal.status === "expertAssigned" && (
                                <p className="btn btn-primary">Scheduled</p>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="bg-white-ptbrl-2 mb-5 mt-1">
                  <div className="table-responsive b-radius-10">
                    <h3 className="pb-3">Healthcare Consultation</h3>
                    <table className="table table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th className="wd-15p">Expert Name</th>
                          <th className="text-center">Consultation For</th>
                          <th className="text-center">Consultation Date</th>
                          <th className="text-center">Consultation Time</th>
                          <th className="text-center">Action</th>
                          <th className="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hcbookings.map((hcbookingVal, index) => (
                          <tr key={index}>
                            <td className="wd-20p">
                              {hcbookingVal.expertId
                                ? hcbookingVal.expertId[0].firstName
                                : null}
                            </td>
                            <td className="text-center">
                              {hcbookingVal.expertiseId
                                ? hcbookingVal.expertiseId[0].name
                                : null}
                            </td>
                            <td className="text-center">
                              {Moment(hcbookingVal.startDate).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td className="text-center">
                              {" "}
                              {Moment(
                                hcbookingVal.startTime,
                                "HH:mm:ss"
                              ).format("hh:mm A")}
                            </td>
                            <td className="text-center">
                              {hcbookingVal.status === "scheduled" && (
                                <a
                                  href="https://meet.jit.si/{hcbookingVal.meetingLink}"
                                  targte="_blank"
                                  className="btn btn-primary"
                                >
                                  Join
                                </a>
                              )}
                            </td>
                            <td className="text-center">
                              {hcbookingVal.status === "initiated" && (
                                <p className="btn btn-warning">Initiated</p>
                              )}
                              {hcbookingVal.status === "paymentRequested" && (
                                <p className="btn btn-default">
                                  Payment Requested
                                </p>
                              )}
                              {hcbookingVal.status === "scheduled" && (
                                <p className="btn btn-primary">Scheduled</p>
                              )}
                              {hcbookingVal.status === "completed" && (
                                <p className="btn btn-success">Completed</p>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyConsultation;
