import React from "react";
import { Link } from "react-router-dom";
import shop from "./../images/store_6.jpg";

const Shop = () => {
  return (
    <div id="__next">
      <div className="main-banner">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                  <div className="main-banner-content">
                    <h2>Coming Soon!</h2>

                    <p>
                      A wide range of Health and wellness related products like:
                      <br />
                      &bull; Home based Fitness equipment’s
                      <br />
                      &bull; Sports accessories like Sports Shoes, Fitness bands
                      and sports wear <br />
                      &bull; Supplements -- All branded supplements with very
                      attractive discounts
                      <br />
                      &bull; Nutraceuticals - Very attractive range of products
                      <br />
                      &bull; Ayurvedic products – Of all reputed brands
                      <br />
                      &bull; Physiotherapy accessories…etc.
                      <br />
                      In the meantime, if you have any specific enquiry, please
                      write to us on{" "}
                      <span style={{ color: "blue" }}>
                        support@healthonify.com
                      </span>
                    </p>
                    <div className="banner-btn">
                      <Link to="/" className="default-btn-one">
                        Back to Home
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <img src={shop} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
