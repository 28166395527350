import React from "react";
import Carousel from "react-bootstrap/Carousel";
import travel3 from "./../images/travel3.jpg";
import travel1 from "./../images/travel2.jpg";
import travel2 from "./../images/travel1.jpg";

import banner1 from "./../images/travelonify/hotels-management/banner1.png";
import banner2 from "./../images/travelonify/hotels-management/banner2.png";

import property1 from "./../images/property1.jpg";
import property2 from "./../images/property2.jpg";
import property3 from "./../images/property3.jpg";
import property4 from "./../images/property4.jpg";
import property5 from "./../images/property5.jpg";
import property6 from "./../images/property6.jpg";
import client1 from "./../images/travelonify/client/client1.jpeg";
import client2 from "./../images/travelonify/client/client2.jpeg";
import client3 from "./../images/travelonify/client/client3.png";
import client4 from "./../images/travelonify/client/client4.jpeg";
import Hotelnavbar from "./Hotel-navbar";
import Travelfooter from "./Travelfooter";

const Hospitality = () => {
  return (
    <div>
      <Hotelnavbar />
      <div className="space_up banner_carousel">
        <Carousel fade className="">
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 phy_img_carousel3" src={banner1} />
            <Carousel.Caption>
              <div align="center">
                <button
                  type="button"
                  className="btn btn-warning"
                  style={{ color: "white", width: "200px", height: "60px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#myenquiry"
                >
                  Enquire Now
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={2500}>
            <img className="d-block w-100 phy_img_carousel3" src={banner2} />
            <Carousel.Caption>
              <div align="center">
                <button
                  type="button"
                  className="btn btn-warning"
                  style={{ color: "white", width: "200px", height: "60px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#myenquiry"
                >
                  Enquire Now
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>

      <section className="mt-2 mb-5 pt-3 pb-3">
        <div className="container">
          <p className="intro_text">
            Travelonify (Division of Healthonify Private Limited) one of the
            fastest growing Hospitality brand and Digital platform with intense
            market knowledge with deep focus on operational excellence and
            customer delight. Our primary focus is to build a sustainable and
            profitable business module for our partners. Approximately handling
            more than 30 properties.
          </p>
        </div>
      </section>

      <section className="container mt-3 mb-5">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12 mb-3">
            <div className="col-lg-12 col-md-12 col-sm-12 cards2">
              <img src={travel1} alt="" className="cards_img" />
              <h4 className="hos_text1">
                Partnered with 16 Hotel/ Resorts across the country.{" "}
              </h4>
              <p className="hos_text2">
                Our intense market research analysis and expertise combined with
                focused operational skills enables us to grow shoulder to
                shoulder with our clients and partners and drive even more value
                to hotel owners.
              </p>
            </div>
          </div>

          <div className="col-lg-2 col-md-2" />

          <div className="col-lg-5 col-md-5 col-sm-12 mb-3">
            <div className="col-lg-12 col-md-12 col-sm-12 cards2">
              <img src={travel2} alt="" className="cards_img" />
              <h4 className="hos_text1">Hospitality Verticals</h4>
              <p className="hos_text2">
                We have expertise in running Hotels and luxury resorts, full
                restaurant operations, Event management, destination weddings,
                adventure tourism, marketing of room nights through our network,
                etc...
                <p data-bs-toggle="collapse" data-bs-target="#demo">
                  ..
                </p>
                <div id="demo" className="collapse">
                  We partner with our clients on a customized fee model which
                  delivers full convenience and more increased productivity to
                  owners. We take over operations of Resorts / Hotels and will
                  change the way the business is run and create a profitable
                  value preposition to the stakeholders. Pl contact us for more
                  details
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="intro_2 mt-2 mb-2 pt-5 pb-4 "
        style={{ backgroundColor: "#dadada" }}
      >
        <div className="container">
          <p className="intro_text2 ">
            We partner with our clients on a customized fee model which delivers
            full convenience and more increased productivity to owners.
          </p>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-warning"
              style={{ color: "white" }}
              data-bs-toggle="modal"
              data-bs-target="#myenquiry"
            >
              Enquire Now
            </button>
          </div>
        </div>
      </section>

      <section className="container manage_prt  mt-5 mb-5">
        <p className="mng_text_head">OUR MANAGED PROPERTIES</p>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 mt-1 mb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-sh">
              <img src={property1} alt="pro" className="manage_img" />
              <p className="mag-text">Hotel Meera, Udaipur</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-1 mb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-sh">
              <img src={property2} alt="pro" className="manage_img" />
              <p className="mag-text">Hotel Elet Prima, Kumbalgarh</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-1 mb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-sh">
              <img src={property3} alt="pro" className="manage_img" />
              <p className="mag-text">Meer Baugh Resorts, Silvassa</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-1 mb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-sh">
              <img src={property4} alt="pro" className="manage_img" />
              <p className="mag-text">Golden Dunes,Jaisalmer</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-1 mb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-sh">
              <img src={property5} alt="pro" className="manage_img" />
              <p className="mag-text">Hotel Chandra Inn , Bhimtal</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-1 mb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-sh">
              <img src={property6} alt="pro" className="manage_img" />
              <p className="mag-text">Chail Village Retreat,Chail</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <p className="pat_text">OUR PARTNERS</p>
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
              <img src={client1} alt="" className="pat_img" />
            </div>
          </div>

          <div className="col-lg-3 col-md-3">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
              <img src={client2} alt="" className="pat_img" />
            </div>
          </div>

          <div className="col-lg-3 col-md-3">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
              <img src={client3} alt="" className="pat_img" />
            </div>
          </div>

          <div className="col-lg-3 col-md-3">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
              <img src={client4} alt="" className="pat_img" />
            </div>
          </div>
        </div>
      </section>
      <Travelfooter />
    </div>
  );
};

export default Hospitality;
