import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import expertss from "./../images/experts.jpg";
import weightmng from "./../images/team/weight-management.png";
import doctor from "./../images/team/doctor.png";
import physiotherapy from "./../images/team/physiotherapy.png";
import sleep from "./../images/team/sleep.png";
import ayurveda from "./../images/team/ayurveda.png";
import personaltrn from "./../images/team/personal-trainer.png";
import lifestylemng from "./../images/team/lifestyle-management.png";
import stressmng from "./../images/team/stress-management.png";
import Slider from "react-slick";
import expert from "./../images/team/expert.png";
import fitnesstrn from "./../images/team/fitness-trainer.png";
import support from "./../images/team/support.png";
import medicalapp from "./../images/team/medical-app.png";
import ecossytm from "./../images/team/ecosystem.png";
import expertperson from "./../images/team/1.jpg";

import chat from "./../images/team/chat.png";
import call from "./../images/team/call.png";
import videocall from "./../images/team/video-call.png";

import Footer from "./Footer";
import Header from "./navbar";

import { getAllExpert } from "../store/slices/experts";

const Expert = () => {
  const [experts, setExperts] = useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAllExpert())
      .unwrap()
      .then((data) => {
        setExperts(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      <Header />
      <section className="container pt-5 mt-5">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-lg-6 col-sm-12 col-md-6">
            <div className="d-flex justify-content-center mt-5 ">
              <button
                type="button"
                className="btn btn-warning col-white"
                // data-bs-toggle="modal"
                // data-bs-target="#myenquiry"
              >
                Chat Now
              </button>
              &nbsp; &nbsp; &nbsp;
              <button
                type="button"
                className="btn btn-warning col-white"
                data-bs-toggle="modal"
                data-bs-target="#myenquiry"
              >
                Request Book Appointment
              </button>
            </div>
            <div className="col-lg-12 pt-5">
              <p className="col-black bold-t" style={{ fontSize: "19px" }}>
                &bull; Healthonify's Team of experts at your service
                <br />
                &bull; Our Expert Health coach will guide and handhold you
                <br />
                &bull; Book a consultation with the required expert
                <br />
                &bull; Avail the required service and guidance
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12 col-md-6">
            <img src={expertss} alt="" className="" />
          </div>
        </div>
      </section>

      <section className="container pt-5 pb-2">
        <div className="col-lg-12">
          <h3 className="text-center wel-text">Domains Of Experts</h3>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={weightmng}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="col-black pt-3 text-center text-d-mod">
                Weight Management - NUTRITIONISTS
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={doctor}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="col-black pt-3 text-center text-d-mod">
                Health Care / Doctors
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={physiotherapy}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="col-black pt-3 text-center text-d-mod">
                Physiotherapy
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={stressmng}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="col-black pt-3 text-center text-d-mod">
                Mindfullness / Stress Management
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img src={sleep} alt="domain-img" className="domains-img-mod" />
              </div>
              <p className="col-black pt-3 text-center">Sleep</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={ayurveda}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="col-black pt-3 text-center text-d-mod">Ayurvedic</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={personaltrn}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="col-black pt-3 text-center text-d-mod">
                Fitness / Personal Traniner
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={lifestylemng}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="col-black pt-3 text-center text-d-mod">
                Lifestyle Management
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 pb-2">
          <h3 className="text-center wel-text">Why Healthonify</h3>
        </div>
        <div className="row">
          <div className="col-lg-2-5-col col-md-4 col-xs-6 pt-1 pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red-mod">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={expert}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="text-center text-d2-mod col-black pb-2">
                Qulified Expert
              </p>
            </div>
          </div>

          <div className="col-lg-2-5-col col-md-4 col-xs-6 pt-1 pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red-mod">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={ecossytm}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="text-center text-d2-mod col-black pb-2">
                Complete Health Eco-System
              </p>
            </div>
          </div>

          <div className="col-lg-2-5-col col-md-4 col-xs-6 pt-1 pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red-mod">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={fitnesstrn}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="text-center text-d2-mod col-black pb-2">
                Health coach for Cordination
              </p>
            </div>
          </div>

          <div className="col-lg-2-5-col col-md-4 col-xs-6 pt-1 pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red-mod">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={support}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="text-center text-d2-mod col-black pb-2">
                24 / 7 Support
              </p>
            </div>
          </div>

          <div className="col-lg-2-5-col col-md-4 col-xs-6 pt-1 pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 bg-cover-d-red-mod">
              <div className="col-lg-12 d-flex justify-content-center pt-4">
                <img
                  src={medicalapp}
                  alt="domain-img"
                  className="domains-img-mod"
                />
              </div>
              <p className="text-center text-d2-mod col-black pb-2">
                Easy to use App
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container pt-3">
        <div className="col-lg-12 pb-2">
          <h3 className="text-center wel-text">How Can We Consult</h3>
        </div>
        <div className="row pb-5">
          <div className="col-lg-4 col-md-4 pt-1 pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12  hover-shadow-overlay pt-3 pb-3">
              <p className="text-center col-black">Online Chat Sesssion</p>
              <div className="col-lg-12 d-flex justify-content-center pt-2 pb-4">
                <img src={chat} alt="" className="con-img-mod" />
              </div>
              <p className="text-center col-black">
                Chat with an of your choice, This vice is available anytime
                anywhere
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 pt-1 pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 hover-shadow-overlay pt-3 pb-3">
              <p className="text-center col-black">Voice Call</p>
              <div className="col-lg-12 d-flex justify-content-center pt-2 pb-4">
                <img src={call} alt="" className="con-img-mod" />
              </div>
              <p className="text-center col-black">
                Speak to our expert, Get personlized atention right when you
                need.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 pt-1 pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12  hover-shadow-overlay pt-3 pb-3">
              <p className="text-center col-black">Video Call</p>
              <div className="col-lg-12 d-flex justify-content-center pt-2 pb-4">
                <img src={videocall} alt="" className="con-img-mod" />
              </div>
              <p className="text-center col-black">
                Face to Face Video session to get coustomized solution
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-warning col-white"
            style={{ fontSize: "19px" }}
          >
            Request Appointment Now
          </button>
        </div>
      </section>

      <section className="container pt-5">
        <div className="col-lg-12">
          <h3 className="wel-text text-center">Our Expert</h3>
        </div>

        <div className="container">
          <div className="row pb-5">
            <Slider {...settings}>
              {experts.map((expertVal, index) => (
                <div className="slide slick-space">
                  <div className="card_slicks">
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 border-black-radius ">
                        <div className="col-lg-12 d-flex justify-content-center pt-3 pb-2">
                          {expertVal.imageUrl != "" && (
                            <img
                              src={expertVal.imageUrl}
                              className="img-mod-expert"
                              alt=""
                            />
                          )}
                          {expertVal.imageUrl === "" && (
                            <img
                              src={expertperson}
                              className="img-mod-expert"
                              alt=""
                            />
                          )}
                        </div>
                        <div className="push-text">
                          <p className="col-white text-center">
                            {expertVal.firstName} {expertVal.lastName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myenquiry">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Enquire Now</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <form>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                />

                <br />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                />
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                />

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Message"
                  className="form-control"
                />
                <p className="alert-message">{}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick=""
                >
                  Submit
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Expert;
