import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class InternationalPackageService {
   
    static internationalpackage() {
        return axios
          .get( API_PATHS.internationalPackage)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default InternationalPackageService;