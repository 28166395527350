import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Navbar from "./navbar";

const Kneepain = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Knee Pain</h3>
        </div>

        <div className="pt-4">
          <p>
            Physical therapy for knee pain involves a thorough evaluation and
            assessment of your entire lower extremity from your hip to your
            foot. Your PT can assess your knee pain and prescribe the right
            treatments — including exercises and modalities — to help decrease
            your knee pain and improve your overall mobility.
          </p>
          <p>
            <b>Location of Knee Pain Symptoms</b>
            <br />
            The location of your knee pain can help determine which structures
            are at fault and can help ensure proper treatment.1 Remember to
            check with your physician, physical therapist, or healthcare
            provider if symptoms are severe or last more than a few weeks.
            <br />
            <ul>
              <li>
                Pain in the Front of the Knee: If you feel pain in the front of
                the knee, there may be a problem with the tracking and position
                of the kneecap, often called patellofemoral stress syndrome
                (PFSS). The kneecap and the tendon between the kneecap and the
                shin may become inflamed and painful. Pain here usually limits
                the ability to kneel, ascend or descend stairs, or run and jump.
              </li>
              <li>
                Pain on the Inside of the Knee: If you have pain on the inside
                portion of the knee, there is likely an injury to the medial
                meniscus or medial collateral ligament. These structures are
                usually injured during athletic activity when the foot is
                planted on the ground, and the body twists over the knee. The
                medial meniscus is a shock absorber located inside the knee.
                Occasionally, it suffers from wear and tear or arthritis and can
                be damaged with no specific injury.
              </li>
              <li>
                Pain on the Outside of the Knee: Pain on the outside aspect of
                your knee can be the result of injury to many structures. There
                is a ligament there that may be injured during athletic
                activity. Pain here can also be caused by iliotibial band (ITB)
                stress.The ITB is a thick band of tissue that runs from the
                outside of your hip to the front of your knee. As it crosses the
                knee, the ITB can rub abnormally on the knee, and a burning pain
                can ensue. Also, on the outside part of the knee is one of the
                three hamstring tendons. Strain to this tendon may be a source
                of knee pain.
              </li>
              <li>
                Pain in the Back of the Knee: Pain in the back of the knee is
                rare but can occur. One of the hamstring tendons attaches here,
                and pain here is likely due to a hamstring strain. Another
                possible cause of pain here is a Baker's cyst. This is an
                abnormal swelling of the knee joint that occupies space in the
                back of the knee and causes pain with excessive bending of the
                knee.
              </li>
            </ul>
            <br />
            If you develop acute knee pain, immediately follow the R.I.C.E.
            principle. R.I.C.E. stands for Rest, Ice, Compression, and
            Elevation. After a few days of R.I.C.E., you can begin using the
            leg, only gently.
            <br />
            If knee pain persists for more than 2-3 weeks, you should visit a
            doctor, physical therapist, or another healthcare provider to rule
            out any major problem and to ensure proper diagnosis and management.
          </p>
          <p>
            <b>PT Treatment for Knee Pain</b>
            <br />
            After a focused examination has been completed, your physical
            therapist can work with you to initiate the correct treatment. It is
            very important for you to be active and engaged in the program.
            Often, exercises to help strengthen and improve the mobility of the
            knee will be prescribed. You may be required to perform exercises at
            home as well as part of a home exercise program.
            <br />
            Exercise should be your main tool for treating your knee pain.
            Exercises to help your knee pain may include:
            <br />
            <ul>
              <li>Quad sets and straight leg raise </li>
              <li> Short arc quads </li>
              <li>
                Exercises to strengthen your hips (Your hip muscles help control
                the position of your knees. Weakness here may cause knee pain.)
              </li>
              <li>Lower extremity stretches </li>
              <li> Balance exercises</li>
            </ul>
          </p>
          <p>
            Your PT will tell you how often to perform your exercises at home,
            and he or she should monitor your progress when you visit the PT
            clinic. He or she may also perform other treatments while you are in
            the PT clinic. These may include:
            <br />
            <ul>
              <li>Ultrasound </li>
              <li> Electric stimulation </li>
              <li> Kinesiology taping </li>
              <li>Application of heat or ice </li>
              <li> Soft tissue massages or knee joint mobilization</li>
            </ul>
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Kneepain;
