import React from "react";
import Logo from "./logo.png";

import aimg1 from "./../images/blog/password.png";

import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// import { useForm } from "react-hook-form";

const Forget = () => {
  return (
    <div id="__next">
      <div id="navbar" className="navbar-area">
        <div className="neemo-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="logo" to="/">
                <img src={Logo} alt="logo" style={{ width: "150px" }} />
              </Link>
              <button
                className="navbar-toggler navbar-toggler-right collapsed"
                type="button"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="/#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/physiotherapy">
                      Physiotherapy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Travel <ArrowDropDownIcon />
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/domestic-adventure"
                        >
                          Domestic Adventure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/international-adventure"
                        >
                          International Adventure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/domestic-leisure"
                        >
                          Domestic Leisure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/international-leisure"
                        >
                          International Leisure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/travel/wellness">
                          Wellness
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/weight-loss">
                      Weight Loss
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="others-options">
                <button className="btn btn-primary   btn-sm" type="button">
                  Get it know
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <section className="about-section pt-100" style={{ marginTop: "60px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-4">
              <div className="contact-text">
                <div className="about-image">
                  <img src={aimg1} alt="image" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <form className="contact-form">
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="number"
                        name="mobile"
                        placeholder="Enter mobile number"
                        style={{ width: "300px" }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <br />

                  <br />
                  <div className="col-lg-12 col-sm-12 ">
                    <div className="send-btn">
                      <button type="submit" className="send-btn-one">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-section pt-100 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="footer-area">
                <img src={Logo} alt="" style={{ width: "150px" }} />
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-6" align="right">
              <div className="footer-item-area">
                <Link to="#" style={{ color: "white" }}>
                  Career
                </Link>
                &nbsp; &nbsp;
                <Link to="/about" style={{ color: "white" }}>
                  About Us
                </Link>
                &nbsp; &nbsp;
                <Link to="/privacy" style={{ color: "white" }}>
                  Privacy Policy
                </Link>
                &nbsp; &nbsp;
                <Link to="/refund" style={{ color: "white" }}>
                  Refund Policy
                </Link>
                &nbsp; &nbsp;
                <Link to="/terms-and-conditions" style={{ color: "white" }}>
                  Terms & Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center" align="center">
            <div className="col-lg-12">
              <ul className="footer-social" style={{ textAlign: "center" }}>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/healthonify"
                    target="_blank"
                    className="bg-3955bc"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.facebook.com/healthonify/"
                    target="_blank"
                    className="bg-1da1f2"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.twitter.com/healthonify"
                    target="_blank"
                    className="bg-004dff"
                  >
                    <i className="fab fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/healthonify/"
                    target="_blank"
                    className="bg-0273af"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                </li>
              </ul>
              <p>
                © 2020-22 All Rights Reserved | Powered By &nbsp;
                <Link to="https://octaloptimum.com/" target="_blank">
                  Octal Optimum Technology
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
