import React, { Component } from "react";
import mumbai from "../images/trip/Mumbai.jpg";
import Kerla from "../images/trip/Kerla.jpg";
import india from "../images/trip/india.jpg";
import USA from "../images/trip/USA.jpg";
import Japan from "../images/trip/Japan.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        color: "orange",
        zIndex: "5",
        right: "0%",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        color: "orange",
        zIndex: "5",
        left: "0%",
      }}
      onClick={onClick}
    />
  );
}
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      },
    },
  ],
};
function FeaturedHotel() {
  return (
    <div>
      <div className="container">
        <h2 className="mt-5 mb-5" align="center" style={{ color: "black" }}>
          Travelonify dream deals on Hotel Bookings
        </h2>
        <Slider {...settings}>
          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={india} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Trident
                </h4>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Japan} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Lemon Tree
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={USA} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  OYO
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={mumbai} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Country Club
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Kerla} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Taj
                </h4>
              </div>
            </div>
          </Link>
        </Slider>
      </div>
    </div>
  );
}

export default FeaturedHotel;
