import React from "react";
import { Link } from "react-router-dom";
import Header from "./navbar";
import Footer from "./Footer";
import { MdDashboard } from "react-icons/md";
import { BsBookFill, BsFillChatRightFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { BiCubeAlt } from "react-icons/bi";
import { MdNotifications } from "react-icons/md";
import { MdSettings } from "react-icons/md";
import user from "./../images/client-image/3.jpg";

import DiamondIcon from "@mui/icons-material/Diamond";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import RocketIcon from "@mui/icons-material/Rocket";

import EditIcon from "@mui/icons-material/Edit";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LockIcon from "@mui/icons-material/Lock";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";

function Plan() {
  return (
    <div>
      <Header />

      <div className="container-fluid" style={{ paddingLeft: "0px" }}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 bg-light">
            <div className="tab-content">
              <div
                id="monthly"
                className="tabs-item"
                style={{ display: "block" }}
              >
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6">
                    <div className="single-pricing-box bg-f6f5fb">
                      <div className="pricing-header">
                        <h3>Lite</h3>
                      </div>
                      <div className="price">
                        <RocketIcon />
                      </div>
                      <ul className="pricing-features">
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service{" "}
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                      </ul>
                      <div className="price-btn">
                        <a className="price-btn-one" href="/contact/">
                          Select Plan
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-pricing-box bg-ed0678">
                      <div className="pricing-header">
                        <h3>Pro</h3>
                      </div>
                      <div className="price">
                        <DiamondIcon />
                      </div>
                      <ul className="pricing-features">
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service{" "}
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                      </ul>
                      <div className="price-btn">
                        <a className="price-btn-one" href="/contact/">
                          Select Plan
                        </a>
                        <br />
                        <p style={{ color: "white", paddingTop: "20px" }}>
                          7 Day Money-back guarantee Cancel anytime
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-pricing-box bg-edfbf8">
                      <div className="pricing-header">
                        <h3>EnterPrice</h3>
                      </div>
                      <div className="price">
                        <LightbulbIcon />
                      </div>
                      <ul className="pricing-features">
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                        <li className="">
                          <i className="flaticon-check-mark"></i>Service
                        </li>
                      </ul>
                      <div className="price-btn">
                        <a className="price-btn-one" href="/contact/">
                          Select Plan
                        </a>

                        <p style={{ color: "Black", paddingTop: "20px" }}>
                          {" "}
                          7 Day Money-back guarantee Cancel anytime
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Plan;
