import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import packageReducer from "./slices/package";
import frequentlyReducer from "./slices/frequently";
import userReducer from "./slices/user";
import enquiryReducer from "./slices/enquiry";



const reducer = {
    auth: authReducer,
    package: packageReducer,
    frequently: frequentlyReducer,
    user: userReducer,
    enquiry: enquiryReducer,

}

const store = configureStore({
    reducer: reducer,
    devTools: true,
})

export default store;