import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";

import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Navbar from "./navbar";

const Neurodisorders = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Neuro Disorders</h3>
        </div>

        <div className="pt-4">
          <p>
            Neurological physiotherapy treats patients with neurological
            conditions, which are movement and functional disorders caused by
            impairments affecting the brain, spinal cord and the nervous system.
          </p>
          <p>
            <b>CONDITIONS</b>
            <br />
            Some of the conditions include:
            <br />
            <ul>
              <li> Stroke</li>
              <li> Acquired Brain Injury</li>
              <li> Multiple Sclerosis</li>
              <li> Parkinson's Disease</li>
              <li> Spinal Cord Injury</li>
              <li> Balance and Mobility Issues</li>
              <li> Difficulty in Walking</li>
              <li> Ataxia</li>
            </ul>
          </p>
          <p>
            <b>Benefits of Neurological Physiotherapy</b>
            <br />
            Many patients with neurological conditions struggle with a loss of
            range of motion or function, muscle weakness, decreased balance and
            stamina, which may severely affect their daily routines. Targeted to
            improve strength, movement, balance and coordination, neurological
            physiotherapy works by stimulating the nervous system through
            various activities and exercises. Not only does neurological
            physiotherapy help with a patient's mobility related disorders, it
            also helps them gain a better outlook and confidence on their
            conditions by improving their overall quality of life through:
            <br />
            <ul>
              <li> Better balance and coordination</li>
              <li> Improved walking</li>
              <li> Optimized function on affected areas</li>
              <li> Increased stamina and endurance</li>
              <li> Increased strength and less tension in affected muscles</li>
              <li> Reduced contractures</li>
              <li> Optimized independence</li>
            </ul>
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Neurodisorders;
