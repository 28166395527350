import React, { useState } from "react";
import UserNavbar from "./UserNavbar";
import { appointments } from "../store/slices/consult";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
const MyAppointment = () => {
  const [physiobookings, setPhysioBookings] = useState([]);
  const [hcbookings, setHcBookings] = useState([]);
  const [wmbookings, setWmBookings] = useState([]);
  const [physiosession, setPhysiosession] = useState([]);
  const [wmsession, setWmsession] = useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(appointments())
      .unwrap()
      .then((data) => {
        setPhysioBookings(data.user.data.physioConsultationsData);
        setHcBookings(data.user.data.hcConsultationsData);
        setWmBookings(data.user.data.wmConsultationsData);
        setPhysiosession(data.user.data.physioSessionData);
        setWmsession(data.user.data.wmSessionData);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <section className="add-ui">
                  <div className="col-md-12">
                    <h2 className="mb-4">My Appointments</h2>
                  </div>

                  <div className="bg-white-ptbrl-2 mb-5 mt-1">
                    <div className="table-responsive b-radius-10">
                      <h3 className="pb-3">Physiotherapy Consultation</h3>
                      <table className="table table-hover">
                        <thead className="table-dark">
                          <tr>
                            <th className="text-center">Expert Name</th>
                            <th className="text-center">Consultation For</th>
                            <th className="text-center">Consultation Date</th>
                            <th className="text-center">Consultation Time</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {physiobookings.map((PhysiBookingVal, index) => (
                            <tr key={index}>
                              <td className="wd-20p">
                                {PhysiBookingVal.expertId
                                  ? PhysiBookingVal.expertId[0].firstName
                                  : null}{" "}
                                &nbsp;{" "}
                                {PhysiBookingVal.expertId
                                  ? PhysiBookingVal.expertId[0].lastName
                                  : null}
                              </td>
                              <td className="wd-20p">
                                {PhysiBookingVal.expertiseId
                                  ? PhysiBookingVal.expertiseId[0].name
                                  : null}
                              </td>
                              <td className="text-center">
                                {Moment(PhysiBookingVal.startDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td className="text-center">
                                {Moment(
                                  PhysiBookingVal.startTime,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}
                              </td>

                              <td className="text-center">
                                {PhysiBookingVal.status ===
                                  "expertAssigned" && <p>Expert Assigned</p>}
                                {PhysiBookingVal.status === "completed" && (
                                  <p className="btn btn-success">Completed</p>
                                )}
                                {PhysiBookingVal.status === "initiated" && (
                                  <p className="btn btn-warning">Initiated</p>
                                )}
                              </td>
                              <td className="text-center">
                                {PhysiBookingVal.status ===
                                  "expertAssigned" && (
                                  <a
                                    className="btn btn-success"
                                    href="https://meet.jit.si/{PhysiBookingVal.meetingLink}"
                                    targte="_blank"
                                    className="btn btn-primary"
                                  >
                                    Join
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="bg-white-ptbrl-2 mb-5 mt-1">
                    <div className="table-responsive b-radius-10">
                      <h3 className="pb-3">Physiotherapy Session</h3>
                      <table className="table table-hover">
                        <thead className="table-dark">
                          <tr>
                            <th className="text-center">Expert Name</th>
                            <th className="text-center">Session Date</th>
                            <th className="text-center">Session Time</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {physiosession.map((PhysiSessionVal, index) => (
                            <tr key={index}>
                              <td className="wd-20p">
                                {PhysiSessionVal.specialExpertId.firstName}{" "}
                                &nbsp;{" "}
                                {PhysiSessionVal.specialExpertId.lastName}
                              </td>

                              <td className="text-center">
                                {Moment(PhysiSessionVal.startDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td className="text-center">
                                {Moment(
                                  PhysiSessionVal.startTime,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}
                              </td>

                              <td className="text-center">
                                {PhysiSessionVal.status ===
                                  "expertAssigned" && <p>Expert Assigned</p>}
                                {PhysiSessionVal.status === "sessionEnded" && (
                                  <p className="btn btn-success">Completed</p>
                                )}
                                {PhysiSessionVal.status === "initiated" && (
                                  <p className="btn btn-warning">Initiated</p>
                                )}
                              </td>
                              <td className="text-center">
                                {PhysiSessionVal.status ===
                                  "expertAssigned" && (
                                  <a
                                    className="btn btn-success"
                                    href="https://meet.jit.si/{PhysiBookingVal.meetingLink}"
                                    targte="_blank"
                                    className="btn btn-primary"
                                  >
                                    Join
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAppointment;
