import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Navbar from "./navbar";

const Sportsinjury = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Sports Injury</h3>
        </div>

        <div className="pt-4">
          <p>
            Sports injury rehabilitation or ‘rehab’ is a structured programme
            designed by physiotherapists for people who have sustained a
            sporting injury. Depending on the type of injury you have sustained,
            your programme will include a number of parts which may include
            strengthening exercises, mobilising, pain relief techniques and
            functional skills.{" "}
          </p>
          <p>
            <b>Benefits of sports injury rehab.</b>
            <br />
            Our specialist musculoskeletal physiotherapists will work with you
            to provide a thorough rehabilitation programme which will encourage
            a number of benefits including:
            <ul>
              <li>Restoring full function as soon as possible</li>
              <li> A faster recovery and return to sport</li>
              <li> Strengthening weakened muscle groups</li>
              <li> Reducing any pain and inflammation from your injury</li>
              <li>
                {" "}
                Maintaining cardiovascular fitness whilst you are out of sport
              </li>
              <li> Minimising the risk of future injuries</li>
              <li> Improving your flexibility and coordination</li>
              <li> Advice on correct footwear and equipment</li>
            </ul>
          </p>
          <p>
            <b>CONDITIONS FOR CURE</b>
          </p>
          <p>
            <b>Knee Injuries</b>
            <br />
            When participating in sports the knee joint is critical to keep in
            good condition to avoid knee injuries. However, an awkward, subtle
            movement can easily result in a knee injury. Our sports
            physiotherapist will diagnose knee pain and tailor an individual
            rehabilitation treatment plan with strengthening exercises that
            targeted specific muscles while increasing knee strength and
            flexibility.
          </p>
          <p>
            <b>Stress Fractures</b>
            <br />
            If you have a history of repetitive stress on your bones, such as
            running or jumping in sports then it may be time to consider seeing
            one of our sports physios. They can help identify and educate any
            imbalances that lead an individual toward developing these types of
            stress fractures to prevent future complications by strengthening
            muscles needed during certain sports activities while also focusing
            attention where it’s most needed – identifying weaknesses before
            anything else happens.
            <br />
            Sports Physiotherapy may involve the following:
            <ul>
              <li>
                {" "}
                Decreasing pain and inflammation via hot and cold therapy
              </li>
              <li> Assessing the range of motion of the affected limb</li>
              <li> Assessing muscle strength</li>
              <li>
                {" "}
                Protection of area involved (immobilisation, bracing, or using
                crutches)
              </li>
              <li>
                {" "}
                Creating a rehabilitation plan with the patient based on the
                findings above
              </li>
              <li>
                {" "}
                Educating the patient on what to do, when, how, and why during
                the process. (This includes education about movement patterns
                involved in a specific sports injury)
              </li>
              <li>
                {" "}
                Sports injury rehabilitation may take a few days or up to a few
                weeks depending on the type and severity of the injury.
              </li>
            </ul>
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Sportsinjury;
