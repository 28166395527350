import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import ReminderService from './../../services/reminder.service';

export const getmealreminder = createAsyncThunk(
    "reminder/get/reminder",
    async(thunkAPI) => {
        try {
            const data = await ReminderService.getmeal();
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const getwaterreminder = createAsyncThunk(
    "reminder/get/reminder",
    async(thunkAPI) => {
        try {
            const data = await ReminderService.getwater();
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const getwalkreminder = createAsyncThunk(
    "reminder/get/reminder",
    async(thunkAPI) => {
        try {
            const data = await ReminderService.getwalk();
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const getsleepreminder = createAsyncThunk(
    "reminder/get/reminder",
    async(thunkAPI) => {
        try {
            const data = await ReminderService.getsleep();
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const postmealreminder = createAsyncThunk(
    "reminder/post/addreminder",
    async(item,thunkAPI) => {
        try {
            const data = await ReminderService.postmeal(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const postwaterreminder = createAsyncThunk(
    "reminder/post/addreminder",
    async(item,thunkAPI) => {
        try {
            const data = await ReminderService.postwater(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const postwalkreminder = createAsyncThunk(
    "reminder/post/addreminder",
    async(item,thunkAPI) => {
        try {
            const data = await ReminderService.postwalk(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const postsleepreminder = createAsyncThunk(
    "reminder/post/addreminder",
    async(item,thunkAPI) => {
        try {
            const data = await ReminderService.postsleep(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);




// export const logout = createAsyncThunk("auth/logout", async () => {
//   EnquiryService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const reminderSlice = createSlice({
    name: "reminder",
    initialState,
    extraReducers: {
        [getmealreminder.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getmealreminder.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getmealreminder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        [getwaterreminder.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getwaterreminder.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getwaterreminder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        
        [getwalkreminder.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getwalkreminder.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getwalkreminder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        
        [getsleepreminder.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getsleepreminder.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getsleepreminder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },
       

        [postmealreminder.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postmealreminder.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postmealreminder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        [postwaterreminder.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postwaterreminder.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postwaterreminder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        
        [postwalkreminder.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postwalkreminder.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postwalkreminder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        
        [postsleepreminder.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postsleepreminder.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postsleepreminder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },
       

        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = reminderSlice;
export default reducer;