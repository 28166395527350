import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";

import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Navbar from "./navbar";

const Geriatric = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };
  return (
    <div id="__next">
      <Navbar />
      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Geriatric Physiotherapy</h3>
        </div>

        <div className="pt-4">
          <h3 className="col-head-color-p">Introduction</h3>
          <p>
            Geriatric is a specialty that focuses on health care of elderly
            people. It aims to promote health by preventing and treating
            diseases and disabilities in older adults. According to World Health
            Organisation (WHO) in developed countries the person having
            chronological age above 65 falls under the old age category. For
            India the age group above 60 is considered as old age. According to
            2016 Central Statistics Office Ministry of Statistics and Programme
            Implementation Government of India, 73.3 million population falls
            under old age, out of which life expectancy is only17.9 million and
            death rate is as high as 18.4 million.
          </p>
          <br />
          <p>
            <b>WHO divides old age in 3 major categories as below:</b>
            <br />
            <table border="2">
              <tr>
                <th>Age Group</th>
                <th>Categories</th>
              </tr>
              <tr>
                <td>60-69 Years</td>
                <td>Young Old</td>
              </tr>
              <tr>
                <td>70-79 Years</td>
                <td>Old Old</td>
              </tr>
              <tr>
                <td>80 and Above</td>
                <td>Very Old</td>
              </tr>
            </table>
          </p>
          <p>
            <b>
              Aging is very board term which is falsely interpreted many a
              times, WHO divides aging into two types:
            </b>
            <br />
            Normal AgingHealthy AgingPhysiotherapy promotes healthy aging so as
            to have a proper mental, physical and spiritual development of an
            individual.
          </p>
          <p>
            <b>
              A role of physiotherapist in geriatric treatment is as follows:
            </b>
            <br />
            To address functional limitations of the patientTo encourage and
            maintain a healthy lifestyleTo provide safe environmentReferral to
            other necessary specialists.
            <br />
            To educate the elderly population about the various services and
            support that are available for them.
            <br />
            Conducting assessments at home: The assessor can observe the living
            and family situation & Address potential barriers
          </p>
          <p>
            <b>Process Of Rehabilitation:</b>
            <br />
            <b>Importance of geriatric assessment:</b>
            <br />
            Goal: Promote wellness and independenceFocus: Improve Function and
            performance in day to day activities Approach:
            MultidisciplinaryEfficiency: Ability to perform rapid screening to
            identify target areas Success: Maintaining or improving quality of
            life.
          </p>
          <p>
            <b>Goal Oriented Treatment (Goal Setting):</b>
            <br />
            Every treatment is tailored made depending upon the patient’s
            condition, past medical and surgical history. The ultimate goal of
            physiotherapy is to improve the quality of life of a patient by
            making them functionally independent thus resulting in improve
            participation in their social life.
            <br />
            Relieve Pain- According to the progression of age there is a lot of
            wear and tear in the joints resulting in pain and pain is the most
            common reason for barrier in performing day to day activities in
            this.
            <br />
            Improve Range of Motion for joints for example; complete shoulder
            range of motion can benefit the patient in getting dress, taking a
            bath, getting some clothes out of the cupboard.
            <br />
            To improve or maintain the ambulatory status of the patient so there
            won’t be any barriers in performing toileting activities or engaging
            in social.
            <br />
            To improve or maintain strength and endurance of muscles for example
            a patient should have sufficient strength to pick up a bottle of
            Prevention of falls- Many factors lead to fall they might be
            internal reasons (related to body impairments) or external factors
            (related to environment).
            <br />
            Improve or maintain cardiovascular endurance of the patient so the
            basic activity like walking is not
          </p>
          <p>
            <b>Therapeutic Intervention</b>
            <br />
            <ul>
              <li>Ergonomic Advise</li>
              <li>Mobilisation Exercises</li>
              <li>Stretching Exercises</li>
              <li>Strengthening Exercises</li>
              <li>Posture Correction Exercises</li>
              <li>Gait Training</li>
              <li>Balance Training</li>
              <li>Aerobic/ Cardiovascular Training</li>
              <li>Electro-modalities</li>
              <li>Myofasical Release Orthotics </li>
            </ul>
            After giving the set therapeutic interventions the therapist will
            re-assess the patient and note the changes.
          </p>
          <p>
            <b>Common Conditions:</b>
            <br />
            Musculoskeletal Conditions, Arthritis, Joint Replacement, Avascular
            Necrosis, Muscle Weakness, Muscle Wasting/Deconditioning, Spinal
            Deformities like Scolosis/Kyphosis, Walking Difficulty, Prolapse
            Disc and its complications
            <br /> Neurological Conditions, Cardiac and Pulmonary Conditions.
          </p>
          <p>
            <b>Endocrine Conditions</b>
            <br />
            StrokeVertigo, Cognitive Impairments, Dementia, Balance and
            Cordination, Parkinsons Disease, Pre and Post Cardiac Surgeries for
            example- CABG, Valve Repair, Angioplasties etc, Pre And Post Any
            Thoracic Surgeries for example -Tissue Removal for Breast Cancer,
            Lung, Diabetes, Thyroid Problems, Menopausal And Andropausal Issues
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Geriatric;
