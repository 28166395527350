import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Footer from "./Footer";
import Navbar from "./navbar";

const Nurological = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />
      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="col-head-color-p">Neurological Physiotherapy</h3>
        </div>

        <div className="pt-4">
          <h3 className="col-head-color-p">Introduction</h3>
          <p>
            Neurological physiotherapy involves rehabilitation of people with
            neurological conditions.
            <br />
            Neurological conditions are problems with the brain, spinal cord or
            peripheral nerves. People with neurological conditions may have
            difficulties with their physical functions including mobility,
            muscle strength, range of movement and balance.
            <br />
            Neurological physiotherapy is a type of rehabilitation offering
            examination and treatment of the physical problems that are caused
            by an individual’s neurological condition.
            <br />
            We treat patients with many different neurological conditions
            including Stroke, Multiple Sclerosis and Parkinson’s.
            <br />
            Neurological Physiotherapy aims to increase the quality of life of
            people with neurological conditions through physical means. Every
            person with a neurological condition presents differently. Treatment
            will be focused on your condition and symptoms.
          </p>
        </div>

        <div className="pt-4">
          <p>
            <b>Neurological physiotherapy treatment aims to:</b>
            <br />
            RehabilitateSlow down the physical deteriorationMaximize
            potentialHelp children with disabilities achieve developmental
            milestones
          </p>
        </div>

        <div className="pt-4">
          <p>
            <b>PRINCIPLES OF NEUROLOGICAL PHYSIOTHERAPY</b>
            <br />
            Neurological physiotherapy is based around numerous principles of
            rehabilitation.
          </p>

          <p>
            <b>
              Principles of neurological physiotherapy base their treatments on
              include the following:
            </b>
            <br />
            The brain is dynamic and capable of adapting to change following
            injury to the brain or spinal cord.
            <br />
            Employing a problem solving and eclectic approach, adapting
            treatment to patient’s symptoms/extent of injury.
            <br />
            Encouraging whatever movement is possible and building on it,
            through; strengthening, sensory stimulation, positive reinforcement,
            and verbal feedback.
            <br />
            Encouragement of normal movement and functionCorrecting and varying
            positioning and posture in lying, sitting and standingPromotion of
            functional activities based around daily tasks.
            <br />
            Use of verbal and visual prompts to encourage the patient to think
            about task and learn from treatment.
            <br />
            Prevention of muscle shortening as this leads to spasticity and
            abnormal movement.Using a team approach to the rehabilitation of
            patients.
            <br />
            Implementation of realistic goals that are measured and
            time-framed.Actively engaging the patient in treatment sessions.
            <br />
            Achieving as great a degree of independence and quality of life
            possible
          </p>
        </div>

        <div className="pt-4">
          <p>
            <b>THE ROLE OF A NEUROLOGICAL PHYSIOTHERAPIST</b>
            <br />
            Neurological physiotherapists are experienced and trained to treat
            neurological conditions with the aim to provide interventions which
            assist an individual to regain or maintain their maximum movement
            and functional independence. This is achieved by aiding in the
            development of new pathways through repetition and exercise.
          </p>
        </div>

        <div className="pt-4">
          <p>
            <b>NEUROLOGICAL ASSESSMENT</b>
            <br />
            Treatment can only be as good as the assessment on which it was
            based. So in order for us to progress and manage our treatment plan
            and ensure we are identifying out patients problems assessment
            should be an ongoing and continuous, and should allow us to
            individualized, patient-centered goals and ultimately a tailor-made
            treatment plan for the individual.
          </p>
          <p>
            <b>TREATMENT INTERVENTIONS</b>
            <br />A number of treatment approaches are used, often incorporating
            a selection of the following as appropriate
            StretchingStrengtheningBalance re-educationGait re-educationJoint
            mobilizationElectrical stimulationPostural exerciseSpasticity
            managementAdvice/Education on lifestyle, fatigue management, and
            exercise
          </p>
          <p>
            <b>BENEFITS OF NEUROLOGICAL PHYSIOTHERAPY</b>
            <br />
            Neurological physiotherapy should commence as soon as possible
            following injury for the best possible recovery.
            <br />
            We use a combination of different neurological physiotherapy
            principles to provide the most beneficial treatment for our
            patients.
            <br />
            These include the Bobath Concept (normal movement), the Carr and
            Shepherd Concept (motor relearning), the Brunnstrom Approach
            (synergistic movement) Conductive Education and functional
            rehabilitation. The treatment approach used varies depending on the
            individual, their symptoms and their goals. This allows us to
            maximize the potential of our patients.
            <br />
            Following injury muscle strength and movement may be affected.
            Muscles may become weak or tight with spasms. There may also be
            changes to sensation and difficulties with speech and swallowing.
          </p>
          <p>
            <b>Physiotherapy treatment will help:</b>
            <br />
            Make movements easier to achieve that are precise and goal
            directedRetrain normal patterns of movementImprove ability with
            everyday activitiesIncrease muscle strengthIncrease range of
            movementImprove gross or fine motor skillsImprove postureIncrease
            balanceLengthen tight muscles to help decrease spasticity and reduce
            contracturesIncrease fitness levels and enduranceHelp problems with
            breathingReduce risk of chest infectionsReduce risk of fallingReduce
            stress and anxietyRelieve painIncrease independenceAchieve maximum
            potential.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Nurological;
