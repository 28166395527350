import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getRoomGroupService from "../../services/roomgroup.service";
import AuthService from "../../services/auth.service";



export const roomGroup = createAsyncThunk(
    "roomgroup/get/roomGroup",
    async(thunkAPI) => {
        try {
            const data = await getRoomGroupService.roomgroup();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);




// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const roomgroupSlice = createSlice({
    name: "roomGroup",
    initialState,
    extraReducers: {
      
        [roomGroup.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [roomGroup.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [roomGroup.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
       
  
        
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = roomgroupSlice;
export default reducer;