import React, { useState } from "react";

import Footer from "./Footer";

import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/blog/hb1.jpg";
import banner2 from "./../images/blog/hb2.jpg";
import { Link } from "react-router-dom";

import Header from "./navbar";

const Yoga = () => {
  //enquiry form
  return (
    <div id="__next">
      <Header />

      <section>
        <div className="carousel_cover ">
          <Carousel className="">
            <Carousel.Item interval={1500}>
              <img className="d-block w-100 phy_img_carousel " src={banner1} />
              <Carousel.Caption>
                <h1 className="text1" style={{ color: "black" }}>
                  Health is Life
                </h1>
                <p style={{ color: "black" }}>Live life to the fullest</p>
                <p style={{ color: "black" }}>
                  Quantifiable reslts| We are away by your side.
                </p>
                <button
                  type="button"
                  className="btn btn-warning"
                  style={{
                    color: "box",
                    lineHeight: "2 !important",
                    width: "200px",
                    height: "50px",
                  }}
                >
                  Join Now
                </button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel"
                src={banner2}
                alt=""
              />
              <Carousel.Caption>
                <h1 className="text1" style={{ color: "black" }}>
                  Health is Life
                </h1>
                <p style={{ color: "black" }}>Live life to the fullest</p>
                <p style={{ color: "black" }}>
                  Quantifiable reslts| We are away by your side.
                </p>
                <button
                  type="button"
                  className="btn btn-warning"
                  style={{
                    color: "box",
                    lineHeight: "2 !important",
                    width: "200px",
                    height: "50px",
                  }}
                >
                  Join Now
                </button>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={2500}>
              <img className="d-block w-100 phy_img_carousel " src={banner1} />
              <Carousel.Caption>
                <h1 className="text1" style={{ color: "black" }}>
                  Health is Life
                </h1>
                <p style={{ color: "black" }}>Live life to the fullest</p>
                <p style={{ color: "black" }}>
                  Quantifiable reslts| We are away by your side.
                </p>
                <button
                  type="button"
                  className="btn btn-warning"
                  style={{
                    color: "box",
                    lineHeight: "2 !important",
                    width: "200px",
                    height: "50px",
                  }}
                >
                  Join Now
                </button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Yoga</h3>
        </div>

        <div className="pt-4">
          <p className="col-white"></p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Yoga;
