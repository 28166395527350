import React, { useState } from "react";
import Footer from "./Footer";
import { getPrescription } from "../store/slices/consult";
import { useDispatch, useSelector } from "react-redux";
import UserNavbar from "./UserNavbar";
import Moment from "moment";
import { Link } from "react-router-dom";

const MyPrescription = () => {
  const [prescriptions, setPrescriptions] = useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getPrescription())
      .unwrap()
      .then((data) => {
        setPrescriptions(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <div className="col-md-12">
                  <h3 className="mb-2">My Prescription</h3>
                </div>
                <div className="bg-white-ptbrl-2 mb-5 mt-1">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th className="wd-15p">Date of Consultation</th>
                          <th className="wd-15p">Prescription Generated At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prescriptions.map((prescriptionsVal, index) => (
                          <tr key={index}>
                            <td>
                              {" "}
                              {Moment(prescriptionsVal.created_at).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td>{prescriptionsVal.time}</td>
                            <td>
                              <a href={prescriptionsVal.prescriptionUrl}>
                                Download
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyPrescription;
