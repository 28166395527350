import React, { useState, useEffect, useRef } from "react";
import TravelHeader from "./travel-navbar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import { Link, useNavigate } from "react-router-dom";
import mumbai from "../images/trip/Mumbai.jpg";
import Kerla from "../images/trip/Kerla.jpg";
import india from "../images/trip/india.jpg";
import USA from "../images/trip/USA.jpg";
import Japan from "../images/trip/Japan.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";

import travel1 from "./../images/travelonify/hotels/banner1.png";
import travel2 from "./../images/travelonify/hotels/banner2.jpg";
import travel3 from "./../images/travelonify/hotels/banner3.jpg";

import { GethotelSearch } from "../store/slices/hotel";
import { useDispatch, useSelector } from "react-redux";
import Sticky from "react-stickynode";
import Travelfooter from "./Travelfooter";

function Hotels() {
  // Increase and Decrease Numbers Hooks Start
  const [count_A, setCount_A] = useState(1);
  const [count_C, setCount_C] = useState(0);
  const [count_R, setCount_R] = useState(1);
  const [hotelsearch, setHotelsearch] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  React.useEffect(() => {
    dispatch(GethotelSearch())
      .unwrap()
      .then((data) => {
        setHotelsearch(data.user.data);
      })
      .catch(({ message }) => {
        //alert(message);
      });
  }, [dispatch]);

  const IncNum_A = () => {
    setCount_A(count_A + 1);
  };

  const IncNum_C = () => {
    setCount_C(count_C + 1);
    // setCount_C(document.getElementById("hidden").classList.toggle("showhere")+1);
  };

  const IncNum_R = () => {
    setCount_R(count_R + 1);
  };
  const DecNum_A = () => {
    setCount_A((count_A) => Math.max(count_A - 1, 1));
  };

  const DecNum_C = () => {
    setCount_C((count_C) => Math.max(count_C - 1, 0));
  };

  const DecNum_R = () => {
    setCount_R((count_R) => Math.max(count_R - 1, 1));
  };
  // Increase and Decrease Numbers Hooks End
  const [query, setQuery] = useState("");
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 10;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const myDatas = {
    destination: query,
  };
  async function searchHotels(e) {
    e.preventDefault();
    navigate("/travelonify/HotelList/", { state: myDatas });
  }

  return (
    <div id="_next">
      <TravelHeader />

      <section>
        <br />
        <div className="carousel_cover-sticky" style={{ position: "relative" }}>
          <Carousel fade style={{ zIndex: "-10" }}>
            <Carousel.Item interval={2500} style={{ zIndex: "-20 !important" }}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel1}
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item interval={2500} style={{ zIndex: "-20 !important" }}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel2}
                alt=""
              />
            </Carousel.Item>

            <Carousel.Item interval={2500} style={{ zIndex: "-20 !important" }}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel3}
                alt=""
              />
            </Carousel.Item>
          </Carousel>

          <Sticky enabled={true} top={268} bottomBoundary={3000}>
            <div
              className="col-lg-12 col-md-12 fixed-content pb-2"
              style={{
                transition: "1s ease",
                color: "black",
                backgroundColor: navBackground ? "#001E79" : "transparent",
              }}
            >
              <form className="container" onSubmit={searchHotels}>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-2 col-sm-12 col-md-3">
                    <p className="col-white">Destination</p>
                    <input
                      type="text"
                      placeholder="Destination"
                      className="hotel_form"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      style={{ height: "47px" }}
                      required
                    />
                  </div>
                  <div
                    className="col-lg-3 col-md-5 col-sm-12"
                    style={{ height: "50px" }}
                  >
                    <p className="col-white">No . of Person</p>
                    <Dropdown className="">
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        className="drop_downs sub_names"
                      >
                        {count_A} Adults , {count_C} Children , {count_R} Rooms
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="droper">
                        <Dropdown className="sub_dropers">
                          <span className="sub_names">Adults</span>{" "}
                          <span className="math">
                            <span className="clickmath" onClick={DecNum_A}>
                              <AiOutlineMinusCircle />
                            </span>
                            <span className="count"> {count_A}</span>
                            <span className="clickmath" onClick={IncNum_A}>
                              <AiOutlinePlusCircle />
                            </span>
                          </span>
                        </Dropdown>
                        <Dropdown className="sub_dropers">
                          <span className="sub_names">Children</span>{" "}
                          <span className="math">
                            <span className="clickmath" onClick={DecNum_C}>
                              <AiOutlineMinusCircle />
                            </span>
                            <span className="count"> {count_C}</span>
                            <span className="clickmath" onClick={IncNum_C}>
                              <AiOutlinePlusCircle />
                            </span>
                          </span>
                        </Dropdown>
                        <div className="row child_count" id="hidden">
                          <div className="col-lg-6 col-md-6 col-sm-12">age</div>
                        </div>
                        <Dropdown className="sub_dropers">
                          <span className="sub_names">Rooms</span>{" "}
                          <span className="math">
                            <span className="clickmath" onClick={DecNum_R}>
                              <AiOutlineMinusCircle />
                            </span>
                            <span className="count"> {count_R}</span>
                            <span className="clickmath" onClick={IncNum_R}>
                              <AiOutlinePlusCircle />
                            </span>
                          </span>
                        </Dropdown>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <p className="col-white">From</p>
                    <input
                      type="date"
                      className="hotel_form"
                      style={{ height: "47px" }}
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <p className="col-white">To</p>
                    <input
                      type="date"
                      className="hotel_form"
                      style={{ height: "47px" }}
                    />
                  </div>

                  <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-center">
                    <button
                      className="btn btn-warning btn_hotel btn_hotel-mod"
                      style={{ lineHeight: "2" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Sticky>
        </div>
      </section>

      <br />

      <div className="container">
        <h2 className="mb-5" align="center">
          Top Destination in India
        </h2>
        <Slider {...settings}>
          <Link to="/travelonify/SearchHotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={india} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Mumbai</h4>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Japan} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Hyderabad</h4>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={USA} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Bangalore</h4>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={mumbai} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Chennai</h4>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Kerla} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Cochi</h4>
                </div>
              </div>
            </div>
          </Link>
        </Slider>
      </div>

      <br />

      <div className="container">
        <h2 className="mt-5 mb-5" align="center">
          Top Destination International
        </h2>
        <Slider {...settings}>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={india} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Dubai</h4>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Japan} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">China</h4>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={USA} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Japan</h4>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={mumbai} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Japan</h4>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/hotel">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Kerla} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Dubai</h4>
                </div>
              </div>
            </div>
          </Link>
        </Slider>
      </div>

      <div className="container">
        <h2 className="mt-5 mb-5" align="center">
          Travelonify dream deals on Hotel Bookings
        </h2>
        <Slider {...settings}>
          <div className="slide slick-space">
            <div className="card_slicks">
              <img src={india} alt="Card image" className="slick-img" />
              <div className="cardsss">
                <h4 className="card-title">Hotel</h4>
              </div>
            </div>
          </div>

          <div className="slide slick-space">
            <div className="card_slicks">
              <img src={Japan} alt="Card image" className="slick-img" />
              <div className="cardsss">
                <h4 className="card-title">Hotel</h4>
              </div>
            </div>
          </div>

          <div className="slide slick-space">
            <div className="card_slicks">
              <img src={USA} alt="Card image" className="slick-img" />
              <div className="cardsss">
                <h4 className="card-title">Hotel</h4>
              </div>
            </div>
          </div>

          <div className="slide slick-space">
            <div className="card_slicks">
              <img src={mumbai} alt="Card image" className="slick-img" />
              <div className="cardsss">
                <h4 className="card-title">Hotel</h4>
              </div>
            </div>
          </div>
          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Kerla} alt="Card image" className="slick-img" />
                <div className="cardsss">
                  <h4 className="card-title">Hotel</h4>
                </div>
              </div>
            </div>
          </Link>
        </Slider>
      </div>

      <br />
      <br />

      <Travelfooter />
    </div>
  );
}

export default Hotels;
