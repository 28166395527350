import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./component/home";
import Physiotherapy from "./component/Physiotherapy";
import Travel from "./component/travel";
import Weight from "./component/weight";
import International from "./component/international";
import Domestic from "./component/domestic";
import About from "./component/about";
import Aboutus from "./component/About-us";
import Contact from "./component/contact";
import Signin from "./component/signin";
import TravelSignin from "./component/TravelSignin";
import Types from "./component/Types";
import Thanks from "./component/Thanks";
// import Order from "./component/Order";
import EditProfile from "./component/EditProfile";
import Appointment from "./component/Appointment";
import Traveldetails from "./component/Traveldetails";
import Hospitality from "./component/Hospitality";
import Privacy from "./component/Privacy";
import Terms from "./component/Terms";
import Refund from "./component/Refund";
import Notfoundpage from "./component/Not-foundpage";
import Hotelnavbar from "./component/Hotel-navbar";

import Register from "./component/Register";
import TravelRegister from "./component/TravelRegister";
import Forgot from "./component/Forgot";
import Otp from "./component/Otp";
import TravelHeader from "./component/travel-navbar";
import HotelList from "./component/HotelList";
import Hotels from "./component/Hotels";
import HotelDetails from "./component/HotelDetails";

import ProtectedRoutes from "./component/ProtectedRoutes";
import PublicRoutes from "./component/PublicRoutes";

import TravelCity from "./component/TravelCity";
import ImageUpload from "./component/ImageUpload";

import Blog from "./component/Blog";
// import Offer from "./component/offer";
import FeaturedHotel from "./component/featuredhotel";
import HotelBooking from "./component/HotelBooking";
import Booking from "./component/Booking";

import Nurological from "./component/nurological-physio";
import Cardiothoracic from "./component/cardiothoracic-physio";
import Workplace from "./component/workplace-physio";
import Sports from "./component/sports-physio";
import Paediatrics from "./component/paediatrics-physio";
import Geriatric from "./component/geriatric-physio";
import Therapeutic from "./component/therapeutic-physio";
import Rehab from "./component/rehab";
import Orthopedic from "./component/Orthopedic-physiotherapy";
import Women from "./component/Women-physio";
import Backpain from "./component/Back-pain";
import Kneepain from "./component/Knee-pain";
import Neckpain from "./component/Neck-pain";
import Arthitis from "./component/Arthitis";
import Neurodisorders from "./component/Neuro-Disorders";
import Postsurgery from "./component/Post-surgery";
import Slippeddisc from "./component/Slipped-disc";
import Shoulderpain from "./component/Shoulder-pain";
import Sportsinjury from "./component/Sports-injury";
import Healthmeter from "./component/Health-meter";
import Tool from "./component/Tool";
import Experts from "./component/Experts";
import Fitnesscenter from "./component/Fitness-center";
import Sleepmanagement from "./component/Sleep-management";
import Fitness from "./component/Fitness";
import Rightdiet from "./component/Right-diet";
import Stressmanagement from "./component/Stress-management";

import Adventure from "./component/Adventure";
import Expertconsultation from "./component/Expert-consultation";
import Healthcare from "./component/Health-care";
import Expert from "./component/Expert";
import Privacypolicy from "./component/Privacy-policy";
import Cancellationpolicy from "./component/Cancellation-policy";
import Termscondition from "./component/Terms-condition";
import ScrollToTop from "./component/scrollToTop";
import Yoga from "./component/Yoga";
import Happysleep from "./component/Happy-sleep";

import Corporate from "./component/Corporate";
import SearchByDestination from "./component/Searchbydestination";
import SearchByTheme from "./component/Searchbytheme";
import Traveldetails2 from "./component/Traveldetails2";
import AddHealthRecord from "./component/Add-HealthRecord";
import HealthRecords from "./component/Health-records";
import AddInsurnace from "./component/Add-insuranceLocker";
import HotelSearch from "./component/HotelSearch";
import MyAppointment from './component/MyAppointment';
import MyConsultation from './component/MyConsultation';
import Plan from "./component/Plan";
import MyPlan from './component/MyPlan';
import MyTravel from './component/MyTravel';
import MyTools from './component/MyTools';
import MyExpert from './component/MyExpert';
import MyShop from './component/MyShop';
import Shop from "./component/Shop";
import MyPrescription from './component/MyPrescription';
import Analysis from './component/Analysis';
import MySubscription from './component/MySubscription';
import MyGoals from './component/MyGoals';
import MyReminders from './component/MyReminders';
import Mycommunity from './component/Mycommunity';
import MySettings from './component/MySettings';
import MyProfile from './component/Myprofile';
import MyCourse from './component/MyCourse';
import MyFitness from './component/MyFitness';
import UserNavbar from './component/UserNavbar';

import Personaltraining from './component/Personal-training';
import Gymfranchise from './component/Gym-franchise';
import HealthCare from './component/HealthCare';
import Medicine from './component/Medicine';
import HealthCareAyurveda from './component/HealthCare-Ayurveda';
import LiveWell from './component/LiveWell';
import AddCommunity from './component/AddCommunity';
import EditCommunity from './component/EditCommunity';

import AddMeal from './component/AddMeal';
import AddWater from './component/AddWater';
import AddWalk from './component/AddWalk';
import AddSleep from './component/AddSleep';

import Questionnaire from './component/Questionnaire';
import CorporateSignin from './component/CorporateSignin';
import FitnessForm from './component/FitnessForm';
import MedicalHistory from './component/MedicalHistory';
import LifeStyle from './component/LifeStyle';
import Tools from './component/Tools';
import MyPayment from "./component/MyPayments";
import FitnessCenterDetails from "./component/FitnessCenterDetails";
import HRA from "./component/hra";


function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/UserNavbar" element={<UserNavbar />} />
      <Route exact path="/weight-loss" element={<Weight />} />
      <Route exact path="/Tools" element={<Tools />} />
      <Route exact path="/Mycommunity/AddCommunity" element={<AddCommunity />} />
      <Route exact path="/Mycommunity/EditCommunity/:id" element={<EditCommunity />} />
      <Route exact path="/fitness" element={<Fitness />} />
      <Route exact path="/Questionnaire" element={<Questionnaire />} />
      <Route exact path="/FitnessForm" element={<FitnessForm />} />
      <Route exact path="/MedicalHistory" element={<MedicalHistory />} />
      <Route exact path="/LifeStyle" element={<LifeStyle />} />

      <Route exact path="/MyReminders/MealReminder/AddMeal" element={<AddMeal />} />
      <Route exact path="/MyReminders/WaterReminder/AddWater" element={<AddWater />} />
      <Route exact path="/MyReminders/WalkReminder/AddWalk" element={<AddWalk />} />
      <Route exact path="/MyReminders/SleepReminder/AddSleep" element={<AddSleep />} />

      <Route exact path="/Gym-franchise" element={<Gymfranchise />} />
      <Route exact path="/HRA" element={<HRA />} />
      <Route exact path="/corporate" element={<Corporate />} />
      <Route exact path="/CorporateSignin" element={<CorporateSignin />} />
      <Route exact path="/physiotherapy" element={<Physiotherapy />} />
      <Route exact path="/aboutus" element={<Aboutus />} />
      <Route exact path="/signin" element={<Signin />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/MyAppointment" element={<MyAppointment />} />
      <Route exact path="/MyFitness" element={<MyFitness />} />
      <Route exact path="/MyCourse" element={<MyCourse />} />
      <Route exact path="/MyProfile" element={<MyProfile />} />
      <Route exact path="/MyConsultation" element={<MyConsultation />} />
      <Route exact path="/MyPlan" element={<MyPlan />} />
      <Route exact path="/MyTravel" element={<MyTravel />} />
      <Route exact path="/MyPayment" element={<MyPayment />} />
      <Route exact path="/MyTools" element={<MyTools />} />
      <Route exact path="/LiveWell" element={<LiveWell />} />
      <Route exact path="/MyExpert" element={<MyExpert />} />
      <Route exact path="/MyShop" element={<MyShop />} />
      <Route exact path="Shop" element={<Shop/>}/>
      <Route exact path="/MyPrescription" element={<MyPrescription />} />
      <Route exact path="/Analysis" element={<Analysis />} />
      <Route exact path="/MySubscription" element={<MySubscription />} />
      <Route exact path="/MyGoals" element={<MyGoals />} />
      <Route exact path="/MyReminders" element={<MyReminders />} />
      <Route exact path="/Mycommunity" element={<Mycommunity />} />
      <Route exact path="/MySettings" element={<MySettings />} />
      <Route exact path="/HealthCare" element={<HealthCare />} />
      <Route exact path="/Plan" element={<Plan/>}/>
      <Route exact path="Not-foundpage" element={<Notfoundpage />} />
      <Route exact path="Hotel-navbar" element={<Hotelnavbar />} />
      <Route exact path="orthopedic-physiotherapy" element={<Orthopedic />} />
      <Route exact path="nurological-physiotherapy" element={<Nurological />} />
      <Route exact path="Back-pain" element={<Backpain />} />
      <Route exact path="Knee-pain" element={<Kneepain />} />
      <Route exact path="Neck-pain" element={<Neckpain />} />
      <Route exact path="Add-Healthrecord" element={<AddHealthRecord />} />
      <Route exact path="Health-records" element={<HealthRecords />} />
      <Route exact path="Add-insurnaceLocker" element={<AddInsurnace />} />
      <Route exact path="Arthitis" element={<Arthitis />} />
      <Route exact path="Expert" element={<Expert />} />
      <Route exact path="Neuro-Disorders" element={<Neurodisorders />} />
      <Route exact path="Post-surgery" element={<Postsurgery />} />
      <Route exact path="Slipped-disc" element={<Slippeddisc />} />
      <Route exact path="Shoulder-pain" element={<Shoulderpain />} />
      <Route exact path="Sports-injury" element={<Sportsinjury />} />
      <Route exact path="Health-meter" element={<Healthmeter />} />
      <Route exact path="Tool" element={<Tool />} />
      <Route exact path="Experts" element={<Experts />} />
      <Route exact path="Fitness-center" element={<Fitnesscenter />} />
      <Route exact path="Sleep-management" element={<Sleepmanagement />} />
      <Route exact path="Right-diet" element={<Rightdiet />} />
      <Route exact path="Stress-management" element={<Stressmanagement />} />
      <Route exact path="Adventure" element={<Adventure />} />
      <Route exact path="Gym-franchise" element={<Gymfranchise />} />
      <Route exact path="Privacy-policy" element={<Privacypolicy />} />
      <Route exact path="Medicine" element={< Medicine/>} />
      <Route exact path="HealthCare-Ayurveda" element={< HealthCareAyurveda/>} />
      <Route
        exact
        path="Cancellation-policy"
        element={<Cancellationpolicy />}
      />
      <Route exact path="Terms-condition" element={<Termscondition />} />
      <Route
        exact
        path="Expert-consultation"
        element={<Expertconsultation />}
      />
      <Route exact path="Health-care" element={<Healthcare />} />

<Route
        exact
        path="/FitnessCenterDetails/:id"
        element={<FitnessCenterDetails />}
      />
      <Route
        exact
        path="cardiothoracic-physiotherapy"
        element={<Cardiothoracic />}
      />
      <Route exact path="women-physiotherapy" element={<Women />} />
      <Route exact path="workplace-ergonomics" element={<Workplace />} />
      <Route exact path="sports-physiotherapy" element={<Sports />} />
      <Route exact path="Personal-training" element={<Personaltraining />} />
      <Route exact path="paediatrics-physiothepary" element={<Paediatrics />} />
      <Route exact path="geriatric-physiotherapy" element={<Geriatric />} />
      <Route exact path="therapeutic-physiotherapy" element={<Therapeutic />} />
      <Route exact path="rehab" element={<Rehab />} />
      <Route exact path="Booking" element={<Booking />} />

      <Route exact path="/Blog/:id" element={<Blog />} />

      <Route exact path="FeaturedHotel" element={<FeaturedHotel />} />
      {/* <Route exact pth="Offer" element={<Offer />} /> */}
      <Route exact path="HotelBooking" element={<HotelBooking />} />  
      <Route exact path="about" element={<About />} />
      <Route exact path="contact" element={<Contact />} />
      <Route exact path="travelnavbar" element={<TravelHeader />} />
      <Route
        exact
        path="/travelonify/indian-holidays/TravelCity/:id"
        element={<TravelCity />}
      />
       <Route
        exact
        path="/travelonify/SearchHotel/:id"
        element={<HotelSearch />}
      />
      <Route
        exact
        path="/travelonify/SearchbyTheme"
        element={<SearchByTheme />}
      />
      <Route
        exact
        path="/travelonify/SearchbyDestination"
        element={<SearchByDestination />}
      />
      <Route exact path="/travelonify/indian-holidays" element={<Domestic />} />

      <Route exact path="/travelonify/HotelList" element={<HotelList />} />
      <Route
        exact
        path="/travelonify/international-holidays"
        element={<International />}
      />
      <Route
        exact
        path="/travelonify/TravelSignin"
        element={<TravelSignin />}
      />

      <Route exact path="/Types" element={<Types />} />
      <Route exact path="/ImageUpload" element={<ImageUpload />} />
      <Route exact path="/travelonify/Thanks" element={<Thanks />} />

     
      <Route exact path="/Appointment" element={<Appointment />} />
      <Route exact path="/Travelonify" element={<Travel />} />
      <Route exact path="/privacy" element={<Privacy />} />
      <Route exact path="/refund" element={<Refund />} />
      <Route exact path="/Terms" element={<Terms />} />
      <Route exact path="/Privacy" element={<Privacy />} />
      <Route exact path="/travel" element={<Travel />} />
      {/* <Route exact path="/Order" element={<Order />} /> */}
      <Route exact path="/Appointment" element={<Appointment />} />
      <Route exact path="/Travelonify" element={<Travel />} />
      <Route
        exact
        path="/travelonify/Traveldetails/:id"
        element={<Traveldetails />}
      />
      <Route
        exact
        path="/travelonify/Traveldetails2/:id"
        element={<Traveldetails2 />}
      />
     
      <Route
        exact
        path="/travelonify/TravelRegister"
        element={<TravelRegister />}
      />
      <Route
        exact
        path="travelonify/Hospitality"
        element={<Hospitality />}
      ></Route>
      <Route exact path="Forgot" element={<Forgot />} />
      <Route exact path="travelonify/OTP" element={<Otp />} />

       {/* <Route path="/Dashboard" element={<ProtectedRoutes />}/> */}

     
      {/* </Route> */}
      {/* <Route path="Signin" element={<PublicRoutes/>}> */}
    
      {/* </Route> */}

      <Route exact path="travelonify/Hotels" element={<Hotels />} />
      <Route exact path="travelonify/HotelDetails/:id" element={<HotelDetails />} />
      <Route exact path="Yoga" element={<Yoga />} />
      <Route exact path="Happy-sleep" element={<Happysleep />} />
      <Route exact path="EditProfile" element={<EditProfile />} />
    </Routes>
  );
}

export default App;
