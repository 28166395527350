import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Footer from "./Footer";
import Navbar from "./navbar";

const Workplace = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };
  return (
    <div id="__next">
      <Navbar />
      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="col-head-color-p">WorkPlace Ergonomics</h3>
        </div>

        <div className="pt-4">
          <h3 className="col-head-color-p">Introduction</h3>
          <p>
            Ergonomics is the study of people in their workplace and is the
            process in which workplaces, products and systems are designed or
            rearranged so that they fit the people who use them. It aims to
            improve workspaces and environments to reduce the risk of
            injury.Ergonomics is a science-based discipline. It brings together
            knowledge from anatomy and physiology, psychology, engineering and
            statistics and ensures that the designs complement the strengths and
            abilities of people who use it.
          </p>
          <p>
            <b>Why is Ergonomics important?</b>
            <br />
            <ul>
              <li>
                In workplace, when body is stressed by an awkward posture,
                extreme temperature, or repeated movement, the musculoskeletal
                system can be affected. Hence ergonomics play major role in
                reducing these work related injuries or illness like computer
                vision syndrome, neck and back pain, and carpal tunnel syndrome
                etc.
              </li>
              <li>
                To create safe, comfortable and productive workspaces by
                bringing human abilities and limitations into the design of a
                workspace, which includes the individual’s body size, strength,
                skill, speed, sensory abilities and attitudes.
              </li>
              <li>
                To make employees more comfortable and increase productivity.
              </li>
            </ul>
          </p>
          <p>
            <b>Ergonomics Domains of Specialization</b>
            <br />
            According to the International Ergonomics Association, there are
            three broad domains of ergonomics.
            <ul>
              <li> Physical</li>
              <li> Cognitive</li>
              <li> Organizational</li>
            </ul>
          </p>
          <p>
            <b>Benefits of Ergonomics</b>
            <br />
            1. Reduced risk of work related injuries and illness
            <br />
            2. Higher productivity
            <br />
            3. Improved health
            <br />
            4. Improved mental insight
            <br />
            5. Better product quality
            <br />
            6. Decreased pain
            <br />
            7. Happy employee
            <br />
            8. Improved employee engagement
            <br />
            9. Better safety culture
          </p>
          <p>
            <b>Neck Pain</b>
            <br />
            Neck pain is more common among office workers than any other
            occupation, while the annual prevalence ranges from 42-63%. The
            socioeconomic burden is another issue as neck pain impacts the
            employer, as well as the employee's performance, health,
            productivity, medical costs, and compensation claims.One study
            indicated that 90% of employees spend more than 4hours/ day behind a
            computer. This emphasizes the need for workplace interventions to
            reduce and prevent neck pain in office workers, as well as reduce
            costs and improve productivity.
            <br />
            <b>Risk Factors</b>
            <ul>
              <li>
                Gender: Females are at higher risk for developing neck pain and
                research suggest the reason may be that women have a higher
                musculoskeletal load and report symptoms more often.
              </li>
              <li>
                Age: The risk of developing neck pain increases until 50 years
                of age and then starts to decline.
              </li>
              <li>
                Exercise frequency: Exercising at least 3 times a week may
                reduce the risk of developing neck pain by 1.5 times.
              </li>
              <li> Mobility of the cervical spine.</li>
              <li>
                Prolonged sustained posture: Holding the neck in a semi-flexed
                position for a long duration increases the risk of developing
                neck pain. The neck and upper back muscles are required to
                provide stability to the 'moving' arms, hands and finger, which
                in turn leads to muscle tension and repetitive strain. Prolonged
                sitting posture affects the natural curvature of the spine,
                while also increasing the pressure on vertebral discs,
                ligaments, and muscles.
              </li>
              <li>
                Workstation design and sitting posture/ ergonomics: A poorly
                designed working station has a direct impact on musculoskeletal
                injuries such as neck pain, as it overloads certain body parts.
              </li>
              <li>
                Psychological stress: Slightly elevated stress levels can
                increase the risk of developing neck pain by 1.6 times. Other
                aspects like anxiety, depression and support also increase the
                risk of developing neck pain. Effect of Office Ergonomics on the
                Neck
              </li>
            </ul>
            <br />
            The height and the distance of the computer monitor, chair, and desk
            has a direct impact on neck pain.
            <ul>
              <li>
                The chair influences the pressure distribution and the curvature
                of the spine, which may lead to increased pressure through the
                vertebral bodies, compensation, muscle fatigue and asymmetry.
              </li>
              <li>
                If the monitor is too far away, the individual is required to
                extend their neck forwards and slouch to see better.
              </li>
              <li>
                When the arms aren't supported on the armrests or the desk it
                provides an excess load on the neck and scapula stabilizers
                leading to muscle fatigue and strain.
              </li>
              <li>
                The sustained position of the neck also fatigues or strains the
                neck muscles.
              </li>
              <li>
                The angle of the gaze also affects the neck position - whether
                the neck is extended or slightly flexed.
              </li>
            </ul>
          </p>
          <h3 className="col-head-color-p">Physiotherapy Management</h3>
          <p>
            <b>Manual therapy</b>
            <br />
            Physiotherapists can use manual therapy techniques such as
            myofascial release, trigger point therapy, cervical spine
            mobilizations and manipulations, as well as dry needling, to relieve
            neck pain. Electrotherapy modalities like TENS, laser and infra-red
            therapy have also been proven to effectively reduce neck pain.
          </p>
          <p>
            <b>Rehabilitation</b>
            <br />
            <b>Stretching</b>
            <br />
            Neck stretches are important in managing neck pain as it improves
            range of motion, relieves tension in tight muscles, improves
            circulation and prevents stiffness from developing due to prolonged
            sustained positions.
          </p>
          <p>
            <b>Strengthening</b>
            <br />
            Besides stretching, strengthening exercises of the neck, shoulders
            and thoracic muscles are also important to improve posture,
            circulation and endurance. Strengthening exercises include;
            <br />
            <ul>
              <li> Isometric neck rotations.</li>
              <li> Scapula stabilization exercises.</li>
              <li> Postural retraining.</li>
              <li> Deep Neck Flexors (DNF) activation and training.</li>
              <li>
                Shoulder abduction,- flexion and - extension exercises with
                resistance bands or dumbells.
              </li>
            </ul>
            Research suggests a 20-minutes strength training program, 3 times a
            week for 10 weeks, to relieve neck pain effectively.
          </p>
          <p>
            <b>General fitness training</b>
            <br />
            Lastly, studies suggest that as little as 1hour of general fitness
            training per week may effectively reduce neck pain.
          </p>
          <p>
            <b>Education</b>
            <br />
            <b>Stress management</b>
            <br />
            Relaxation techniques such as breathing exercises, meditation, yoga
            and cognitive behavioral therapy (CBT) can effectively reduce
            symptoms of stress, anxiety and depression which often contributes
            to neck pain.
          </p>
          <p>
            <b>Ergonomics & workstation design</b>
            <br />
            Workstation design is important to ensure the individual's body is
            supported and well-aligned.
            <br />
            <ul>
              <li>Feet must be supported.</li>
              <li>
                Knees bent at 90 degrees and thighs parallel to the floor.
              </li>
              <li>
                Lumbar support must be provided to maintain the natural
                curvature of the spine.
              </li>
              <li> Elbows should be supported on the armrests or desk.</li>
              <li>
                The monitor has to be at about arm-length distance and at
                eye-level or slightly lower.
              </li>
              <li>
                The individual shouldn't have to rotate the body to reach the
                computer or desk.
              </li>
              <li> Sufficient leg space is also important.</li>
            </ul>
          </p>
          <p>
            <b>Frequent breaks</b>
            <br />
            The key issue with office or desk work is the fact that most
            individuals go hours without moving their body. This restricts blood
            flow, fatigues postural muscles and increase tightness. Studies
            support the use of microbreaks and indicating the taking short
            active breaks every 20 minutes is optimal. These microbreaks improve
            comfort, relieves pain and enhances productivity.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Workplace;
