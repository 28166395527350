import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BlogService from "../../services/blog.service";
import AuthService from "../../services/auth.service";

export const getblog = createAsyncThunk(
    "blog/get/blog",
    async(thunkAPI) => {
        try {
            const data = await BlogService.getBlog();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const getTravelblog = createAsyncThunk(
    "blog/get/blog",
    async(thunkAPI) => {
        try {
            const data = await BlogService.getTravelBlog();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getWmblog = createAsyncThunk(
    "blog/get/blog",
    async(thunkAPI) => {
        try {
            const data = await BlogService.getWMBlog();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getPhysioBlog = createAsyncThunk(
    "blog/get/blog",
    async(thunkAPI) => {
        try {
            const data = await BlogService.getPhysioBlog();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getFitnessBlog = createAsyncThunk(
    "blog/get/blog",
    async(thunkAPI) => {
        try {
            const data = await BlogService.getFitnessBlog();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getHcBlog = createAsyncThunk(
    "blog/get/blog",
    async(thunkAPI) => {
        try {
            const data = await BlogService.getHcBlog();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const blogSlice = createSlice({
    name: "bolg",
    initialState,
    extraReducers: {
        [getblog.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getblog.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getblog.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = blogSlice;
export default reducer;