import React, { useRef, useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import { Link, NavLink } from "react-router-dom";
import logo from "./logo_travelonify.png";
import { MdWifiCalling } from "react-icons/md";

function TravelHeader() {
  let enquiryFor = "travelPackage";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [destination, setDestination] = useState("");
  const [travelDate, settravelDate] = useState("");
  const [childCount, setchildCount] = useState("");
  const [adultCount, setadultCount] = useState("");

  //alert messages//

  const [alertname, setalertName] = useState("");
  const [alertemail, setalertEmail] = useState("");
  const [alertmobile, setalertMobile] = useState("");
  const [alertmessage, setalertMessage] = useState("");
  const [alertenq, setAlertenq] = useState("");
  const [alertpeople, setAlertpeople] = useState("");
  const [alertdestination, setAlertDestination] = useState("");

  const [alertdate, setalertDate] = useState("");

  const [alertadult, setAlertadult] = useState("");
  const [alertchild, setAlertchild] = useState("");

  const peopleCount = adultCount + " Adult, " + childCount + " Children ";
  const dispatch = useDispatch();

  async function upload(e) {
    e.preventDefault();
    let item = {
      name,
      email,
      contactNumber,
      message,
      enquiryFor,
      travelDate,
      destination,
      peopleCount,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setName("");
        setEmail("");
        setcontactNumber("");
        setMessage("");
        setDestination("");
        settravelDate("");
        setchildCount("");
        setadultCount("");
        document.getElementById("myenquiry").style.display = "none";
      })

      .catch(({ emessage }) => {
        alert(emessage);
      });
  }

  const travelsave = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("Please enter a valid name");
      e.preventDefault();
    }

    const vMessage = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage.test(message)) {
      setalertMessage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMessage("Please enter your message");
      e.preventDefault();
    } else {
      setalertMessage("");
    }

    const vDestination = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vDestination.test(destination)) {
      setAlertDestination("");
    } else if (!vDestination.test(destination) && destination === "") {
      setAlertDestination("Please enter destination");
      e.preventDefault();
    } else {
      setAlertDestination("");
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setalertMobile("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setalertMobile("Please enter your mobile number");
      e.preventDefault();
    } else {
      setalertMobile("Valid mobile numbers only");
      e.preventDefault();
    }

    const regcount = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regcount.test(peopleCount)) {
      setAlertpeople("");
    } else if (!regcount.test(peopleCount) && peopleCount === "") {
      setAlertpeople("Please enter no. people's");
      e.preventDefault();
    } else {
      setAlertpeople("");
    }

    const vAdult = /^[1-9][0-9]?$|^100$/;
    if (vAdult.test(adultCount)) {
      setAlertadult("");
    } else if (!vAdult.test(adultCount) && adultCount === "") {
      setAlertadult("Please enter  no.of adults");
      e.preventDefault();
    } else {
      setAlertadult("Min value should be 1");
      e.preventDefault();
    }

    const vDate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate.test(travelDate)) {
      setalertDate("");
    } else if (!vDate.test(travelDate) && travelDate === "") {
      setalertDate("Please enter your date of travel");
      e.preventDefault();
    } else {
      setalertDate("");
    }

    const child = /^[0-9][0-9]?$|^100$/;
    if (child.test(childCount)) {
      setAlertchild("");
    } else if (!child.test(childCount) && childCount === "") {
      setAlertchild("Please enter  no.of child");
      e.preventDefault();
    } else {
      setAlertchild("");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setalertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearform = () => {
    setName("");
    setEmail("");
    setcontactNumber("");
    setMessage("");
    setDestination("");
    settravelDate("");
    setchildCount("");
    setadultCount("");
    // alert message clear
    setalertName("");
    setalertEmail("");
    setalertMobile("");
    setalertMessage("");
    setAlertpeople("");
    setAlertDestination("");
    setalertDate("");
    setAlertadult("");
    setAlertchild("");
  };
  // const user = localStorage.getItem("user");
  // let data = JSON.parse(localStorage.getItem("user"));
  // let role = data.data.roles[0];
  // const navigate = useNavigate();
  // const prevLocation = useLocation();
  // navigate.push(`/login?redirectTo=${prevLocation}`);
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 80;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [user, setUser] = useState({});
  useEffect(() => {
    {
    }
    setInterval(() => {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);

      setUser(user);
    }, []);
  }, 500000);

  const travelLinks = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#001E79" : "white",
      borderRadius: isActive ? "0px" : "0px",
      color: isActive ? "white" : "gray",
    };
  };

  const logout = () => {
    return localStorage.clear();
  };
  if (!user) {
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          fixed="top"
          style={{
            transition: "1s ease",
            color: "black",
            backgroundColor: navBackground ? "white" : "white",
          }}
        >
          <Container>
            <Navbar.Brand to="/travelonify">
              <Link to="/travelonify">
                <img
                  className="logo-3"
                  src={logo}
                  alt=""
                  style={{ height: "80px" }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavLink
                  to="/travelonify"
                  className="nav-link nav-items-travel "
                >
                  Home
                </NavLink>

                <NavLink
                  to="/travelonify/indian-holidays"
                  className="nav-link nav-items-travel"
                  style={travelLinks}
                >
                  Indian Holidays
                </NavLink>
                <NavLink
                  to="/travelonify/international-holidays"
                  className="nav-link nav-items-travel"
                  style={travelLinks}
                >
                  International Holidays
                </NavLink>
                <NavLink
                  to="/travelonify/Hotels"
                  className="nav-link nav-items-travel"
                >
                  Hotels
                </NavLink>

                <NavLink
                  to="/travelonify/Hospitality"
                  className="nav-link nav-items-travel"
                  style={travelLinks}
                >
                  Hotel Management
                </NavLink>

                {/* <NavDropdown title="Login" id="basic-nav-dropdown">
                  <NavDropdown.Item
                    to="http://heal-admin.saketherp.in/"
                    target="_blank"
                  >
                    Partner Login
                  </NavDropdown.Item> */}
                <NavLink
                  to="/travelonify/TravelSignin"
                  className="nav-link nav-items-travel"
                >
                  Login
                </NavLink>
                {/* </NavDropdown> */}
                {/* )} */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="icon-bar" style={{ marginRight: "30px" }}>
          <button
            type="button"
            className=" btn btn-blue"
            data-bs-toggle="modal"
            data-bs-target="#myenquiry"
          >
            Enquire Now
          </button>
        </div>
        <div className="call-btn">
          <a href="tel:+919773233030">
            <MdWifiCalling className="call-icon-orange" />{" "}
          </a>
        </div>

        <div className="modal fade" id="myenquiry">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Enquire Now</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={clearform}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />

                      <p className="alert-message">{alertname}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        maxlength="10"
                        type="number"
                        placeholder="Mobile Number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setcontactNumber(e.target.value)}
                      />

                      <p className="alert-message">{alertmobile}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <p className="alert-message">{alertemail}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="date"
                        name="datemin"
                        placeholder="Travel Date"
                        value={travelDate}
                        onChange={(e) => settravelDate(e.target.value)}
                        className="form-control"
                        id="date"
                      />
                      <p className="alert-message">{alertdate}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Destination"
                        className="form-control"
                        value={destination}
                        onChange={(e) => setDestination(e.target.value)}
                      />
                      <p className="alert-message"> {alertdestination}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        maxlength="2"
                        placeholder="No.of Adult"
                        className="form-control"
                        value={adultCount}
                        min="1"
                        onChange={(e) => setadultCount(e.target.value)}
                        id="adult"
                      />
                      <p className="alert-message">{alertadult}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                        maxlength="2"
                        placeholder="No.of Child"
                        className="form-control"
                        value={childCount}
                        onChange={(e) => setchildCount(e.target.value)}
                        id="child"
                      />
                      <p className="alert-message">{alertchild}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <textarea
                        placeholder="Brief us on your requirement"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <p className="alert-message">{alertmessage}</p>
                    </div>
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white "
                    // data-bs-dismiss="modal"
                    onClick={upload}
                  >
                    Submit
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white "
                    onClick={clearform}
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="background" data-bs-dismiss="modal" />
        </div>
      </div>
    );
  }
  if (user) {
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          fixed="top"
          style={{ backgroundColor: "white" }}
        >
          <Container>
            <Navbar.Brand to="/travelonify">
              <Link to="/travelonify">
                <img
                  className="logo-3"
                  src={logo}
                  alt=""
                  style={{ height: "80px" }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavLink
                  to="/travelonify"
                  className="nav-link nav-items-travel "
                >
                  Home
                </NavLink>

                <NavLink
                  to="/travelonify/indian-holidays"
                  className="nav-link nav-items-travel"
                  style={travelLinks}
                >
                  Indian Holidays
                </NavLink>
                <NavLink
                  to="/travelonify/international-holidays"
                  className="nav-link nav-items-travel"
                  style={travelLinks}
                >
                  International Holidays
                </NavLink>
                <NavLink
                  to="/travelonify/Hotels"
                  className="nav-link nav-items-travel"
                  style={travelLinks}
                >
                  Hotels
                </NavLink>

                <NavLink
                  to="/travelonify/Hospitality"
                  className="nav-link nav-items-travel"
                  style={travelLinks}
                >
                  Hotel Management
                </NavLink>

                <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <NavLink to="/MyProfile">My Profile</NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item to="/travelonify" onClick={logout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
                {/* )} */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="icon-bar" style={{ marginRight: "40px" }}>
          <button
            type="button"
            className=" btn btn-blue"
            data-bs-toggle="modal"
            data-bs-target="#myenquiry"
          >
            Enquire Now
          </button>
        </div>
        <div className="call-btn">
          <a href="tel:+919773233030">
            <MdWifiCalling className="call-icon-orange" />{" "}
          </a>
        </div>

        <div className="modal fade" id="myenquiry" style={{ height: "100%" }}>
          <div
            className="modal-dialog modal-align-center"
            style={{ position: "relative", height: "100%", marginTop: "0%" }}
          >
            <div className="modal-contents">
              <div className="modal-header">
                <h4 className="modal-title">Enquire Now</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={clearform}
                ></button>
              </div>
              <form onSubmit={upload}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />

                      <p className="alert-message">{alertname}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        maxlength="10"
                        type="number"
                        placeholder="Mobile Number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setcontactNumber(e.target.value)}
                      />

                      <p className="alert-message">{alertmobile}</p>
                    </div>

                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <p className="alert-message">{alertemail}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        type="date"
                        name="datemin"
                        placeholder="Travel Date"
                        value={travelDate}
                        onChange={(e) => settravelDate(e.target.value)}
                        className="form-control"
                        id="date"
                      />
                      <p className="alert-message">{alertdate}</p>
                    </div>

                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        type="text"
                        placeholder="Destination"
                        className="form-control"
                        value={destination}
                        onChange={(e) => setDestination(e.target.value)}
                      />
                      <p className="alert-message"> {alertdestination}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        maxlength="2"
                        placeholder="No.of Adult"
                        className="form-control"
                        value={adultCount}
                        min="1"
                        onChange={(e) => setadultCount(e.target.value)}
                        id="adult"
                      />
                      <p className="alert-message">{alertadult}</p>
                    </div>

                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        maxlength="2"
                        placeholder="No.of Child"
                        className="form-control"
                        value={childCount}
                        onChange={(e) => setchildCount(e.target.value)}
                        id="child"
                      />
                      <p className="alert-message">{alertchild}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <textarea
                        placeholder="Brief us on your requirement"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <p className="alert-message">{alertmessage}</p>
                    </div>
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white "
                    // data-bs-dismiss="modal"
                    onClick={travelsave}
                  >
                    Submit
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white "
                    onClick={clearform}
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="background" data-bs-dismiss="modal" />
        </div>
      </div>
    );
  }
}

export default TravelHeader;
