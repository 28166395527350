import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import CommunityService from './../../services/community.service';

export const getcommunitypost = createAsyncThunk(
    "community/get/community",
    async(thunkAPI) => {
        try {
            const data = await CommunityService.getcommunity();
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const communitydetails = createAsyncThunk(
    "community/get/community",
    async(thunkAPI) => {
        try {
            const data = await CommunityService.getcommunitydetails();
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const postcommunityposts = createAsyncThunk(
    "community/post/addcommunity",
    async(item,thunkAPI) => {
        try {
            const data = await CommunityService.postcommunitys(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const putcommunityposts = createAsyncThunk(
    "community/put/community",
    async(item, thunkAPI) => {
        try {
            const data = await CommunityService.editcommunity(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );


  export const enablecommmunity = createAsyncThunk(
    "community/put/community",
    async(item, thunkAPI) => {
        try {
            const data = await CommunityService.enablecommunitypost(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

// export const logout = createAsyncThunk("auth/logout", async () => {
//   EnquiryService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const communitySlice = createSlice({
    name: "community",
    initialState,
    extraReducers: {
        [getcommunitypost.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getcommunitypost.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getcommunitypost.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        [postcommunityposts.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postcommunityposts.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postcommunityposts.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        [putcommunityposts.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [putcommunityposts.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [putcommunityposts.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        [communitydetails.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [communitydetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [communitydetails.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        [enablecommmunity.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [enablecommmunity.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [enablecommmunity.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },

        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = communitySlice;
export default reducer;