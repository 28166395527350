import React, { Component } from "react";
import Footer from "./Footer";
import logo from "./logo.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "./logo.png";

import { MdDashboard } from "react-icons/md";
import {
  BsBookFill,
  BsFillChatRightFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { BiCubeAlt } from "react-icons/bi";

import { MdNotifications } from "react-icons/md";
import { MdSettings } from "react-icons/md";
import user from "./../images/client-image/3.jpg";
import UserNavbar from "./UserNavbar";


const MyCourses = () => {
  return (
    <div className="bg-light">
    <UserNavbar />

    <div className="container-fluid response-cover">
    <div className="row">
      <div className="col-lg-2 col-md-4" />
      <div className="col-lg-10 col-md-8">

      <div className="container pt-5">
      <div className="pt-5 mt-5">
      <div className="col-md-12">
      <h3 className="mb-2">My Courses</h3>
  {  /* <button type="button" className="btn btn-warning adjust-btn col-white mt-3">Add</button>*/}
      </div>     
   

      <div className="row pt-5 pb-5">
        <div className="col-lg-4 col-md-6">
      <div className="post-card">
      <div className="d-flex justify-content-start">
  <div className="profile-icon"/> {/*you can use this classname to image div*/}
  &nbsp;
  &nbsp;
  
  <div className="pro-info">
    <p className="info-text" style={{color:"black", fontWeight:"500"}}>Title</p>
    <p className="info-text">01-01-2000</p>
  </div>    

      </div>

      <p style={{color:"black"}} className="pt-4 pb-2">Discription ......</p>

      <div className="post-image"/>
      </div>
      </div>

      <div className="col-lg-4 col-md-6">
      <div className="post-card">
      <div className="d-flex justify-content-start">
  <div className="profile-icon"/> {/*you can use this classname to image div*/}
  &nbsp;
  &nbsp;
  
  <div className="pro-info">
    <p className="info-text" style={{color:"black", fontWeight:"500"}}>Title</p>
    <p className="info-text">01-01-2000</p>
  </div>    

      </div>

      <p style={{color:"black"}} className="pt-4 pb-2">Discription ......</p>

      <div className="post-image"/>
      </div>
      </div>

      <div className="col-lg-4 col-md-6">
      <div className="post-card">
      <div className="d-flex justify-content-start">
  <div className="profile-icon"/> {/*you can use this classname to image div*/}
  &nbsp;
  &nbsp;
  
  <div className="pro-info">
    <p className="info-text" style={{color:"black", fontWeight:"500"}}>Title</p>
    <p className="info-text">01-01-2000</p>
  </div>    

      </div>

      <p style={{color:"black"}} className="pt-4 pb-2">Discription ......</p>

      <div className="post-image"/>
      </div>
      </div>

      </div>
    
        
      </div>
    </div>

      </div>
      </div>
      </div>
     
    </div>
  );
}
export default MyCourses;
