import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import client from "../images/client-image/3.jpg";
import Header from "./navbar";
import Footer from "./Footer";
import Gapp from "./../images/project/Gapp.png";
import Iapp from "./../images/project/apple.png";
import corporateimg from "./../images/corporate/Corporate-07.png";
import tech from "./../images/mhome/tech-platform.png";
import heart from "./../images/mhome/heart.png";
import coach from "./../images/mhome/life-coach.png";
import doctor from "./../images/mhome/doctor.png";
import tracker from "./../images/mhome/tracker.png";
import report from "./../images/mhome/report.png";
import engagement from "./../images/mhome/engagement.png";
import risk from "./../images/mhome/risk.png";
import community from "./../images/mhome/community.png";
import leader from "./../images/mhome/leader.png";
import medical from "./../images/corporate/icons/reductionmedicalcost.png";
import physical from "./../images/corporate/icons/physicalactivity.png";
import employee from "./../images/corporate/icons/employeeengagment.png";

import banner1 from "../images/corporate/banner1.jpg";
import banner2 from "../images/corporate/banner2.jpg";
import banner3 from "../images/corporate/banner3.jpg";
import banner4 from "../images/corporate/banner4.jpg";
import banner5 from "../images/corporate/banner5.jpg";
import banner6 from "../images/corporate/banner6.jpg";

import { TravelEnquiry } from "../store/slices/enquiry";

const Corporate = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  const [alertname, setAlertName] = useState("");
  const [alertemail, setAlertEmail] = useState("");
  const [alertcompanyName, setAlertCompanyName] = useState("");
  const [alertmessage, setAlertMessage] = useState("");
  const [alertcontactNumber, setAlertContactNumber] = useState("");

  const [alertname1, setAlertName1] = useState("");
  const [alertemail1, setAlertEmail1] = useState("");
  const [alertcompanyName1, setAlertCompanyName1] = useState("");
  const [alertmessage1, setAlertMessage1] = useState("");
  const [alertcontactNumber1, setAlertContactNumber1] = useState("");

  async function upload(e) {
    e.preventDefault();
    let enquiryFor = "companyOnboarding";
    let item = {
      name,
      email,
      companyName,
      message,
      contactNumber,
      enquiryFor,
    };
    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");

        setName("");
        setEmail("");
        setCompanyName("");
        setMessage("");
        setContactNumber("");

        document.getElementById("enquire").style.display = "none";
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }

  async function upload1(e) {
    e.preventDefault();
    let enquiryFor = "companyOnboarding";
    let item = {
      name,
      email,
      companyName,
      message,
      contactNumber,
      enquiryFor,
    };
    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setCompanyName("");
        setMessage("");
        setContactNumber("");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const corporatenquiry = (e) => {
    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertEmail1("");
    } else if (!regemail.test(email) && email === "") {
      setAlertEmail1("Please enter your email");
      e.preventDefault();
    } else {
      setAlertEmail1("Please enter a valid email");
    }

    const textname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (textname.test(name)) {
      setAlertName1("");
    } else if (!textname.test(name) && name === "") {
      setAlertName1("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName1("");
    }

    const testname2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (testname2.test(companyName)) {
      setAlertCompanyName1("");
    } else if (!testname2.test(companyName) && companyName === "") {
      setAlertCompanyName1("Please enter your company name");
      e.preventDefault();
    } else {
      setAlertCompanyName1("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setAlertMessage1("");
    } else if (!vMessage.test(message) && message === "") {
      setAlertMessage1("Please enter your message");
      e.preventDefault();
    } else {
      setAlertMessage1("");
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setAlertContactNumber1("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber1("Please enter your mobile number");
      e.preventDefault();
    } else {
      setAlertContactNumber1("Valid mobile numbers only");
      e.preventDefault();
    }
  };
  const join = (e) => {
    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setAlertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setAlertEmail("Please enter a valid email");
    }

    const textname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (textname.test(name)) {
      setAlertName("");
    } else if (!textname.test(name) && name === "") {
      setAlertName("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName("");
    }

    const testname2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (testname2.test(companyName)) {
      setAlertCompanyName("");
    } else if (!testname2.test(companyName) && companyName === "") {
      setAlertCompanyName("Please enter your company name");
      e.preventDefault();
    } else {
      setAlertCompanyName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setAlertMessage("");
    } else if (!vMessage.test(message) && message === "") {
      setAlertMessage("Please enter your message");
      e.preventDefault();
    } else {
      setAlertMessage("");
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setAlertContactNumber("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber("Please enter your mobile number");
      e.preventDefault();
    } else {
      setAlertContactNumber("Valid mobile numbers only");
      e.preventDefault();
    }
  };

  const clear = () => {
    setAlertName1("");
    setAlertCompanyName1("");
    setAlertEmail1("");
    setAlertMessage1("");
    setAlertContactNumber1("");
    setName("");
    setEmail("");
    setCompanyName("");
    setMessage("");
    setContactNumber("");
    setAlertName("");
    setAlertEmail("");
    setAlertCompanyName("");
    setAlertMessage("");
    setAlertContactNumber("");
  };

  return (
    <div>
      <Header />
      <section>
        <div className="carousel_cover ">
          <Carousel className="">
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner1}
                alt=""
              />
              <Carousel.Caption>
                <button
                  type="button"
                  className="btn btn-healthonify  col-white"
                  data-bs-toggle="modal"
                  data-bs-target="#enquire"
                  style={{
                    color: "box",
                    lineHeight: "2 !important",
                    width: "200px",
                    height: "50px",
                  }}
                >
                  LEARN MORE
                </button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner2}
                alt=""
              />
              <Carousel.Caption>
                <button
                  type="button"
                  className="btn btn-healthonify  col-white"
                  data-bs-toggle="modal"
                  data-bs-target="#enquire"
                  style={{
                    color: "box",
                    lineHeight: "2 !important",
                    width: "200px",
                    height: "50px",
                  }}
                >
                  LEARN MORE
                </button>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img className="d-block w-100 phy_img_carousel3" src={banner3} />
              <Carousel.Caption>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#enquire"
                  className="btn btn-healthonify  col-white"
                  style={{
                    color: "box",
                    lineHeight: "2 !important",
                    width: "200px",
                    height: "50px",
                  }}
                >
                  LEARN MORE
                </button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <div className="col-lg-12 d-flex justify-content-center section-title pt-5 pb-2">
        <h3>Corporate Wellness Programs-Why You Need </h3>
      </div>
      <div className="container">
        <div className="col-lg-12 pt-1 pb-4">
          <h3 className="text-center col-dark-blue">
            A healthy employee is a valuable asset to any organization
          </h3>
          <p className="text-left">
            In today’s fast and complex life, wellbeing is becoming crucial to
            organizational productivity. More and more organizations around the
            world are beginning to realize the benefits of creating a work
            environment which encourages health, wellness, and optimum fitness
            for their employees. It is important for organizations, and HR
            professionals to understand that holistic wellbeing, and not merely
            one-dimensional wellness is essential to attract, engage and retain
            today’s and tomorrow’s workforce. As employers spend most time at
            the workplace, HR team must provide necessary wellness alternatives
            for smooth work culture.
          </p>
          <p className="text-left pb-5">
            If the employee’s well-being is not considered as a key stakeholder
            business need, an organization will now lose its competitive
            advantage. Wellness programs are still a new concept in India and
            many employers find it difficult to understand the benefits of these
            programs. They fail to realize that a happier and healthier
            workforce is the key to increase productivity and save
            health-related costs. Even though a wellness program would require
            time to establish a change graph in the physical health of the
            employees, it’s an essential step towards a fitter workforce.
          </p>

          <h3 className="text-center col-dark-blue">
            New age Healthonify employee wellness programs
          </h3>
          <p className="text-left pb-5">
            Healthonify Technology with its onsite services is filling in the
            gaps left by traditional models. As technology fragments tools,
            behaviors and processes and rewrites internal communications
            structures, organizations need to understand, embrace and integrate
            their business with their employees at the center.
          </p>
        </div>
      </div>

      <section className="container">
        <h3 className="text-center col-dark-blue">
          What makes us so effective
        </h3>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={tech} alt="" className="flat-icon-mod" />
              </div>

              <p className="text-center  h-text-bold  col-white">
                APP BASED TECH PLATFORM
              </p>
              <p className="text-center text-transform-small col-white">
                Mobile application to aid you with Tools and Trackers to attain
                desired results
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={heart} alt="" className="flat-icon-mod" />
              </div>

              <p className="text-center  h-text-bold col-white">
                COMPLETE ECO SYSTEM OF HEALTH & WELLNESS
              </p>
              <p className="text-center text-transform-small col-white">
                Our programs include all verticals of Fitness, Health care &
                Mental Wellness
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={coach} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center  h-text-bold col-white">
                EXPERIENCED COACHES
              </p>
              <p className="text-center text-transform-small col-white">
                Specially trained qualified health coaches, trainers and
                dietitians
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={doctor} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center h-text-bold  col-white">
                DOCTORS AND HEALTH CONSULTANTS
              </p>
              <p className="text-center text-transform-small col-white">
                Qualified MBBS doctors and specialist health consultants
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={tracker} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center h-text-bold  col-white">TRACKERS</p>
              <p className="text-center text-transform-small col-white">
                Special trackers to track weight, calories, water intake,
                exercises, Blood preassure, blood sugar, heart rate, BMI, BMR
                etc.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={report} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center  h-text-bold col-white">REPORTS</p>
              <p className="text-center text-transform-small col-white">
                Special reports for HR Department to track employee engagement
                and progress.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={engagement} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center  h-text-bold col-white">ENGAGEMENT</p>
              <p className="text-center text-transform-small col-white">
                Highly engaging programs with reminders self-help videos,
                challenges and live trainers online and onsite
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={risk} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center h-text-bold  col-white">
                HEALTH RISK ASSESSMENT
              </p>
              <p className="text-center text-transform-small col-white">
                Health Risk assessment for preventive health care
              </p>
            </div>
          </div>

          <div className="col-lg-3" />

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={community} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center h-text-bold  col-white">COMMUNITY</p>
              <p className="text-center text-transform-small col-white">
                Community feature to exchange views and promote engagement
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 div-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={leader} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center h-text-bold  col-white">LEADERBOARD</p>
              <p className="text-center text-transform-small col-white">
                Ranking of all participants in activities with rewards and
                recognitions to motivate
              </p>
            </div>

            <div className="col-lg-3" />
          </div>
        </div>

        <div className="col-lg-12 d-flex justify-content-center pt-5 ">
          <button
            type="button"
            className="btn btn-warning mod-warning-eff col-white"
            data-bs-toggle="modal"
            data-bs-target="#enquire"
          >
            Enquire Now
          </button>
        </div>
      </section>

      <section className="container mt-5  mb-2 pt-5 card-mod">
        <h3 className="text-center col-dark-blue">
          Five good reasons why you should onboard Healthonify corporate
          wellness programs
        </h3>

        <ul className="pt-4">
          <li className="col-black bold-t">Lowers health care costs</li>
          <p className="col-black bold-t">
            &bull; Healthonify Wellness programs contributed to 86% of
            healthcare cost savings.
            <br />
            &bull; Specifically -Disease management program generated savings
            per member, per month and 30% reduction in hospital admissions.
          </p>
        </ul>

        <ul className="pt-4">
          <li className="col-black bold-t">Increases employee loyalty</li>
          <p className="col-black bold-t">
            Healthonify workplace wellness program increases employee health and
            happiness significantly. Happy employees are 12% more productive and
            87% more loyal to company thereby increasing overall productivity.
          </p>
        </ul>

        <ul className="pt-4">
          <li className="col-black bold-t">Reduces Absenteeism cost</li>
          <p className="col-black bold-t">
            Healthonify workplace wellness programs can reduce the absenteeism
            significantly. For every $1 invested in wellness programs,
            absenteeism costs fall by 100% and medical costs fall by 200%.
          </p>
        </ul>

        <ul className="pt-4">
          <li className="col-black bold-t">Increases employee engagement</li>
          <p className="col-black bold-t">
            Gallup survey found that 8% of workers worldwide are not actively
            engaged in their jobs which cost employee cost up to $450 to $550
            billion per year. Healthonify programs engage employees providing
            them solutions and services of stress management, nutritionist,
            health consulting, healthy activities which in turn reduce the costs
            related to employee disengagement.
          </p>
        </ul>

        <ul className="pt-4">
          <li className="col-black bold-t">Reduces Stress Levels</li>
          <p className=" col-black bold-t">
            The stress mapping techniques used in corporate wellness programs
            can assist Human resource managers to determine the stress
            initiating factors in corporate and take appropriate actions to
            reduce the stress levels. This is going to result in increased
            employee happiness, employee retention, and reduced health insurance
            costs for your business.
          </p>

          <p className="col-black bold-t">
            &bull; High level of stress environment is responsible for 50%
            increase in Healthcare expenditures.
            <br />
            &bull; Employees under high level of stress cost up to 40% more than
            employees with low levels of stress.
          </p>

          <p className="col-black bold-t">
            Healthonify Employee wellness program encourages a happier,
            healthier work culture and boost employee morale. It would create a
            conducive work environment where an individual can comfortably focus
            on work productivity rather than stress, health, finances and home.
          </p>
        </ul>
      </section>

      <section className="container pt-5 mt-5  mb-5">
        <h3 className="text-center pb-4 col-dark-blue">
          Our clients have witnessed
        </h3>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 mt-2 mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 banner-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={physical} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center col-black">
                60% increase in physical activity
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-2 mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 banner-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={medical} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center col-black">
                20% Reduction in Medical Cost
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm- mt-2 mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 banner-card">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={employee} alt="" className="flat-icon-mod" />
              </div>
              <p className="text-center col-black">
                60% Improvement in employee engagement in India
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="carousel_cover ">
        <Carousel className="">
          <Carousel.Item interval={3000}>
            <img className="d-block w-100 phy_img_carousel " src={banner4} />
            <Carousel.Caption>
              <button
                type="button"
                className="btn btn-healthonify  col-white"
                data-bs-toggle="modal"
                data-bs-target="#enquire"
                style={{
                  color: "box",
                  lineHeight: "2 !important",
                  width: "200px",
                  height: "50px",
                }}
              >
                Enquire Now
              </button>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 phy_img_carousel"
              src={banner5}
              alt=""
            />
            <Carousel.Caption>
              <button
                type="button"
                className="btn btn-healthonify  col-white"
                data-bs-toggle="modal"
                data-bs-target="#enquire"
                style={{
                  color: "box",
                  lineHeight: "2 !important",
                  width: "200px",
                  height: "50px",
                }}
              >
                Enquire Now
              </button>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={3000}>
            <img className="d-block w-100 phy_img_carousel " src={banner6} />
            <Carousel.Caption>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#enquire"
                className="btn btn-healthonify  col-white"
                style={{
                  color: "box",
                  lineHeight: "2 !important",
                  width: "200px",
                  height: "50px",
                }}
              >
                Enquire Now
              </button>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>

      <section className="container pt-1 pb-5">
        <div className="row" style={{ alignItems: "center" }}>
          {/* <div className="col-lg-6 col-md-6 col-sm-12">
            <ul>
              <li className="list-mark">Workout Plans</li>
              <li className="list-mark">Diet Plans</li>
              <li className="list-mark">Weight Management</li>
              <li className="list-mark">Health Care</li>
              <li className="list-mark">Physiotherapy</li>
              <li className="list-mark">Mindfulness</li>
              <li className="list-mark">Stress Management</li>
              <li className="list-mark">Sound Therapy </li>
              <li className="list-mark">Sleep Management</li>
              <li className="list-mark">
                Trackers- Steps, Calories, BP, Blood sugar, Sleep etc
              </li>
              <li className="list-mark">Fitness Tools-BMI, BMR, CALORIE etc</li>
              <li className="list-mark">Challenges</li>
              <li className="list-mark">Leader Board</li>
              <li className="list-mark">Community</li>
              <li className="list-mark">Adventures</li>
              <li className="list-mark">Live Video Assistance</li>
            </ul>
          </div> */}

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="col-lg-12 d-flex justify-content-center">
              <img src={corporateimg} alt="" className="phone-img3" />
            </div>
          </div>
        </div>
      </section>

      <section className="cover2 mb-5 ">
        <h3 className="text-center col-white">ENQUIRE NOW</h3>
        <form onSubmit={upload1}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 mt-2 mb-4">
                <p className="forms-text col-white">Name</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your name"
                  style={{ lineHeight: "2" }}
                />
                <p className="alert-message">{alertname1}</p>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 mt-2 mb-4">
                <p className="forms-text col-white">Email</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                  style={{ lineHeight: "2" }}
                />
                <p className="alert-message">{alertemail1}</p>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 mt-2 mb-4">
                <p className="forms-text col-white">Mobile Number</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your phone"
                  style={{ lineHeight: "2" }}
                />
                <p className="alert-message">{alertcontactNumber1}</p>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 mt-2 mb-4">
                <p className="forms-text col-white">Company Name</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your company"
                  style={{ lineHeight: "2" }}
                />
                <p className="alert-message">{alertcompanyName1}</p>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 mt-2 mb-4">
                <p className="forms-text col-white">Message</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Brief your enquiry"
                  style={{ lineHeight: "2" }}
                />
                <p className="alert-message">{alertmessage1}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <p className="col-white">
                By clicking Request Now, you agree to our&nbsp;
                <a
                  href="https://healthonify.com/Terms-condition"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Terms and Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://healthonify.com/Privacy-policy"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="col-lg-12 d-flex justify-content-center mt-3">
              <button
                className="btn btn-warning col-white adjust-btn"
                onClick={corporatenquiry}
              >
                Request Now
              </button>
              &nbsp; &nbsp;
              <button
                type="reset"
                onClick={clear}
                className="btn btn-blue col-white adjust-btn"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </section>

      <section className="container pb-5">
        <h3 className="text-center">Our Clients</h3>

        <div className="row">
          <div className="col-lg-2 col-md-3 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <img src={client} alt="" className="client-img" />
            </div>
          </div>

          <div className="col-lg-2 col-md-3 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <img src={client} alt="" className="client-img" />
            </div>
          </div>

          <div className="col-lg-2 col-md-3 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <img src={client} alt="" className="client-img" />
            </div>
          </div>

          <div className="col-lg-2 col-md-3 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <img src={client} alt="" className="client-img" />
            </div>
          </div>

          <div className="col-lg-2 col-md-3 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <img src={client} alt="" className="client-img" />
            </div>
          </div>

          <div className="col-lg-2 col-md-3 col-xs-6 pt-2 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <img src={client} alt="" className="client-img" />
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial pt-5">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <p className="wel-text text-center pb-2">Testimonial</p>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-orange-card fix_space">
                {/* <div className="circle-profile d-flex justify-content-center">
                  <img src={team1} alt="" />
                </div> */}
                <h4 className="text-center">Kudos RMG</h4>
                <p className="col-white">
                  We took the Healthonify Corporate wellness program. All out
                  employees were given access to Healthonify App. Separate group
                  was created within the company and we ran a “Great Losers
                  challenge and Highest Step count challenge. It was real fun.
                  Expecting more such things from Team Healthonify
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-orange-card fix_space">
                {/* <div className="circle-profile d-flex justify-content-center">
                  <img src={team3} alt="" />
                </div> */}
                <h4 className="text-center">2 Go Living</h4>
                <p className="col-white">
                  We always wanted a corporate wellness program for our company.
                  We tried many things like setting up a small gym at office,
                  weekend yoga program etc. but there was no participation from
                  the employees., We got Healthonify’s Corporate wellness
                  program which is very engaging and motivating, it induces the
                  employee to follow some sort of Fitness regime, the Trackers
                  and fitness tools are very handy, Community, challenges,
                  health related sections has really made our employees’ health
                  conscious. A big Thanx to Team Healthonify
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-orange-card fix_space">
                {/* <div className="circle-profile d-flex justify-content-center">
                  <img src={team4} alt="" />
                </div> */}
                <h4>Global TEFC Academy</h4>

                <p className="col-white">
                  We always wanted a corporate wellness program for our company.
                  We tried many things like setting up a small gym at office,
                  weekend yoga program etc. but there was no participation from
                  the employees., We got Healthonify’s Corporate wellness
                  program which is very engaging and motivating, it induces the
                  employee to follow some sort of Fitness regime, the Trackers
                  and fitness tools are very handy, Community, challenges,
                  health related sections has really made our employees’ health
                  conscious. A big Thanx to Team Healthonify
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-orange-card fix_space">
                {/* <div className="circle-profile d-flex justify-content-center">
                  <img src={team2} alt="" />
                </div> */}
                <h4>Global Champions</h4>

                <p className="col-white">
                  Our HR have tried many things in the past and used to post
                  articles on Fitness, stress management etc. The Healthonify
                  Corporate wellness program has so many features like workout
                  at office in your workspace, destressing exercises,
                  Physiotherapy tips for stretching… The list is long. Our
                  employees are very happy.
                </p>
              </div>
            </div>
          </div>

          <br />
          <br />
        </div>
      </section>

      <Footer />
      {/*Join Now Form*/}

      <div className="modal fade" id="enquire" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Corporate Enquiry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={clear}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={upload}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      <p className="alert-message">{alertname}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                      />
                      <p className="alert-message">{alertemail}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Enter your company name"
                      />
                      <p className="alert-message">{alertcompanyName}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Enter your contact number"
                      />
                      <p className="alert-message">{alertcontactNumber}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        type="text"
                        row="50"
                        col="50"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter message"
                      ></textarea>
                      <p className="alert-message">{alertmessage}</p>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={join}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={clear}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>

      {/*Join Now Form*/}
    </div>
  );
};

export default Corporate;
