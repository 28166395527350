import React, { useState } from "react";
import { useDispatch } from "react-redux";

import UserNavbar from "./UserNavbar";
import { healthrecords } from "../store/slices/healthrecord";
// import { getrecord } from "../store/slices/healthrecord";
import { API_PATHS } from "../utils/constants/api.constants";
import poster from '../../src/images/shape/upload.png';


const AddHealthRecord = () => {
    const [reportName, setReportName] = useState(""); 
    const [reportType, setReportType] = useState(""); 
    const [date, setDate] = useState(""); 
    const [time, setTime] = useState(""); 
    const [mediaLink, setMediaLink] = useState(poster); 

    // alert-message
    const [alertreportName, setAlertreportName] = useState("");
    const [alertreportType, setAlertreportType] = useState("");
    const [alertdate, setAlertdate] = useState("");
    const [alerttime, setAlerttime] = useState("");
    const [alertmediaLink, setAlertmediaLink] = useState("");


    const dispatch = useDispatch();
   
        async function recordpost(e) {
            let entryDateTime =   new Date().getFullYear() +
            "-" +
            ("0" + (new Date().getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + new Date().getDate()).slice(-2);
            e.preventDefault();
            let item = {
                reportName,
                reportType,
                date,
                time,
                mediaLink,
                entryDateTime
            };
        
            dispatch(healthrecords(item))
              .unwrap()
              .then(() => {
                alert("HelathLocker saved");
                setReportName("");
                setReportType("");
                setDate("");
                setTime("");
                setMediaLink(poster);
              })
        
              .catch(({ emessage }) => {
                alert("Error");
              });
          }


          

    const save = (e) =>{

      const textregx = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
      if (textregx.test(reportName)) {
        setAlertreportName("");
      } else if (!textregx.test(reportName) && reportName === "") {
        setAlertreportName("Please enter report name");
        e.preventDefault();
      } else {
        setAlertreportName("");
        
      }
    

    const textregx2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (textregx2.test(reportType)) {
      setAlertreportType("");
    } else if (!textregx2.test(reportType) && reportType === "") {
      setAlertreportType("Please enter report type");
      e.preventDefault();
    } else {
      setAlertreportType("");
    
    }

    const vDate =
    /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
  if (vDate.test(date)) {
    setAlertdate("");
  } else if (!vDate.test(date) && date === "") {
    setAlertdate("Please enter your date");
    e.preventDefault();
  } else {
    setAlertdate("");
  }

  const timeregx =  /^[0-2][0-3]:[0-5][0-9]$/;
if (timeregx.test(time)) {
  setAlerttime("");
} else if (!timeregx.test(time) && time === "") {
  setAlerttime("Please enter your time");
  e.preventDefault();
} else {
  setAlerttime("");
}

const regimage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
if (regimage.test(mediaLink)) {
  setAlertmediaLink("");
} else if (!regimage.test(mediaLink) && mediaLink === "") {
  setAlertmediaLink("Please enter image link");
  e.preventDefault();
} else {
  setAlertmediaLink("Invalid file");
  e.preventDefault();
}

  }


  const cancel = () =>{

    setReportName("");
setReportType("");
setDate("");
setTime("");
setMediaLink("");
setAlertreportName("");
setAlertreportType("");
setAlertdate("");
setAlerttime("");
setAlertmediaLink("");
  } 

    async function imageUpload(e) {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("file", e.target.files[0]);
    
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        let response = await fetch(API_PATHS.uploadImage, requestOptions);
        let data = await response.json();
        setMediaLink(data.data.location);
        // setMessage(data.message);
      }

  return (
    <div>
    <UserNavbar/>
 <div className="container">
 
<section className='form-section pt-5'>
<div className='row pt-5'>
<div className='col-lg-4'/>
<div className='col-lg-4'>
<div className="col-lg-12">
<h5 className='text-center'>Now you can add your health records by :</h5>

<div className ="d-flex justify-content-center pb-5">
<img src={mediaLink} alt="" className="uploade-image"/>
</div>

<div className ="d-flex justify-content-center pb-5">
<input type="file" onChange={(e) => imageUpload(e)} />
</div>
<p className="alert-message">{alertmediaLink}</p>

<form onSubmit={recordpost}>
<div className='row'>

<div className='col-md-6 mt-2 mb-2'>
<p className="m-0">Record Name</p>
<input type="text" className="form-control"   value={reportName}
onChange={(e) => setReportName(e.target.value)} placeholder="Enter Record Name"/>
<p className="alert-message">{alertreportName}</p>
</div>

<div className='col-md-6 mt-2 mb-2'>
<p className="m-0">Hospital Records</p>
<input type="text" className="form-control"   value={reportType}
onChange={(e) => setReportType(e.target.value)} placeholder="Enter Record type"/>
<p className="alert-message">{alertreportType}</p>
  </div>

{/*
<div className='col-md-6 mt-2 mb-2'>
<p className="m-0">Hospital Records</p>
<select name="select" id="select" className='form-control'>
<option value="">Select Hospital Records</option>
<option value={reportType} onChange={(e) => setReportType(e.target.value)}>Thyrocare Report</option>
</select>
</div>*/}

<div className='col-md-6 mt-2 mb-2'>
<p className="m-0">Choose Date</p>
<input type="date" value={date} onChange={(e) => setDate(e.target.value)} className='form-control'/>
<p className="alert-message">{alertdate}</p>
</div>

<div className='col-md-6 mt-2 mb-2'>
<p className="m-0">Choose Time</p>
<input type="time" value={time} onChange={(e) => setTime(e.target.value)} className='form-control'/>
<p className="alert-message">{alerttime}</p>
</div>

<div className='d-flex justify-content-center pt-5'>
<button type="submit" className='btn btn-danger' onClick={save}>Save</button>
&nbsp;
&nbsp;
&nbsp;
<button type="button" className='btn btn-danger' onClick={cancel}>Cancel</button>
</div>

</div>
</form>
</div>
</div>
<div className='col-lg-4'/>
</div>
</section>

 </div>   


    </div>
  )
}

export default AddHealthRecord;