import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import UserNavbar from "./UserNavbar";
import { postcommunityposts } from "../store/slices/community";
// import Moment from "moment";
const AddCommunity = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [mediaLink, setMediaLink] = useState("");
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");
 

  async function upload(e) {
    e.preventDefault();
    let userId = "628e07c3295cbb2a64996d2d";
    let userType = "user";
    let isActive = true;
    let item = {
         
        mediaLink,
        description,
        date,
        userId,
userType,
isActive,
    
}
    dispatch(postcommunityposts(item))
      .unwrap()
      .then(() => {
        alert("Profile updated succesfully");
        // navigate("/MyProfile");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
    setMessage(data.message);
  }
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container">
              <div className="pt-5 mt-5">
                <div className="car">
                  <div className="a" align="center">

                    <form className="contact-form" onSubmit={upload}>
                      <div className="row">

                        <div className="col-md-4 col-sm-12" align="left">
                          <div className="form-group">
                            <b>Decription</b>
                            <input
                              type="text"
                              name="First Name"
                              placeholder="Enter the decription"
                              className="form-control mt-2"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              //  value={agentVal.firstName}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-12" align="left">
                        <div className="form-group">
                          <b>Date</b>
                          <input
                            type="date"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12" align="left">
                 
                        <b>Upload Image</b>
                        
                        <input
                        type="file"
                        className="form-control mt-2"
                        Placeholder=""
                        name="imageUrl"
                        style={{lineHeight:"2.5"}}
                        onChange={(e) => imageUpload(e)}
                      />
                
                    </div>
                       

                        <center
                          className="send-btn"
                          style={{ marginBottom: "20px" }}
                        >
                          <button className="btn btn-warning col-white submit-btn">
                            Save
                          </button>
                          &nbsp;
                          <button className="btn btn-blue col-white submit-btn">
                            Cancel
                          </button>
                        </center>
                      </div>
                    </form>
                    {/* ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCommunity;
