import axios from "axios";
import { API_BASE_URL, API_PATHS } from "../utils/constants/api.constants";

class FitnessCenterService {
   

    static postfitnescenter(item) {
        return axios
            .post(API_PATHS.postfitnessenquire,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static searchFitnessCenter() {
        let api = '';
        let id = localStorage.getItem("service");
        let city = localStorage.getItem("city");
        let locality = localStorage.getItem("locality");
          api = API_PATHS.searchFitnesscenter + '?fitnessServiceName=' + id + '&locationName=' + locality + '&cityName=' + city ;
       
        return axios
          .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static searchByFitnessCenter() {
        let api = '';
        let item = JSON.parse(localStorage.getItem("item"));
          api = API_PATHS.searchFitnesscenter + '?fitnessServiceName=' + item.type + '&locationName=' + item.localities+ '&cityName=' + item.cities ;
       
        return axios
          .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }


    static trendingfitnescenter() {
            let api='';
            
             api = API_PATHS.trendingfitnesscenter;
             return axios
             .get( api)
                 .then((response) => {
                     if (response.data) {
                         // localStorage.setItem("user", JSON.stringify(response.data));
                     }
                     return response.data;
                 });
         }

         
    static fitnescenterDetails() {
      let api='';
      let id = localStorage.getItem("id");
       api = API_PATHS.getfitnesscenter+ '?id=' + id ;
       return axios
       .get( api)
           .then((response) => {
               if (response.data) {
                   // localStorage.setItem("user", JSON.stringify(response.data));
               }
               return response.data;
           });
   }



    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default FitnessCenterService;