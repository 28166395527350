import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class cityService {
  
    static getCity() {
       
          return axios
            .get( API_PATHS.getCity)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }
    static getCityDetails() {
      let api = '';
        let id = localStorage.getItem("id"); 
          api = id ? API_PATHS.getCityDetails + '?_id=' + id : API_PATHS.getCityDetails;
        localStorage.removeItem("id");
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }
    static cityPackage() {
      let api = '';
        let id = localStorage.getItem("cityid"); 
          api =API_PATHS.packagetravel + '?cityId=' + id;
        localStorage.removeItem("id");
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default cityService;