import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Logo from "./logo.png";
import Footer from "./Footer";

import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import banner2 from "./../images/banner/physio2.jpg";
import { Link } from "react-router-dom";

import Navbar from "./navbar";

const Neckpain = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>
      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Neck Pain</h3>
        </div>

        <div className="pt-4">
          <p>
            Physical therapy is one of the most common treatments for chronic
            neck pain. Most physical therapy programs for neck pain involve
            applying treatments to reduce pain and/or stiffness enough to begin
            an exercise program of strengthening and stretching the neck. The
            specific methods and exercises used in physical therapy, as well as
            the duration of the treatment plan, can vary from person to person.
            <br />
            Physical therapy for neck pain typically includes the following
            goals:
            <br />
            <ul>
              <li>Reduce pain and stiffness </li>
              <li> Improve head and neck range of motion </li>
              <li>
                Develop dynamic strengthening of the neck and its supporting
                musculature
              </li>
              <li> Develop strategies to prevent pain from recurring</li>
            </ul>
            <br />
            Even if pain cannot be completely eliminated, physical therapy may
            play an important role in improving neck posture and function for
            daily movements.
          </p>
          <p>
            <b>Cases when Physical Therapy May Be Recommended</b>
            <br />
            Physical therapy for the neck might be recommended in a variety of
            cases, such as:
            <br />
            <ul>
              <li>
                Unspecified chronic pain. When neck pain lingers or keeps
                recurring, the exact source or mechanism of pain can be
                difficult to identify. Even without a diagnosis, increasing the
                strength of the neck’s muscles may help them to better support
                the cervical spine and become more resistant to pain.{" "}
              </li>
              <li>
                Recovering from injury. Some injuries, such as whiplash, can
                damage the neck’s soft tissues and joints, resulting in pain
                and/or stiffness that can last for weeks or much longer. A
                physical therapy program can reduce pain and help return the
                neck to normal functioning.{" "}
              </li>
              <li>
                {" "}
                Recovering from surgery. Some surgeries performed on the neck
                can result in significant pain and stiffness in the weeks and
                months that follow. For example, an anterior cervical discectomy
                with fusion (ACDF) surgery involves the fusion of 2 or more
                vertebrae in the neck, which can alter how some neck and upper
                back muscles move. In such cases, physical therapy may help to
                work through stiffness, increase neck function, and reduce or
                prevent painful spasms as the muscles are reconditioned.
              </li>
            </ul>
            <br />
            Physical therapy for the neck may be recommended in other cases as
            well, such as part of a larger treatment program for other diseases
            or chronic conditions.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Neckpain;
