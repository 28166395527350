import React from "react";
import Footer from "./Footer";
import Header from "./navbar";

const Privacypolicy = () => {
  return (
    <div>
      <Header />
      <div className="header pt-5 mt-5">
        <h2 className="header-text-section">Privacy & Policy</h2>
      </div>

      <div className="container pb-2">
        <h4 className="pt-1">GENERAL</h4>
        <p>
          Healthonify Private Limited (“Healthonify”) is committed to the
          protection of personal information provided by the Customer, Business
          Partners, Professionals & other associate entity's and/or individuals
          to Healthonify. Customer, Business Partners, Professionals & other
          associate entity's and/or individuals agree that use of Healthonify
          Website and/or its mobile application, hereinafter referred to as the
          “Healthonify Platforms” shows their consent to the Healthonify Privacy
          Policy (“Privacy Policy”).
        </p>
        <p>
          Healthonify takes the privacy of Customer, Business Partners,
          Professionals & other associate entity's and/or individuals seriously.
          Healthonify is committed to safeguarding the privacy of Customer,
          Business Partners, Professionals & other associate entity's and/or
          individuals while providing its services through Healthonify
          Platforms, in a personalized and valuable manner.
        </p>
        <p>
          No information provided by the Customer, Business Partners,
          Professionals & other associate entity's and/or individuals to
          Healthonify is rented or sold to any third party. When a Customer,
          Business Partners, Professionals & other associate entity's and/or
          individuals uses the Healthonify Platforms, their IP address and other
          personal information may automatically get stored. A high standard of
          security is maintained by Healthonify for Customer, Business Partners,
          Professionals & other associate entity's and/or individuals. However,
          the transmission of information via the internet and web networks is
          not completely secure. While Healthonify does its best to protect
          Customer, Business Partners, Professionals & other associate entity's
          and/or individuals’ information, particularly with respect to
          protection of Customer, Business Partners, Professionals & other
          associate entity's and/or individuals’ personal data. Healthonify
          cannot ensure the security for data transmitted via the internet, web
          network or any other networks.
        </p>
        <p>
          Access to the contents of Healthonify is conditional upon Customer,
          Business Partners, Professionals & other associate entity's and/or
          individuals’ approval of the Privacy Policy which should be read
          together with the Terms and Conditions (“Terms”). Customer, Business
          Partners, Professionals & other associate entity's and/or individuals
          acknowledge that this Privacy Policy, together with Terms and
          Conditions, forms a legally binding agreement with Healthonify in
          relation to the use of Healthonify Platforms, (“Agreement”). All
          capitalized terms used but not defined herein shall have the meaning
          ascribed to such term in the Terms.
        </p>
        <p>
          Healthonify reserves the right to contact the customer via any
          mechanisms, not limited to Email, SMS, or phone calls, to solicit
          feedback of customer's experience and to provide any additional
          services that customer would be eligible for, either as a customer or
          as a visitor
        </p>
        <h5>1. Acceptance</h5>
        <p>
          1. By directly or indirectly accessing or making use of the services
          offered or made available by Healthonify Private Limited (the “
          <b>Vendor</b>”), including without limitation the Healthonify software
          as a service platform, and any website, application or widget
          associated therewith (the “<b>Services</b>”), and/or by clicking the
          acceptance button, you signify, represent and warrant that you are
          older than 18 years of age and that you have, and at all times shall
          have, the necessary power, capacity and authority to enter into, abide
          by, comply with and perform your obligations under this privacy policy
          (this “<b>Privacy </b>).
          <br />
          <br />
          2. The Vendor is committed to protecting the personal information of
          identifiable individuals and legal entities, disclosed or provided to
          the Vendor on or through the Services (the “
          <b>Personal Information</b>”), and this Privacy Policy outlines how
          the Vendor collects, uses and discloses such Personal Information.
          <br />
          <br />
          3. The Vendor reserves the right to, at its sole discretion, amend
          this Privacy Policy at any time and without notice, the most current
          version of which shall always be available at
          https://www.healthonify.com/Privacy-policy. You acknowledge and agree
          that your continued use of the Services, following any amendment of
          this Privacy Policy, shall signify your assent to, and acceptance of,
          such amended terms and conditions.
          <br />
          <br />
          4. If you do not agree to any term or condition of this Privacy
          Policy, or any subsequently amended term or condition thereof, you
          cannot use the Services.
        </p>
        <h5>2. Collection and Use of Personal Information </h5>
        <p>
          1. You expressly acknowledge, agree and consent to the collection and
          use of your Personal Information as specified in this Privacy Policy.
          The legal bases for our processing of Personal Information are
          primarily that the processing is necessary for providing the Services
          and that the processing is carried out in our legitimate interests,
          which are further explained below. Without limiting the generality of
          the foregoing, the Vendor may on occasion, in specific circumstances,
          ask you to consent when the Vendor intends to collect or use your
          Personal Information.
          <br />
          <br />
          2. In the event that you choose to register as a user of the Services,
          you will be asked to create a unique username and private password
          (collectively, the “Access ID”) which will be used to create and
          activate an account through which the Vendor can make the Services
          available to you (the “Account”). You expressly acknowledge and agree
          that certain information such as your name, address, telephone number,
          email address, billing information, financial information, and certain
          additional information may be required by the Vendor to make the
          Services available to you, or may be disclosed by you on or through
          the Services.
          <br />
          <br />
          3. We take steps designed to ensure that only those employees who need
          access to your Personal Information to fulfil their employment duties
          will have access to it. You expressly acknowledge, agree and consent
          to the Vendor collecting and using your Personal Information:
        </p>
        <ul>
          <li>to create and activate your Account;</li>
          <li>to respond to any request for customer service;</li>
          <li>
            to make the Services available to you and to other users of the
            Services;
          </li>
          <li>
            {" "}
            as necessary to allow the Vendor to provide you with access to and
            use of the added services, features and functionality of the
            Vendor’s partner organizations, as incorporated into and/or linked
            to the Services;
          </li>
          <li>
            to improve the quality of the Services, including without
            limitation, through polls, surveys and other similar feedback
            gathering activities conducted by the Vendor or any third-party;
          </li>
          <li>
            to assess service levels, monitor traffic patterns and gauge
            popularity of different features, functionalities and service
            options of, or related to, the Services;
          </li>
          <li>
            to create and manage your Account, and to verify access rights to
            the Services;
          </li>
          <li>
            to bill, or render invoices relating to, your Account, and to
            collect any debt owing to the Vendor, and to facilitate payments
            between you and other users of the Services as contemplated by the
            features and functionality of the Services;
          </li>
          <li>
            to communicate with you, including without limitation for the
            purpose of providing you with notifications, content, marketing
            materials, commercial electronic messages, and information about
            your Account and/or the Services;
          </li>
          <li>
            to install software relating to the Services, on one or more of your
            electronic devices;
          </li>
          <li>
            to protect against fraud or error, and to respond to claims of any
            violation of the Vendor’s rights or those of any third-party;
          </li>
          <li>
            to protect the rights, property or personal safety of yourself, the
            Vendor, the Vendor’s agents, the Vendor’s users and the public;
          </li>
          <li>
            to enforce this Privacy Policy, or any other agreement entered into
            between yourself and the Vendor;
          </li>
          <li>
            as required to comply with any applicable laws, or as authorized by
            any applicable laws; and
          </li>
          <li>
            for other purposes set out in this Privacy Policy, and for any other
            purpose the Vendor may disclose to you from time to time.
          </li>
        </ul>
        <p>
          4. You may at any point withdraw your consent to receive the
          communications set out in Section 2(c)(viii). The Vendor provides an
          “opt-out” function within all email communications of this nature,
          and/or will cease communications of this nature if you inform the
          Vendor that you would like to “opt-out”. You however expressly
          acknowledge and agree that you may not “opt-out” of communications
          related to the Services, your Account, a bill, an invoice, or where
          such withdrawal of consent would frustrate the performance of a legal
          obligation (but the Vendor will attempt to minimize the aforementioned
          communications).
        </p>
        <h5 className="pt-1">
          3. Users Uploaded Media or User Generated Content
        </h5>
        <p>
          1. For a better experience, while using our Service, we may require
          you to provide us with certain personally identifiable information,
          including Images, Videos and other media. The information that we
          request will be retained by us and used as described in this privacy
          policy. Uploading of media (images, videos etc.) is voluntary for the
          user. Media files of users are kept confidential and are not disclosed
          with any third-party services. Also, the app does use third-party
          services that may collect information used to identify you.
        </p>
        <p>
          2. Users posting their views or images or content via the “Community
          “feature of Healthonify App are hereby informed that their posts will
          be scrutinised by the administrator of Healthonify for any
          objectionable content and then only the content will be allowed to
          publish on the app for other user’s view. Healthonify Private Limited
          reserves the right to publish the content or reject the content.
        </p>
        <h5 className="pt-1">4. Disclosure of Personal Information</h5>
        <p>
          1. The Vendor will only disclose your Personal Information as set out
          in this Privacy Policy in accordance with your instructions, for the
          purposes set out in Section 2(c), and in any event, in accordance with
          applicable law. Without limiting the generality of the foregoing, you
          understand and agree that the purposes set out in Section 2(c) include
          the disclosure of your Personal Information to the Vendor’s partner
          organizations in connection with your use of the functionality of the
          Services. For example, if you utilize the meal planning functionality
          of the Services, your Personal Information may be shared with the
          Vendor’s partner organization that provides such meal planning service
          functionality. You expressly acknowledge and agree that your Personal
          Information disclosed by the Vendor to others hereunder may be subject
          to the terms of another privacy policy other than this Privacy Policy,
          and that the Vendor assumes no responsibility for the privacy policies
          or practices of any third-party.
          <br />
          <br />
          2. Your Personal Information may be stored and processed in any
          country where we have facilities or in which we engage third party
          service providers. By using the Services, you consent to the transfer
          of information to countries outside your country of residence, which
          may have different data protection rules than in your country. While
          such information is outside of India, it is subject to the laws of the
          country in which it is held, and may be subject to disclosure to the
          governments, courts or law enforcement or regulatory agencies of such
          other country, pursuant to the laws of such country. However, our
          practices regarding your Personal Information will at all times
          continue to be governed by this Privacy Policy.
          <br />
          <br />
          3. You expressly acknowledge, agree and consent to the disclosure of
          your Personal Information as specified in this Privacy Policy. When we
          disclose your Personal Information to third parties, we take
          reasonable measures to ensure that the rules set forth in this Privacy
          Policy are complied with and these third parties provide sufficient
          guarantees to implement appropriate technical and organizational
          measures.
          <br />
          <br />
          4. Without limiting the foregoing, you expressly acknowledge and agree
          that, for the purposes of billing your Account and facilitating
          payments between you and other users of the Services as contemplated
          by the features and functionality of the Services, in each case if
          applicable, the Vendor may disclose your Personal Information with its
          Payment Processing Services Provider (as defined in the Terms of
          Service), banks or other third-parties in order to process payments.
        </p>
        <h5 className="pt-5">5. Retention of your Personal Information </h5>
        <p>
          Your Personal Information will be retained for as long as may be
          necessary or relevant for the identified purpose of collection, or as
          may be required or permitted by law, after which time the Vendor will
          no longer retain such information in a form capable of identifying an
          individual or legal entity.
        </p>
        <br />
        <br />
        <b>1. Children's Privacy</b>
        <p>
          The Services are not directed to children under the age of 18, and we
          do not knowingly collect Personal Information from children under the
          age of 18 without obtaining parental consent. If you are under 18
          years of age, then please do not use or access the Service at any time
          or in any manner. If we learn that Personal Information has been
          collected on the Services from persons under 18 years of age and
          without verifiable parental consent, then we will take the appropriate
          steps to delete this information. If you are a parent or guardian and
          discover that your child under 18 years of age has provided Personal
          Information, then you may alert us as set forth in the "Contact The
          Vendor" section and request that we delete that child's Personal
          Information from our systems.
        </p>
        <br />
        <b>2. Rights to Your Information</b>
        <p>
          On written request and subject to proof of identity, you may access
          the Personal Information that we hold, used or communicated and ask
          that any necessary corrections be made, where applicable, as
          authorized or required by law. However, to make sure that the Personal
          Information we maintain about you is accurate and up to date, please
          inform us immediately of any change in your Personal Information by
          mail or email.
        </p>
        <br />
        <b>3. Location Information</b>
        <p>
          You expressly acknowledge, agree and consent to the Vendor collecting
          Personal Information concerning your location, from your IP address
          and other sources, for the purpose of matching the Vendor’s products
          and services to areas of demand and for other business purposes of the
          Vendor.
        </p>
        <br />
        <b>4. Aggregated Data</b>
        <p>
          You expressly acknowledge, agree and consent to the Vendor collecting,
          combining, manipulating and using your Personal Information to create
          generalized, anonymous information, stripped of data capable of
          identifying an individual or legal entity (the “Aggregated Data”), and
          using, disclosing and commercializing such Aggregated Data as the
          Vendor deems fit. Your identity and Personal Information will be kept
          anonymous in the Aggregated Data.
        </p>
        <br />
        <b>5. Software Installation, Cookies and Log Files</b>
        <p>
          You expressly acknowledge, agree and consent to the Vendor installing
          software and cookies relating to the Services on one or more of your
          electronic devices and using tracking tools, pixel tags, cookies and
          log files for purposes the Vendor deems fit, including without
          limitation, to track which page variants you have seen, to track if
          you have clicked on a page variant, to monitor traffic patterns, to
          gauge popularity of service options and to gather information used to
          deliver relevant content and services to you.
        </p>
        <br />
        <b>6. Change of Ownership or Business Transition</b>
        <p>
          You expressly acknowledge and agree that in the event of a change of
          ownership or other business transition, such as a merger, acquisition
          or sale of the Vendor’s assets, your Personal Information may be
          transferred in accordance with applicable privacy laws.
        </p>
        <br />
        <b>7. Withdrawing Consent</b>
        <p>
          {" "}
          You have the right to withdraw your consent relating to the
          collection, use or disclosure of your Personal Information at any
          time, except where such withdrawal would frustrate the performance of
          a legal obligation.
        </p>
        <br />

        <b> 8. Security </b>
        <br />
        <p>
          1. The Vendor will strive to prevent unauthorized access to your
          Personal Information and will periodically enhance its security aimed
          at ensuring that your Personal Information is kept safe from
          unauthorized access, use and disclosure.
          <br />
          2. While the Vendor has technology and procedures to guard your
          Personal Information against unauthorized access, use or disclosure,
          you expressly acknowledge and agree there is no guarantee that such
          technology or procedures can or will eliminate the risks of
          unauthorized access, use, disclosure, theft, loss or misuse.
          <br />
          3. The Vendor strongly recommends that you do not disclose your Access
          ID to anyone. If you forget your password, the Vendor will ask you for
          the username associated with your Access ID and will send an email
          containing your password to the email address associated with your
          Access ID.
          <br /> 4. Notwithstanding anything contained in this Privacy Policy,
          you expressly acknowledge and agree that ultimately, you control what
          Personal Information you provide while using the Services, and that
          you are responsible for maintaining the secrecy of your Personal
          Information and Access ID. <br />
          5. You expressly acknowledge and agree that the Vendor is not
          responsible for, and does not control, the use by others of any
          information which you provide to them and that you should use caution
          in selecting the Personal Information you provide to others through
          the Services.
          <br /> 6. If we learn of a security systems breach, we will inform you
          and the authorities of the occurrence of the breach in accordance with
          applicable laws.
        </p>
        <br />
        <b>9. Steps Data</b>
        <br />
        <p>
          1. Client Health Data from Apple Health App [Health Kit] and android pedometer
          .
          <br />
          2. The access is taken only for syncing or reading the step count, we do not write data to pedometer or Apple Health App.

          <br />
          3. Your steps data you choose to send from HealthKit is not transferred by the App to any third parties for marketing or advertising and not used by App to track the users in any way other than specified in this Privacy Policy. You can remove Apple HealthKit read access to App at any time inside the Apple Health app. We handle all iOS Health app and HealthKit data in accordance with Apple's development guidelines. To view Apple's HealthKit developer guidelines, go to https://developer.apple.com/app-store/review/guidelines/#health-and-health-research.
          <br /> 4. However, revoking these permissions may prevent you from using all or some of the functionality of our services.

        </p>
        <b>2. Governance</b>
        <br />
        <p>
          {" "}
          1. This Privacy Policy will be governed by the laws of the Maharashtra
          and the laws of India applicable therein, excluding any conflict of
          rules that would apply to another body of law. You hereby agree to
          submit to the exclusive jurisdiction of the courts in Mumbai,
          Maharashtra with respect to any claim, proceeding or action relating
          to or otherwise arising out of this Privacy Policy or your access to
          or use of the Services, howsoever arising, provided always that the
          Vendor may seek and obtain injunctive relief (or an equivalent type of
          urgent legal relief) in any jurisdiction.
          <br /> 2. This Privacy Policy together with any other agreement
          entered into between yourself and the Vendor which specifically
          references this Privacy Policy constitutes and contains the entire
          agreement between you and the Vendor with respect to its subject
          matter and supersedes any prior oral or written agreements.
          <br /> 3. Healthonify Platforms, include provision of an online
          platform for facilitating buying and selling of various Health and
          Wellness Services. The Health and Wellness Services made available
          through the Healthonify Platforms, for sale are sold on ‘as-is’ basis
          and Healthonify has no responsibility with respect to adequacy,
          relevancy or authenticity of such Health and Wellness Services.
          Healthonify is not responsible for any information provided by one
          Customer, Business Partners, Professionals & other associate entity's
          and/or individuals to others, in the course of Service and this
          Privacy Policy governs Healthonify relationship with the Customer,
          Business Partners, Professionals & other associate entity's and/or
          individuals only. <br />
          4. Contact The Vendor If you have any questions or comments about this
          Privacy Policy or your Personal Information, to make an access or
          correction request, to exercise any applicable rights, to make a
          complaint, or to obtain information about our policies and practices
          with respect to any service providers outside India, our Privacy
          Officer (or Data Protection Officer) can be reached by email using the
          following contact information: support@healthonify.com
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Privacypolicy;
