import React, { useState, useRef } from "react";
import TravelHeader from "./travel-navbar";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import hotel1 from "./../images/hotel/hotel1.jpg";
import hotel2 from "./../images/hotel/hotel2.jpg";
import hotel3 from "./../images/hotel/hotel3.jpg";
import hotel4 from "./../images/hotel/hotel4.jpg";
import hotel5 from "./../images/hotel/hotel5.jpg";
import hotel6 from "./../images/hotel/hotel6.jpg";
import hotel7 from "./../images/hotel/hotel7.jpg";
import { hotelDetail } from "../store/slices/hotel";
import { roomGroup } from "../store/slices/roomgroup";
import Travelfooter from "./Travelfooter";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { BsPlusCircleFill } from "react-icons/bs";
import Moment from "moment";
import { AiFillMinusCircle } from "react-icons/ai";
import {
  CalculateHotelPrice,
  PackageBooking,
  PaymentStore,
} from "./../store/slices/booking";

function HotelDetails() {
  const [open, setOpen] = useState(false);
  const [hotelName, sethotelName] = useState("");
  // GetHotelRoom Detials
  const [hotelgroup, setHotelgroup] = useState([]);
  const ref = useRef(null);

  const [adultCount, setadultCount] = useState(1);
  const [childrenCount, setchildrenCount] = useState(0);
  const [childrenCountWB, setchildrenCountWB] = useState(0);
  const [checkinDates, setcheckinDates] = useState("");
  const [checkoutDates, setcheckoutDates] = useState("");
  const [bookingIds, setbookingId] = useState("");
  const navigate = useNavigate("");
  let checkinDate = Moment(checkinDates).format("MM/DD/YYYY");
  let checkoutDate = Moment(checkoutDates).format("MM/DD/YYYY");
  const [grossAmount, setgrossAmount] = useState("");
  const [facilitatingFees, setfacilitatingFees] = useState("");
  const [reversalOnFf, setreversalOnFf] = useState("");
  const [basicRoomRate, setbasicRoomRate] = useState("");
  const [gstOnEffectivePrice, setgstOnEffectivePrice] = useState("");
  const [gstOnFf, setgstOnFf] = useState("");

  const [roomgroupname, setroomgroupName] = useState("");
  const [roomgroupId, setroomgroupId] = useState("");
  const [roomgroups, setroomGroups] = useState([]);
  const [hotelId, sethotelId] = useState("");
  const [rzpOrderId, setrzpOrderId] = useState("");
  const [pricingDetails, setpricingDetails] = useState("");
  const [bookedRooms, setbookedRooms] = useState([
    {
      roomGroupId: "6319bb25dac8063434f8fc78",
      adultCount: 0,
      childrenCount: 0,
      addOns: [],
    },
  ]);

  const handleAddForms = () => {
    let rooms = [...bookedRooms];
    rooms.push({
      roomGroupId: "6319bb25dac8063434f8fc78",
      adultCount: 0,
      childrenCount: 0,
      addOns: [],
    });
    setbookedRooms(rooms);
  };

  const handleDeleteForms = (e) => {
    if (bookedRooms.length > 1) {
      let rooms = [...bookedRooms];
      rooms.pop();
      setbookedRooms(rooms);
    }
  };
  const handleRowChange = (e, index) => {
    const rooms = [...bookedRooms];
    rooms[index][e.currentTarget.name] = e.currentTarget.value;
    setbookedRooms(rooms);
    // console.log(tourIternary);
  };

  const dispatch = useDispatch();

  const { id } = useParams();
  localStorage.setItem("id", id);
  localStorage.setItem("hotelId", id);

  React.useEffect(() => {
    dispatch(hotelDetail())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          sethotelId(data.user.data[0]._id);
          sethotelName(data.user.data[0].name);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(roomGroup())
      .unwrap()
      .then((data) => {
        setHotelgroup(data.user.data);
        setroomGroups(data.user.data);
        setroomgroupId(data.user.data[0]._id);
        setroomgroupName(data.user.data[0].name);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const IncNum_A = (index) => {
    const list = [...bookedRooms];
    list[index]["adultCount"] = bookedRooms[index].adultCount + 1;
    setbookedRooms(list);
  };

  const DecNum_A = (index) => {
    const list = [...bookedRooms];
    list[index]["adultCount"] = bookedRooms[index].adultCount - 1;
    setbookedRooms(list);
  };

  const IncNum_B = (index) => {
    const list = [...bookedRooms];
    list[index]["childrenCount"] = bookedRooms[index].childrenCount + 1;
    setbookedRooms(list);
  };

  const DecNum_B = (index) => {
    const list = [...bookedRooms];
    list[index]["childrenCount"] = bookedRooms[index].childrenCount - 1;
    setbookedRooms(list);
  };

  const IncNum_C = (index) => {
    const list = [...bookedRooms];
    list[index]["childWithoutBedCount"] =
      bookedRooms[index].childWithoutBedCount + 1;
    setbookedRooms(list);
  };

  const DecNum_C = (index) => {
    const list = [...bookedRooms];
    list[index]["childWithoutBedCount"] =
      bookedRooms[index].childWithoutBedCount - 1;
    setbookedRooms(list);
  };

  var serviceFee = +facilitatingFees + +gstOnFf;

  const calculate = () => {
    document.getElementById("hideshow").style.height = "340px";
  };

  async function save(e) {
    document.getElementById("book").style.height = "270px";
    e.preventDefault();
    let item = {
      hotelId,
      bookedRooms,
      checkoutDate,
      checkinDate,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(CalculateHotelPrice(item))
      .unwrap()
      .then((data) => {
        setgrossAmount(data.user.data.totalCharge);
        setfacilitatingFees(data.user.data.facilitatingFees);
        setreversalOnFf(data.user.data.reversalOnFf);
        setbasicRoomRate(data.user.data.grossAmount);
        setpricingDetails(data.user.data);
        setgstOnEffectivePrice(data.user.data.gstOnEffectivePrice);
        setgstOnFf(data.user.data.gstOnFf);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }

  async function payNow(e) {
    const user = localStorage.getItem("user");

    if (user) {
      let data = JSON.parse(localStorage.getItem("user"));
      let userId = data.data.id;
      let userName = data.data.firstName + " " + data.data.lastName;
      let userType = "loggedin";
      let userEmail = data.data.email;
      let userMobileNo = data.data.mobileNo;
      let startTime = "13:30:00";
      let startDate = checkinDates;
      let endDate = "12/15/2022";
      e.preventDefault();
      let item = {
        hotelId,
        userId,
        userName,
        userType,
        userEmail,
        userMobileNo,
        startTime,
        startDate,
        endDate,
        adultCount,
        childrenCount,
        pricingDetails,
        setchildrenCountWB,
      };
      localStorage.setItem("item", JSON.stringify(item));

      dispatch(PackageBooking(item))
        .unwrap()
        .then((data) => {
          setrzpOrderId(data.user.data.paymentData.rzpOrderId);
          setbookingId(data.user.data.bookingId);
        })
        .catch(({ message }) => {
          // alert(message);
        });

      let options = {
        key: "rzp_test_ZyEGUT3SkQbtE6",
        amount: grossAmount * 100, // 2000 paise = INR 20, amount in paisa
        name: "Helathonify",
        description: "Travel Package",
        image: "https://healthonify.com/uploads/frontend/images/logo.png",
        order_id: rzpOrderId,
        handler: function (response) {
          let item = {
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: rzpOrderId,
            razorpay_payment_id: response.razorpay_payment_id,
            bookingId: bookingIds,
          };
          localStorage.setItem("item", JSON.stringify(item));
          dispatch(PaymentStore(item))
            .unwrap()
            .then((data) => {
              if (data.user.status === 0) {
                alert(data.user.message);
              } else if (data.user.status === 1) {
                alert(data.user.message);
                navigate("/travelonify/Thanks");
              }
            })
            .catch(({ message }) => {
              alert(message);
            });
        },

        prefill: {
          name: userName,
          email: userEmail,
          mobile: userMobileNo,
        },
        notes: {
          address: "Bangalore",
        },
        theme: {
          color: "#F37254",
        },
      };

      let rzp1 = new window.Razorpay(options);
      rzp1.open();
    } else {
      navigate("/travelonify/TravelSignin");
    }
  }

  return (
    <div id="_next">
      <TravelHeader />

      <section className="form container  pt-5 mt-5 ">
        <h3 className="pt-5  pb-3">{hotelName}</h3>
        <Gallery>
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <Item
                original={hotel1}
                thumbnail={hotel1}
                width="1024"
                height="600"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    alt=""
                    className="img-room-main corner-right"
                    src={hotel1}
                  />
                )}
              </Item>
            </div>

            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-xs-6 pt-1 pb-3">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Item
                      original={hotel2}
                      thumbnail={hotel2}
                      width="1024"
                      height="600"
                    >
                      {({ ref, open }) => (
                        <img
                          ref={ref}
                          onClick={open}
                          className="img-room-group"
                          src={hotel2}
                        />
                      )}
                    </Item>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-xs-6 pt-1 pb-3">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Item
                      original={hotel3}
                      thumbnail={hotel3}
                      width="1024"
                      height="600"
                    >
                      {({ ref, open }) => (
                        <img
                          ref={ref}
                          onClick={open}
                          alt=""
                          className="img-room-group"
                          src={hotel3}
                        />
                      )}
                    </Item>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-xs-6 pt-1 pb-3">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Item
                      original={hotel4}
                      thumbnail={hotel4}
                      width="1024"
                      height="600"
                    >
                      {({ ref, open }) => (
                        <img
                          ref={ref}
                          alt=""
                          onClick={open}
                          className="img-room-group corner-top"
                          src={hotel4}
                        />
                      )}
                    </Item>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-xs-6 pt-1 pb-3">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Item
                      original={hotel5}
                      thumbnail={hotel5}
                      width="1024"
                      height="600"
                    >
                      {({ ref, open }) => (
                        <img
                          ref={ref}
                          onClick={open}
                          className="img-room-group "
                          src={hotel5}
                          alt=""
                        />
                      )}
                    </Item>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-xs-6 pt-1 pb-3">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Item
                      original={hotel6}
                      thumbnail={hotel6}
                      width="1024"
                      height="600"
                    >
                      {({ ref, open }) => (
                        <img
                          ref={ref}
                          onClick={open}
                          className="img-room-group"
                          src={hotel6}
                          alt=""
                        />
                      )}
                    </Item>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-xs-6 pt-1 pb-3">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Item
                      original={hotel7}
                      thumbnail={hotel7}
                      width="1024"
                      height="600"
                    >
                      {({ ref, open }) => (
                        <img
                          ref={ref}
                          onClick={open}
                          alt=""
                          className="img-room-group corner-bottom"
                          src={hotel7}
                        />
                      )}
                    </Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Gallery>
      </section>

      {/* <section className="form container pt-5 pb-5 ">
        <div className="col-lg-14 col-md-12 border">
          <div className="row">
            <div className="col-lg-2 col-md-3">
              <p>OverView</p>
            </div>
            <div className="col-lg-2 col-md-3">
              <p>Rooms</p>
            </div>
            <div className="col-lg-2 col-md-3">
              <p>Facilities</p>
            </div>
            <div className="col-lg-2 col-md-3">
              <p>Reviews</p>
            </div>
            <div className="col-lg-2 col-md-3">
              <p>Location</p>
            </div>
            <div className="col-lg-2 col-md-3">
              <p>Policies</p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="form container   ">
        <div className="col-lg-14 col-md-12 border">
          <h3 className="pt-4 pb-4" style={{ color: "#001E79" }}>
            HighLights
          </h3>
          <div className="row mt-3">
            <div className="col-lg-2-5row col-md-3">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                <img src="https://img.icons8.com/fluency/48/undefined/door.png" />
              </div>
              <p className="text-center heightlight-text">
                Front desk [24-hour]
              </p>
            </div>
            <div className="col-lg-2-5row col-md-3">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                <img src="https://img.icons8.com/fluency/48/undefined/car.png" />
              </div>
              <p className="text-center heightlight-text">Airport transfer </p>
            </div>
            <div className="col-lg-2-5row col-md-3">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                <img src="https://img.icons8.com/color/48/undefined/wifi--v1.png" />
              </div>
              <p className="text-center heightlight-text">
                Free Wi-Fi in all rooms!
              </p>
            </div>
            <div className="col-lg-2-5row col-md-3">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                <img src="https://img.icons8.com/fluency/48/undefined/hot-springs.png" />
              </div>
              <p className="text-center heightlight-text">Hot tub</p>
            </div>
            <div className="col-lg-2-5row col-md-3">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                <img src="https://img.icons8.com/fluency/48/undefined/laptop.png" />
              </div>
              <p className="text-center heightlight-text">
                Remote work friendly
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="form container pt-5 pb-5 ">
        <div className="col-lg-14 col-md-12 border">
          <div>
            The car parking and the Wi-Fi are always free, so you can stay in
            touch and come and go as you please. Conveniently situated in the
            Bandra part of Mumbai, this property puts you close to attractions
            and interesting dining options. Don't leave before paying a visit to
            the famous Gateway of India. Rated with 4.5 stars, this high-quality
            property provides guests with access to massage, restaurant and hot
            tub on-site
            <span
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="gross"
            >
              Show More ....
            </span>
          </div>

          <Collapse in={open}>
            <div id="example-collapse-text">
              The car parking and the Wi-Fi are always free, so you can stay in
              touch and come and go as you please. Conveniently situated in the
              Bandra part of Mumbai, this property puts you close to attractions
              and interesting dining options. Don't leave before paying a visit
              to the famous Gateway of India. Rated with 4.5 stars, this
              high-quality property provides guests with access to massage,
              restaurant and hot tub on-site.
            </div>
          </Collapse>
        </div>
      </section>

      <section className="form container  pb-5 ">
        <div className="col-lg-14 col-md-12 border">
          <h4 style={{ color: "#001E79" }}>Facilities</h4>
          <div className="row mt-3">
            <div className="col-lg-3 col-md-3 d-flex justify-content-center pt-2 pb-3">
              <i className="fa fa-check"> &nbsp; Airport transfer</i>
            </div>
            <div className="col-lg-3 col-md-3 d-flex justify-content-center pt-2 pb-3">
              <i className="fa fa-check"> &nbsp; Valet parking</i>
            </div>
            <div className="col-lg-3 col-md-3 d-flex justify-content-center pt-2 pb-3">
              <i className="fa fa-check">&nbsp; Shuttle service</i>
            </div>
            <div className="col-lg-3 col-md-3 d-flex justify-content-center pt-2 pb-3">
              <i className="fa fa-check"> &nbsp; Free Wi-Fi in all rooms!</i>
            </div>
            <div className="col-lg-3 col-md-3 d-flex justify-content-center pt-2 pb-3">
              <i className="fa fa-check"> &nbsp; Fitness Center</i>
            </div>
            <div className="col-lg-3 col-md-3 d-flex justify-content-center pt-2 pb-3">
              <i className="fa fa-check">&nbsp; Hot tub</i>
            </div>
            <div className="col-lg-3 col-md-3  d-flex justify-content-center pt-2 pb-3">
              <i className="fa fa-check">&nbsp; Front desk [24-hour]</i>
            </div>
            <div className="col-lg-3 col-md-3  d-flex justify-content-center pt-2 pb-3">
              <i className="fa fa-check">&nbsp; Check-in/out [express]</i>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <h3>Select Your Room</h3>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {hotelgroup.map((hotelVal, index) => (
              <div className="row border">
                <h5>{hotelVal.name}</h5>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <img src={hotel5} className="full-image" alt="" />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h4>Facilities Details</h4>
                  <p>{hotelVal.description}</p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  {/* <div className="d-flex justify-content-evenly"> */}
                  <h4>Price</h4>
                  <p>Rs. {hotelVal.twinShareRate}</p>
                  <button
                    type="button"
                    className="btn btn-blue col-white "
                    onClick={calculate}
                  >
                    Buy Now
                  </button>
                  {/* </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <br />
      <section className="container d-hide-show" id="hideshow" ref={ref}>
        <form onSubmit={save}>
          <div className="row booking">
            <div className="row ">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="row">
                  <input
                    value={hotelId}
                    onChange={(e) => sethotelId(e.target.value)}
                    type="hidden"
                  />
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-warning col-white adjust-btn"
                      onClick={(e) => handleAddForms(e)}
                      
                    >
                      <i className="fa fa-plus-circle" /> Add
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="button"
                      className="btn btn-blue col-white adjust-btn"
                      onClick={(e) => handleDeleteForms(e)}
                    >
                      <i className="fa fa-minus-circle" /> Remove
                    </button>
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  {bookedRooms.map((hotel, index) => (
                    <div className="overflow-control">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        key={index}
                      >
                        <div className="sec-1">
                          <p className="" style={{ width: "max-content" }}>
                            No. of travellers
                          </p>
                        </div>

                        <div className="sec-1">
                          <p className="sec-text">Adult</p>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                              <AiFillMinusCircle
                              style={{borderRadius:"5px 0px 0px 5px"}}

                                className="logic-icon"
                                onClick={() => DecNum_A(index)}
                              />
                            </div>
                            <input
                              type="text"
                              className="num-form-input"
                              value={bookedRooms[index].adultCount}
                              onChange={(e) => handleRowChange(e, index)}
                            />
                            <div className="">
                              <BsPlusCircleFill
                              style={{borderRadius:"0px 5px 5px 0px"}}

                                className="logic-icon"
                                onClick={() => IncNum_A(index)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="sec-1">
                          <p className="sec-text">Child (With bed)</p>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                              <AiFillMinusCircle
                              style={{borderRadius:"5px 0px 0px 5px"}}

                                className="logic-icon"
                                onClick={() => DecNum_B(index)}
                              />
                            </div>
                            <input
                              type="text"
                              className="num-form-input"
                              value={bookedRooms[index].childrenCount}
                              onChange={(e) => handleRowChange(e, index)}
                            />

                            <div className="">
                              <BsPlusCircleFill
                              style={{borderRadius:"0px 5px 5px 0px"}}

                                className="logic-icon"
                                onClick={() => IncNum_B(index)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="sec-1">
                          <p className="sec-text">Child (Without bed)</p>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                              <AiFillMinusCircle
                              style={{borderRadius:"5px 0px 0px 5px"}}

                                className="logic-icon"
                                onClick={() => DecNum_C(index)}
                              />
                            </div>
                            <input
                              type="text"
                              className="num-form-input"
                              value={bookedRooms[index].childWithoutBedCount}
                              onChange={(e) =>
                                setchildrenCountWB(e.target.value)
                              }
                            />
                            <div className="">
                              <BsPlusCircleFill
                              style={{borderRadius:"0px 5px 5px 0px"}}

                                className="logic-icon"
                                onClick={() => IncNum_C(index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <p className="inline bok-text">Checkin Date</p>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setcheckinDates(e.target.value)}
                        vaue={checkinDates}
                        required
                      />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <p className="inline bok-text">Checkout Date</p>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setcheckoutDates(e.target.value)}
                        vaue={checkoutDates}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 pt-5 d-flex justify-content-center">
                      <button type="submit" className="btn btn-blue col-white">
                        Calculate Package Price
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center">
                <div
                  className="col-lg-12 col-10 book-now"
                  id="book"
                  align="left"
                >
                  <div className="space-t">
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <p style={{ color: "white" }}>Room Cost:</p>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{basicRoomRate}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <p style={{ color: "white" }}>GST:</p>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{gstOnEffectivePrice}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <p style={{ color: "white" }}>Service Fee:</p>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{serviceFee.toFixed(2)}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <p style={{ color: "white" }}>Rev. of service fee:</p>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{reversalOnFf}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7 col-7">
                        <b style={{ color: "white" }}>Total price:</b>
                      </div>
                      <div className="col-md-5 col-5" align="center">
                        <span className="navig">{grossAmount}</span>
                      </div>
                    </div>
                  </div>
                  <div align="center">
                    <button
                      onClick={payNow}
                      type="button"
                      style={{ background: "white", width: "80%" }}
                      className="btn btn-default"
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      <br />
      <Travelfooter />
    </div>
  );
}

export default HotelDetails;
