import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import { AxiosSetup } from "./utils/helpers/api.helpers";
import { BrowserRouter } from "react-router-dom";


AxiosSetup();

ReactDOM.render( <
    BrowserRouter >

    <Provider store = { store } >
    <App / >
    </Provider>

    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();