import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class Hotel {
  

    static hotels() {
      let api = '';
        // let id = localStorage.getItem("id"); 
          api = API_PATHS.getHotel + '?isApproved=true';
       
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static hotelDetails() {
      let api = '';
        let id = localStorage.getItem("id"); 
        api = API_PATHS.getHotel + '?_id=' + id;
        localStorage.removeItem("id");
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static searchHotel() {
      let api = '';
      let id = localStorage.getItem("id"); 
        api = API_PATHS.searchotel + "?query=" + id + '&isApproved=true';
      localStorage.removeItem("id");
      return axios
        .get(api)
      .then((response) => {
        if (response.data) {
         
        }
        return response.data;
      });
  }

  static searchHotels() {
    let api = '';
    let item = JSON.parse(localStorage.getItem("item"));
 
      api = API_PATHS.searchotel + "?query=" + item.query + '&isApproved=true';
    localStorage.removeItem("id");
    return axios
      .get(api)
    .then((response) => {
      if (response.data) {
       
      }
      return response.data;
    });
}


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default Hotel;