import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Footer from "./Footer";
import Navbar from "./navbar";
const Women = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };
  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Women Physiotherapy</h3>
        </div>

        <div className="pt-4">
          <h3 className="col-head-color-p">Introduction</h3>
          <p>
            Women’s health physiotherapy is a non-surgical treatment used for a
            number of women’s health problems. Women’s health physiotherapy
            treats a wide range of women’s needs in addition to pregnancy and
            birth support. Due to hormones, structural makeup, pregnancy and
            childbirth, women require specific advice when it comes to exercise,
            flexibility, and dealing with aches and pains in the body.
            <br />
            <br />
            Women’s health physiotherapy helps treat women’s health issues such
            as incontinence, pelvic/ vaginal pain, prenatal and postpartum
            musculoskeletal pain, osteoporosis, rehabilitation following breast
            surgery, lymphedema, education prevention, wellness and exercise and
            so much more.
            <br />
            <br />
            Women’s health physiotherapists can tailor an exercise plan to help
            you improve a variety of symptoms caused by women’s health concerns.
            For example, bone density naturally decreases during menopause. So,
            a physiotherapist can help you strengthen your bones and improve
            your bone health through exercise.
            <br />
            <br />
            Additionally, strengthening your pelvic floor, especially during and
            after pregnancy, is a huge component of women’s health
            physiotherapy. Pregnancy and childbirth obviously put a lot of
            stress on your pelvic floor and it’s essential to strengthen this
            area as much as possible. It’ll not only help you during childbirth
            but also can help prevent incontinence, prolapse and other pelvic
            floor related issues.
          </p>
          <br />
          <h3 className="col-head-color-p">Treatment Conditions </h3>
          <p>
            Pregnancy-related pain
            <br />
            Relaxation and mindfulness exercises to aid in childbirth
            <br />
            Abdominal separation due to pregnancy
            <br />
            Pelvic floor strengthening during and after pregnancy
            <br />
            Caesarean/Perineal scar management
            <br />
            Bone density concerns related to menopause
            <br />
            Pelvic girdle instability or pain
            <br />
            Urinary or fecal incontinence
            <br />
            Pelvic floor prolapse due to childbirth
            <br />
            Pelvic pain or pain related to sexual intercourse
            <br />
            Overactive bladders
            <br />
            Urinary urgency
            <br />
            Constipation
            <br />
            Antenatal and postnatal exercise
            <br />
            Antenatal and postnatal Pilates
            <br />
            Antenatal massage
            <br />
            Mastitis or Blocked Milk Ducts
            <br />
            Electronic Muscle Stimulation (EMS)
          </p>
          <br />
          <b>When should you see a physio when pregnant?</b>
          <p>
            You can see a physio at any point once you become pregnant.
            Especially if you want to exercise safely and learn what you can do
            to prepare your body for childbirth, it’s never too early to work
            with a women’s health physiotherapist once you fall pregnant.
            <br />
            It’s particularly beneficial to see a women’s health physio if you
            start to feel any pain or discomfort in your pelvic girdle, lower
            back, ribs or hips during your pregnancy
          </p>
          <b>When to consult Women’s Health Physiotherapy?</b>
          <p>
            Generally, if you notice any unfamiliar aches and pains, it’s best
            to see a women’s health physio as soon as possible to help you work
            out your options.
            <br />
            Many women will continue to see their physiotherapists even after
            giving birth to help them with any post-natal issues that many, if
            not all women face after giving birth. These issues could be as
            simple as wanting to get back into an exercise routine safely or as
            important as dealing with extreme abdominal separation and prolapse.
          </p>
          <b>What does a pregnancy physiotherapist do?</b>
          <p>
            A pregnancy or women’s health physiotherapist can help pregnant
            women both during and after pregnancy with antenatal, postnatal, and
            prolapse physiotherapy.
          </p>
          <br />
          <p>
            <b>• Antenatal Physiotherapy</b> <br />
            Antenatal (or prenatal) physiotherapy includes the following
            physiotherapy treatments for women during their pregnancy.Childbirth
            education including birthing classes, relaxation techniques, and
            stretches to help during childbirth.
            <br /> How to exercise safely during pregnancy through gentle
            workouts like yoga and Pilates. Women’s health physiotherapists can
            also offer advice on whether you should stop exercising during
            pregnancy and how you can safely work out during each trimester.
            <br />
            Treatment of pelvic girdle pain by strengthening the pelvic floor
            and making postural adjustments.
          </p>
          <p>
            <b>• Postnatal Physiotherapy</b>
            <br /> Postnatal physiotherapy includes the following physiotherapy
            treatments for women after they’ve given birth.Rectus diastasis
            (abdominal separation) treatment to rebuild the abdominal wall and
            improve core activation post-pregnancy.Re-strengthening the pelvic
            floor which will always be an issue after giving birth no matter the
            circumstances.Returning to exercise post-pregnancy to enjoy a
            healthy and fit lifestyle safely.Caesarean scar treatment and
            connective tissue therapy to help women recover from a C-section
            during childbirth.Adjusting to postural changes due to larger
            breasts, changing weight distributions, and breastfeeding positions.
          </p>
          <p>
            <b>• Prolapse Physiotherapy</b> <br />
            Prolapse occurs when the pelvic floor separates from the uterus and
            drops a significant amount after childbirth.Women’s health
            physiotherapists can help women experiencing prolapse in the
            following ways.Fitting pessaries which are inserted into the vagina
            to help support your pelvic floor. Pessaries are removable and can
            be inserted and removed on your own with the instruction of your
            physio.
            <br /> Supplementation recommendations to boost your estrogen levels
            and rebalance your hormones.Surgery recommendations are rarely
            given, however, they are certainly a viable option that your
            physiotherapist can go through with you.
          </p>
          <p>
            <b>At what month can a pregnant woman start exercising?:</b> <br />
            For many pregnant women, you can continue to exercise throughout
            your entire pregnancy, leading right up to giving birth. However,
            the types of exercises you should do may vary. In the first
            trimester, most women can continue their normal exercise routines.
            However, if your current workout routine allows for the possibility
            of being hit or falling (such as rugby, horseback riding, or rock
            climbing), you’ll want to try other exercises that are just as
            active but less risky for your baby.
            <br />
            <br />
            During your second trimester, if you’re still feeling up to it, you
            can certainly continue exercising. However, keep in mind, you’ll be
            carrying a bit more weight at this point and you may want to switch
            to more low-impact exercises like swimming, walking, or prenatal
            Pilates. It’s also not recommended to do workouts that require you
            to lie on your back for long periods of time. It could cut off your
            circulation causing you to feel dizzy and unwell.
            <br />
            <br />
            In the third trimester, you can continue to exercise if you feel
            comfortable all the way up to the birth of your child. But, again,
            you’ll be carrying a lot more weight now which can cause unnecessary
            injuries along with achy muscles and joints. Plus, you’ll be using a
            lot of your energy towards your growing baby, so don’t be alarmed if
            you don’t feel as motivated to work out as much as you did before
            getting pregnant. In the third trimester, it’s generally advised to
            avoid running and jumping as well since this can cause additional
            pressure to your hips and pelvic floor, creating issues later on.In
            most cases, it’s safe and, in fact, quite important to stay active
            throughout your pregnancy as much as possible. After all, labour
            gets its name for a reason. Your body will need to be strong and
            flexible to ensure the smoothest birth possible.
            <br />
            <br />
            But with that being said, the best way to ensure your exercise
            routine is safe for your pregnancy is by working with a women’s
            health physiotherapist. Based on your unique body and circumstances,
            your physio can offer a personalised exercise plan that strengthens
            your pelvic floor, prepares your body for labour, and helps you feel
            your best throughout your pregnancy.Then, after you give birth, you
            can continue to work with your physio to help your body adjust to
            motherhood and your post-natal body. If you’re struggling with pain
            in your pelvic area or post-natal issues, or you simply need help
            with your pregnancy and post-pregnancy exercise routine, please get
            in touch with one of our expert women’s health physiotherapists at
            Healthonify.
            <br />
            <br />
            We’re here to help women feel empowered in their bodies whether
            you’re pregnant, you’ve recently given birth, you’re experiencing
            menopause, and anywhere in between.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Women;
