import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import banner1 from "./../images/fitness/banner1.png";
import banner2 from "./../images/fitness/banner2.png";
import banner3 from "./../images/fitness/banner3.png";
import banner4 from "./../images/fitness/banner4.png";
import { TravelEnquiry } from "../store/slices/enquiry";
import { getblog } from "../store/slices/blog";
import coach from "./../images/homepage/icons/coach.png";
import appease from "./../images/homepage/icons/app-ease.png";
import scientfic from "./../images/homepage/icons/scientfic.png";
import results from "./../images/homepage/icons/results.png";
import fitnessplan from "./../images/homepage/icons/fitnessplan.png";
import personaltarining from "./../images/fitness/personal-training.jpg";
import Moment from "moment";
// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Footer from "./Footer";

import Header from "./navbar";

import { WmEnquiry } from "../store/slices/enquiry";

const Personaltraining = () => {
  const dispatch = useDispatch();

  // Fitness Enquire form

  const [gender, setGender] = useState("");
  const [profession, setProfession] = useState("");
  const [healthConditions, setHealthConditions] = useState("");
  const [prefferedLanguage, setPrefferedLanguage] = useState("");
  const [age, setAge] = useState("");
  const [blogs, setBlogs] = useState([]);

  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [alertname2, setAlertname2] = useState("");
  const [alertemail2, setAlertemail2] = useState("");
  const [alertprofession2, setAlertprofession2] = useState("");
  const [alerthealthcondition2, setAlerthealthcondition2] = useState("");
  const [alertprefferedlanguage2, setAlertprefferedlanguage2] = useState("");
  const [alertmessage2, setAlertmessage2] = useState("");
  const [alertcontactnumber2, setAlertcontactnumber2] = useState("");
  const [alertage2, setAlertage2] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [alertgender2, setalertGender2] = useState("");

  const [errorname1, seterrorName1] = useState("");
  const [erroremail1, seterrorEmail1] = useState("");
  const [errorcontactnumber1, seterrorcontactNumber1] = useState("");
  const [errormessage1, seterrorMessage1] = useState("");
  const [errorcity1, seterrorCity1] = useState("");
  const [errordate1, seterrorDate1] = useState("");

  const [errorname2, seterrorName2] = useState("");
  const [erroremail2, seterrorEmail2] = useState("");
  const [errorcontactnumber2, seterrorcontactNumber2] = useState("");
  const [errormessage2, seterrorMessage2] = useState("");
  const [errorcity2, seterrorCity2] = useState("");
  const [errordate2, seterrorDate2] = useState("");

  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setGender(target.value);
    }
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }
  React.useEffect(() => {
    dispatch(getblog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const weightform = (e) => {
    e.preventDefault();
    let enquiryFor = "personal-trainer";
    let role = "client";

    let item = {
      name,
      email,
      profession,
      healthConditions,
      prefferedLanguage,
      message,
      contactNumber,
      enquiryFor,
      gender,
      age,
      role,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(WmEnquiry(item))
      .unwrap()
      .then(() => {
        setEmail("");
        setName("");
        setContactNumber("");
        setMessage("");
        setProfession("");
        setAge("");
        setHealthConditions("");
        setPrefferedLanguage("");
        setGender("");
        setAge("");
        alert("Enquiry submitted succesfully! We will get back to you.");
        document.getElementById("freeconsultation").style.display = "none";
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const fitnessform1 = (e) => {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let category = "Fitness";

    let item = {
      name,
      email,
      message,
      contactNumber,
      enquiryFor,
      category,
      date,
      city,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        setEmail("");
        setName("");
        setContactNumber("");
        setMessage("");
        setProfession("");
        setAge("");
        setCity("");
        setHealthConditions("");
        setPrefferedLanguage("");
        setGender("");
        setDate("");
        alert("Enquiry submitted succesfully! We will get back to you.");
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const fitnessform2 = (e) => {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let category = "Fitness";
    let message = "";
    let item = {
      name,
      email,
      contactNumber,
      enquiryFor,
      category,
      date,
      message,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        setEmail("");
        setName("");
        setContactNumber("");
        setDate("");
        alert("Enquiry submitted succesfully! We will get back to you.");
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const freeconsultation = (e) => {
    const regxname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxname.test(name)) {
      setAlertname2("");
    } else if (!regxname.test(name) && name === "") {
      setAlertname2("Please enter your name");
      e.preventDefault();
    } else {
      setAlertname2("");
    }

    if (gender === "") {
      setalertGender2("Please select");
      e.preventDefault();
    } else {
      setalertGender2("");
    }

    const regxCondition = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxCondition.test(healthConditions)) {
      setAlerthealthcondition2("");
    } else if (
      !regxCondition.test(healthConditions) &&
      healthConditions === ""
    ) {
      setAlerthealthcondition2("Please select ");
      e.preventDefault();
    } else {
      setAlerthealthcondition2("");
    }

    const regxProfession = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxProfession.test(profession)) {
      setAlertprofession2("");
    } else if (!regxProfession.test(profession) && profession === "") {
      setAlertprofession2("Please select ");
      e.preventDefault();
    } else {
      setAlertprofession2("");
    }

    const regxLanguage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxLanguage.test(prefferedLanguage)) {
      setAlertprefferedlanguage2("");
    } else if (
      !regxLanguage.test(prefferedLanguage) &&
      prefferedLanguage === ""
    ) {
      setAlertprefferedlanguage2("Please select");
      e.preventDefault();
    } else {
      setAlertprefferedlanguage2("");
    }

    const regxemail =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regxemail.test(email)) {
      setAlertemail2("");
    } else if (!regxemail.test(email) && email === "") {
      setAlertemail2("Please enter the email");
      e.preventDefault();
    } else {
      setAlertemail2("Please enter a valid email");
      e.preventDefault();
    }

    const regxmobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regxmobile.test(contactNumber)) {
      setAlertcontactnumber2("");
    } else if (!regxmobile.test(contactNumber) && contactNumber === "") {
      setAlertcontactnumber2("Please enter the contact number");
      e.preventDefault();
    } else {
      setAlertcontactnumber2("Please enter a valid contact number");
      e.preventDefault();
    }

    const regxMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxMessage.test(message)) {
      setAlertmessage2("");
    } else if (!regxMessage.test(message) && message === "") {
      setAlertmessage2("Please enter the message");
      e.preventDefault();
    } else {
      setAlertmessage2("");
    }

    const regxage = /^(1[89]|[2-9]\d)$/;
    if (regxage.test(age)) {
      setAlertage2("");
    } else if (!regxage.test(age) && age === "") {
      setAlertage2("Please enter  your age");
      e.preventDefault();
    } else {
      setAlertage2("");
    }
  };
  const fitnessconsultation2 = (e) => {
    const regxname2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxname2.test(name)) {
      seterrorName2("");
    } else if (!regxname2.test(name) && name === "") {
      seterrorName2("Please enter your name");
      e.preventDefault();
    } else {
      seterrorName2("");
    }

    const vDate2 =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate2.test(date)) {
      seterrorDate2("");
    } else if (!vDate2.test(date) && date === "") {
      seterrorDate2("Please enter your date of consultation");
      e.preventDefault();
    } else {
      seterrorDate2("");
    }

    const regxemail2 =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regxemail2.test(email)) {
      seterrorEmail2("");
    } else if (!regxemail2.test(email) && email === "") {
      seterrorEmail2("Please enter the email");
      e.preventDefault();
    } else {
      seterrorEmail2("Please enter a valid email");
      e.preventDefault();
    }

    const regxmobile2 = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regxmobile2.test(contactNumber)) {
      seterrorcontactNumber2("");
    } else if (!regxmobile2.test(contactNumber) && contactNumber === "") {
      seterrorcontactNumber2("Please enter the contact number");
      e.preventDefault();
    } else {
      seterrorcontactNumber2("Please enter a valid contact number");
      e.preventDefault();
    }
  };
  const fitnessconsultation1 = (e) => {
    const regxname1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxname1.test(name)) {
      seterrorName1("");
    } else if (!regxname1.test(name) && name === "") {
      seterrorName1("Please enter your name");
      e.preventDefault();
    } else {
      seterrorName1("");
    }

    const vDate1 =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate1.test(date)) {
      seterrorDate1("");
    } else if (!vDate1.test(date) && date === "") {
      seterrorDate1("Please enter your date of consultation");
      e.preventDefault();
    } else {
      seterrorDate1("");
    }

    const regxemail1 =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regxemail1.test(email)) {
      seterrorEmail1("");
    } else if (!regxemail1.test(email) && email === "") {
      seterrorEmail1("Please enter the email");
      e.preventDefault();
    } else {
      seterrorEmail1("Please enter a valid email");
      e.preventDefault();
    }

    const regxmobile1 = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regxmobile1.test(contactNumber)) {
      seterrorcontactNumber1("");
    } else if (!regxmobile1.test(contactNumber) && contactNumber === "") {
      seterrorcontactNumber1("Please enter the contact number");
      e.preventDefault();
    } else {
      seterrorcontactNumber1("Please enter a valid contact number");
      e.preventDefault();
    }

    const regxMessage1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxMessage1.test(message)) {
      seterrorMessage1("");
    } else if (!regxMessage1.test(message) && message === "") {
      seterrorMessage1("Please enter the message");
      e.preventDefault();
    } else {
      seterrorMessage1("");
    }

    const regxcity1 = /^(1[89]|[2-9]\d)$/;
    if (regxcity1.test(city)) {
      seterrorCity1("");
    } else if (!regxcity1.test(city) && city === "") {
      seterrorCity1("Please enter  your city");
      e.preventDefault();
    } else {
      seterrorCity1("");
    }
  };

  const formclear = () => {
    setName("");
    setEmail("");
    setAge("");
    setProfession("");
    setHealthConditions("");
    setPrefferedLanguage("");
    setMessage("");
    setContactNumber("");

    setAlertname2("");
    setAlertemail2("");
    setAlertprofession2("");
    setAlerthealthcondition2("");
    setAlertprefferedlanguage2("");
    setAlertmessage2("");
    setAlertcontactnumber2("");
    setAlertage2("");
  };
  return (
    <div>
      <Header />

      <section>
        <div className="carousel_cover position-relative">
          <Carousel className="">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={personaltarining}
              />
              <Carousel.Caption>
                <h2
                  className="col-black"
                  style={{ fontWeight: "600", color: "black" }}
                >
                  Certified online personal training
                </h2>
              </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel "
                src={banner2}
                alt=""
              />
              <Carousel.Caption>
                <h2
                  className="col-black"
                  style={{ fontWeight: "600", color: "black" }}
                >
                  Certified online pesonal training
                </h2>
              </Carousel.Caption>
            </Carousel.Item> */}

            {/* <Carousel.Item interval={2500}>
              <img className="d-block w-100 phy_img_carousel" src={banner3} />
              <Carousel.Caption>
                <h2 className="col-black" style={{fontWeight:"600", color:"black"}}>Certified online pesonal training</h2>
              </Carousel.Caption>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </section>

      <div className="container pt-4">
        <div className=" d-flex justify-content-center section-title pt-5">
          <h3>Get certifed result oriented personal trainers</h3>
        </div>
        <div className="row align-items-center">
          <div className="col-md-12">
            <p style={{ fontWeight: "500" }}>
              &bull; Healthonify’s Online Personal Training
              <br />
              &bull; ACSM / ACE certified Personal Trainers <br />
              &bull; Result oriented trainers
              <br />
              &bull; Transformations – transformation records and case studies
              <br />
              &bull; Specialization in Online Personal training
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-2 pb-2">
          <button
            type="button"
            className="btn btn-warning col-white adjust-btn"
            data-bs-toggle="modal"
            data-bs-target="#freeconsultation"
            style={{ width: "200px" }}
          >
            Enquire
          </button>
        </div>

        <section className="section-card">
          <section className="container pt-5 pb-5">
            <div className="col-lg-12">
              <p className="wel-text  text-center pb-2">Explore Experts</p>
            </div>
            <div className="container">
              <Slider {...settings}>
                <div className="d-flex justify-content-center">
                  <img
                    src={banner1}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <img
                    src={banner2}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <img
                    src={banner3}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <img
                    src={banner4}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <img
                    src={banner1}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <img
                    src={banner2}
                    alt="live-well"
                    className="lie-img-slide"
                  />
                </div>
              </Slider>
            </div>
          </section>
        </section>

        <section className="section-card pt-5 pb-5">
          <p className="wel-text text-center">
            Why Healthonify Personal Trainers
          </p>
          <div className="row">
            <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={scientfic} className="icon-f" alt="" />
                </div>

                <p
                  className="text-center text-mark-2"
                  style={{ fontWeight: "bold" }}
                >
                  100% Scientific in approach
                </p>
              </div>
            </div>

            <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={coach} className="icon-f" alt="" />
                </div>

                <p
                  className="text-center text-mark-2"
                  style={{ fontWeight: "bold" }}
                >
                  Certified personal trainer
                </p>
              </div>
            </div>

            <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={results} className="icon-f" alt="" />
                </div>

                <p
                  className="text-center text-mark-2"
                  style={{ fontWeight: "bold" }}
                >
                  Result oriented
                </p>
              </div>
            </div>

            <div className="col-row-5 col-md-6 col-xs-6 pt-2 pb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={fitnessplan} className="icon-f" alt="" />
                </div>

                <p
                  className="text-center text-mark-2"
                  style={{ fontWeight: "bold" }}
                >
                  Certified fitness plans
                </p>
              </div>
            </div>

            <div className="col-row-5 col-md-6 col-sm-12 pt-2 pb-2">
              <div className="card-background">
                <div className="d-flex justify-content-center">
                  <img src={appease} className="icon-f" alt="" />
                </div>

                <p
                  className="text-center text-mark-2"
                  style={{ fontWeight: "bold" }}
                >
                  Healthonify app for ease of co-ordination
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="cover mt-2 mb-5">
        <section className="container booking pt-3 pb-5 ">
          <div className="col-lg-12 pt-1 pb-3 d-flex justify-content-center">
            <p className="head-text2">Book A Trial Session</p>
          </div>
          <form onSubmit={fitnessform1}>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Name
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      style={{ lineHeight: "2" }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </p>
                  <p className="alert-message">{errorname1}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Email
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email "
                      style={{ lineHeight: "2" }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </p>
                  <p className="alert-message">{erroremail1}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Mobile
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter your mobile number"
                      style={{ lineHeight: "2" }}
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                  </p>
                  <p className="alert-message">{errorcontactnumber1}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Goal
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your goal"
                      style={{ lineHeight: "2" }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </p>{" "}
                  <p className="alert-message">{errormessage1}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Preferred Date
                    <input
                      type="date"
                      min={Moment().add(1, "d").format("YYYY-MM-DD")}
                      className="form-control"
                      placeholder="Enter your date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      style={{ lineHeight: "2" }}
                    />
                  </p>
                  <p className="alert-message">{errordate1}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    City
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your city"
                      style={{ lineHeight: "2" }}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </p>
                  <p className="alert-message">{errorcity1}</p>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-start">
              <p className="col-white">
                By clicking Request Now, you agree to our&nbsp;
                <a
                  href="https://healthonify.com/Terms-condition"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Terms and Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://healthonify.com/Privacy-policy"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="col-lg-12 d-flex justify-content-center ">
              <button
                type="submit"
                className="btn btn-warning col-white adjust-btn"
                onClick={fitnessconsultation1}
              >
                Request Now
              </button>
              &nbsp; &nbsp;
              <button
                type="reset"
                className="btn btn-blue col-white adjust-btn"
              >
                Reset
              </button>
            </div>
          </form>
        </section>
      </div>

      <section className="section-card">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Personal Training Plans</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner3} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner4} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <div className="cover mt-5 mb-5">
        <section className="container booking pt-3 pb-5 ">
          <div className="col-lg-12 pt-1 pb-3 d-flex justify-content-center">
            <p className="head-text2 text-center">
              Book A Free Fitness Consultation
            </p>
          </div>
          <form onSubmit={fitnessform2}>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Name
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your Name"
                      style={{ lineHeight: "2" }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </p>
                  <p className="alert-message">{errorname2}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Email
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email "
                      style={{ lineHeight: "2" }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </p>
                  <p className="alert-message">{erroremail2}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Mobile
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter your mobile number"
                      style={{ lineHeight: "2" }}
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                  </p>
                  <p className="alert-message">{errorcontactnumber2}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <p className="form-text2">
                    Preferred Date
                    <input
                      type="date"
                      min={Moment().add(1, "d").format("YYYY-MM-DD")}
                      className="form-control"
                      style={{ lineHeight: "2" }}
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </p>
                  <p className="alert-message">{errordate2}</p>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-start">
              <p className="col-white">
                By clicking Request Now, you agree to our&nbsp;
                <a
                  href="https://healthonify.com/Terms-condition"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Terms and Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://healthonify.com/Privacy-policy"
                  target="_blank"
                  style={{ color: "#ffc107" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>

            <div className="col-lg-12 d-flex justify-content-center ">
              <button
                className="btn btn-warning col-white adjust-btn"
                onClick={fitnessconsultation2}
              >
                Request Now
              </button>
              &nbsp; &nbsp;
              <button
                type="reset"
                className="btn btn-blue col-white adjust-btn"
              >
                Reset
              </button>
            </div>
          </form>
        </section>
      </div>

      <section className="section-card">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Transformation</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="slick-responsive">
                <div className="bg-white-shadow">
                  <img
                    src={banner1}
                    alt="live-well"
                    className="lie-img-slide-with-text"
                  />
                  <p className="col-black slick-text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry, Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
              </div>

              <div className="slick-responsive">
                <div className="bg-white-shadow">
                  <img
                    src={banner2}
                    alt="live-well"
                    className="lie-img-slide-with-text"
                  />
                  <p className="col-black slick-text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry, Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
              </div>

              <div className="slick-responsive">
                <div className="bg-white-shadow">
                  <img
                    src={banner3}
                    alt="live-well"
                    className="lie-img-slide-with-text"
                  />
                  <p className="col-black slick-text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry, Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
              </div>

              <div className="slick-responsive">
                <div className="bg-white-shadow">
                  <img
                    src={banner4}
                    alt="live-well"
                    className="lie-img-slide-with-text"
                  />
                  <p className="col-black slick-text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry, Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
              </div>

              <div className="slick-responsive">
                <div className="bg-white-shadow">
                  <img
                    src={banner1}
                    alt="live-well"
                    className="lie-img-slide-with-text"
                  />
                  <p className="col-black slick-text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry, Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
              </div>

              <div className="slick-responsive">
                <div className="bg-white-shadow">
                  <img
                    src={banner2}
                    alt="live-well"
                    className="lie-img-slide-with-text"
                  />
                  <p className="col-black slick-text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry, Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <section className="section-card">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Blogs</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              {blogs.map((blogsVal, index) => (
                <div className="d-flex justify-content-center">
                  <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                    <img
                      src={blogsVal.mediaLink}
                      alt=""
                      className="lie-img-slide"
                    />
                    <p>{blogsVal.blogTitle}</p>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </section>

      <section className="section-card">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">
              Shop For Nutrients And Supplements
            </p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner3} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner4} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <Footer />

      <div
        className="modal fade"
        id="freeconsultation"
        style={{ height: "100%" }}
      >
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Online Training Consultation</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={formclear}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={weightform}>
                <p className="bmi_text">
                  Gender:
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="male"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Male
                  </span>
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="Female"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Female
                  </span>
                  <span className="edit_radio_form">
                    <input
                      type="radio"
                      name="Other"
                      value="Other"
                      checked={gender === "Other"}
                      onChange={handleChange}
                      style={{ margin: "5px" }}
                    />
                    Other
                  </span>
                </p>
                <p className="alert-message">{alertgender2}</p>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      <p className="alert-message">{alertname2}</p>
                      <br />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        placeholder="Enter your Age"
                      />
                      <p className="alert-message">{alertage2}</p>

                      <br />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Enter your contact number"
                      />
                      <p className="alert-message">{alertcontactnumber2}</p>

                      <br />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="alert-message">{alertemail2}</p>
                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <select
                        name="health"
                        id="health"
                        className="select-mod"
                        value={healthConditions}
                        onChange={(e) => setHealthConditions(e.target.value)}
                      >
                        <option value="" className="">
                          --Select health condition--
                        </option>
                        <option value="Thyriod">Thyriod</option>
                        <option value="Acidity">Acidity</option>
                        <option value="Cholesterol">Cholesterol</option>
                        <option value="Kneepain">Knee Pain</option>
                        <option value="Backpain">Back Pain</option>
                        <option value="Diabetes">Diabetes(T2)</option>
                        <option value="BP">BP</option>
                        <option value="Prediabetes">Pre Diabetes</option>
                        <option value=" pcod">PCOD</option>
                        <option value="None">None</option>
                        <option value="Other">Other</option>
                      </select>
                      <p className="alert-message">{alerthealthcondition2}</p>
                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <select
                        name="language"
                        id="profession"
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        className="select-mod"
                      >
                        <option value="" className="">
                          --Select your profession--
                        </option>
                        <option value="Homemaker">Homemaker</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Business">Business</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Service">Service</option>
                        <option value="Retired">Retired</option>
                        <option value="Student">Student</option>
                        <option value="Unemployed">Unemployed</option>
                        <option value="Others">Others</option>
                      </select>
                      <p className="alert-message">{alertprofession2}</p>

                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 pb-1">
                      <select
                        name="language"
                        id="language"
                        value={prefferedLanguage}
                        onChange={(e) => setPrefferedLanguage(e.target.value)}
                        className="select-mod"
                      >
                        <option value="" className="">
                          --Select your language--
                        </option>
                        <option value="hindi">Hindi</option>
                        <option value="english">English</option>
                        <option value="kannada">Kannada</option>
                        <option value="Marathi">Marathi</option>
                        <option value="Telugu">Telugu</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Malayalam">Malayalam</option>
                        <option value="Punjabi">Punjabi</option>
                        <option value="Gujarati">Gujarati</option>
                        <option value="Bengali">Bengali</option>
                        <option value="Urdu">Urdu</option>
                      </select>
                      <p className="alert-message">{alertprefferedlanguage2}</p>

                      <br />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <textarea
                        col="50"
                        row="50"
                        type="text"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Describe your requirement"
                      ></textarea>
                      <p className="alert-message">{alertmessage2}</p>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={freeconsultation}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={formclear}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
    </div>
  );
};

export default Personaltraining;
