import React from "react";
import { Link } from "react-router-dom";
import Logo from "./logo.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Footer from "./Footer";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import tick from "./../images/tick.png";

const Appointment = () => {
  return (
    <div id="__next">
      <div id="navbar" className="navbar-area">
        <div className="neemo-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="logo" to="/">
                <img src={Logo} alt="logo" style={{ width: "150px" }} />
              </Link>
              <button
                className="navbar-toggler navbar-toggler-right collapsed"
                type="button"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="/#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/physiotherapy">
                      Physiotherapy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Travel <ArrowDropDownIcon />
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/domestic-adventure"
                        >
                          Domestic Adventure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/international-adventure"
                        >
                          International Adventure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/domestic-leisure"
                        >
                          Domestic Leisure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/international-leisure"
                        >
                          International Leisure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/travel/wellness">
                          Wellness
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/weight-loss">
                      Weight Loss
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="others-options">
                <button className="btn btn-primary   btn-sm" type="button">
                  Get it know
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <section
        className="about-section pt-100"
        style={{
          display: "flex",
          margin: "100px",
          paddingLeft: "20px;",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <br />
            <br />
            <center>
              <img
                src={tick}
                alt="tick"
                style={{ height: "60px", width: "60px", marginBottom: "30px" }}
              />

              <h3>Your appointment has been confirmed</h3>

              <p>
                Your booking is complete. Please check your email for the
                further details.
              </p>
              <p>You booking Id is : #123456789</p>
              <p>Doctor Name: Dr. Anoop Jalota </p>
              <p>Booking Date : 17th March 2022</p>
              <p>Appointment Time : 10:00 AM</p>
              <a className="default-btn-one" href="about-us//">
                Continue Shopping
              </a>
            </center>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default Appointment;
