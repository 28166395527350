import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Tab, Tabs } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import travel3 from "./../images/travel3.jpg";
import travel1 from "./../images/travel2.jpg";
import travel2 from "./../images/travel1.jpg";
import "./Travel.css";
import not from "./../images/404/noresult.jpg";
import TravelHeader from "./travel-navbar";
import Travelfooter from "./Travelfooter";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchTheme, Themes, searchByThemes } from "../store/slices/package";

const SearchByTheme = (props) => {
  const location = useLocation();
  const values = location.state;

  localStorage.setItem("id", values.theme);

  const [searchResults, setsearchResults] = useState([]);
  const [theme, setTheme] = useState("");
  const [themes, setThemes] = useState([]);
  const [query, setQuery] = useState("");
  const [querys, setQuerys] = useState("");

  const [datacount, setDataCount] = useState("");
  const [datacounts, setDataCounts] = useState("");
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const myDatas = {
    destination: query,
  };
  React.useEffect(() => {
    dispatch(Themes())
      .unwrap()
      .then((data) => {
        setThemes(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(searchTheme())
      .unwrap()
      .then((data) => {
        setsearchResults(data.user.data);
        setDataCount(data.user.dataCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function searchDestination(e) {
    e.preventDefault();
    navigate("/travelonify/SearchByDestination/", { state: myDatas });
  }

  async function searchByTheme(e) {
    e.preventDefault();
    let querys = theme;
    let item = { querys };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(searchByThemes())
      .unwrap()
      .then((data) => {
        setsearchResults(data.user.data);
        setDataCount(data.user.dataCount);

        //setQuery("");
      })
      .catch(({ message }) => {
        //alert(message);
      });
  }

  const handleThemeChange = (e) => {
    var value = themes.filter(function (category) {
      return category.name == e.target.value;
    });
    setTheme(value[0].name);
  };
  return (
    <div id="_next">
      <TravelHeader />
      <section>
        <div className="carousel_cover">
          <Carousel fade>
            <Carousel.Item interval={2500}>
              <img className="d-block w-100 carimg" src={travel3} alt="" />
              <Carousel.Caption className="carousel_text_top">
                <h1 className="text1">
                  Create your dream memories with Travelonify
                </h1>
                <p style={{ color: "black" }}>
                  Search&gt;&gt;Select&gt;&gt;Book&gt;&gt;Pay online&gt;&gt;Get
                  Going
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img className="d-block w-100 carimg" src={travel1} alt="" />
              <Carousel.Caption className="carousel_text_top">
                <h1 className="text1">
                  Create your dream memories with Travelonify
                </h1>
                <p style={{ color: "black" }}>
                  Search&gt;&gt;Select&gt;&gt;Book&gt;&gt;Pay online&gt;&gt;Get
                  Going
                </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={2500}>
              <img className="d-block w-100 carimg" src={travel2} alt="" />
              <Carousel.Caption className="carousel_text_top">
                <h1 className="text1">
                  Create your dream memories with Travelonify
                </h1>
                <p style={{ color: "black" }}>
                  Search&gt;&gt;Select&gt;&gt;Book&gt;&gt;Pay online&gt;&gt;Get
                  Going
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className="container">
            <div className="carousel-inner-content-overlay">
              <Tabs
                id="uncontrolled-tab-example"
                style={{ background: "white", borderRadius: "5px" }}
              >
                <Tab eventKey="home" title="Search by Destination">
                  <form onSubmit={searchDestination}>
                    <div className="row" style={{ padding: "5px 0" }}>
                      <div className="col-md-9 col-8">
                        <input
                          type="text"
                          placeholder="Search by Destination"
                          className="search-mod"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3 col-2" align="left">
                        <button
                          className="btn btn-blue col-white"
                          style={{ paddingLeft: "25px", paddingRight: "25px" }}
                          id="save"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab>
                <Tab eventKey="profile" title="Search by Theme">
                  <form onSubmit={searchByTheme}>
                    <div className="row" style={{ padding: "5px 0" }}>
                      <div className="col-md-9 col-8">
                        {/* <input
                          type="text"
                          placeholder="Search by  Theme"
                          className="search-mod"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        /> */}
                        <select
                          className="form-control"
                          onChange={handleThemeChange}
                          required
                        >
                          <option value="">Select</option>
                          {themes.map((option) => (
                            <option value={option.name}>{option.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-3 col-2" align="left">
                        <button
                          type="submit"
                          className="btn btn-blue col-white"
                          // style={{ marginLeft: "30px", width: "120px" }}
                          style={{ paddingLeft: "30px", paddingRight: "30px" }}
                          id="save"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      {datacounts === 0 && (
        <section className="">
          <div className="not-found-section" style={{ position: "relative" }}>
            <img src={not} alt="img" className="cover-image" />
            <p className="wrapper-text">Locations coming soon ...!</p>
          </div>
        </section>
      )}
      {datacount === 0 && (
        <section className="">
          <div className="not-found-section" style={{ position: "relative" }}>
            <img src={not} alt="img" className="cover-image" />
            <p className="wrapper-text">Locations coming soon ...!</p>
          </div>
        </section>
      )}
      {datacount != 0 && (
        <section className="img_section container pt-5 pb-5">
          <div className="row">
            {searchResults.map((PackageVal, index) => (
              <div
                className="col-lg-3 col-md-3 col-sm-12"
                style={{ padding: "5px" }}
              >
                <Link
                  to={`/travelonify/Traveldetails/${
                    PackageVal._id ? PackageVal._id : null
                  }`}
                >
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 white_card"
                    align="center"
                  >
                    <img
                      src={PackageVal.imageUrl[0].mediaLink}
                      alt=""
                      className="trans_imgs"
                    />

                    <h4 className="card-title slick_text">
                      {PackageVal.packageName.substring(0, 25)}
                    </h4>
                    <p className="card-text">
                      {PackageVal.duration} {searchResults.length}
                      <br />
                      <b style={{ color: "blue" }}>
                        &#x20b9; ({PackageVal.twinShareRate / 2})
                      </b>
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>
      )}
      {datacounts != 0 && (
        <section className="img_section container pt-5 pb-5">
          <div className="row">
            {searchResults.map((PackageVal, index) => (
              <div
                className="col-lg-3 col-md-3 col-sm-12"
                style={{ padding: "5px" }}
              >
                <Link
                  to={`/travelonify/Traveldetails/${
                    PackageVal._id ? PackageVal._id : null
                  }`}
                >
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 white_card"
                    align="center"
                  >
                    <img
                      src={PackageVal.imageUrl[0].mediaLink}
                      alt=""
                      className="trans_imgs"
                    />

                    <h4 className="card-title slick_text">
                      {PackageVal.packageName.substring(0, 25)}
                    </h4>
                    <p className="card-text">
                      {PackageVal.duration} {searchResults.length}
                      <br />
                      <b style={{ color: "blue" }}>
                        &#x20b9; ({PackageVal.twinShareRate / 2})
                      </b>
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>
      )}
      <Travelfooter />
    </div>
  );
};
export default SearchByTheme;
