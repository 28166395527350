import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Logo from "./logo.png";
import Footer from "./Footer";

import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import banner2 from "./../images/banner/physio2.jpg";
import { Link } from "react-router-dom";

import Navbar from "./navbar";

const Backpain = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Back Pain</h3>
        </div>

        <div className="pt-4">
          <p>
            Most types of lower back pain are often referred to physical therapy
            as one of the first-line treatments. Physical therapy for low back
            pain includes guided therapeutic exercises that strengthen the lower
            back muscles and condition the spinal tissues and joints.
            <br />
            The short- and long-term goals of physical therapy for back pain
            typically include the following:
            <br />
            <ul>
              <li> Decrease painful symptoms in the lower back and/or leg</li>
              <li>
                Improve low back function to tolerate daily activities as
                independently as possible
              </li>
              <li>
                Increase the spine’s flexibility and improve its range of motion
              </li>
              <li>
                Formulate a maintenance program to prevent the recurrence of
                back problems
              </li>
            </ul>
          </p>
          <p>
            The exercises are intended to provide flexibility and strength
            training to the entire kinetic chain—groups of body segments,
            joints, and muscles that work together to perform bodily movements.
            <br />
            Physical therapy helps restore the patient’s ability to perform
            daily activities with little-to-no discomfort. Large-scale studies
            have shown that physical therapy can provide up to 60% improvement
            in lower back pain and other symptoms.
          </p>
          <p>
            <b>Types of Back Pain That May Benefit from Physical Therapy</b>
            <br />
            Most lower back conditions, unless considered a medical emergency,
            may benefit from some form of physical therapy and exercise. The
            underlying cause of back pain dictates the foundation of the
            physical therapy program and the types of exercises that need to be
            included.
          </p>
          <p>
            <b>Axial back pain</b>
            <br />
            Back pain that is not attributable to a known, recognizable cause is
            called axial or nonspecific back pain. This condition typically
            results from a group of contributing factors, such as using
            unsupported posture and/or being overweight.
            <br />
            Physical therapy is usually recommended for chronic axial back pain
            that has lasted for over 3 months 9 and focuses on stretching and
            strengthening the lower back while also trying to eliminate
            lifestyle factors that may be contributing to the pain.
          </p>
          <p>
            <b>Sciatica</b>
            <br />
            While not a medical diagnosis, sciatica is a set of symptoms caused
            by an underlying condition that may irritate or compress a spinal
            nerve in the lower back, causing lower back pain and/or leg pain.
            Physical therapy and exercise for sciatica typically include
            extension and flexion (forward and backward bending) exercises,
            lumbar strengthening exercises, and nerve and joint mobilization
            methods, among others.
          </p>
          <p>
            <b>Post-surgical pain and healing</b>
            <br />
            Following spine surgery, physical therapy and exercise can help
            eliminate fatigue, return to normal day-to-day activities, and avoid
            re-injury. Being involved in a consistent, guided exercise program
            can be critical in helping the body heal from the surgical procedure
            and in minimizing future episodes of back pain.
            <br />
            Physical therapists typically recommend abdominal, back, and leg
            strengthening exercises with some aerobic exercise to start the
            post-surgical exercise program.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Backpain;
