import React, { useState } from "react";
import Logo from "./logo.png";

import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import Footer from "./Footer";

import Header from "./navbar";

const Contact = () => {
  return (
    <div id="_next">
      <Header />
      <div
        className="page-title-area"
        style={{
          backgroundImage: "url(./static/media/back.jpg);",
        }}
      >
        <div className="container">
          <div className="page-title-content">
            <h2>Contact Us</h2>
          </div>
        </div>
      </div>

      <section className="contact-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="contact-box">
                <div className="icon">
                  <i className="fa fa-phone" />
                </div>
                <div className="content">
                  <h4>Phone </h4>
                  <p>+91 97732 33030</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact-box">
                <div className="icon">
                  <i className="fas fa-envelope" />
                </div>
                <div className="content">
                  <h4>E-mail</h4>
                  <p>support@healthonify.com</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact-box">
                <div className="icon">
                  <i className="fa fa-map-marker" />
                </div>
                <div className="content">
                  <h4>Location</h4>
                  <p>
                    304, Krishna Rdc, Sunder Nagar, Malad West Mumbai - 400064
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="contact-text">
                <h3>Get in Touch</h3>

                <ul className="social-links">
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.pinterest.com/" target="_blank">
                      <i className="fab fa-pinterest" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <form className="contact-form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        style={{ width: "200px" }}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="number"
                        placeholder="Phone number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="text"
                        cols={30}
                        rows={6}
                        placeholder="Write your message..."
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="send-btn">
                      <button type="submit" className="send-btn-one">
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Contact;
