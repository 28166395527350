import axios from "axios";
import { API_BASE_URL, API_PATHS } from "../utils/constants/api.constants";

class InsuranceService {
   

    static postinsurancelocker(item) {
        return axios
            .post(API_PATHS.postinsurance,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    
    static getinsurancelocker() {
        let api = '';
        let id = localStorage.getItem("id"); 
        api = API_PATHS.getinsurance + '?userId=' + id;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                } 
                return response.data;
            });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default InsuranceService;