import React from "react";

import Footer from "./Footer";
import Header from "./navbar";

const Refund = () => {
  return (
    <div id="_next">
      <Header />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>REFUND POLICY</h2>
          </div>
        </div>
      </div>

      <section className="about-section pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="about-area-content">
                <h5 align="center">REFUNDS POLICY</h5>

                <p>
                  Any sale of Health & Fitness Products and Wellness Services
                  will constitute a contract of sale between the Customer who
                  purchase such Health & Fitness Products and Wellness Services
                  and the Seller and/or Partner Gym of such Health & Fitness
                  Products and Wellness Services All the Customer need to get in
                  touch with respective Seller and/or Partner Gyms for refund.
                  HEALTHONIFY SHALL NOT PROVIDE ANY REFUND TO ANY CUSTOMER.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <br></br>
      <Footer />
    </div>
  );
};

export default Refund;
