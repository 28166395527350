import React, { useState } from "react";
import Header from "./navbar";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import Moment from "moment";
import { Link } from "react-router-dom";
import { getHcBlog } from "../store/slices/blog";
// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TravelEnquiry } from "../store/slices/enquiry";
import home1 from "./../images/homepage/banner1.jpg";
import home2 from "./../images/homepage/banner2.jpg";
import home3 from "./../images/homepage/banner3.jpg";
import home4 from "./../images/homepage/banner4.jpg";
import img1 from "./../images/ayurveda/ayurveda1.jpg";
import paralysis from "./../images/healthcare/paralysis.jpg";
import eldercare from "./../images/healthcare/eldercare.jpg";
import immunity from "./../images/healthcare/immunity.jpg";
import panchkarma from "./../images/healthcare/panchkarma.jpg";
import eyecare from "./../images/healthcare/eyecare.jpg";
import painmanagement from "./../images/healthcare/painmanagement.jpg";
import gastric from "./../images/healthcare/gastric.jpg";
import skin from "./../images/healthcare/skin.jpg";
import depression from "./../images/healthcare/depression.jpg";
import detoxification from "./../images/healthcare/detoxification.jpg";
import weightloss from "./../images/healthcare/weightloss.jpg";
import shop1 from "./../images/shop/shop1.jpg";
import shop2 from "./../images/shop/shop2.webp";
import shop3 from "./../images/shop/shop3.jpg";
import shop4 from "./../images/shop/shop4.jpg";
import shop5 from "./../images/shop/shop5.jpg";
import doctor from "./../images/mhome/doctor.png";
import videocall from "./../images/team/video-call.png";
import ayurvedaeco from "./../images/healthcare/ayurveda.png";
import { useDispatch } from "react-redux";
const HealthCareAyurveda = () => {
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [alertCity, setAlertCity] = useState("");
  const [alertdate, setAlertdate] = useState("");
  const [alertCategory, setAlertCategory] = useState("");
  const [alertemail1, setAlertEmail1] = useState("");
  const [alertname1, setAlertName1] = useState("");
  const [alertcontactNumber1, setAlertContactNumber1] = useState("");
  const [alertmessage1, setAlertMessage1] = useState("");
  const reqConsult = (e) => {
    const vName1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName1.test(name)) {
      setAlertName1("");
    } else if (!vName1.test(name) && name === "") {
      setAlertName1("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName1("Please enter a valid name");
      e.preventDefault();
    }

    const vCity1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vCity1.test(city)) {
      setAlertCity("");
    } else if (!vCity1.test(city) && city === "") {
      setAlertCity("Please enter your city name");
      e.preventDefault();
    } else {
      setAlertCity("Please enter a valid city name");
      e.preventDefault();
    }

    const vemail1 = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vemail1.test(email)) {
      setAlertEmail1("");
    } else if (!vemail1.test(email) && email === "") {
      setAlertEmail1("Please enter your  email");
      e.preventDefault();
    } else {
      setAlertEmail1("Please enter a valid email");
      e.preventDefault();
    }

    const mobile1 = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile1.test(contactNumber)) {
      setAlertContactNumber1("");
    } else if (!mobile1.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber1("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setAlertContactNumber1("Please enter a valid mobile number");
      e.preventDefault();
    }

    if (category === "") {
      setAlertCategory("Please select expertise");
      e.preventDefault();
    } else {
      setAlertCategory("");
    }

    const vDate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate.test(date)) {
      setAlertdate("");
    } else if (!vDate.test(date) && date === "") {
      setAlertdate("Please enter your date of consultation");
      e.preventDefault();
    } else {
      setAlertdate("");
    }

    const vMessage1 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage1.test(message)) {
      setAlertMessage1("");
    } else if (!vMessage1.test(message) && message === "") {
      setAlertMessage1("Please enter your issue");
      e.preventDefault();
    } else {
      setAlertMessage1("");
    }
  };

  const cancel = () => {
    setEmail("");
    setName("");
    setContactNumber("");
    setMessage("");
    setCity("");
    setDate("");
    setCategory("");
    setAlertCity("");
    setAlertdate("");
    setAlertCategory("");
  };

  async function uploadconsult(e) {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let category = "Ayurveda";
    let item = {
      name,
      email,
      contactNumber,
      city,
      date,
      message,
      category,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setContactNumber("");
        setCity("");
        setDate("");
        setMessage("");
        setCategory("");
        document.getElementById("consult").style.display = "none";
      })

      .catch(({ emessage }) => {
        alert(emessage);
      });
  }
  React.useEffect(() => {
    dispatch(getHcBlog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-warning verticalButton buttonLink"
        data-bs-toggle="modal"
        data-bs-target="#consult"
      >
        FREE CONSULTATION
      </button>
      <Header />
      <section>
        <div className="carousel_cover pb-5 ">
          <Carousel className="">
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3 "
                src={img1}
                alt=""
              />
              <Carousel.Caption>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-warning col-white adjust-btn"
                    type="button"
                    style={{ fontSize: "20px" }}
                    data-bs-toggle="modal"
                    data-bs-target="#consult"
                  >
                    Book Now
                  </button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            {/* <Carousel.Item interval={3000}>
              <img className="d-block w-100 phy_img_carousel3 " src={home3} />
              <Carousel.Caption>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-warning col-white adjust-btn"
                    type="button"
                    style={{ fontSize: "20px" }}
                  >
                    Book Now
                  </button>
                </div>
              </Carousel.Caption>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </section>

      <div className="container">
        <section className="section-card  pt-5 pb-1">
          <p className=" bold-t" style={{ fontSize: "18px" }}>
            The term ‘Ayurveda’ means ‘science of life’. ‘Ayur’ means life and
            ‘Veda’ means science. The theories and notions of Ayurveda have been
            practiced since the past 5000 years. It recommends methods for
            appropriate living and longevity. It includes instructions to
            maintain good health as well as dealing with illness through yoga,
            treatments, herbal medicines, correct diet and lifestyle changes.
          </p>

          <h2 className=" text-center pt-5">
            The Ayurvedic Treatment Philosophy
          </h2>

          <p className="bold-t pb-2" style={{ fontSize: "18px" }}>
            According to Ayurveda, one can live a long and healthy life through
            intelligent co-ordination of one’s body (sharira), mind (mana),
            senses (indriya) and soul (atma). Ayurvedic science revolves around
            the five elements (panchmahabhoot) i.e., earth (prithvi), water
            (jal), fire (agni), air (vayu), ether (aakash) that goes into the
            making of the three physical energies – Vata, Pitta, Kapha and the
            three mental energies – Satwa, Rajas and Tamas which in turn
            determines the constitution (prakriti). Ayurveda thus offers a
            unique blend of science and philosophy that balances the physical,
            mental, emotional and spiritual components necessary for holistic
            health.
          </p>
        </section>

        <section className="section-card pt-5 pb-4">
          <h3 className="wel-text text-center pb-3">
            Ayurvedic Consultations & Treatments
          </h3>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={paralysis}
                    style={{ borderRadius: "10px" }}
                    alt=""
                  />
                </div>

                <p className="text-transform position-absolute col-white">
                  Paralysis
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={eldercare}
                    style={{ borderRadius: "10px" }}
                    alt=""
                  />
                </div>
                <p className="text-transform position-absolute col-white">
                  Elder Care
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img src={immunity} style={{ borderRadius: "10px" }} alt="" />
                </div>
                <p className="text-transform position-absolute col-white">
                  Immunity
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={panchkarma}
                    style={{ borderRadius: "10px" }}
                    alt=""
                  />
                </div>
                <p className="text-transform position-absolute col-white">
                  Panchkarma
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img src={eyecare} style={{ borderRadius: "10px" }} alt="" />
                </div>
                <p className="text-transform position-absolute col-white">
                  Eye Care
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={painmanagement}
                    style={{ borderRadius: "10px" }}
                    alt=""
                  />
                </div>
                <p className="text-transform position-absolute col-white">
                  Pain Management
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img src={gastric} style={{ borderRadius: "10px" }} alt="" />
                </div>
                <p className="text-transform position-absolute col-white">
                  Gastric problems
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img src={skin} style={{ borderRadius: "10px" }} alt="" />
                </div>
                <p className="text-transform position-absolute col-white">
                  Skin and hair Care
                </p>
              </div>
            </div>
            <div className="col-lg-1 col-md-4 col-xs-6 mt-1 mb-2" />
            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={depression}
                    style={{ borderRadius: "10px" }}
                    alt=""
                  />
                </div>
                <p className="text-transform position-absolute resp-text col-white">
                  Depression and anxiety
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={detoxification}
                    style={{ borderRadius: "10px" }}
                    alt=""
                  />
                </div>
                <p className="text-transform position-absolute col-white">
                  Detoxification
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 mt-1 mb-2">
              <div className="card-all-3 box-text position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={weightloss}
                    style={{ borderRadius: "10px" }}
                    alt=""
                  />
                </div>
                <p className="text-transform position-absolute col-white">
                  Weight loss
                </p>
              </div>
            </div>

            <div className="col-12 d-flex justify-content-center pt-4">
              <button
                type="button"
                className="btn btn-warning col-white"
                style={{ fontSize: "20px" }}
                data-bs-toggle="modal"
                data-bs-target="#consult"
              >
                Request Appointment
              </button>
            </div>
          </div>
        </section>

        <section className="section-card pt-5">
          <h3 className="wel-text text-center pb-3">
            Ayurvedic Doctors For Best Medical Counselling And Treatment
          </h3>
          <p className="point-text">
            Healthonify’s Ayurvedic doctors have a significant experience of
            serving patients. We provide Ayurvedic treatments that are
            thoroughly tested and analysed. Our principle of treatment believes
            in striking the correct balance of mental and physical aspects of
            ones ailments ,thus providing an overall experience which is very
            professional and therapeutic.
          </p>

          <h3 className="wel-text text-center pb-2 pt-5">Why Healthonify</h3>

          <div className="row">
            <div className="col-md-4 col-sm-12 pt-1 pb-1">
              <div className="position-relative round-border text-center">
                <img src={doctor} alt="" className="icon-f mt-1" />

                <p className="text-transform position-absolute col-white mt-5">
                  Certfied Experienced Doctor
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 pt-1 pb-1">
              <div className="position-relative round-border text-center">
                <img src={videocall} alt="" className="icon-f mt-1" />
                <p className="text-transform position-absolute col-white mt-5">
                  Online Video Consultation
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 pt-1 pb-1">
              <div className="position-relative round-border text-center">
                <img src={ayurvedaeco} alt="" className="icon-f mt-1" />
                <p className="text-transform position-absolute col-white mt-5">
                  Complete ecosystem of Ayurveda
                  <br /> at one place
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center pt-5 pb-1">
            <button
              type="button"
              className="btn btn-warning col-white"
              style={{ fontSize: "20px" }}
              data-bs-toggle="modal"
              data-bs-target="#consult"
            >
              Book Appointment Now
            </button>
          </div>
        </section>

        <section className="section-card">
          <section className="container pt-5 ">
            <div className="col-lg-12">
              <p className="wel-text text-center pb-2">Shop</p>
            </div>
            <div className="container">
              <Slider {...settings}>
                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop1} alt="" className="lie-img-slide" />
                  </div>
                </Link>
                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop2} alt="" className="lie-img-slide" />
                  </div>
                </Link>

                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop3} alt="" className="lie-img-slide" />
                  </div>
                </Link>

                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop4} alt="" className="lie-img-slide" />
                  </div>
                </Link>

                <Link to="/Shop">
                  <div className="d-flex justify-content-center">
                    <img src={shop5} alt="" className="lie-img-slide" />
                  </div>
                </Link>
              </Slider>
            </div>
          </section>
        </section>

        <section className="section-card ">
          <section className="container pt-5 pb-5">
            <div className="col-lg-12">
              <p className="wel-text text-center pb-2">Blog</p>
            </div>
            <div className="container">
              <Slider {...settings}>
                {blogs.map((blogsVal, index) => (
                  <div className="d-flex justify-content-center">
                    <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                      <div
                        className="col-12"
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                        align="center"
                      >
                        <img
                          src={blogsVal.mediaLink}
                          alt=""
                          className="lie-img-slide"
                        />
                        <p>{blogsVal.blogTitle}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        </section>

        <section className="section-card pt-4 pb-2">
          <p className="wel-text text-center pb-2">
            Clients Speak- About their Ayurvedic Experience
          </p>

          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  {/* <div className="circle-profile d-flex justify-content-center">
                    <img src={team1} alt="" />
                  </div> */}
                  <h4 className="text-center">Prathima</h4>

                  <p className="col-white">
                    My uncle had a paralysis stroke and his right side of the
                    body was fully paralyzed. I got is touch with the
                    Healthonoify’s Health coach who guided me to the Ayurvedic
                    expert. The Panchakarma treatment along with Physiotherapy
                    got him back on his feet. Thank you Healthonify
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  {/* <div className="circle-profile d-flex justify-content-center">
                    <img src={team2} alt="" />
                  </div> */}
                  <h4 className="text-center">Bhavana</h4>

                  <p className="col-white">
                    After the delivery of my 1st child, I was really worried as
                    I put on around 12 kgs of weight and really gone out of
                    shape. I got in touch with Healthonify Ayurvedic Weight loss
                    Expert Ms.Vidya and under her expert guidance, I am coming
                    back to shape. The Healthonify App itself is a good guidance
                    tool and it has many features wherein my online weight loss
                    journey is going on well
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  {/* <div className="circle-profile d-flex justify-content-center">
                    <img src={team3} alt="" />
                  </div> */}
                  <h4 className="text-center">Mahesha</h4>

                  <p className="col-white">
                    My name is Mahesha and post Covid 19, I feel my immunity has
                    dropped a lot and I get sick and contact any infection very
                    easily. I tried many immunity boosting medicines available
                    in the market but it gave me temporary relief. I contacted
                    the Healthonify expert wherein he put me through the
                    Detoxification and immunity builder program. Now I feel more
                    energetic and feel good
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="single-features-item bg-orange-card fix_space">
                  {/* <div className="circle-profile d-flex justify-content-center">
                    <img src={team4} alt="" />
                  </div> */}
                  <h4 className="text-center">Sachin</h4>

                  <p className="col-white">
                    I lost my job and I was in the state of Depression. I had
                    lost interest in everything and I had given up hope. One of
                    my friend told about Healthonify expert. I downloaded the
                    app, post which I got a free consultation with an Ayurvedic
                    expert who treated me for Depression. Now I feel very
                    relieved and lively
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      </div>
      <div className="modal fade" id="consult" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header">
              <h4 className="modal-title">Doctor Consultation</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={cancel}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={uploadconsult}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={name}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <p className="alert-message">{alertname1}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={email}
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="alert-message">{alertemail1}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={contactNumber}
                      placeholder="Enter your mobile number"
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                    <p className="alert-message">{alertcontactNumber1}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="date"
                      min={Moment().add(0, "d").format("YYYY-MM-DD")}
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                    <p className="alert-message">{alertdate}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={city}
                      placeholder="Enter your city name"
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <p className="alert-message">{alertCity}</p>
                  </div>
                  <div className="col-md-6 col-sm-12 pt-1">
                    <select
                      name="cars"
                      id="cars"
                      className="select-opt"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Select Expertise</option>
                      <option>Paralysis</option>
                      <option>Elder Care</option>
                      <option>Immunity</option>
                      <option>Panchkarma</option>
                      <option>Eye Care</option>
                      <option>Pain Management</option>
                      <option>Gastric Problems</option>
                      <option>Skin & Hair care</option>
                      <option>Depression & Anxiety</option>
                      <option>Detoxification</option>
                      <option>Weight loss</option>
                      <option>Others</option>
                    </select>
                    <p className="alert-message">{alertCategory}</p>
                  </div>

                  <div className="col-12 pt-2">
                    <textarea
                      row="50"
                      col="50"
                      className="form-control"
                      placeholder="Brief your issue"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>

                    <p className="alert-message">{alertmessage1}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-center pt-4">
                  <button
                    type="submit"
                    className="btn btn-warning col-white adjust-btn"
                    onClick={reqConsult}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white adjust-btn"
                    onClick={cancel}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
      <Footer />
    </div>
  );
};

export default HealthCareAyurveda;
