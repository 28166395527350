import React from "react";
import image3 from "./../images/shape/8.png";
import image4 from "./../images/shape/5.png";
import image5 from "./../images/shape/12.svg";
import image6 from "./../images/shape/13.svg";
import image7 from "./../images/shape/14.png";
import team1 from "./../images/rahul.png";
import team2 from "./../images/dinesh.png";
import aimg1 from "./../images/about-image.png";
import Footer from "./Footer";

import Header from "./navbar";

const About = () => {
  return (
    <div id="__next">
      <Header />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>About Us</h2>
            <ul></ul>
          </div>
        </div>
      </div>

      <section className="about-section pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={aimg1} alt="image" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-area-content">
                <span>About Us</span>
                <h3>WELCOME</h3>

                <p>
                  Welcome to Healthonify – the unique one stop platform for all
                  your Health & Wellness needs. We will be providing you and
                  will make you experience Holistic Health & Wellness in the
                  Healthonify ecosystem. Come – Experience Healthonify.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-area pt-100 pb-70">
        <div className="container">
          <div className="features-title">
            <h3>WE EMPHASIZE ON 4 CHARACTERISTICS</h3>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-b5a2f8">
                <div className="icon">
                  <i className="flaticon-seo" />
                </div>
                <h3>Customer Centric</h3>
                <p>
                  We keep in touch with our customers through various customers
                  engagement programs aided with technology. We track their
                  progress and will be handholding them throughout their journey
                  at Healthonify.<br></br>&nbsp;<br></br>&nbsp;<br></br>&nbsp;
                  <br></br>&nbsp;
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-f27e19">
                <div className="icon">
                  <i className="flaticon-analytics" />
                </div>
                <h3>Convinience</h3>
                <p>
                  One Stop Solution for all your needs of Holistic Health and
                  Wellness - gym membership, rejuvenation - spa, sauna and
                  massage centers, ayurveda and immunity building services,
                  Nutritionist, Dietician and Physiotherapists, salons/beauty
                  centers , adventure tourism and E Commerce platform for Health
                  & Wellness merchandise.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-1db294">
                <div className="icon">
                  <i className="flaticon-laptop" />
                </div>
                <h3>CREDIBILITY AND RESULT ORIENTED</h3>
                <p>
                  Certified and experienced professionals combined with seamless
                  business processes and superior technology ensuring quality
                  offerings in all aspects right from goal setting to educating
                  , monitoring and motivating the clients to reach their goals.
                  <br></br>&nbsp;
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-e80d82">
                <div className="icon">
                  <i className="flaticon-analysis-1" />
                </div>
                <h3>SUSTAINABLE</h3>
                <p>
                  It is not only a one time transformation that we aim for, but
                  also a complete changeover of lifestyle and habits in an
                  organic manner ensuring sustainable improvement in health for
                  lifetime by supporting them with technology and creating a
                  community to motivate them for a healthier and happier life.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="features-animation">
          <div className="shape-img1">
            <img src={image3} alt="image" />
          </div>
          <div className="shape-img2">
            <img src={image5} alt="image" />
          </div>
        </div>
      </section>

      <section className="team-section pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Team</span>
            <h3>Meet Our Team</h3>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="team-item-area">
                <div className="team-image" align="center">
                  <img src={team1} alt="image" />
                </div>
                <div className="team-content">
                  <h3>Rahul Narasimha</h3>
                  <span>
                    Business Strategist Worked in Retail ,Service and Healthcare
                    sectors
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-item-area">
                <div className="team-image" align="center">
                  <img src={team2} alt="image" />
                </div>
                <div className="team-content">
                  <h3>Dinesh Rao</h3>
                  <span>
                    Finance Expert Worked in Banking, Finance and Healthcare
                    sector
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="team-animation">
          <div className="shape-img1">
            <img src={image3} alt="image" />
          </div>
          <div className="shape-img2">
            <img src={image4} alt="image" />
          </div>
          <div className="shape-img3">
            <img src={image5} alt="image" />
          </div>
          <div className="shape-img4">
            <img src={image6} alt="image" />
          </div>
          <div className="shape-img5">
            <img src={image7} alt="image" />
          </div>
        </div>
      </section>

      <br></br>
      <Footer />
    </div>
  );
};

export default About;
