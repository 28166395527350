import React, { Component } from "react";

import UserNavbar from "./UserNavbar";
const Analysis =()=> {
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <div className="col-md-12">
                  <h3 className="mb-2">Analysis</h3>
                </div>
                <div className="bg-white-ptbrl-2 mb-5 mt-1">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead class="table-dark">
                        <tr>
                          <th className="wd-15p">Workout analysis &nbsp;</th>
                          <th className="wd-15p">Diet analysis &nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>null</td>
                          <td>null</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Analysis;
