import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Footer from "./Footer";
import Navbar from "./navbar";

const Orthopedic = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Orthopedic And Sport Rehabilitation</h3>
        </div>
        <div className="pt-4">
          <h3 className="col-head-color-p">Introduction</h3>
          <p>
            Orthopedic and Sport Rehabilitation includes treatment of
            musculoskeletal system that has been subjected to injury or trauma.
            Orthopedic surgeons use both surgical and non-surgical ways to treat
            and cure injuries and different conditions like degenerative
            diseases, infections, tumours and birth defects, to achieve the
            desirable change which the surgeon has set for his patient there is
            a need for a physiotherapist as well. Primarily in this field of
            rehabilitation the therapist deals with sprains, strains, pre and
            post different orthopedic and sport injury surgeries, repetitive
            injuries, soft tissue injuries like ligaments, tendons and also
            provides prophylactic rehabilitation to a sportspersons/ athletic,
            according to their various sports they are prone to injure some
            areas for example – A football player is highly prone to injure his
            foot and ankle and his neck too till some extent (if he aims for a
            headshot),proper conditioning, training, strengthening of those
            muscles prior to the game will be of greater benefit since it
            reduces the chance for any severe or major injury.
          </p>
          <p>
            In India it is usually seen that the patient comes to visit a doctor
            only when the condition has worsen or it has reached a chronic
            stage, till then the patient tries home remedies which are not
            favourable every time. So now to break that chronic stage of the
            condition it requires much more time and efforts. Mostly the major
            difference that we see in these two stages is the level and duration
            of pain perceived, here is the difference between the acute and
            chronic stage of any condition.
          </p>
          <p>
            A physiotherapist will always aim to cease the further worsening of
            the condition, prevent it from getting into chronic stage.
          </p>

          <p>
            A role of physiotherapist in orthopedic and sport rehabilitation is
            as follows:
          </p>
          <ul>
            <li>To address functional limitations of the patient</li>
            <li>To prevent musculoskeletal injuries</li>
            <li>To ensure proper muscle strength and balance</li>
            <li>To establish proper body biomechanics and posture</li>
            <li>Improve and maintain cardiovascular strength</li>
            <li>To encourage and maintain a healthy lifestyle</li>
          </ul>
        </div>

        <div className="pt-4">
          <h3 className="col-head-color-p">Process of Rehabilitation</h3>
          <p>
            <b>Musculoskeletal Assessment:</b>
            <br />
            Assessment is performed both pre and post-operatively for
            appropriate goal setting.
          </p>
        </div>

        <div className="pt-4">
          <p>
            {" "}
            <b>Goal Setting:</b>
            <br />
            Every treatment is tailored made depending upon the patient’s
            condition, past medical and surgical history. The ultimate goal of
            physiotherapy is to improve the quality of life of a patient by
            making them functionally independent and if it’s a sports person
            returning back to his sports will be the ultimate goal.
          </p>

          <ul>
            <li>
              To relieve pain- According to underlying condition pain can be
              relieved, for example if the pain is arising due to inflammation
              of the soft tissues use of cold packs will help relieve the pain,
              on the contrary if the pain is arising due to chronic stage of
              muscle spasm a hot pack would be the choice of treatment.
              Similarly electrotherapeutic modalities also help in relieving the
              pain.
            </li>
            <li>
              To improve range of motion of a joint- Due to the injury in the
              joint sometimes to protect the rest of the joint the surrounding
              structures go into contacting state thus resulting in decreased
              range of motion of the joint. Depending upon the stage of recovery
              joint range can be improved.
            </li>
            <li>
              Improving muscle strength and conditioning- Due to the injury as
              well as due to operative procedures the muscle strength reduce
              tremendously so according to the recovery phase of the patient
              different forms of muscle strengthening is performed.
            </li>
            <li>
              Improving Core Stability – Core provides a very strong support to
              all the muscles so improving core strengthen and stability is
              necessary thus it enhances healing
            </li>
            <li>
              Prevention of injuries- Some patients due to their condition are
              prone to more injuries and even some sport persons are also prone
              to injury due to their sports so depending upon the requirement,
              training of specific muscles is performed.
            </li>
            <li>
              Sport Specific Training- Depending upon which sport is played by
              the patient training of specific muscles differs.
            </li>
            <li>
              Improving Cardiovascular Strength- It is highly necessary to
              improve and maintain cardiovascular strength as all the sports
              require good cardiac strength for better results and in normal
              people improving cardiovascular strength helps in faster healing
              and is quite beneficial for the patient
            </li>
          </ul>
        </div>

        <div className="pt-4">
          <p>
            <b>Therapeutic Interventions:</b>
            <ul>
              <li>Hot packs/Cryotherapy (cold packs)</li>
              <li>Ergonomics Advice</li>
              <li>Manual Mobilisations</li>
              <li>Electrotherapeutic Modalities</li>
              <li>Active Assisted Exercises</li>
              <li>Core Developing Exercises</li>
              <li>
                Sports Specific Exercises – for example if we have to train a
                football player we know according to his sport requirement he
                needs high level of strength as well as endurance in his lower
                limbs rather than upper limb, he also needs to have faster
                agility and speed, So knowing these components we can train him
                accordingly
              </li>
              <li>Gait Training</li>
              <li>Postural Training</li>
              <li>Orthotics Training</li>
              <li>Myofascial Release</li>
              <li>Therapeutic Tapping</li>
              <li>Therapeutic Ultrasound</li>
              <li>Dry Needling</li>
              <li>Improving Cardiovascular Strength</li>
            </ul>
          </p>
        </div>

        <div className="pt-4">
          <p>
            <b>Common Conditions:</b>
            <br /> Postural Dysfunction is also commonly seen it is due to
            misalignment of body structures either due to muscle weakness or
            tightness. It can be seen in any joint but more commonly seen in
            cervical and lumbar spine.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Orthopedic;
