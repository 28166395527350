import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "./logo.png";
import TravelHeader from "./travel-navbar";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { PackageDetails } from "./../store/slices/package";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import { CalculatePrice } from "./../store/slices/booking";
import { useNavigate } from "react-router-dom";
import { roomGroup } from "./../store/slices/roomgroup";
import Travelfooter from "./Travelfooter";

function Traveldetails2(e) {
  const [adultCount, setadultCount] = useState(1);
  const [childrenCount, setchildrenCount] = useState(0);
  const [childrenCountWB, setchildrenCountWB] = useState(0);
  const [checkinDate, setcheckinDate] = useState("");
  const navigate = useNavigate("");

  const [bookedRooms, setbookedRooms] = useState([
    {
      roomGroupId: "62c7e882d18a138255f57277",
      adultCount: 0,
      childCount: 0,
      childWithoutBedCount: 0,
    },
  ]);

  const handleAddForms = () => {
    let rooms = [...bookedRooms];
    rooms.push({
      roomGroupId: "62c7e882d18a138255f57277",
      adultCount: 0,
      childCount: 0,
      childWithoutBedCount: 0,
    });
    setbookedRooms(rooms);
  };

  const handleDeleteForms = (e) => {
    if (bookedRooms.length > 1) {
      let rooms = [...bookedRooms];
      rooms.pop();
      setbookedRooms(rooms);
    }
  };
  const handleRowChange = (e, index) => {
    const rooms = [...bookedRooms];
    rooms[index][e.currentTarget.name] = e.currentTarget.value;
    setbookedRooms(rooms);
    // console.log(tourIternary);
  };
  const [name, setName] = useState("");
  const [roomgroupname, setroomgroupName] = useState("");
  const [roomgroupId, setroomgroupId] = useState("");
  const [roomgroups, setroomGroups] = useState([]);
  const [grossAmount, setgrossAmount] = useState("");
  const [city, setCity] = useState("");
  const [packageId, setpackageId] = useState("");
  const [packageName, setpackageName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [ratings, setRatings] = useState("");
  const [departureLocation, setdepartureLocation] = useState("");
  const [inclusive, setInclusive] = useState("");
  const [exclusive, setExclusive] = useState("");
  const [termsCondition, settermsCondition] = useState("");
  const [importantNotes, setimportantNotes] = useState("");
  const [bookingProcedure, setbookingProcedure] = useState("");
  const [twinShareRate, settwinShareRate] = useState("");
  const [imageUrl, setimageUrl] = useState([{ mediaLink: "" }]);
  const [pricing, setPricing] = useState("");
  const [tourIternary, settourIternary] = useState([
    { day: "", description: "", meal: "" },
  ]);
  const [hotelDescription, sethotelDescription] = useState([
    {
      destination: "",
      HotelName: "",
      address: "",
      duration: "",
    },
  ]);

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { id } = useParams();
  localStorage.setItem("id", id);
  localStorage.setItem("packageId", id);
  React.useEffect(() => {
    dispatch(PackageDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setDuration(data.user.data[0].duration);
          setCity(data.user.data[0].city);
          setdepartureLocation(data.user.data[0].departureLocation);
          setInclusive(data.user.data[0].inclusive);
          setExclusive(data.user.data[0].exclusive);
          settermsCondition(data.user.data[0].termsCondition);
          setimageUrl(data.user.data[0].imageUrl);
          setimportantNotes(data.user.data[0].importantNotes);
          setbookingProcedure(data.user.data[0].bookingProcedure);
          setpackageName(data.user.data[0].packageName);
          setDescription(data.user.data[0].description);
          setRatings(data.user.data[0].ratings);
          settwinShareRate(data.user.data[0].twinShareRate);
          setPricing(data.user.data[0].pricing);
          settourIternary(data.user.data[0].tourIternary);
          sethotelDescription(data.user.data[0].hotelDescription);
          setpackageId(data.user.data[0]._id);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  // const { id } = useParams();

  React.useEffect(() => {
    dispatch(roomGroup())
      .unwrap()
      .then((data) => {
        setroomGroups(data.user.data);
        setroomgroupId(data.user.data[0]._id);
        setroomgroupName(data.user.data[0].name);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const back = () => {
    document.getElementById("book").classList.toggle("shown");
  };

  const IncNum_A = (index) => {
    const list = [...bookedRooms];
    list[index]["adultCount"] = bookedRooms[index].adultCount + 1;
    setbookedRooms(list);
  };

  const DecNum_A = (index) => {
    const list = [...bookedRooms];
    list[index]["adultCount"] = bookedRooms[index].adultCount - 1;
    setbookedRooms(list);
  };

  const IncNum_B = (index) => {
    const list = [...bookedRooms];
    list[index]["childCount"] = bookedRooms[index].childCount + 1;
    setbookedRooms(list);
  };

  const DecNum_B = (index) => {
    const list = [...bookedRooms];
    list[index]["childCount"] = bookedRooms[index].childCount - 1;
    setbookedRooms(list);
  };

  const IncNum_C = (index) => {
    const list = [...bookedRooms];
    list[index]["childWithoutBedCount"] =
      bookedRooms[index].childWithoutBedCount + 1;
    setbookedRooms(list);
  };

  const DecNum_C = (index) => {
    const list = [...bookedRooms];
    list[index]["childWithoutBedCount"] =
      bookedRooms[index].childWithoutBedCount - 1;
    setbookedRooms(list);
  };

  const handleroomgroupChange = (e) => {
    var value = roomgroups.filter(function (bedtypes) {
      return bedtypes.name == e.target.value;
    });
    setroomgroupId(value[0]._id);
    setroomgroupName(value[0].name);
  };
  let checkoutDate = "2022-07-12";
  async function save(e) {
    document.getElementById("book").style.height = "300px";
    e.preventDefault();
    let item = {
      packageId,
      bookedRooms,
      checkoutDate,
      checkinDate,
    };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(CalculatePrice(item))
      .unwrap()
      .then((data) => {
        setgrossAmount(data.user.data.grossAmount);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }

  const payNow = () => {
    const user = localStorage.getItem("user");
    if (user) {
      let options = {
        key: "rzp_test_JOC0wRKpLH1cVW",
        amount: 2000, // 2000 paise = INR 20, amount in paisa
        name: "Merchant Name",
        description: "Purchase Description",
        image: "/your_logo.png",
        handler: function (response) {
          alert(response);
        },
        modal: {
          ondismiss: function () {
            //Write Failure code here
          },
        },
        prefill: {
          name: "Test",
          email: "test@gmail.com",
        },
        notes: {
          address: "Test",
        },
        theme: {
          color: "#F37254",
        },
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      navigate("/travelonify/TravelSignin");
    }
  };

  return (
    <div id="_next">
      <TravelHeader />

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            {imageUrl.map((image, index) => (
              <Carousel.Item interval={2500}>
                <img
                  className="d-block w-100 carimg "
                  src={image.mediaLink}
                  alt=""
                />
                <div className="topText">
                  <h5>
                    <span
                      style={{
                        color: "#90EE90",
                        fontWeight: "700",
                        fontSize: "20px",
                        textDecoration: "line-through",
                        textDecorationColor: "red",
                        width: "50px",
                      }}
                    >
                      &#x20b9; {pricing}
                    </span>
                    <br />
                    <span className="glow">
                      Offer Price <br />
                      &#x20b9; {twinShareRate}
                    </span>
                    <br />
                    <span style={{ fontSize: "10px", fontWeight: "400" }}>
                      Per person on twin sharing basis*
                    </span>
                  </h5>
                </div>
                <Carousel.Caption className="carousel_text_top">
                  <h1 className="text1" style={{ color: "black" }}>
                    {packageName}
                  </h1>
                  <br />
                  <a
                    href="#calculateprice"
                    className="btn btn-primary"
                    style={{ width: "150px" }}
                  >
                    Book Now
                  </a>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>

      <section className="container">
        <div className="row pb-1">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div>
              <h3 style={{ color: "black" }}>Tour Description</h3>
            </div>

            <p>{description}</p>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div style={{ borderBottomStyle: "groove" }}>
              <h3 style={{ color: "black" }}>Itiernary</h3>
            </div>
            <br />
            <div className="col-lg-12">
              <Accordion>
                {tourIternary.map((itiernary, index) => (
                  <Accordion.Item eventKey={itiernary.day}>
                    <Accordion.Header>Day - {itiernary.day}</Accordion.Header>

                    <Accordion.Body>
                      {itiernary.description}
                      <br />
                      <br />
                      <i className="fa fa-food"></i>Food Type: &nbsp;
                      {itiernary.meal}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
        <br />
        <div className="row   pt-3 pb-1">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{ color: "black" }}>Inclusion/ Exclusion</h3>
              <h6 style={{ color: "black" }}> Inclusion</h6>

              <p>{inclusive}</p>
              <br />
              <h6 style={{ color: "black" }}> Exclusion</h6>

              <p>{exclusive}</p>
            </div>
          </div>
        </div>
        <div className="row pt-3 pb-1">
          <h3 style={{ color: "black", textAlign: "left" }}>Hotel Details</h3>
          <div
            className="col-lg-12 col-md-12 col-sm-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th
                          style={{
                            width: "100px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#6a6c72",
                          }}
                        >
                          Location
                        </th>
                        <th
                          style={{
                            width: "600px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#6a6c72",
                          }}
                        >
                          Hotel Name
                        </th>
                        <th
                          style={{
                            width: "200px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#6a6c72",
                          }}
                        >
                          No.of Days
                        </th>
                        <th
                          style={{
                            width: "300px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#6a6c72",
                          }}
                        >
                          No.of Person Per Room
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {hotelDescription.map((hotel, index) => (
                        <tr>
                          <td
                            style={{
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              color: "#6a6c72",
                            }}
                          >
                            {hotel.destination}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              color: "#6a6c72",
                            }}
                          >
                            {hotel.HotelName}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              color: "#6a6c72",
                            }}
                          >
                            {hotel.Duration}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              color: "#6a6c72",
                            }}
                          >
                            {hotel.capacityPerRoom}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <br />
        <div className="row  pt-3 pb-1">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 style={{ color: "black" }}>Terms and Conditions</h3>

              <p>{termsCondition}</p>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
              <img
                src={imageUrl[0].mediaLink}
                alt="travel"
                className="slide-img"
              />
            </div>
          </div>
        </div>
        <div className="row pt-3 pb-1">
          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{ alignItems: "center" }}
          >
            <h2 style={{ color: "black" }}>Important Notes</h2>

            <img
              src={imageUrl[0].mediaLink}
              alt="travel"
              className="slide-img"
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <br />
              <br />

              <p>{importantNotes}</p>
            </div>
          </div>
        </div>
        <div className="row pt-3 pb-1">
          <h3 style={{ textAlign: "left", color: "black" }}>
            Cancellation Policy
          </h3>
          <div
            className="col-lg-12 col-md-12 col-sm-12"
            style={{ alignItems: "center" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p>{bookingProcedure}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container mt-5" id="calculatePrice">
        <form onSubmit={save}>
          <div className="col-lg-12 ">
            <h2 style={{ color: "black" }}>Calculate Price</h2>
          </div>
          <div className="row booking">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4" align="center">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={(e) => handleAddForms(e)}
                >
                  Add Room
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => handleDeleteForms(e)}
                >
                  Remove Room
                </button>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              {bookedRooms.map((hotel, index) => (
                <div className="col-md-8" key={index}>
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                      <p>Room Type</p>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                      <select
                        className="form-control"
                        onChange={handleroomgroupChange}
                        required
                      >
                        <option value="">Select</option>
                        {roomgroups.map((option) => (
                          <option value={option._id}>{option.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                      <br />
                      <br />
                      <br />
                      <p>No.of Travellers</p>
                    </div>
                    <div className="col-xl-3 col-lg-2 col-md-4 col-sm-6 col-xs-6 pt-3">
                      <p className="inline bok-text" style={{ color: "black" }}>
                        Adult
                      </p>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <p className="inline  ">
                          <span
                            className="mathf minus"
                            onClick={() => DecNum_A(index)}
                          >
                            -
                          </span>
                          <input
                            className="var contain-border"
                            value={bookedRooms[index].adultCount}
                            style={{ width: "60px" }}
                            onChange={(e) => handleRowChange(e, index)}
                          />

                          <span
                            className="mathf plus"
                            onClick={() => IncNum_A(index)}
                          >
                            +
                          </span>
                        </p>
                      </div>
                    </div>
                    <input
                      value={packageId}
                      onChange={(e) => setpackageId(e.target.value)}
                      type="hidden"
                    />
                    <div className="col-xl-3 col-lg-2 col-md-4 col-sm-6 col-xs-6 pt-3">
                      <p className="inline bok-text" style={{ color: "black" }}>
                        Child (With bed)
                      </p>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <p className="inline  ">
                          <span
                            className="mathf minus"
                            onClick={() => DecNum_B(index)}
                          >
                            -
                          </span>
                          <input
                            className="var contain-border"
                            value={bookedRooms[index].childCount}
                            onChange={(e) => handleRowChange(e, index)}
                            style={{ width: "60px" }}
                          />

                          <span
                            className="mathf plus"
                            onClick={() => IncNum_B(index)}
                          >
                            +
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-2 col-md-12 col-sm-6 col-xs-6 pt-3 ">
                      <p className="inline bok-text" style={{ color: "black" }}>
                        Child (without bed)
                      </p>
                      <div className="col-lg-12 col-md-3 col-sm-12 col-xs-12">
                        <p className="inline  ">
                          <span
                            className="mathf minus"
                            onClick={() => DecNum_C(index)}
                          >
                            -
                          </span>
                          <input
                            className="var contain-border"
                            value={bookedRooms[index].childWithoutBedCount}
                            onChange={(e) => setchildrenCountWB(e.target.value)}
                            style={{ width: "60px" }}
                          />

                          <span
                            className="mathf plus"
                            onClick={() => IncNum_C(index)}
                          >
                            +
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              ))}

              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6">
                  <p>Date of Travel</p>
                </div>

                <div className="col-lg-6 col-md-4 col-sm-6 col-xs-6">
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setcheckinDate(e.target.value)}
                    vaue={checkinDate}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 pt-5 d-flex justify-content-center">
                  <button type="submit" className="btn btn-primary">
                    Calculate Package Price
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
                <div className="col-lg-12 book-now" id="book">
                  <div className="space-t">
                    <p>
                      Adult : <span className="navig">{adultCount}</span>
                    </p>
                    <p>
                      Child(with-bed) :
                      <span className="navig">{childrenCount}</span>
                    </p>
                    <p>
                      Child(without-bed) :
                      <span className="navig">{childrenCountWB}</span>
                    </p>

                    <p>
                      Total price <span className="navig">{grossAmount}</span>
                    </p>
                  </div>
                  <button
                    onClick={payNow}
                    type="button"
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      <br />
      <br />
      <Travelfooter />
    </div>
  );
}

export default Traveldetails2;
