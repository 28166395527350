import React, { useState } from "react";
import { Link } from "react-router-dom";
import { register } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TravelHeader from "./travel-navbar";
import { VscEye } from "react-icons/vsc";
import Footer from "./Footer";

function TravelRegister(e) {
  const [firstName, setfirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [alertfn, setAlertfn] = useState("");
  const [alertln, setAlertln] = useState("");
  const [alertmes, setAlertmes] = useState("");
  const [alertmail, setAlertmail] = useState("");
  const [alertpass, setAlertpass] = useState("");
  let roles = "client";
  const [password, setPassword] = useState("");
  const navigate = useNavigate("");
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // hide/show password start
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  // hide/show password end

  const save = (e) => {
    const fName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fName.test(firstName)) {
      setAlertfn("");
    } else if (!fName.test(firstName) && firstName === "") {
      setAlertfn("Please enter your first name");
      e.preventDefault();
    } else {
      setAlertfn("");
    }

    const passReg = /^(?=.*?[A-Za-z]).{6,9}$/;
    if (passReg.test(password)) {
      setAlertpass("");
    } else if (!passReg.test(password) && password === "") {
      setAlertpass("Please enter the password");
      e.preventDefault();
    } else {
      setAlertpass(
        "Password should not more then 6 characters and not more then 9 characters"
      );
      e.preventDefault();
    }

    const lName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fName.test(lastName)) {
      setAlertln("");
    } else if (!lName.test(lastName) && lastName === "") {
      setAlertln("Please enter your last name");
      e.preventDefault();
    } else {
      setAlertln("");
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile.test(mobileNo)) {
      setAlertmes("");
    } else if (!mobile.test(mobileNo) && mobileNo === "") {
      setAlertmes("Please enter the mobile number");
      e.preventDefault();
    } else {
      setAlertmes("Invalid mobile number");
      e.preventDefault();
    }

    const emailregx =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (emailregx.test(email)) {
      setAlertmail("");
    } else if (!emailregx.test(email) && email === "") {
      setAlertmail("Please enter the email");
      e.preventDefault();
    } else {
      setAlertmail("Invalid email");
      e.preventDefault();
    }
  };

  async function signUp(e) {
    e.preventDefault();
    let item = { firstName, email, mobileNo, password, lastName, roles };

    dispatch(register(item))
      .unwrap()
      .then(() => {
        navigate("/travelonify/Otp");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div id="__next">
      <TravelHeader />
      <section className="login-bg-image">
        <div className="register-form">
          <p className="login-main-head text-center">Registration</p>
          <form onSubmit={signUp}>
            <div className="container">
              <div className="row">
                {/*error && <Alert variant="danger">{error}</Alert>*/}

                <div className="col-md-6 pt-3 pb-3">
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setfirstName(e.target.value)}
                    placeholder="First Name"
                    className="form-control"
                    style={{ lineHeight: "2" }}
                  />
                  <p className="alert-message">{alertfn}</p>
                </div>
                <br />
                <div className="col-md-6 pt-3 pb-3">
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setlastName(e.target.value)}
                    placeholder="Last Name"
                    className="form-control"
                    style={{ lineHeight: "2" }}
                  />
                  <p className="alert-message">{alertln}</p>
                </div>

                <br />

                <div className="col-md-6 pt-3 pb-3">
                  <input
                    type="text"
                    value={mobileNo}
                    onChange={(e) => setmobileNo(e.target.value)}
                    placeholder="Mobile Number"
                    className="form-control"
                    style={{ lineHeight: "2" }}
                  />
                  <p className="alert-message">{alertmes}</p>
                </div>
                <br />

                <div className=" col-md-6 pt-3 pb-3">
                  <div className="position-r">
                    <input
                      type={passwordShown ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      className="form-control"
                      style={{ lineHeight: "2" }}
                    />
                    <VscEye
                      className="eye_icon_login"
                      onClick={togglePassword}
                    />
                  </div>
                  <p className="alert-message">{alertpass}</p>
                </div>
                <br />

                <div className="col-md-6 pt-3 pb-3">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    style={{ lineHeight: "2" }}
                    className="form-control"
                  />
                  <p className="alert-message">{alertmail}</p>
                </div>
                <br />

                <div className="col-lg-12 d-flex justify-content-center pt-4">
                  <button
                    type="submit"
                    className="btn btn-blue  col-white"
                    onClick={save}
                    style={{ width: "150px" }}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </form>
          <p className="text-center pt-2">
            Already have an account?{" "}
            <Link to="/travelonify/TravelSignin" className="heightlight-black">
              Sign In
            </Link>
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default TravelRegister;
