import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";

import travel1 from "./../images/travelonify/international/banner1.jpg";
import travel2 from "./../images/travelonify/international/banner2.jpg";
import travel3 from "./../images/travelonify/international/banner3.jpg";
import { getTravelblog } from "../store/slices/blog";
import mumbai from "../images/trip/Mumbai.jpg";
import Kerla from "../images/trip/Kerla.jpg";
import tour1 from "../images/blog/temple-tour-banner.png";
import tour2 from "../images/blog/International-banner_1236.jpg";
import india from "../images/trip/india.jpg";
import USA from "../images/trip/USA.jpg";
import { Tab, Tabs } from "react-bootstrap";
import Japan from "../images/trip/Japan.jpg";
import { Link } from "react-router-dom";
import "./Travel.css";
import TravelHeader from "./travel-navbar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import { InternationalPackage } from "./../store/slices/internationalpackage";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";

import team1 from "./../images/team/pic1.jpg";
import team2 from "./../images/team/pic2.jpg";
import team3 from "./../images/team/pic3.jpg";
import team4 from "./../images/team/pic4.jpg";
import team5 from "./../images/team/pic5.jpg";

import { Themes } from "../store/slices/package";
import Travelfooter from "./Travelfooter";

function International() {
  const [blogs, setBlogs] = useState([]);
  var today = new Date();
  let enquiryFor = "travelPackage";
  const [isShown, setIsShown] = useState(true);
  const [isShowns, setIsShowns] = useState(true);
  const [searchResult, setsearchResult] = useState([]);
  const [searchResults, setsearchResults] = useState([]);
  const [name, setName] = useState("");
  const [travelDate, settravelDate] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [theme, setTheme] = useState("");
  const [themes, setThemes] = useState([]);
  // const [enquiryFor, setenquiryFor] = useState("");
  const [expertises, setexpertise] = useState([]);
  const [alertmail, setAlertmail] = useState("");
  const [alertdate, setalertDate] = useState("");
  const [alertfn, setAlertfn] = useState("");
  const [alertmes, setAlertmes] = useState(""); /** mobile Number**/
  const [alertdest, setAlertdest] = useState("");
  const [alertper, setAlertper] = useState("");
  const [alertmessage, setAlertmessage] = useState("");
  const [querys, setQuerys] = useState("");
  const [destination, setDestination] = useState("");

  const [childcount, setchildCount] = useState("");
  const [alertchild, setAlertchild] = useState("");
  const [adultCount, setadultCount] = useState("");
  const peopleCount = adultCount + " Adult, " + childcount + " Children ";

  const [query, setQuery] = useState([]);
  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Increase and Decrease Numbers Hooks Start
  const [count_A, setCount_A] = useState(1);
  const [count_C, setCount_C] = useState(0);
  const [count_R, setCount_R] = useState(1);

  const IncNum_A = () => {
    setCount_A(count_A + 1);
  };

  const IncNum_C = () => {
    setCount_C(count_C + 1);
  };

  const IncNum_R = () => {
    setCount_R(count_R + 1);
  };

  const DecNum_A = () => {
    setCount_A((count_A) => Math.max(count_A - 1, 1));
  };

  const DecNum_C = () => {
    setCount_C((count_C) => Math.max(count_C - 1, 0));
  };

  const DecNum_R = () => {
    setCount_R((count_R) => Math.max(count_R - 1, 1));
  };
  // Increase and Decrease Numbers Hooks End

  const formSubmit = (e) => {
    const fName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fName.test(name)) {
      setAlertfn("");
    } else if (!fName.test(name) && name === "") {
      setAlertfn("Please enter your name");

      e.preventDefault();
    } else {
      setAlertfn("Number's not allowed");

      e.preventDefault();
    }

    const tain = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (tain.test(destination)) {
      setAlertdest("");
    } else if (!tain.test(destination) && destination === "") {
      setAlertdest("Please enter the destination");

      e.preventDefault();
    } else {
      setAlertdest("");

      e.preventDefault();
    }

    const mess = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (mess.test(message)) {
      setAlertmessage("");
    } else if (!mess.test(message) && message === "") {
      setAlertmessage("Please enter the message");

      e.preventDefault();
    } else {
      setAlertmessage("");

      e.preventDefault();
    }

    const son = /^[1-9][0-9]?$|^100$/;
    if (son.test(adultCount)) {
      setAlertper("");
    } else if (!son.test(adultCount) && adultCount === "") {
      setAlertper("Please enter  the no.of adults");
      e.preventDefault();
    } else {
      setAlertper("Min value should be 1");

      e.preventDefault();
    }

    const child = /^[0-9][0-9]?$|^100$/;
    if (child.test(childcount)) {
      setAlertchild("");
    } else if (!child.test(childcount) && childcount === "") {
      setAlertchild("Please enter the no.of child");
      e.preventDefault();
    } else {
      setAlertchild("");

      e.preventDefault();
    }

    const vDate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate.test(travelDate)) {
      setalertDate("");
    } else if (!vDate.test(travelDate) && travelDate === "") {
      setalertDate("Please enter your date of travel");
      e.preventDefault();
    } else {
      setalertDate("");
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile.test(contactNumber)) {
      setAlertmes("");
    } else if (!mobile.test(contactNumber) && contactNumber === "") {
      setAlertmes("Please enter the mobile  number");

      e.preventDefault();
    } else {
      setAlertmes("Please enter a valid mobile number");

      e.preventDefault();
    }

    const textvil = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (textvil.test(email)) {
      setAlertmail("");
    } else if (!textvil.test(email) && email === "") {
      setAlertmail("Please enter the  email");

      e.preventDefault();
    } else {
      setAlertmail("Please enter a valid email");

      e.preventDefault();
    }

    // const tdate =
    //   /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    // if (!tdate.test(travelDate) && travelDate === "") {
    //   setalertDate("Please enter travel date");
    // }
  };

  React.useEffect(() => {
    dispatch(Themes())
      .unwrap()
      .then((data) => {
        setThemes(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  const formReset = (e) => {
    setDestination("");
    setMessage("");
    setchildCount("");
    setName("");
    setEmail("");
    setcontactNumber("");
    setadultCount("");
    settravelDate("");

    setAlertmail("");
    setAlertfn("");
    setAlertmes("");
    setAlertdest("");
    setAlertper("");
    setAlertmessage("");
    setchildCount("");
    setAlertchild("");
  };
  //submit enquiry
  async function upload(e) {
    e.preventDefault();
    let item = {
      name,
      email,
      contactNumber,
      message,
      enquiryFor,
      travelDate,
      destination,
      peopleCount,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        navigate("/travelonify/international-holidays");
        setName("");
        settravelDate("");
        setEmail("");
        setcontactNumber("");
        setMessage("");
        setDestination("");
        setchildCount("");
        setadultCount("");
      })
      .catch(({ emessage }) => {
        alert("Error");
      });
  }
  const myData = {
    theme: querys,
  };

  const myDatas = {
    destination: query,
  };
  async function searchDestination(e) {
    e.preventDefault();
    navigate("/travelonify/SearchByDestination/", { state: myDatas });
  }

  async function searchTheme(e) {
    e.preventDefault();
    navigate("/travelonify/SearchByTheme/", { state: myData });
  }

  //get packages
  React.useEffect(() => {
    dispatch(InternationalPackage())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getTravelblog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const handleThemeChange = (e) => {
    var value = themes.filter(function (category) {
      return category.name == e.target.value;
    });
    setTheme(value[0].name);
  };
  return (
    <div id="_next">
      <TravelHeader />
      <section>
        <div className="carousel_cover">
          <Carousel fade>
            <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel1}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top">
                <div className="d-flex justify-content-center">
                  <h1 className="text1">
                    Create your dream memories with Travelonify
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="overlaywhite-text">
                    Search&gt;&gt; Select&gt;&gt; Book&gt;&gt; Pay
                    online&gt;&gt; Get Going
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel2}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top">
                <div className="d-flex justify-content-center">
                  <h1 className="text1">
                    Create your dream memories with Travelonify
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="overlaywhite-text">
                    Search&gt;&gt; Select&gt;&gt; Book&gt;&gt; Pay
                    online&gt;&gt; Get Going
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={travel3}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top">
                <div className="d-flex justify-content-center">
                  <h1 className="text1">
                    Create your dream memories with Travelonify
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="overlaywhite-text">
                    Search&gt;&gt; Select&gt;&gt; Book&gt;&gt; Pay
                    online&gt;&gt; Get Going
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className="container">
            <div className="carousel-inner-content-overlay">
              <Tabs
                // defaultActiveKey="home"
                style={{ background: "white", borderRadius: "5px" }}
                id="uncontrolled-tab-example"
                // className="mb-1"
              >
                <Tab
                  eventKey="home"
                  title="Enter Destination"
                  // style={{ height: "20px" }}
                >
                  <form onSubmit={searchDestination}>
                    <div className="row" style={{ padding: "5px 0" }}>
                      <div className="col-md-9 col-xs-6">
                        <input
                          type="text"
                          placeholder="Search by Destination"
                          className="search-mod border-active-none"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                      <div
                        className="col-md-3 col-xs-6"
                        align="left"
                        style={{ padding: "0%" }}
                      >
                        <button
                          type="submit"
                          className="btn btn-blue col-white"
                          style={{ width: "100%" }}
                          id="save"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab>
                <Tab eventKey="profile" title="Search by Theme">
                  <form onSubmit={searchTheme}>
                    <div className="row" style={{ padding: "5px 0" }}>
                      <div className="col-md-10 col-xs-6">
                        {/* <input
                          type="text"
                          placeholder="Search by  Theme"
                          className="search-mod"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        /> */}
                        <select
                          className="form-control"
                          onChange={handleThemeChange}
                          required
                        >
                          <option value="">Select</option>
                          {themes.map((option) => (
                            <option value={option.name}>{option.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-xs-6" align="left">
                        <button
                          type="submit"
                          className="btn btn-blue col-white"
                          style={{ width: "100%", height: "40px" }}
                          id="save"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      <div
        className="container"
        style={{
          display: (isShown, isShowns) ? "block" : "none",
        }}
      >
        <form className="contact-form" onSubmit={upload} name="form" id="forms">
          <h2 align="center" style={{ color: "#001E79 " }}>
            Plan your Holidays with our Travel Expert - Please fill in your
            details
          </h2>
          <br />
          <div className="row">
            <div className="col-md-3">
              <p className="ptext">Name</p>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                />

                <p className="alert-message">{alertfn}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Mobile No</p>
                <input
                  type="text"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                  id="num"
                />
                <p className="alert-message"> {alertmes}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Email</p>
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="mail"
                />
                <p className="alert-message">{alertmail}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Travel Date</p>
                <input
                  type="date"
                  name="datemin"
                  placeholder="Travel Date"
                  value={travelDate}
                  onChange={(e) => settravelDate(e.target.value)}
                  className="form-control"
                  id="date"
                  required
                />

                <p className="alert-message">{alertdate}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Destination</p>
                <input
                  type="text"
                  placeholder="Destination"
                  className="form-control"
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                  id="des"
                />
                <p className="alert-message">{alertdest}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">No.of Adult</p>
                <input
                  type="number"
                  placeholder="No.of Adult"
                  className="form-control"
                  min="1"
                  value={adultCount}
                  id="adult"
                  onChange={(e) => setadultCount(e.target.value)}
                />
                <p className="alert-message">{alertper}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">No.of Children</p>
                <input
                  type="number"
                  placeholder="No.of Children"
                  className="form-control"
                  min="0"
                  value={childcount}
                  id="child"
                  onChange={(e) => setchildCount(e.target.value)}
                />
                <p className="alert-message">{alertchild}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p className="ptext">Brief us on your requirement</p>
                <input
                  type="text"
                  placeholder="Brief us on your requirement"
                  className="form-control"
                  value={message}
                  id="message"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertmessage}</p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white submit-btn"
                  onClick={formSubmit}
                  id="save"
                >
                  Submit
                </button>
                &nbsp; &nbsp;
                <input
                  type="reset"
                  className="btn btn-blue col-white submit-btn"
                  value="Reset"
                  onClick={formReset}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <br />

      <section
        className=" banner2 pb-5"
        style={{
          display: (isShown, isShowns) ? "block" : "none",
        }}
      >
        <div className="container">
          <div className="row">
            <center>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="pt-5 pb-4"
                  style={{ color: "#001E79 ", fontSize: "25px" }}
                >
                  Travelonify Special
                </h2>
              </div>
            </center>

            <div className="container">
              <Slider {...settings}>
                {expertises.map((PackageVal, index) => (
                  <Link
                    to={`/travelonify/Traveldetails/${
                      PackageVal._id ? PackageVal._id : null
                    }`}
                  >
                    <div className="slide slick-space">
                      <div className="card_slicks-mod">
                        <img
                          src={PackageVal.imageUrl[0].mediaLink}
                          className="slick-img"
                        />

                        <h4
                          className="card-title slick_text"
                          style={{ fontSize: "15px", padding: "5px" }}
                        >
                          {PackageVal.packageName}
                        </h4>
                        <p className="card-text">
                          {PackageVal.duration}

                          <p
                            style={{
                              color: "#001E79",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            &#x20b9; {PackageVal.twinShareRate / 2}
                          </p>

                          <p style={{ fontSize: "10px", marginTop: "-20px" }}>
                            Price per person on twin sharing basis*
                          </p>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          display: (isShown, isShowns) ? "block" : "none",
        }}
      >
        <div className="container">
          <div className="row">
            <center>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2
                  className="pt-5 pb-4"
                  style={{ color: "#001E79 ", fontSize: "25px" }}
                >
                  Adventures You will Love
                </h2>
              </div>
            </center>

            <div className="container">
              <Slider {...settings}>
                {expertises.map((PackageVal, index) => (
                  <Link
                    to={`/travelonify/Traveldetails/${
                      PackageVal._id ? PackageVal._id : null
                    }`}
                  >
                    <div className="slide slick-space">
                      <div className="card_slicks-mod">
                        <img
                          src={PackageVal.imageUrl[0].mediaLink}
                          className="slick-img"
                        />
                        <div className="cardsss">
                          <h4
                            className="card-title slick_text"
                            style={{ fontSize: "15px", padding: "5px" }}
                          >
                            {PackageVal.packageName}
                          </h4>
                          <p className="card-text">
                            {PackageVal.duration}

                            <p
                              style={{
                                color: "#001E79",
                                fontSize: "20px",
                                fontWeight: "700",
                              }}
                            >
                              &#x20b9; {PackageVal.twinShareRate}
                            </p>

                            <p style={{ fontSize: "10px", marginTop: "-20px" }}>
                              Price per person on twin sharing basis*
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <section className="hotelbanner">
        <div className="col-lg-12 col-md-12">
          <p className="form_text-top" style={{ color: "white" }}>
            Hotel Booking
          </p>
          <div className="container">
            <form>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-md-12">
                  <p className="ptext" style={{ color: "white" }}>
                    Destination
                  </p>
                  <input
                    type="text"
                    placeholder="Destination"
                    className="hotel_form"
                    style={{ height: "50px" }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <p className="ptext" style={{ color: "white" }}>
                    From
                  </p>
                  <input
                    type="date"
                    className="hotel_form"
                    style={{ height: "50px" }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <p className="ptext" style={{ color: "white" }}>
                    To
                  </p>
                  <input
                    type="date"
                    className="hotel_form"
                    style={{ height: "50px" }}
                  />
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <p className="ptext" style={{ color: "white" }}>
                    Destination
                  </p>
                  <Dropdown className="">
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                      className="drop_downs sub_names"
                    >
                      {count_A} Adults , {count_C} Children , {count_R} Rooms
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="droper">
                      <Dropdown className="sub_dropers">
                        <span className="sub_names">Adults</span>{" "}
                        <span className="math">
                          <span className="clickmath" onClick={DecNum_A}>
                            <AiOutlineMinusCircle />
                          </span>
                          <span className="count"> {count_A}</span>
                          <span className="clickmath" onClick={IncNum_A}>
                            <AiOutlinePlusCircle />
                          </span>
                        </span>
                      </Dropdown>
                      <Dropdown className="sub_dropers">
                        <span className="sub_names">Children</span>{" "}
                        <span className="math">
                          <span className="clickmath" onClick={DecNum_C}>
                            <AiOutlineMinusCircle />
                          </span>
                          <span className="count"> {count_C}</span>
                          <span className="clickmath" onClick={IncNum_C}>
                            <AiOutlinePlusCircle />
                          </span>
                        </span>
                      </Dropdown>
                      <Dropdown className="sub_dropers">
                        <span className="sub_names">Rooms</span>{" "}
                        <span className="math">
                          <span className="clickmath" onClick={DecNum_R}>
                            <AiOutlineMinusCircle />
                          </span>
                          <span className="count"> {count_R}</span>
                          <span className="clickmath" onClick={IncNum_R}>
                            <AiOutlinePlusCircle />
                          </span>
                        </span>
                      </Dropdown>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="d-flex justify-content-center pt-3 pb-4">
                <button className="btn btn-warning col-white submit-btn">
                  Submit
                </button>
                &nbsp; &nbsp;
                <button className="btn btn-blue col-white submit-btn">
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <div
        className="container pb-5"
        style={{
          display: (isShown, isShowns) ? "block" : "none",
        }}
      >
        <h2 className="mt-5 mb-5" align="center" style={{ color: "#001E79 " }}>
          Travelonify dream deals on Hotel Bookings
        </h2>
        <Slider {...settings}>
          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={india} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Trident
                </h4>
              </div>
            </div>
          </Link>
          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Japan} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Lemon Tree
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={USA} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  OYO
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={mumbai} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Country Club
                </h4>
              </div>
            </div>
          </Link>

          <Link to="/travelonify/HotelDetails">
            <div className="slide slick-space">
              <div className="card_slicks">
                <img src={Kerla} className="slick-img" />

                <h4
                  className="card-title pt-2 pb-2"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Taj
                </h4>
              </div>
            </div>
          </Link>
        </Slider>
      </div>

      <section
        className="banner_carosel pt-5 pb-5"
        style={{
          display: (isShown, isShowns) ? "block" : "none",
        }}
      >
        <Carousel className="carousel_small_mob">
          <Carousel.Item interval={1500}>
            <img className="d-block w-100 carimg2" src={tour2} />
            <Carousel.Caption>
              <p style={{ color: "white" }}>Offer's</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img className="d-block w-100 carimg2" src={tour1} />
            <Carousel.Caption>
              <p style={{ color: "white" }}>Offer's</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section
        className="testimonial"
        style={{
          display: (isShown, isShowns) ? "block" : "none",
        }}
      >
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <p className="wel-text pb-3">Testimonial</p>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-orange-card fix_space">
                <div className="circle-profile d-flex justify-content-center">
                  <img src={team1} alt="" />
                </div>
                {/* <h4>Dinesh Rao</h4> */}

                <p className="col-white">
                  We really enjoyed the trip. Everything was well organized and
                  our tour guide / Cab Driver and hotel staff were very nice.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-orange-card fix_space">
                <div className="circle-profile d-flex justify-content-center">
                  <img src={team2} alt="" />
                </div>
                {/* <h4>Rahul</h4> */}

                <p className="col-white">
                  We are back from our wonderful trip and all thanks to
                  Travelonify. Planning and bookings were well done as expected.
                  Your set itinerary made the best use of our time...
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-orange-card fix_space">
                <div className="circle-profile d-flex justify-content-center">
                  <img src={team3} alt="" />
                </div>
                {/* <h4>Gagan</h4> */}

                <p className="col-white">
                  Thanx team Travelonify for creating a wonderful travel
                  experience. Already planning my next holiday soon thru you.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-features-item bg-orange-card fix_space">
                <div className="circle-profile d-flex justify-content-center">
                  <img src={team4} alt="" />
                </div>
                {/* <h4>Puneet</h4> */}

                <p className="col-white">
                  What an experience..Hotel, food, sightseeing, local travel..
                  Everything was taken care of well. Happy to refer you to my
                  family and friends.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="livewell container pt-2 pb-5">
        <div className="col-lg-12">
          <p className="wel-text pt-2">Blog</p>
        </div>
        <div className="container">
          <Slider {...settings}>
            {blogs.map((blogsVal, index) => (
              <div className="d-flex justify-content-center">
                <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                  <div
                    className="col-12"
                    style={{
                      background: "rgba(128, 128, 128,0.2)",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    align="center"
                  >
                    <img
                      src={blogsVal.mediaLink}
                      alt=""
                      className="lie-img-slide"
                    />
                    <p>{blogsVal.blogTitle}</p>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <Travelfooter />
    </div>
  );
}

export default International;
