import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Navbar from "./navbar";

const Arthitis = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Arthitis</h3>
        </div>

        <div className="pt-4">
          <p>
            Arthritis is a painful condition affecting the body’s joints.
            Arthritis can vary in severity, from mild to severe, and affect a
            person at any age. Arthritis doesn’t have a cure but it doesn’t have
            to remain painful. It can be effectively managed.
            <br />
            There are over 100 different types of arthritis, however, three of
            the most common are osteoarthritis, rheumatoid arthritis, and gout.
          </p>
          <p>
            <b> The Symptoms of Arthritis</b>
            <br />
            The symptoms of arthritis vary among individuals. Although there are
            some common symptoms that are seen in most people with arthritis.
            These include:
            <br />
            <ul>
              <li>Pain </li>
              <li> Inflammation </li>
              <li> Swelling and redness </li>
              <li> Stiffness </li>
              <li>Reduced movement</li>
            </ul>
            There are other symptoms not associated with the joints that some
            people may experience with arthritis such as:
            <br />
            <ul>
              <li> Fatigue</li>
              <li> Skin issues</li>
              <li> Weight loss</li>
              <li> Malaise (feeling unwell)</li>
            </ul>
          </p>
          <p>
            <b>How Effective Is Physiotherapy for Arthritis?</b>
            <br />
            Managing your symptoms and looking after your joints are important
            aspects in the treatment of arthritis. Your physio will suggest a
            number of techniques to help ease your symptoms and reduce the
            impact that arthritis has on your life.
            <br />
            Arthritis is a degenerative condition and physiotherapy for
            arthritis aims to improve symptoms and quality of life for patients.
            Often you will begin to see a positive improvement after a few
            sessions. The main goals of physio for arthritis include the
            following:
            <br />
            <ul>
              <li>Increase your range of motion </li>
              <li> Reduce muscle and joint pain </li>
              <li>Reduce inflammation </li>
              <li> Strengthen muscles </li>
              <li> Improve muscle length</li>
              <li>
                Improve balance, proprioception (joint position sense) and
                mobility
              </li>
              <li>
                Improve movement quality with advice and movement education
              </li>
              <li> Regain and maintain movement in joints and soft tissues </li>
              <li> Reduce acute episodes of pain</li>
            </ul>
          </p>
          <p>
            <b>Types Of Physiotherapy Treatments</b>
            <br />
            Your treatment for arthritis will depend on the joints affected,
            your goals, and any existing pain. Treatment may include are:
            <br />
            <ul>
              <li>
                A specific exercise program to correct muscle weakness around
                the affected joint
              </li>
              <li> A weight loss program if you’re overweight</li>
              <li> Joint mobilisation techniques </li>
              <li> Dry needling and massage to reduce local muscle pain </li>
              <li> Advice on hydrotherapy (water-based exercise) </li>
              <li> Taping and bracing</li>
            </ul>
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Arthitis;
