import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./navbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getblog } from "../store/slices/blog";

const Blog = () => {
  const dispatch = useDispatch();
  const [blogTitle, setblogTitle] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();
  localStorage.setItem("id", id);
  localStorage.setItem("packageId", id);
  React.useEffect(() => {
    dispatch(getblog())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setblogTitle(data.user.data[0].blogTitle);
          setmediaLink(data.user.data[0].mediaLink);
          setDescription(data.user.data[0].description);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <Header />
      <section className="pt-5 mt-5 mb-4">
        <div className="col-lg-12 d-flex justify-content-center">
          <img src={mediaLink} alt="blog-img" className="blog-img" />
        </div>

        <div className="container">
          <div className="col-lg-12 pt-3 mb-3">
            <h5 className="blog-head-text pt-5">{blogTitle}</h5>

            <p
              className="blog-text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Blog;
