import React from "react";
import Logo from "./logo.png";

import { Link } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Privacy = () => {
  return (
    <div id="_next">
      <div id="navbar" className="navbar-area navbar-two">
        <div className="neemo-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="logo" to="/">
                <img src={Logo} alt="logo" style={{ width: "150px" }} />
              </Link>
              <button
                className="navbar-toggler navbar-toggler-right collapsed"
                type="button"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="/#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/physiotherapy">
                      Physiotherapy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Travel <ArrowDropDownIcon />
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/domestic-adventure"
                        >
                          Domestic Adventure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/international-adventure"
                        >
                          International Adventure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/domestic-leisure"
                        >
                          Domestic Leisure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/travel/international-leisure"
                        >
                          International Leisure
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/travel/wellness">
                          Wellness
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/weight-loss">
                      Weight Loss
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="others-options">
                <div className="option-item">
                  <i
                    className="search-btn fa fa-search"
                    style={{ display: "block" }}
                  />
                  <i className="close-btn fa fa-times " />
                  <div
                    className="search-overlay search-popup"
                    style={{ display: "none" }}
                  >
                    <div className="search-box">
                      <form className="search-form">
                        <input
                          type="text"
                          className="search-input"
                          name="search"
                          placeholder="Search"
                        />
                        <button className="search-button" type="submit">
                          <i className="fas fa-search" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>PRIVACY POLICY</h2>
            <ul></ul>
          </div>
        </div>
      </div>

      <section className="about-section pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="about-area">
                <h5 align="center">GENERAL</h5>

                <p>
                  Healthonify Private Limited (“Healthonify”) is committed to
                  the protection of personal information provided by the
                  Customer, Seller and/or Partner Gym to Healthonify. Customer,
                  Seller and/or Partner Gym agree that use of Healthonify
                  Website implies consent to the collection, retention and use
                  of Customer, Seller and/or Partner Gym personal information in
                  accordance with the terms of this Privacy Policy (“Privacy
                  Policy”).
                </p>

                <p>
                  Healthonify takes the privacy of Customer, Seller and/or
                  Partner Gym seriously. Healthonify is committed to
                  safeguarding the privacy of Customer, Seller and/or Partner
                  Gym while providing Healthonify Website in a personalized and
                  valuable manner.
                </p>

                <p>
                  No information provided by the Customer, Seller and/or Partner
                  Gym to Healthonify is rented or sold to any third party. When
                  a Customer, Seller and/or Partner Gym uses the Healthonify
                  Website their IP address and other personal information may
                  automatically get stored. A high standard of security is
                  maintained by Healthonify for Customer, Seller and/or Partner
                  Gym. However, the transmission of information via the internet
                  and web networks is not completely secure. While Healthonify
                  does its best to protect Customer, Seller and/or Partner Gym’
                  information, particularly with respect to protection of
                  Customer, Seller and/or Partner Gym’ personal data.
                  Healthonify cannot ensure the security of Customer, Seller
                  and/or Partner Gym’ data transmitted via the internet, web
                  network or any other networks.
                </p>

                <p>
                  Access to the contents of Healthonify is conditional upon
                  Customer, Seller and/or Partner Gym’s approval of the Privacy
                  Policy which should be read together with the Terms and
                  Conditions (“Terms”). Customer, Seller and/or Partner Gym
                  acknowledge that this Privacy Policy, together with Terms and
                  Conditions, forms a legally binding agreement with Healthonify
                  in relation to the use of Healthonify Website (“Agreement”).
                  All capitalized terms used but not defined herein shall have
                  the meaning ascribed to such term in the Terms.
                </p>

                <p>
                  Healthonify reserves the right to contact the customer via any
                  mechanisms, not limited to Email, SMS, or phone calls, to
                  solicit feedback of customer's experience and to provide any
                  additional services that customer would be eligible for,
                  either as a customer or as a visitor.
                </p>

                <h5 align="center" style={{ marginTop: "20px" }}>
                  INFORMATION COLLECTED
                </h5>

                <h5>Traffic Data Collected</h5>
                <p>
                  Healthonify automatically track and collect the following
                  categories of information when Customer, Seller and/or Partner
                  Gym access the Website:
                </p>

                <p>IP addresses;</p>

                <p>Domain server details;</p>

                <p>Types of devices accessing the Website;</p>

                <p>
                  Referring source which may have sent Customer, Seller and/or
                  Partner Gym to the Website; and/or
                </p>

                <p>
                  Other information associated with the interaction of Customer,
                  Seller and/or Partner Gym’s device and the Website
                  (collectively "Traffic Data”).
                </p>

                <h5>Personal Information Collected</h5>

                <p>
                  In order for Customer, Seller and/or Partner Gym to access
                  certain areas of the Website, Healthonify may require
                  Customer, Seller and/or Partner Gym to provide Healthonify
                  with certain information that personally identifies such
                  Customer, Seller and/or Partner Gym ("Personal Information").
                  Personal Information inter alia includes the following
                  categories of information:
                </p>

                <p>
                  Contact Data (such as e-mail address, phone number and any
                  other contact details); and/or
                </p>

                <p>
                  Demographic Data (optional) (such as time zone, postal address
                  and location details).
                </p>

                <p>
                  If a Customer, Seller and/or Partner Gym communicates with
                  Healthonify by, for example, e-mail or letter, any information
                  provided in such communication may be retained as Personal
                  Information by Healthonify.
                </p>

                <p>
                  Website may contain links to third party websites. Customer,
                  Seller and/or Partner Gym agree and understand that privacy
                  policies of these third party websites are not under control
                  of Healthonify. Customer, Seller and/or Partner Gym understand
                  that once a Customer, Seller and/or Partner Gym leaves
                  Healthonify’s servers, any information such Customer, Seller
                  and/or Partner Gym provides will be governed by the privacy
                  policy of the operator of the third party website used by such
                  Customer, Seller and/or Partner Gym.
                </p>

                <h5 align="center" style={{ marginTop: "20px" }}>
                  DISCLOSURE OF PERSONAL INFORMATION
                </h5>

                <p>
                  Healthonify reserves the right to disclose Personal
                  Information if required to do so by law or if Healthonify
                  believes that it is necessary to do so to protect and defend
                  the rights, property or personal safety of Healthonify, the
                  Healthonify Website, or other Customer, Seller and/or Partner
                  Gym.
                </p>

                <p>
                  We may disclose to third party services certain personally
                  identifiable information listed below:
                </p>

                <p>
                  information you provide us such as name, email, mobile phone
                  number.
                </p>

                <p>
                  information we collect as you access and use our service,
                  including device information, location and network carrier
                </p>

                <p>
                  This information is shared with third party service providers
                  so that we can personalize the app for you perform behavioral
                  analytics
                </p>

                <h5 align="center" style={{ marginTop: "20px" }}>
                  CONFIDENTIALITY AND SECURITY
                </h5>

                <p>
                  Except as otherwise provided in this Privacy Policy,
                  Healthonify will keep all Personal Information private and
                  will not share it with third parties, unless Healthonify
                  believes in good faith that disclosure of such Personal
                  Information or any other information Healthonify collects
                  about Customer, Seller and/or Partner Gym is necessary for
                  Permitted Use or to:
                </p>

                <p>Comply with a court order or other legal process;</p>

                <p>Enforce the Agreement, including Terms; or</p>

                <p>
                  Respond to claims that any posting or other content violates
                  the rights of third-parties.
                </p>

                <h5 align="center" style={{ marginTop: "20px" }}>
                  SECURITY
                </h5>

                <p>
                  The security of Customer, Seller and/or Partner Gym’ Personal
                  Information is important to Healthonify. Healthonify follows
                  generally accepted industry standards to protect the Personal
                  Information submitted to Healthonify, both during transmission
                  and once Healthonify receives it.
                </p>

                <p>
                  Although Healthonify makes best possible efforts to store
                  Personal Information in a secure operating environment that is
                  not open to the public, Customer, Seller and/or Partner Gym
                  should understand that there is no such thing as complete
                  security, and Healthonify does not guarantee that there will
                  be no unintended disclosures of Personal Information. If
                  Healthonify becomes aware that certain Personal Information
                  has been disclosed in a manner not in accordance with this
                  Privacy Policy, Healthonify will use reasonable efforts to
                  notify the concerned Customer, Seller and/or Partner Gym of
                  the nature and extent of such disclosure (to the extent
                  Healthonify is aware of that information) as soon as
                  reasonably possible and as permitted by law.
                </p>

                <h5 style={{ marginTop: "20px" }}>
                  UPDATES AND CHANGES TO PRIVACY POLICY
                </h5>

                <p>
                  Healthonify reserves the right, at any time, to add to,
                  change, update, or modify this Privacy Policy. Customer,
                  Seller and/or Partner Gym are requested to review this Privacy
                  Policy frequently. If any change has been incorporated in this
                  Privacy Policy by Healthonify, then Healthonify will post such
                  changes on this page. In all cases, use of information
                  collected by Healthonify is subject to this Privacy Policy.
                </p>

                <h5 style={{ marginTop: "20px" }}>
                  CUSTOMER, SELLER AND/OR PARTNER GYM RIGHTS
                </h5>

                <p>
                  Customer, Seller and/or Partner Gym have a right to correct
                  any errors in such Customer, Seller and/or Partner Gym’s
                  Personal Information available with Healthonify. A Customer,
                  Seller and/or Partner Gym may request Healthonify in writing
                  that Healthonify ceases to use such Customer, Seller and/or
                  Partner Gym’s Personal Information. Healthonify may stop
                  providing Services to such a Customer, Seller and/or Partner
                  Gym, if so required.
                </p>

                <h5 style={{ marginTop: "20px" }}>
                  CUSTOMER, SELLER AND/OR PARTNER GYM RIGHTS
                </h5>

                <p>
                  No warranty of any kind, implied, expressed or statutory,
                  including but not limited to the warranties of
                  non-infringement of third party rights, title,
                  merchantability, fitness for a particular purpose and freedom
                  from computer virus, is given with respect to the Services
                  provided by Healthonify, including provisions of Website.
                </p>

                <p>
                  Any reference on the Website to any specific commercial
                  products, processes, or services, or the use of any trade,
                  firm or corporation name is for the information and
                  convenience of the public, and does not constitute
                  endorsement, recommendation, or favoring by Healthonify.
                </p>

                <p>
                  Healthonify Website include provision of an online platform
                  for facilitating buying and selling of Health & Fitness
                  Products and Wellness Services. The Health & Fitness Products
                  and Wellness Services made available through the Healthonify
                  Website for sale are sold on ‘as-is’ basis and Healthonify has
                  no responsibility with respect to adequacy, relevancy or
                  authenticity of such Health & Fitness Products and Wellness
                  Services. Healthonify is not responsible for any information
                  provided by one Customer, Seller and/or Partner Gym to the
                  other Customer, Seller and/or Partner Gym, or by Seller to the
                  Customer, or by Customer to the Seller, in the course of
                  Service and this Privacy Policy governs Healthonify’s
                  relationship with the Customer, Seller and/or Partner Gym only
                  with respect to information provided by the Customer, Seller
                  and/or Partner Gym to Healthonify and/or the information
                  collected by Healthonify from the Customer, Seller and/or
                  Partner Gym.
                </p>

                <p>
                  If Customer, Seller and/or Partner Gym have questions or
                  concerns, they may email Healthonify at{" "}
                  <a href="mailto:support@healthonify.com">
                    support@healthonify.com
                  </a>{" "}
                  and Healthonify will attempt to address Customer, Seller
                  and/or Partner Gym’ concerns at the earliest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <br></br>
      <footer className="footer-section pt-100 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="footer-area">
                <img src={Logo} alt="" style={{ width: "150px" }} />
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-6" align="right">
              <div className="footer-item-area">
                <Link to="/" style={{ color: "white" }}>
                  Career
                </Link>{" "}
                &nbsp; &nbsp;
                <Link to="/About" style={{ color: "white" }}>
                  About Us
                </Link>{" "}
                &nbsp; &nbsp;
                <Link to="/Privacy" style={{ color: "white" }}>
                  Privacy Policy
                </Link>
                &nbsp; &nbsp;
                <Link to="/Refund" style={{ color: "white" }}>
                  Refund Policy
                </Link>
                &nbsp; &nbsp;
                <Link to="/Terms" style={{ color: "white" }}>
                  Terms & Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <p>
                © 2021 All Rights Reserved | Powered By &nbsp;
                <a href="https://octaloptimum.com/" target="_blank">
                  Octal Optimum Technology
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
