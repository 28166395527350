import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ReminderService {
   
    static getmeal() {
        let api='';
        api =  API_PATHS.getmealrem;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static getwater() {
        let api='';
        api =  API_PATHS.getwaterrem;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static getwalk() {
        let api='';
        api =  API_PATHS.getwalkrem;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static getsleep() {
        let api='';
        api =  API_PATHS.getsleeprem;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static postmeal(item) {
      return axios
          .post(API_PATHS.postmealrem,
              item
          )
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          });
  }


  static postwater(item) {
    return axios
        .post(API_PATHS.postwaterrem,
            item
        )
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static postwalk(item) {
    return axios
        .post(API_PATHS.postwalkrem,
            item
        )
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static postsleep(item) {
    return axios
        .post(API_PATHS.postsleeprem,
            item
        )
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default ReminderService;