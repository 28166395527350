import React, { Component } from "react";
import Footer from "./Footer";
import logo from "./logo.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "./logo.png";

import { MdDashboard } from "react-icons/md";
import {
  BsBookFill,
  BsFillChatRightFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { BiCubeAlt } from "react-icons/bi";

import { MdNotifications } from "react-icons/md";
import { MdSettings } from "react-icons/md";
import user from "./../images/client-image/3.jpg";
import UserNavbar from "./UserNavbar";


const MyTravel =() => {
  return (
    <div className="bg-light">
    <UserNavbar />

    <div className="container-fluid response-cover">
    <div className="row">
      <div className="col-lg-2 col-md-4" />
      <div className="col-lg-10 col-md-8">

      <div className="container pt-5">
      <div className="pt-5 mt-5">
      <div className="col-md-12">
      <h3 className="mb-2">My Expert</h3>
      </div>     
      <div className="bg-white-ptbrl-2 mb-5 mt-1">
      <div className="table-responsive" >
      <table className="table table-hover">
      <thead className="table-dark">
                <tr>
                  <th className="wd-15p">Doctor &nbsp;</th>
                  <th className="wd-15p">Health expert &nbsp;</th>
                  <th className="wd-20p">Physio &nbsp;</th>
                  <th className="wd-15p">Trainer &nbsp;</th>
                  <th className="wd-15p">Dietitian &nbsp;</th>
                  <th className="wd-15p">Others &nbsp;</th>
              
                </tr>
              </thead>
              <tbody>
              <tr>
              
              <td>null</td>
              <td>null</td>
              <td>null</td>
              <td>null</td>
              <td>null</td>
              <td>null</td>

              </tr>
              </tbody>
            </table>
          </div>
          </div>
        
      </div>
    </div>

      </div>
      </div>
      </div>
     
    </div>
  );
}
export default MyTravel;
