import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class Questionnaire {

    static getquestion() {
        return axios
            .get(API_PATHS.getquestion)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    

    static postAnswer(item) {
        return axios
            .post(API_PATHS.storeAnswer,item)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default Questionnaire;