import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class travelPackage {
    static specialPackage() {
        let api = '';
        let id = localStorage.getItem("id"); 
          api = id ? API_PATHS.packagetravel + '?_id=' + id + '&?isSpecial=true' : API_PATHS.packagetravel + '?isSpecial=true';
        localStorage.removeItem("id");
        return axios
          .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

    static packagetravel() {
      let api = '';
        // let id = localStorage.getItem("id"); 
          api = API_PATHS.packagetravel + '?isApproved=true';
        localStorage.removeItem("id");
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static packageDetails() {
      let api = '';
        let id = localStorage.getItem("id"); 
        api = id ? API_PATHS.packagetravel + '?_id=' + id : API_PATHS.packagetravel;
        localStorage.removeItem("id");
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    


    static bestcityPackage() {
     
        return axios
          .get(API_PATHS.bestCity)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static searchPackage() {
      let api = '';
      let id = localStorage.getItem("id");
        api = API_PATHS.packageSearch + '?query=' + id + '&destination=1';
     
      return axios
        .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    
    static searchbyPackage() {
      let api = '';
      let item = JSON.parse(localStorage.getItem("item"));
        api = API_PATHS.packageSearch + '?query=' + item.query + '&destination=1';
     
      return axios
        .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static searchByThemes() {
      let api = '';
      let item = JSON.parse(localStorage.getItem("item"));
        api = API_PATHS.packageSearch + '?query=' + item.querys + '&theme=1';
     
      return axios
        .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static searchThemes() {
      let api = '';
      let id = localStorage.getItem("id");
        api = API_PATHS.packageSearch + '?query=' + id + '&theme=1';
     
      return axios
        .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    
    static Honeymoon() {
      let api=""
        api = API_PATHS.packageSearch + '?query=Honeymoon&theme=1';
     
      return axios
        .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static Themes() {
      let api = '';
          api = API_PATHS.getTheme;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

   

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default travelPackage;