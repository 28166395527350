export const API_BASE_URL =
    process.env.API_BASE_URL || "https://api.healthonify.com/";

export const API_BASE_URL_WM =
    process.env.API_BASE_URL || "https://api-wm.healthonify.com/";

    export const API_BASE_URL_HC =
process.env.API_BASE_URL || "https://api-hc.healthonify.com";

export const API_PATHS = {

    //upload media
    uploadImage: API_BASE_URL_WM + "/upload",

    //auth
    login: "/login",
    register: "/register",
    verifyOtp: "/verifyOtp",

    //travelonify
    packagetravel: "/get/travelPackage",
    bestCity:"get/travelPackage?cityId=62a85614c7eadd561cf0c6b6",
    getCity:"/get/city?isActive=true",
    getCityDetails:"/get/city",
    internationalPackage:"/get/travelPackage?categoryId=6253b71c3bf3d42d6a413f07",
    indianPackage:"/get/travelPackage?categoryId=6294711a96d961109b5844b7",
    packageSearch: "/search/travelPackage",
    calculatePrice:"/travel/pricing",
    calculatehotelPrice:"/hotel/pricing",
    packagebooking:"/travel/Booking",
    paymentStore:"/payment/store",
    getroomGroup: "/get/roomGroup",
    getroomGrouphotel: "/get/roomGroup?hotelId=629c47bdc221275684fa437a",
    booking:"get/travelBooking",
    getTheme: "/get/travelTheme",
    getHotel:"/get/hotel",
    searchotel:"/fetch/searchHotel",


    //faq
    frequently: "/get/faq",
    searchFitnesscenter:API_BASE_URL_WM + "/fitness/searchFitnessCenters",

    //
    appointments:"/user/fetchAllTypesConsultationsAndSessions",
    trendingfitnesscenter:API_BASE_URL_WM + "/get/trendingLabsAndFitnessCenters",
   
    //enquiry
    postEnquiry: "/enquiry/saveEnquiry",
    postWmEnquiry:API_BASE_URL_WM + "/wmEnquiry/saveWmEnquiry",
    postfitnessenquire:  API_BASE_URL_WM  + "/saveEnquiryForm",
 
    //fitness tool
    fittoolscalculator:API_BASE_URL_WM + "/calculate/fitness",

    //Date and Time
    postdate:"/user/initiateFreeConsult",

    // Blog
    getblog:API_BASE_URL_WM + "/get/blog",
    gettravelblog:API_BASE_URL_WM + "/get/blog?expertiseId=62fcccde8db90c33d1dfb269",
    getphysioblog:API_BASE_URL_WM + "/get/blog?expertiseId=6229a980305897106867f787",
    getwmblog:API_BASE_URL_WM + "/get/blog?expertiseId=6229a968eb71920e5c85b0af",
    gethcblog:API_BASE_URL_WM + "/get/blog?expertiseId=6343acb2f427d20b635ec853",
    getfitnessblog:API_BASE_URL_WM + "/get/blog?expertiseId=63ef2333eef2ad2bdf410333",

// health locker 
posthealtlocker:API_BASE_URL_WM + "/post/healthLocker",
gethealtlocker:API_BASE_URL_WM + "/get/healthLocker",

 // insurance Locker
 getinsurance: API_BASE_URL_WM +"/get/insuranceLocker",
 postinsurance : API_BASE_URL_WM +"/post/insuranceLocker",

    // profile
    userprofile: "/get/user",
    putuser:"/put/user",

    postlabvendor: API_BASE_URL_HC + "/saveEnquiryForm",
    getparentexpertise: "get/expertise?parentExpertiseId=6343acb2f427d20b635ec853",
    postconsultations: API_BASE_URL_HC + "/user/consultNow",

    //get all consultations
    getAllConsultation:"/user/consultations/fetchAllTypes",

    //get prescriotion
    getprescription: API_BASE_URL_HC +"/get/hcPrescriptions",

    //goal
    postStepGoal:API_BASE_URL_WM +"/put/user",
    getStepGoal:API_BASE_URL_WM +"/get/user",

    // community post
    getcommunitypost:API_BASE_URL +"/user/getAllPosts?userId=62b19dc0d18a138255d39bcd",
    postcommunitypost:API_BASE_URL +"/post/communityPost",
    putcommunitypost:API_BASE_URL +"/put/communityPost",
    getcommunitypostdetails:API_BASE_URL +"/user/getAllPosts",
    communitypostenable:API_BASE_URL +"/put/communityPost?id=62fb4080c7917f0c6a391268",

// Reminders 
getmealrem:API_BASE_URL_WM +"/get/mealReminder?userId=628e07c3295cbb2a64996d2d",
getwaterrem:API_BASE_URL_WM +"/get/waterReminder?userId=628e07c3295cbb2a64996d2d",
getwalkrem:API_BASE_URL_WM +"/get/walkReminder?userId=628e07c3295cbb2a64996d2d",
getsleeprem:API_BASE_URL_WM +"/get/sleepReminder?userId=628e07c3295cbb2a64996d2d",

postmealrem:API_BASE_URL_WM +"/post/mealReminder",
postwaterrem:API_BASE_URL_WM +"/post/waterReminder",
postwalkrem:API_BASE_URL_WM +"/post/walkReminder",
postsleeprem:API_BASE_URL_WM +"/post/sleepReminder",

getlifestyle:API_BASE_URL_WM +"/get/lifestyle",
getmedicalHistory:API_BASE_URL_WM +"/get/userMedicalHistory",
fitnessform:API_BASE_URL_WM +"/get/fitnessAnswers",

getquestion: API_BASE_URL_HC + "/get/hraQuestions",
storeAnswer: API_BASE_URL_HC +"/hraReport/storeHraAnswers",

paymentHistory:  API_BASE_URL_HC + "/get/payment",
getfitnesscenter:API_BASE_URL_WM+ "/get/fitnessCenter",

};