import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";

import Navbar from "./navbar";
import Footer from "./Footer";

const Sports = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  //enquiry form
  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner1}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Sports Physiotherapy</h3>
        </div>

        <div className="pt-4">
          <h3 className="col-head-color-p">Introduction</h3>
          <p>
            Sports physiotherapy is a branch of physical therapy that is geared
            towards both athletes and casual players who have suffered an injury
            or ongoing problem. Sports physiotherapy is a specialized form of
            physiotherapy which is designed to help with injuries or conditions
            occurred during sports activities. The modalities include hands-on
            treatment and rehabilitation. Whether you’re a professional athlete
            or at the beginning of your athletic journey, sports physiotherapy
            can be a suitable form of treatment for you.
            <br />
            Sports activities, whether professional or otherwise, can cause
            muscle strain or even serious injuries. If these problems are not
            given proper medical attention and treatment, they can worsen.
            However, these unwanted injuries can now be alleviated and prevented
            from happening again through the application of sports
            physiotherapy. You can maximize your performance and prevent future
            injuries through various techniques and exercises. Sports
            physiotherapy is meant to help you get back on track as soon as
            possible in a safe, secure, and effective way. The treated
            conditions through sports physiotherapy differ from the nerve,
            ligament, or muscle injuries to other musculoskeletal disorders.
          </p>
          <br />
          <h3 className="col-head-color-p">Treatment Process </h3>
          <p>
            Sports injuries are high-performance injuries that require utmost
            care. A Sports physiotherapist works closely with the athlete/ team
            to identify the requirements of a particular sport and bring out the
            best result post-injury in performance. For instance, if a midfield
            footballer injures his ankle while playing football and goes through
            rehab. He is given specialized rehab exercises to be able to run,
            cut directions and manipulate the ball like before the injury as per
            his sport. Depending on the sport a customized rehab program is
            designed to assist the athlete back to the sport. If it’s a cricket
            injury like hypertension in a fast bowler, then the bowler is guided
            to exercises that prevent hyperextension or stabilize the spine and
            assist in his return to cricket.
            <br />
            Sports physiotherapy can in fact provide various treatment
            modalities. The treatment approaches towards back pain and neck pain
            in regular patients will be dealt with various treatment techniques
            used in sports therapy and mainly on movement rehabilitation. For
            instance, Running has been taken up by many regular individuals for
            fitness, but they struggle with injuries due to a lack of
            guidance/knowledge.
          </p>
          <br />
          <h3 className="col-head-color-p">
            WHAT ARE THE VARIOUS TYPES OF SPORTS WHICH REQUIRE PHYSIOTHERAPY?{" "}
          </h3>
          <p>
            Players are at risk of injury at any moment, they can sustain
            injuries to the head, face, shoulder, arm, thigh, and knee There are
            various measures to prevent the injuries by making sure that the
            muscles and joints are properly warmed up, protective gears are
            used, using safety equipment, and doing proper cool-down exercises.
            The exercises should be done under the guidance of a trained and
            experienced physiotherapist. Sports in which injuries occur most
            commonly are:
          </p>
          <ul>
            <li>Soccer/football</li>
            <li> Tennis</li>
            <li> Cricket</li>
            <li> Hockey</li>
            <li> Athletic</li>
          </ul>
          <br />
          <p>
            Though there are various other sports that also require
            physiotherapy treatment like Basketball, Volleyball, Rugby,
            Badminton, Cycling etc.
          </p>
          <h3 className="col-head-color-p">Treatment Conditions</h3>
          <p>
            <b>Muscle Strain:</b> Muscle strain or pulled muscle is one of the
            most common sports injuries that often take place when a muscle is
            overstretched and gets torn. Muscle strains mainly affect the
            quadriceps, calves, quadriceps, groin, lower back, and shoulder.
          </p>
          <p>
            <b>Tendonitis:</b> Inflammation of the tendon is referred to as
            tendonitis, associated with overuse, but can also be developed when
            a traumatic injury creates micro-tears in the muscle fibers.
          </p>
          <p>
            <b>Stress Fracture:</b> Stress fractures are also a form of overuse
            injury. It takes place when the muscles are no longer able to absorb
            the pressure, thus resulting in a fracture. Stress fractures usually
            affect the lower legs and feet and women athletes are more prone to
            such injury than men.
          </p>
          <p>
            <b>Contusions:</b> Direct impact to the muscle causing injury is
            known as contusions. The faster the speed of the equipment that hits
            the player more severe the injury is resulting in swelling and
            bruising of the area. RICE is recommended for treatment, and medical
            assistance may be required for more severe contusions.
          </p>
          <p>
            <b> Whiplash injury:</b> Whiplash injury can happen by an abrupt or
            unexpected backward or forward motion of the neck. Falling suddenly
            or getting hit causes the head to jerk and the neck gets strained.
            It occurs commonly in contact sports, causing pain, numbness in the
            arms due to minor or major soft tissue damage to muscles and
            ligaments in the neck and vertebrae. The recovery of the player
            depends upon the severity of the injury.
          </p>
          <p>
            <b>Shoulder Impingement:</b> Shoulder impingement occurs due to the
            repetitive overhead movement during playing. These repetitive
            concentric and eccentric movements place demands to maintain high
            levels of muscular control, required to maintain the stability of
            the shoulder joint. Initial physiotherapy treatment for these
            conditions is rest, ice, and Kinesio-taping. To increase the
            flexibility of the muscles, ligaments, and tendons, range of motion
            and strengthening exercises are designed by the physiotherapist.
          </p>
          <p>
            <b>Acromioclavicular joint injury:</b> The prevalence of shoulder
            injuries is high in hockey. The most commonly seen shoulder injury
            is Acromioclavicular (AC) joint injury, also known as a shoulder
            separation. This injury occurs due to direct contact hit into the
            shoulder. The severity can vary from a sprain to a complete tear of
            the involved ligaments. The physiotherapy treatment includes
            immobilization by rest in a sling followed by isometrics and gentle
            ROM exercises.
          </p>{" "}
          <p>
            <b>Rotator cuff injury:</b> The most common cause of a rotator cuff
            tear is repetitive microtrauma, which can occur over some time.
            Bruising or swelling occurs due to the repeated rotator cuff injury
            by pinching or straining, catching, or squeezing the rotator cuff
            tendons. Physiotherapist focuses on reducing inflammation and pain.
            The therapist recommends ROM exercises, isometric exercises, and
            capsular stretching, followed by isotonic exercises and aggressive
            pain-free strengthening.
          </p>
          <p>
            <b>Tennis elbow: </b>Tennis elbow injury occurs to the tendons
            located on the outer side of the elbow, this overuse and overload
            occur from the serve and forehand stroke. Physiotherapy treatment
            for tennis elbow involves rest, ice and Kinesio- taping. A wrist
            brace or tennis elbow band can be used and special physiotherapy
            exercises for stretching and strengthening the muscles are
            beneficial.
          </p>
          <p>
            <b>Golfer’s elbow:</b> Golfer’s elbow Or medial epicondylitis is
            caused due to excessive use of topspin and due to overload on the
            backhand stroke-causing pain and inflammation. Physiotherapy
            treatment involves rest, ice, and Kinesio- taping. A wrist brace can
            be used, followed by stretching and strengthening exercises.
          </p>
          <p>
            <b>Wrist strain:</b> Falling on an outstretched arm includes wrist
            sprains, wrist fractures, and shoulder dislocations. It can occur
            when the wrist moves in an abnormal position, suddenly twist, bends
            backward, or sustains heavy impact. The player feels tenderness,
            warmth, popping or tearing sounds, and difficulty in moving the
            wrist.
          </p>
          <p>
            <b>Carpal tunnel syndrome: </b>These injuries occur due to the
            laid-back grip, rotation of the palm upward, and quickly turning the
            wrist to perform the topspin. Treatment for these conditions
            involves rest, ice, Kinesio- taping and range of motion, and
            strengthening exercises.
          </p>
          <p>
            <b>Lower Back pain:</b>
            Players suffer from low back pain due to the fast weight transfers,
            jumping, and twisting, resulting in repeated flexion, extension,
            rotation and lateral flexion of the spine commonly required during
            cocking or loading phase of the tennis serve. The physiotherapist
            designs strengthening exercises for back and abdominal muscles to
            increase strength.
          </p>
          <p>
            <b>Abdominal side strain:</b> Side strain occurs in the side of the
            body involving the Oblique muscle. Due to the forcible contraction
            of the muscle the injury occurs on the opposite side of the bowling
            arm. Side strains also occur because of repetitive arm action. The
            player feels pain and tenderness associated with internal swelling.
            Treatment requires adequate rest. After examination of the injured
            part, for appropriate strength and conditioning, the player can
            gradually return to activity.
          </p>
          <p>
            <b>Femoroacetabular impingement:</b> Femoroacetabular impingement
            can occur due to the multidirectional movement pattern, loading and
            abruptly starting, cutting, twisting, and stopping. This form of hip
            injury can be treated with the help of RICE and physiotherapy
            sessions under the guidance of a physiotherapist.
          </p>
          <p>
            <b>Groin pull:</b> Groin pull occurs as a result of a pull or
            stretch of the muscles of the inner thigh. The player feels pain and
            tenderness in the groin and the inside of the thigh while raising
            the knee or while bringing the legs together. The player feels
            severe pain with a popping or snapping sound during the injury.
          </p>
          <p>
            <b>Iliotibial Band Syndrome:</b> Iliotibial band syndrome occurs due
            to the overuse injury along the outer thigh. Due to continuous
            friction created by the IT band along the outer side of the thigh,
            during running. The player feels pain and tenderness on the lateral
            or outer part of the thigh and knee just above the knee joint.
          </p>
          <p>
            <b>Hamstring strains:</b> A hamstring strain is usually caused when
            the muscles become overloaded, leading to a strain, or a complete
            tear. Hamstrings are usually injured during sprinting i.e during
            fast bowling while fielding or running between wickets as a batsman.
            The player feels a sudden or severe pain during exercise, pain or
            tightness while straightening or bending the leg, and
            weight-bearing. Physiotherapy treatment starts with RICE followed by
            progressive strengthening through exercise, soft tissue massage,
            scar tissue mobilization, dry needling, and taping.
          </p>
          <p>
            <b>Torn ACL:</b> ACL or anterior cruciate ligament helps in keeping
            the knee joints together and brings stability. A Torn ACL is
            extremely painful and restricts the ability to walk. The injury
            takes place from a direct shot to the knees, or from incorrect
            landing and or stopping and changing direction quickly.
          </p>
          <p>
            <b>Torn MCL:</b> It is another form of a knee injury, which takes
            place when the medial collateral ligament that connects the femur to
            the tibia is injured. It is caused when the knees are pushed
            sideways. This form of knee injury can be treated with help of ice
            compression, braces, and sports physical therapy sessions under the
            watchful eyes of a sports physiotherapist.
          </p>
          <p>
            <b>Patellofemoral pain syndrome:</b> Patellofemoral pain syndrome or
            runner's knee is a condition in which the cartilage under the
            kneecap is damaged due to injury. It can occur due to strained
            tendons or misalignment in the knee. The player feels pain while
            exercising, bending the knee, climbing the stairs, squatting, and
            jumping. The player feels cracking or popping sounds in the knee
            while climbing stairs or after sitting for a longer duration with
            the knee bent.
          </p>
          <p>
            <b>Shin Splits:</b> It affects the insides of the lower leg or shin
            area and is an overuse injury and also a common injury found among
            runners. Athletes having flat feet are more susceptible to this form
            of injury.
          </p>
          <p>
            <b>Pulled Calf Muscle:</b> Pulled calf muscle injury occurs when any
            one of the muscles i.e gastrocnemius or soleus is pulled from the
            Achilles tendon. This occurs due to jumping or running. The player
            feels bruising, redness, mild swelling, and is unable to stand on
            the ball of the foot.
          </p>
          <p>
            <b>Plantar Fasciitis:</b> Plantar Fasciitis is the inflammation of
            the plantar fascia ligament that connects the heel to the front of
            the foot. Putting too much strain and stress are the major reason
            behind this form of injury and is usually treated with rest, ice,
            and stretching exercises.
          </p>
          <p>
            <b> Ankle Sprains:</b> Medial ankle sprain can occur when the toes
            are turned out while the foot is flexed upward. Lateral ankle
            sprains can occur in soccer when a player kicks the ball with the
            top of their foot. Ankle sprains take place when the ligament that
            supports the joint gets overstretched due to incorrect stepping or
            stepping on an uneven surface or due to twists and rolls. Usually,
            ankle strains heal with RICE – rest, ice, compression, and
            elevation.{" "}
          </p>
          <p>
            <b>Achilles tendon Rupture:</b> Achilles tendon rupture occurs due
            to the partial or complete tear of the Achilles tendon. This can
            occur when a player performs a fast or jerky movement. Ankle sprains
            occur when the ligaments (surrounding the ankle joint) are stretched
            or torn.
          </p>
          <p>
            <b>Achilles tendonitis:</b> Achilles tendonitis is the inflammation
            of the lower back of the leg just above the heel. Achilles
            tendonitis injury occurs as a result of overuse. Players perform
            sudden and repetitive movements that may eventually cause this
            injury over time. Physical activity worsens the pain and is usually
            treated with some rest, ice, stretching, and strengthening
            exercises.
            <br /> More or less sports injuries treatment involves sports
            physical therapy along with proper medication to bring relief to the
            athletes. In fact, physiotherapy in sports injury has proven to be
            quite useful in the rapid recovery of athletes and is one of the
            most trusted forms of treatment
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Sports;
