import React, { Component } from "react";
import Footer from "./Footer";
import UserNavbar from "./UserNavbar";

const MySettings = () => {
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <div className="col-md-12">
                  <h3 className="mb-2">Change password</h3>
                </div>
                <div
                  className="bg-white-ptbrl-2 mb-5 mt-1"
                  style={{ border: "none" }}
                >
                  <form>
                    <div className="row pt-5">
                      <div className="col-md-4 pt-1 pb-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter current password"
                        />
                      </div>

                      <div className="col-md-4 pt-1 pb-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter new password"
                        />
                      </div>

                      <div className="col-md-4 pt-1 pb-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Confirm new password"
                        />
                      </div>

                      <div className="d-flex justify-content-center pt-3 pb-5">
                        <button
                          type="submit"
                          className="btn btn-warning submit-btn col-white"
                        >
                          Submit
                        </button>
                        &nbsp; &nbsp; &nbsp;
                        <button
                          type="reset"
                          className="btn btn-blue submit-btn col-white"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MySettings;
