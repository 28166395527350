import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class Profile {

  
    static getProfiles() {
        let api='';
        let user = JSON.parse(localStorage.getItem("user"));
        let id = user.data.id;
        api = API_PATHS.userprofile+ '?id=' + id;
      return axios
          .get(api)
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          });
    }

    static EditProfile(item) {
       
        let api = '';
        let user = JSON.parse(localStorage.getItem("user"));
        let userId=user.data.id;
          api =API_PATHS.putuser + '?id=' + userId;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getProfile() {
        let api = '';
        let userid = localStorage.getItem("id");
        api = API_PATHS.getuser + '?id=' + userid;
        localStorage.removeItem("id");
      return axios
          .get(api)
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          });
    }

    static getPaymentdetails() {
        let api = '';
        let user = JSON.parse(localStorage.getItem("user"));
        let userId=user.data.id;
        let stat="paid";
        api = API_PATHS.paymentHistory + '?userId=' + userId + '&status=' + stat;
        localStorage.removeItem("id");
      return axios
          .get(api)
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          });
    }


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default Profile;