import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cityService from "../../services/city.service";
import AuthService from "../../services/auth.service";

export const City = createAsyncThunk(
    "city/get/city",
    async(thunkAPI) => {
        try {
            const data = await cityService.getCity();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const CityDetails = createAsyncThunk(
    "city/get/cityDetails",
    async(thunkAPI) => {
        try {
            const data = await cityService.getCityDetails();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const CityPackage = createAsyncThunk(
    "city/get/cityPackage",
    async(thunkAPI) => {
        try {
            const data = await cityService.cityPackage();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);




// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const citySlice = createSlice({
    name: "package",
    initialState,
    extraReducers: {
        [City.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [City.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [City.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        [CityDetails.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [CityDetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [CityDetails.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        [CityPackage.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [CityPackage.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [CityPackage.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
     
        
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = citySlice;
export default reducer;