import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Navbar from "./navbar";

const Shoulderpain = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Shoulder Pain</h3>
        </div>

        <div className="pt-4">
          <p>
            The shoulder is the most mobile joint in the body, and we rely on it
            to be able to do a number of activities such as reaching, lifting,
            gardening, or playing sports. Given that the shoulder is a very
            mobile joint, it can be easily injured.
            <br />
            If you suffer from a shoulder injury, you may have pain at night and
            find it difficult to lie on that side to sleep. The shoulder may
            feel really stiff, or it may feel unstable as if it’s about to pop
            out of the socket. You may feel weak in the shoulder muscles, making
            it hard to perform everyday activities, particularly reaching behind
            your back or overhead activities.
          </p>
          <p>
            <b>Common Causes of Shoulder Pain</b>
            <br />
            <ul>
              <li> Lifting something too heavy at an awkward angle</li>
              <li>
                Lifting a heavy object away from the body or above shoulder
                height
              </li>
              <li> Repetitive movements that place stress on the shoulder</li>
              <li>
                Reaching behind the backseat of the car to lift or place heavy
                items
              </li>
            </ul>
          </p>
          <p>
            <b>4 COMMON SHOULDER INJURY CONDITIONS</b>
          </p>
          <p>
            <b>1. Rotator Cuff Injuries</b>
            <br />
            The rotator cuff is a group of muscles surrounding the shoulder that
            work to lift and rotate the arm, as well as support and stabilise
            the joint.
            <br />
            <br />
            Injury to the rotator cuff can be acute such as falling onto an
            outstretched arm, lifting something too heavy, catching a falling
            object, or repetitive overhead work.
            <br />
            <br />
            The rotator cuff can also be injured due to degenerative wear and
            tear. As we get older and are less active, tendons under
            degeneration and start to lose strength. This means they can be torn
            more easily. Repetitive stress on the shoulder can increase the risk
            of developing a tear.
            <br />
            <br />
            Physiotherapy can help with rotator cuff injuries. Your
            Physiotherapist will use manual therapy techniques to help improve
            your range of motion and relax the muscles. You will be prescribed
            with a comprehensive exercise program consisting of stretches and
            strength work. The stretches will help to improve your flexibility
            and range of motion, and the strength work will help to restore
            function and prevent further injury. Your physiotherapist will give
            you advice on posture, desk setup, sleeping positions, and modifying
            your daily activities to minimise the stresses placed on the
            shoulder.
          </p>
          <p>
            <b>2. Shoulder Instability or Dislocations</b>
            <br />
            Shoulder instability is common in young people and athletes. When
            the muscles and ligaments that hold the shoulder together are
            stretched beyond their normal limits, the shoulder can become
            unstable. Certain motions used in tackling, throwing, pitching or
            bowling can place large forces on the shoulder that can overstretch
            the ligaments. People describe the feeling that the shoulder is
            loose or feels weak. When the ligaments become too loose or
            completely tear, a shoulder subluxation or dislocation can occur.
            This is commonly caused by falling onto an outstretched hand, a
            violent twisting motion, or contact with arms overhead.
            <br />
            Physiotherapy for shoulder instability will focus heavily on
            restoring strength and control back into the muscles so that the
            shoulder is better supported. Your physiotherapist will assess your
            strength and biomechanics in order to design an exercise program
            individually tailored to your needs. The program will involve
            strengthening, stability, and endurance work.
          </p>
          <p>
            <b>3. Frozen Shoulder</b>
            <br />
            Frozen shoulder can be triggered by trauma, previous shoulder
            injury, post-operatively, or it can arise without warming. The joint
            capsule tightens and becomes inflamed, forming scar tissue and
            adhesions which result in a very stiff and painful shoulder. It also
            results in very limited range of motion.
            <br />
            Physiotherapy treatment aims to speed up recovery with soft tissue
            massage to loosen up the muscles, mobilisation of the joint itself,
            and exercise to help with strength and flexibility. Your
            physiotherapist will give you stretches to help improve your range
            of motion and relieve the stiffness. As your pain subsides you will
            then begin strengthening and endurance work.
          </p>
          <p>
            <b>4. Sports Injuries</b>
            <br />
            Shoulder injuries can occur with many different sports, including
            tennis, cricket and swimming. If the muscles, ligaments or tendons
            are not working well together or coordinating properly, pain and
            inflammation can arise.
            <br />
            <br />
            Physiotherapy is beneficial in treating sports related shoulder
            injuries, as well as helping to prevent them from occurring. Your
            physiotherapist will conduct a thorough assessment to identify any
            muscle imbalances or weaknesses. They can then prescribe you with an
            individually tailored exercise program to target your strength
            deficits as well as work on flexibility, stability, and endurance.
            Your physiotherapist will also give you advice on your training
            load, as any increases must be gradual to avoid overloading the
            shoulder. They will also be able to analyse your technique, liaise
            with your coach, and offer advice on what you can change to avoid
            placing too much stress on the shoulder.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Shoulderpain;
