import React, { useState } from "react";
import Header from "./navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch } from "react-redux";
import ios from "./../images/blog/screen2.jpg";
import Gapp from "./../images/project/Gapp.png";
import Iapp from "./../images/project/apple.png";
import banner1 from "./../images/fitness/banner1.png";

import home1 from "./../images/homepage/banner1.jpg";
import medicine from "./../images/healthcare/medicine.jpg";
import transform from "./../images/homepage/Transform With Healthonify App.png";

import babycare from "./../images/healthcare/medicine/Medicine_ Baby Care.png";
import alternate from "./../images/healthcare/medicine/Medicine_ alternate medicine.png";
import fitness from "./../images/healthcare/medicine/Medicine_ Fitness and wellness.png";
import personalcare from "./../images/healthcare/medicine/Medicine_ Personal Care.png";
import healthneeds from "./../images/healthcare/medicine/Medicine_ Health needs.png";
import womencare from "./../images/healthcare/medicine/WOMEN CARE.jpg";
import pain from "./../images/healthcare/medicine/PAIN.jpg";
import skincare from "./../images/healthcare/medicine/SKIN CARE.jpg";
import { TravelEnquiry } from "../store/slices/enquiry";
import Moment from "moment";
const Medicine = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [alertCity, setAlertCity] = useState("");
  const [alertdate, setAlertdate] = useState("");
  const [alertCategory, setAlertCategory] = useState("");
  const [alertemail1, setAlertEmail1] = useState("");
  const [alertname1, setAlertName1] = useState("");
  const [alertcontactNumber1, setAlertContactNumber1] = useState("");
  const [alertmessage1, setAlertMessage1] = useState("");
  const reqConsult = (e) => {
    const vName1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName1.test(name)) {
      setAlertName1("");
    } else if (!vName1.test(name) && name === "") {
      setAlertName1("Please enter your name");
      e.preventDefault();
    } else {
      setAlertName1("Please enter a valid name");
      e.preventDefault();
    }

    const vCity1 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vCity1.test(city)) {
      setAlertCity("");
    } else if (!vCity1.test(city) && city === "") {
      setAlertCity("Please enter your city name");
      e.preventDefault();
    } else {
      setAlertCity("Please enter a valid city name");
      e.preventDefault();
    }

    const vemail1 = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vemail1.test(email)) {
      setAlertEmail1("");
    } else if (!vemail1.test(email) && email === "") {
      setAlertEmail1("Please enter your  email");
      e.preventDefault();
    } else {
      setAlertEmail1("Please enter a valid email");
      e.preventDefault();
    }

    const mobile1 = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile1.test(contactNumber)) {
      setAlertContactNumber1("");
    } else if (!mobile1.test(contactNumber) && contactNumber === "") {
      setAlertContactNumber1("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setAlertContactNumber1("Please enter a valid mobile number");
      e.preventDefault();
    }

    if (category === "") {
      setAlertCategory("Please select expertise");
      e.preventDefault();
    } else {
      setAlertCategory("");
    }

    const vDate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate.test(date)) {
      setAlertdate("");
    } else if (!vDate.test(date) && date === "") {
      setAlertdate("Please enter your date of consultation");
      e.preventDefault();
    } else {
      setAlertdate("");
    }

    const vMessage1 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage1.test(message)) {
      setAlertMessage1("");
    } else if (!vMessage1.test(message) && message === "") {
      setAlertMessage1("Please enter your issue");
      e.preventDefault();
    } else {
      setAlertMessage1("");
    }
  };

  const cancel = () => {
    setEmail("");
    setName("");
    setContactNumber("");
    setMessage("");
    setCity("");
    setDate("");
    setCategory("");
    setAlertCity("");
    setAlertdate("");
    setAlertCategory("");
  };

  async function uploadconsult(e) {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let category = "Healthcare";
    let item = {
      name,
      email,
      contactNumber,
      city,
      date,
      message,
      category,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setContactNumber("");
        setCity("");
        setDate("");
        setMessage("");
        setCategory("");
        document.getElementById("consult").style.display = "none";
      })

      .catch(({ emessage }) => {
        alert(emessage);
      });
  }
  return (
    <div>
      <Header />
      <section>
        <div className="carousel_cover pb-5 ">
          <Carousel className="">
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 phy_img_carousel3 "
                src={medicine}
                alt=""
              />
              <Carousel.Caption>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-warning col-white adjust-btn"
                    type="button"
                    style={{ fontSize: "20px" }}
                  >
                    Browse
                  </button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            {/* <Carousel.Item interval={3000}>
              <img className="d-block w-100 phy_img_carousel3 " src={home3} />
              <Carousel.Caption>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-warning col-white adjust-btn"
                    type="button"
                    style={{ fontSize: "20px" }}
                  >
                    Browse
                  </button>
                </div>
              </Carousel.Caption>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </section>

      <div className="container">
        <div className="col-lg-12 section-title">
          <h3 className="text-center">Browse Medicines And Health Products</h3>
        </div>

        <section className="section-card  pb-5">
          <h3 className="wel-text text-left pb-3"> By Health Condition</h3>

          <div className="row">
            <div className="col-lg-3  col-md-3 col-xs-6 mb-2 mt-4">
              <Link to="/Shop">
                <div className="bg-b-o-grandient lie-img-slide-with-text position-relative">
                  <p className="position-absolute text-transform col-white">
                    Skin Care
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-xs-6 mb-2 mt-4">
              <Link to="/Shop">
                <div className="bg-b-o-grandient lie-img-slide-with-text position-relative">
                  <p className="position-absolute text-transform col-white">
                    Sexual Wellness
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-xs-6 mb-2 mt-4">
              <Link to="/Shop">
                <div className="bg-b-o-grandient lie-img-slide-with-text position-relative">
                  <p className="position-absolute text-transform col-white">
                    Weight Management
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-xs-6 mb-2 mt-4">
              <Link to="/Shop">
                <div className="bg-b-o-grandient lie-img-slide-with-text position-relative">
                  <p className="position-absolute text-transform col-white">
                    Pain Relief
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-2-lg col-md-1 col-sm-12" />

            <div className="col-lg-3 col-md-3 col-xs-6 mb-2 mt-4">
              <Link to="/Shop">
                <div className="bg-b-o-grandient lie-img-slide-with-text position-relative">
                  <p className="position-absolute text-transform col-white">
                    Mental Health
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-xs-6 mb-2 mt-4">
              <Link to="/Shop">
                <div className="bg-b-o-grandient lie-img-slide-with-text position-relative">
                  <p className="position-absolute text-transform col-white">
                    Cough & Cold
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-xs-6 mb-2 mt-4">
              <Link to="/Shop">
                <div className="bg-b-o-grandient lie-img-slide-with-text position-relative">
                  <p className="position-absolute text-transform col-white">
                    Diabetes
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-1-lg col-md-1 col-sm-12" />
          </div>
        </section>

        <section className="section-card pt-5 pb-5">
          <h3 className="wel-text text-left pb-3">By Categories</h3>

          <div className="row">
            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img src={skincare} alt="" style={{ borderRadius: "10px" }} />
                  <p className="text-transform col-white position-absolute">
                    Skin Care
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <div className="lie-img-slide-with-text position-relative ">
                <img src={babycare} alt="" style={{ borderRadius: "10px" }} />
                <p className="text-transform col-white position-absolute">
                  Baby Care
                </p>
              </div>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img src={pain} alt="" style={{ borderRadius: "10px" }} />
                  <p className="text-transform col-white position-absolute">
                    Pain Relief
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img
                    src={personalcare}
                    alt=""
                    style={{ borderRadius: "10px" }}
                  />
                  <p className="text-transform col-white position-absolute">
                    Personal Care
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img
                    src={alternate}
                    alt=""
                    style={{ borderRadius: "10px" }}
                  />
                  <p className="text-transform col-white position-absolute">
                    Alternate Medicines
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img
                    src={womencare}
                    alt=""
                    style={{ borderRadius: "10px" }}
                  />
                  <p className="text-transform col-white position-absolute">
                    Women Care
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img
                    src={healthneeds}
                    alt=""
                    style={{ borderRadius: "10px" }}
                  />
                  <p className="text-transform col-white position-absolute">
                    Daily Health Needs
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-md-3 col-sm-12 mt-1 mb-2">
              <Link to="/Shop">
                <div className="lie-img-slide-with-text position-relative">
                  <img src={fitness} alt="" style={{ borderRadius: "10px" }} />
                  <p className="text-transform col-white position-absolute">
                    Fitness & Wellness
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="position-relative mt-5 pb-3">
            <h3 className="text-center">
              Don’t Self Medicate. Talk to an Expert. Consult Doctor Now
            </h3>
            <img
              src={home1}
              alt="banner"
              className="phy_img_carousel3"
              style={{ borderRadius: "10px" }}
            />
            <div className="position-absolute transform-center">
              <button
                type="button"
                className="btn btn-warning col-white"
                style={{ fontSize: "19px", width: "350px" }}
                data-bs-toggle="modal"
                data-bs-target="#consult"
              >
                Book Doctor Appointment
              </button>
            </div>
          </div>
        </section>

        <section className="section-card pt-5 pb-5">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h3 className="text-center">
                Get Healthy with our Healthonify App
              </h3>
              <div className="row">
                <div className="col-lg-8">
                  <img src={transform} alt="" />
                </div>
                <div className="col-md-4 text-center">
                  <img
                    src={Gapp}
                    alt=""
                    style={{ height: "80px" }}
                    className="mt-10"
                  />{" "}
                  <br />
                  <img src={Iapp} alt="" style={{ height: "80px" }} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="modal fade" id="consult" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header">
              <h4 className="modal-title">Doctor Consultation</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={cancel}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={uploadconsult}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={name}
                      placeholder="Enter your name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <p className="alert-message">{alertname1}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={email}
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="alert-message">{alertemail1}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={contactNumber}
                      placeholder="Enter your mobile number"
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                    <p className="alert-message">{alertcontactNumber1}</p>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <input
                      type="date"
                      min={Moment().add(0, "d").format("YYYY-MM-DD")}
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                    <p className="alert-message">{alertdate}</p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control mt-1 mb-2 pt-2 pb-2"
                      value={city}
                      placeholder="Enter your city name"
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <p className="alert-message">{alertCity}</p>
                  </div>
                  <div className="col-md-6 col-sm-12 pt-1">
                    <select
                      name="cars"
                      id="cars"
                      className="select-opt"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Select Expertise</option>
                      <option>Paralysis</option>
                      <option>Elder Care</option>
                      <option>Immunity</option>
                      <option>Panchkarma</option>
                      <option>Eye Care</option>
                      <option>Pain Management</option>
                      <option>Gastric Problems</option>
                      <option>Skin & Hair care</option>
                      <option>Depression & Anxiety</option>
                      <option>Detoxification</option>
                      <option>Weight loss</option>
                      <option>Others</option>
                    </select>
                    <p className="alert-message">{alertCategory}</p>
                  </div>

                  <div className="col-12 pt-2">
                    <textarea
                      row="50"
                      col="50"
                      className="form-control"
                      placeholder="Brief your issue"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>

                    <p className="alert-message">{alertmessage1}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-center pt-4">
                  <button
                    type="submit"
                    className="btn btn-warning col-white adjust-btn"
                    onClick={reqConsult}
                  >
                    Request a Call Back
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white adjust-btn"
                    onClick={cancel}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="background" data-bs-dismiss="modal" />
      </div>
      <Footer />
    </div>
  );
};

export default Medicine;
