import React, { useState } from "react";
import Footer from "./Footer";
import logo from "./logo.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "./logo.png";

import { MdDashboard } from "react-icons/md";
import {
  BsBookFill,
  BsFillChatRightFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { BiCubeAlt } from "react-icons/bi";

import { MdNotifications } from "react-icons/md";
import { MdSettings } from "react-icons/md";
import user from "./../images/client-image/3.jpg";
import UserNavbar from "./UserNavbar";

import bottelrem from "./../images/dashboard/bottelrem.png";
import mealrem from "./../images/dashboard/mealrem.png";
import sleeprem from "./../images/dashboard/sleeprem.png";
import walkingrem from "./../images/dashboard/walkingrem.png";

import { getmealreminder, getwaterreminder, getwalkreminder, getsleepreminder } from "../store/slices/reminder";

import { useDispatch } from "react-redux";

const MyReminders = ()=> {

  const dispatch = useDispatch();


  const [mealreminder, setMealReminder] = useState([]);
  const [waterreminder, setWaterReminder] = useState([]);
  const [walkreminder, setWalkReminder] = useState([]);
  const [sleepreminder, setSleepReminder] = useState([]);


  React.useEffect(() => {
      dispatch(getmealreminder())
        .unwrap()
        .then((data) => {
          setMealReminder(data.user.data);
        })
        .catch(({ message }) => {
          // alert(message);
        });
    }, [dispatch]);

    
    React.useEffect(() => {
      dispatch(getwaterreminder())
        .unwrap()
        .then((data) => {
          setWaterReminder(data.user.data);
        })
        .catch(({ message }) => {
          // alert(message);
        });
    }, [dispatch]);

    React.useEffect(() => {
      dispatch(getwalkreminder())
        .unwrap()
        .then((data) => {
          setWalkReminder(data.user.data);
        })
        .catch(({ message }) => {
          // alert(message);
        });
    }, [dispatch]);

    
    React.useEffect(() => {
      dispatch(getsleepreminder())
        .unwrap()
        .then((data) => {
          setSleepReminder(data.user.data);
        })
        .catch(({ message }) => {
          // alert(message);
        });
    }, [dispatch]);

  return (
    <div className="bg-light">
    <UserNavbar />

    <div className="container-fluid response-cover">
    <div className="row">
      <div className="col-lg-2 col-md-4" />
      <div className="col-lg-10 col-md-8">

      <div className="container pt-5">
      <div className="pt-5 mt-5">
      <div className="col-md-12">
      <h3 className="mb-2">My Reminders</h3>
      </div>     
     
      <div className="row mt-5">

      <div className="col-lg-3 col-md-6 col-sm-12 pt-1 pb-1">
      <div className="calculator  pt-4 pb-4" data-bs-toggle="modal" data-bs-target="#mealreminder">
      <div className="d-flex justify-content-center pb-2">
      <img src={mealrem} alt="reminder-icon"   style={{ height: "50px", width: "50px", borderRadius:"1px" }}/>
      </div>
      <h5 className=" text-center">Meal Reminder</h5>
    </div>
      
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 pt-1 pb-1">
      <div className="calculator pt-4 pb-4" data-bs-toggle="modal" data-bs-target="#waterreminder">


      <div className="d-flex justify-content-center pb-2">
      <img src={bottelrem} alt="reminder-icon"   style={{ height: "50px", width: "50px", borderRadius:"50%" }}/>
      </div>
      <h5 className=" text-center">Water Reminder</h5>
</div>

      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 pt-1 pb-1">
      <div className="calculator pt-4 pb-4" data-bs-toggle="modal" data-bs-target="#walkreminder">

      <div className="d-flex justify-content-center pb-2">
      <img src={walkingrem} alt="reminder-icon"   style={{ height: "50px", width: "50px", borderRadius:"5px" }}/>
      </div>
      <h5 className=" text-center">Walk Reminder</h5>
      </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 pt-1 pb-1" data-bs-toggle="modal" data-bs-target="#sleepreminder">
      <div className="calculator pt-4 pb-4">

      <div className="d-flex justify-content-center pb-2">
      <img src={sleeprem} alt="reminder-icon"   style={{ height: "50px", width: "50px",  borderRadius:"5px"  }}/>
      </div>
      <h5 className=" text-center">Sleep Reminder</h5>
     </div>
      </div>

      </div>
        
      </div>
    </div>

      </div>
      </div>
      </div>
     
      <div className="modal fade" id="mealreminder" style={{ height: "100%" }}>
      <div
        className="modal-dialog modal-align-center modal-sm"
        style={{ position: "relative", height: "100%", marginTop: "0%" }}
      >
        <div className="modal-contents ">
          <div className="modal-header">
            <h4 className="modal-title">Meal Reminder</h4>
            <button
              type="button"
              className="btn btn-close"wallk
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body" align="center">
          {mealreminder.map((reminderVal, index) => (

      <div className="d-flex justify-content-between">
      
      <div className="rem-head-text">
      <p>Break Fast</p>
      <p>Dinner</p>
      <p>Evening Snack</p>
      <p>Lunch</p>
      <p>Morning Snack</p>
      <p>Remind Me Every Day</p>
      <p>Remind Me Every Week</p>
      
      </div>
      
      <div className="rem-text">
      <p>{reminderVal.breakfast} </p>
      <p>{reminderVal.dinner}</p>
      <p>{reminderVal.eveningSnack}</p>
      <p>{reminderVal.lunch}</p>
      <p>{reminderVal.morningSnack}</p>
      <p>{reminderVal.remindmeEverydayAt}</p>
      <p>{reminderVal.remindmeEveryweekAt}</p>
      </div>
      
      </div>
      
            
        ))}

        <Link to="/MyReminders/MealReminder/AddMeal" className="btn btn-warning col-white mt-4" style={{width:"90%"}}>Add Meal Reminder</Link>
          </div>
        </div>
      </div>
      <div className="background" data-bs-dismiss="modal"/>

    </div>

    <div className="modal fade" id="waterreminder" style={{ height: "100%" }}>
    <div
      className="modal-dialog modal-align-center modal-sm"
      style={{ position: "relative", height: "100%", marginTop: "0%" }}
    >
      <div className="modal-contents ">
        <div className="modal-header">
          <h4 className="modal-title">Water Reminder</h4>
          <button
            type="button"
            className="btn btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <div className="modal-body" align="center">
        {waterreminder.map((reminderVal, index) => (

    <div className="d-flex justify-content-between">
    
    <div className="rem-head-text">
    <p>Hourly Reminder</p>
    <p>Reminder From Time</p>
    <p>Reminder Time</p>
    <p>Reminder To Time</p>
    
    </div>
    
    <div className="rem-text">
    <p>{reminderVal.hourlyReminder} </p>
    <p>{reminderVal.reminderFromTime}</p>
    <p>{reminderVal.reminderTimes}</p>
    <p>{reminderVal.reminderToTime}</p>
          </div>
    
    </div>
    
          
      ))}

      <Link to="/MyReminders/WaterReminder/AddWater" className="btn btn-warning col-white mt-4" style={{width:"90%"}}>Add Water Reminder</Link>
        </div>
      </div>
    </div>
    <div className="background" data-bs-dismiss="modal"/>

  </div>

  <div className="modal fade" id="walkreminder" style={{ height: "100%" }}>
  <div
    className="modal-dialog modal-align-center modal-sm"
    style={{ position: "relative", height: "100%", marginTop: "0%" }}
  >
    <div className="modal-contents ">
      <div className="modal-header">
        <h4 className="modal-title">Walk Reminder</h4>
        <button
          type="button"
          className="btn btn-close"
          data-bs-dismiss="modal"
        ></button>
      </div>
      <div className="modal-body" align="center">
      {walkreminder.map((reminderVal, index) => (

  <div className="d-flex justify-content-between">
  
  <div className="rem-head-text">
  <p>Remind Me At</p>
  </div>
  
  <div className="rem-text">
  <p>{reminderVal.remindMeAt} </p>
        </div>
  
  </div>
  
        
    ))}

    <Link to="/MyReminders/WalkReminder/AddWalk" className="btn btn-warning col-white mt-4" style={{width:"90%"}}>Add Walk Reminder</Link>
      </div>
    </div>
  </div>
  <div className="background" data-bs-dismiss="modal"/>

</div>

<div className="modal fade" id="sleepreminder" style={{ height: "100%" }}>
<div
  className="modal-dialog modal-align-center modal-sm"
  style={{ position: "relative", height: "100%", marginTop: "0%" }}
>
  <div className="modal-contents ">
    <div className="modal-header">
      <h4 className="modal-title">Sleep Reminder</h4>
      <button
        type="button"
        className="btn btn-close"
        data-bs-dismiss="modal"
      ></button>
    </div>
    <div className="modal-body" align="center">
    {sleepreminder.map((reminderVal, index) => (

<div className="d-flex justify-content-between">

<div className="rem-head-text">
<p>Sleep Time</p>
<p>Wakeup Time</p>
</div>

<div className="rem-text">
<p>{reminderVal.sleepTime} </p>
<p>{reminderVal.wakeupTime}</p>
      </div>

</div>

      
  ))}

  <Link to="/MyReminders/SleepReminder/AddSleep" className="btn btn-warning col-white mt-4" style={{width:"90%"}}>Add Sleep Reminder</Link>
    </div>
  </div>
</div>
<div className="background" data-bs-dismiss="modal"/>

</div>

    </div>
  );
}
export default MyReminders;
