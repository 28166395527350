import React from 'react'
import Footer from "./Footer";
import Header from "./navbar";

const Aboutus = () => {
  return (
    <div>
    <Header/>

    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <Footer/>
    
    </div>
  )
}

export default Aboutus