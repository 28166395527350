import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./Footer";
import banner1 from "./../images/banner/physio1.jpg";
import Navbar from "./navbar";

const Cardiothoracic = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };
  return (
    <div id="__next">
      <Navbar />
      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner1}
                alt=""
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Cardiothoracic Physiotherapy</h3>
        </div>

        <div className="pt-4">
          <h3 className="col-head-color-p">Introduction</h3>
          <p>
            Cardiovascular disease (CVD) is one of the leading causes of death
            worldwide and is the leading cause of death in the United States.
            <br />
            Cardiac rehabilitation is a complex, interprofessional intervention
            customized to individual patients with various cardiovascular
            diseases such as:
          </p>

          <ul>
            <li>Heart Attack</li>
            <li>Coronary artery disease (CAD)</li>
            <li>Heart failure</li>
            <li>Myocardial infarctions</li>
            <li>
              Patients who have undergone cardiovascular interventions such as
              coronary angioplasty or coronary artery bypass grafting.
            </li>
          </ul>
          <br />
          <h3 className="col-head-color-p">Cardiac rehabilitation program</h3>
          <p>
            Cardiac rehabilitation programs aim to limit the psychological and
            physiological stresses of CVD, reduce the risk of mortality
            secondary to CVD, and improve cardiovascular function to help
            patients achieve their highest quality of life possible.
            Accomplishing these goals is the result of improving overall cardiac
            function and capacity, halting or reversing the progression of
            atherosclerotic disease, and increasing the patient's
            self-confidence through gradual conditioning.
            <br />
            CR ere the process by which patients with cardiac disease, in
            partnership with a multidisciplinary team of health professionals
            are encouraged to support and achieve and maintain optimal physical
            and psychosocial health. The involvement of partners, other family
            members, and carers is also important.
            <br />
            They require a team approach, including a multidisciplinary the
            multidisciplinary team including:
          </p>

          <ul>
            <li>
              Cardiologist/Physician and co-coordinator to lead cardiac
              rehabilitation
            </li>
            <li> Clinical Nurse Specialist</li>
            <li> Physiotherapist</li>
            <li> Clinical nutritionist/Dietitian</li>
            <li> Occupational Therapist</li>
            <li> Pharmacist</li>
            <li> Psychologist</li>
            <li> Smoking cessation counselor/nurse</li>
            <li> Social worker</li>
            <li> Vocational counselor</li>
            <li> Clerical Administration</li>
          </ul>
          <br />
          <p>
            It is essential that all cardiac rehabilitation staff have
            appropriate training, qualifications, skills, and competencies to
            practice within their scope of practice and recognise and respect
            the professional skills of all other disciplines involved in
            providing comprehensive cardiac rehabilitation. The cardiac
            rehabilitation team should actively engage and effectively link with
            the general practitioner and practice nurses, sports and leisure
            industry where phase IV is conducted, community pharmacists and
            other relevant bodies to create a long-term approach to CVD
            management.
          </p>
          <br />
          <h3 className="col-head-color-p">Indication</h3>
          <p>
            Cardiac rehabilitation should be offered to all cardiac patients who
            would benefit:
          </p>
          <ul>
            <li>Recent myocardial infarction</li>
            <li> Acute coronary artery syndrome</li>
            <li> Chronic stable angina</li>
            <li> Congestive heart failure</li>
            <li> After coronary artery bypass surgery</li>
            <li> After a percutaneous coronary intervention</li>
            <li> Valvular surgery</li>
            <li> Cardiac transplantation</li>
          </ul>
          <br />
          <p>
            CR begins as soon as possible in intensive care units (only if the
            patient is in stable medical condition). Intensity of rehabilitation
            depends on the patient's condition and complications in the acute
            phase of disease.[7] Randomized controlled trials and systematic
            analysis show that early mobilization improved physical function
            (distance walked during the 6-min walking test improved by 54 m) at
            the discharge in patients after cardiac surgery.[8] Another
            prospective randomized clinical trial improved postoperative
            functional capacity (6-minute walk test) shorten the duration of
            mechanical ventilation, dependence on oxygen therapy, and reduced
            the time of hospital stay in patients who underwent elective
            Coronary artery bypass graft surgery[9].
          </p>
          <h3 className="col-head-color-p">Goals of Cardiac Rehabilitation</h3>
          <p>
            Comprehensive cardiac rehabilitation program should contain specific
            core components.
            <br />
            These components should optimize cardiovascular risk reduction,
            reduce disability, encourage active and healthy lifestyle changes,
            and help maintain those healthy habits after rehabilitation is
            complete. Cardiac rehabilitation programs should focus on:
          </p>
          <ul>
            <li>Patient assessment nutritional counseling</li>
            <li>Weight management</li>
            <li>Blood pressure management</li>
            <li>Lipid management</li>
            <li>Diabetes management</li>
            <li>Tobacco cessation</li>
            <li>Psychosocial management</li>
            <li>Physical activity counseling</li>
            <li>Exercise training</li>
          </ul>
          <h3 className="col-head-color-p">Phases of Cardiac Rehabilitation</h3>
          <p>Cardiac rehabilitation consists of 3 phases.</p>
          <b>Phase I: Clinical phase</b>
          <p>
            This phase begins in the inpatient setting soon after a
            cardiovascular event or completion of an intervention. It begins by
            assessing the patient's physical ability and motivation to tolerate
            rehabilitation. Therapists and nurses may start by guiding patients
            through non-strenuous exercises in the bed or at the bedside,
            focusing on a range of motion and limiting hospital deconditioning.
            The rehabilitation team may also focus on activities of daily living
            (ADLs) and educate the patient on avoiding excessive stress.
            Patients are encouraged to remain relatively rested until completion
            of treatment of comorbid conditions, or post-operative
            complications. The rehabilitation team assesses patient needs such
            as assistive devices, patient and family education, as well as
            discharge planning.
          </p>
          <b>Phase II: Outpatient cardiac rehab</b>
          <p>
            Once a patient is stable and cleared by cardiology, outpatient
            cardiac rehabilitation may begin. Phase II typically lasts three to
            six weeks though some may last up to up to twelve weeks. Initially,
            patients have an assessment with a focus on identifying limitations
            in physical function, restrictions of participation secondary to
            comorbidities, and limitations to activities. A more rigorous
            patient-centered therapy plan is designed, comprising three
            modalities: information/advice, tailored training program, and a
            relaxation program. The treatment phase intends to promote
            independence and lifestyle changes to prepare patients to return to
            their lives at home.
          </p>
          <b>Phase III: Post-cardiac rehab. Maintenance</b>
          <p>
            This phase involves more independence and self-monitoring. Phase III
            centers on increasing flexibility, strengthening, and aerobic
            conditioning.
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Cardiothoracic;
