import { Link } from "react-router-dom";
import React, { Component } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "./logo.png";
import img1 from "./../images/main-image.png";
import aimg1 from "./../images/Bitmap.png";
import axios from "axios";
import "./services.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import image3 from "./../images/shape/8.png";
import image4 from "./../images/shape/5.png";
import bottle from "./../images/portfolio/1.png";
import dtc from "./../images/dtc.png";
import team1 from "./../images/team/1.jpg";
import Navbar from "./navbar";

class Types extends Component {
  state = {
    expertises: {},
    experts: [],
    errors: {},
  };
  async componentDidMount() {
    const AuthAxios = axios.create({
      headers: {
        Authorization: `x-access-token: ${localStorage.getItem("token")}`,
      },
    });

    try {
      console.log(this.props.match.params.id);
      const { data: expertise } = await AuthAxios.get(
        `http://54.157.233.43:3000/get/expertise?id=${this.props.match.params.id}`
      );
      const expertises = expertise.data[0];
      this.setState({ expertises });
      console.log(expertises);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.name = ex.response.data;
        this.setState({ errors });
      }
    }

    try {
      console.log(this.props.match.params.id);
      const { data: expert } = await AuthAxios.get(
        "http://54.157.233.43:3000/get/user?expertise=6229a9cd47494a4f8c77e149&expert=1"
      );
      const experts = expert.data;
      this.setState({ experts });
      // console.log(experts);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  }

  render() {
    const { name } = this.state.expertises;
    return (
      <div id="__next">
        <Navbar />
        <div className="main-banner">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12">
                    <div className="main-banner-content">
                      <h5>SEAMLESS REHAB EXPERIENCE</h5>
                      <h2>{name}</h2>
                      <p>
                        Disciplined, Dedicated and Professional team of
                        physiotherapists to keep you strong.
                      </p>
                      <div className="banner-btn">
                        <a className="default-btn-one" href="#">
                          Book Appointement
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="banner-image">
                      <img
                        src={img1}
                        className="animate__animated animate__fadeInLeft animate__delay-0.5s"
                        alt="arrow"
                      />
                      <img
                        src="images/saas-image/box1.png"
                        className="animate__animated animate__fadeInUp animate__delay-0.5s"
                        alt="box1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>

        <section className="about-section pt-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-area-content">
                  <h3>{name}</h3>

                  <p>
                    In India it is usually seen that the patient comes to visit
                    a doctor only when the condition has worsen or it has
                    reached a chronic stage, till then the patient tries home
                    remedies which are not favourable every time. So now to
                    break that chronic stage of the condition it requires much
                    more time and efforts. Mostly the major difference that we
                    see in these two stages is the level and duration of pain
                    perceived, here is the difference between the acute and
                    chronic stage of any condition;
                  </p>
                  <br />
                  {/* <a className="default-btn-one" href="#">
                      Book Appointement
    </a> */}
                </div>
              </div>
              <div className="col-lg-1 col-md-12" />
              <div className="col-lg-5 col-md-12">
                <div className="about-image">
                  <img
                    src={aimg1}
                    alt="image"
                    style={{
                      height: "350px",
                      width: "300px",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        </section>

        <section className="choose-section" style={{ background: "#E9610C" }}>
          <div className="container" style={{ backgroundColor: "#E9610C" }}>
            <div className="features-title"></div>

            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div
                  className="icons"
                  style={{
                    height: "90px",
                    width: "90px",
                    backgroundColor: "#b04705",
                    margin: "35px",
                  }}
                >
                  <img src={dtc} alt="dtc" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <p
                  className="text-center"
                  style={{ color: "white", marginTop: "12px" }}
                >
                  Special
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "30px",
                  }}
                >
                  Get a Free Medical
                </p>
              </div>

              <div
                className="col-lg-7 col-md-7 col-sm-7 d-flex justify-content-end"
                style={{ padding: "60px" }}
              >
                <a className="default-btn-one " href="#">
                  Enquiry
                </a>
                &nbsp;
                <a className="default-btn-one" href="#">
                  Book Appointement
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="choose-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-12">
                <div className="choose-image">
                  <img
                    src={aimg1}
                    alt="image"
                    style={{
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-1 col-md-12"></div>
              <div className="col-lg-8 col-md-12">
                <div className="choose-content-area">
                  <span>Process of Rehabilitation:</span>
                  <h3>Musculoskeletal Assessment</h3>
                  <p>
                    <ArrowForwardIosIcon />
                    Assessment is performed both pre and post-operatively for
                    appropriate goal setting.
                  </p>
                  <h4>Goal Setting</h4>
                  <p>
                    Every treatment is tailored made depending upon the
                    patient’s condition, past medical and surgical history. The
                    ultimate goal of physiotherapy is to improve the quality of
                    life of a patient by making them functionally independent
                    and if it’s a sports person returning back to his sports
                    will be the ultimate goal.
                  </p>

                  <p>
                    <ArrowForwardIosIcon />
                    To relieve pain- According to underlying condition pain can
                    be relieved, for example if the pain is arising due to
                    inflammation of the soft tissues use of cold packs will help
                    relieve the pain, on the contrary if the pain is arising due
                    to chronic stage of muscle spasm a hot pack would be the
                    choice of treatment. Similarly electrotherapeutic modalities
                    also help in relieving the pain.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="choose-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-12">
                <div className="choose-content-area">
                  <span>Process of Rehabilitation:</span>
                  <h3>Musculoskeletal Assessment</h3>
                  <p>
                    <ArrowForwardIosIcon />
                    Assessment is performed both pre and post-operatively for
                    appropriate goal setting.
                  </p>
                  <h3>Goal Setting</h3>
                  <p>
                    Every treatment is tailored made depending upon the
                    patient’s condition, past medical and surgical history. The
                    ultimate goal of physiotherapy is to improve the quality of
                    life of a patient by making them functionally independent
                    and if it’s a sports person returning back to his sports
                    will be the ultimate goal.
                  </p>

                  <p>
                    <ArrowForwardIosIcon />
                    To relieve pain- According to underlying condition pain can
                    be relieved, for example if the pain is arising due to
                    inflammation of the soft tissues use of cold packs will help
                    relieve the pain, on the contrary if the pain is arising due
                    to chronic stage of muscle spasm a hot pack would be the
                    choice of treatment. Similarly electrotherapeutic modalities
                    also help in relieving the pain.
                  </p>
                </div>
              </div>
              <div className="col-lg-1 col-md-12"></div>
              <div className="col-lg-3 col-md-12">
                <div className="choose-image">
                  <img
                    src={aimg1}
                    alt="image"
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="team-section pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <p className="text-center">OUR DOCTORS</p>
              <h3>{name} Specialists</h3>
            </div>
            <div className="row">
              {this.state.experts.map((expert) => (
                <div className="col-lg-3 col-md-6">
                  <Link to="/Doctordetails">
                    <div
                      className="team-item-area"
                      style={{ background: "#FFF7F5" }}
                    >
                      <div className="team-image d-flex justify-content-center">
                        <img
                          src={team1}
                          alt="image"
                          style={{
                            height: "150px",
                            width: "150px",
                            marginTop: "12px",
                            border: "3px solid orange",
                            borderRadius: "100px",
                            boxShadow: "0 6px 20px 2px rgba(0, 0, 0, 0.19)",
                          }}
                        />
                      </div>
                      <div className="team-content">
                        <h3>Anoop Jalota</h3>
                        <span>Speciality</span>
                        <ul className="team-social">
                          <li></li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" target="_blank">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div align="center">
              <a
                className="default-btn-one"
                href="/Doctor"
                style={{ width: "200px" }}
                align="center"
              >
                See all
              </a>
            </div>
          </div>

          <div className="team-animation">
            <div className="shape-img1">
              <img src={image3} alt="image" />
            </div>
            <div className="shape-img2">
              <img src={image4} alt="image" />
            </div>
            <div className="shape-img3">
              <img src={image3} alt="image" />
            </div>
            <div className="shape-img4">
              <img src={image4} alt="image" />
            </div>
            <div className="shape-img5">
              <img src={image3} alt="image" />
            </div>
          </div>
        </section>

        <section className="services-section12 pt-100 pb-60 ">
          <div className="container">
            <div className="section-title">
              <p className="text-center">SERVICES</p>
              <h3>How We Can Help?</h3>
            </div>

            <br />
            <div className="row">
              <div className="col-lg-8">
                <div className="col-lg-8 col-md-6 col-sm-12">
                  <div id="accordion">
                    <div className="card" style={{ border: "none" }}>
                      <div className="card-header ch">
                        <a
                          className="btn"
                          data-bs-toggle="collapse"
                          href="#collapsethree"
                        >
                          <h3 className="card-font">
                            {" "}
                            What is Lorem Ipsum?
                            <ArrowForwardIosIcon />{" "}
                          </h3>
                        </a>
                      </div>
                      <div
                        id="collapsethree"
                        className="collapse show"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-sm-12">
                  <div id="accordion">
                    <div className="card" style={{ border: "none" }}>
                      <div className="card-header ch">
                        <a
                          className="btn"
                          data-bs-toggle="collapse"
                          href="#collapseone"
                        >
                          <h3 className="card-font">
                            {" "}
                            What is Lorem Ipsum?
                            <ArrowForwardIosIcon />{" "}
                          </h3>
                        </a>
                      </div>
                      <div
                        id="collapseone"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-sm-12">
                  <div id="accordion">
                    <div className="card" style={{ border: "none" }}>
                      <div className="card-header ch">
                        <a
                          className="btn"
                          data-bs-toggle="collapse"
                          href="#collapsetwo"
                        >
                          <h3 className="card-font">
                            {" "}
                            What is Lorem Ipsum?
                            <ArrowForwardIosIcon />{" "}
                          </h3>
                        </a>
                      </div>
                      <div
                        id="collapsetwo"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 ">
                <p className="t">F.A.Q</p>
                <h1>Frequantly Asked Questions</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer-section pt-100 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="footer-area">
                  <img src={Logo} alt="" style={{ width: "150px" }} />
                </div>
              </div>

              <div className="col-lg-1 col-md-1 col-sm-1">
                <div className="footer-item-area">
                  <div className="footer-heading">
                    <h3>About</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1">
                <div className="footer-item-area">
                  <div className="footer-heading">
                    <h3>Career</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="mb-30">
                  <div className="footer-heading">
                    <h3>Privacy Policy</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="mb-30">
                  <div className="footer-heading">
                    <h3>Terms and condition</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="mb-30">
                  <div className="footer-heading">
                    <h3>Refund Policy</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="copyright-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <p>
                  © 2021 All Rights Reserved | Powered By &nbsp;
                  <a href="https://octaloptimum.com/" target="_blank">
                    Octal Optimum Technology
                  </a>
                </p>
                <ul className="footer-social">
                  <li>
                    <a
                      href="https://www.linkedin.com/company/healthonify"
                      target="_blank"
                      className="bg-3955bc"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/healthonify"
                      target="_blank"
                      className="bg-1da1f2"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/healthonify"
                      target="_blank"
                      className="bg-004dff"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/healthonify"
                      target="_blank"
                      className="bg-0273af"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Types;
