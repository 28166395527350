import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FitnessCenterService from "../../services/fitnesscenter.service";

export const postfitness = createAsyncThunk(
    "get/fitnesscenter/get",
    async(item,thunkAPI) => {
        try {
            const data = await FitnessCenterService.postfitnescenter(item);
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);


export const getTrendingfitness = createAsyncThunk(
    "get/fitnesscenter/get",
    async(thunkAPI) => {
        try {
            const data = await FitnessCenterService.trendingfitnescenter();
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const getFitnessDetails = createAsyncThunk(
    "get/fitnesscenter/get",
    async(thunkAPI) => {
        try {
            const data = await FitnessCenterService.fitnescenterDetails();
            return { user: data };
        } catch (error) {
            const emessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.emessage) ||
                error.emessage ||
                error.toString();
            return thunkAPI.rejectWithValue({ emessage });
        }
    }
);

export const searchFitnessCenter = createAsyncThunk(
    "package/search/fitnessCenter",
    async(thunkAPI) => {
        try {
            const data = await FitnessCenterService.searchFitnessCenter();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const searchByFitnessCenter = createAsyncThunk(
    "package/search/travelPackage",
    async(thunkAPI) => {
        try {
            const data = await FitnessCenterService.searchByFitnessCenter();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   EnquiryService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: FitnessCenterService.getUserDetails() || null,
    isLoggedIn: false,
};

const enquirySlice = createSlice({
    name: "enquiry",
    initialState,
    extraReducers: {
        [postfitness.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postfitness.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postfitness.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.emessage;
            state.isLoggedIn = false;
            state.user = null;
        },


     
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = enquirySlice;
export default reducer;