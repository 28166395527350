import React, { useState } from "react";
import Navbar from "./navbar";
import { API_PATHS } from "../utils/constants/api.constants";

const ImageUpload = () => {
  const [image, setImage] = useState(" No File Chosen");
  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setImage(data.data.location);
  }
  return (
    <div>
      <Navbar />

      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 pt-5">
            <div className="form-group">
              <label for="placeTextarea" className="pb-3">
                Add Your Image
              </label>
              <input
                type="file"
                className="form-control"
                Placeholder="Price Tagline"
                name="imageUrl"
                onChange={(e) => imageUpload(e)}
                required
              />
              <div className="exel">
                <p className="path">Your Image Path :</p>
                <p className="path_text">{image}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
