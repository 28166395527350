import React, { useState } from "react";
import UserNavbar from "./UserNavbar";
import { useDispatch } from "react-redux";
import { getprofile } from "./../store/slices/profile";
const MyProfile = () => {
  var today = new Date();
  let msg = "";
  var curHr = today.getHours();
  if (curHr < 12) {
    msg = "Good Morning";
  } else if (curHr < 18) {
    msg = "Good Afternoon";
  } else {
    msg = "Good Evening";
  }
  const [name, setName] = useState("");
  let uId = JSON.parse(localStorage.getItem("user")).data.id;
  let usersId = JSON.parse(localStorage.getItem("user")).data.id;
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getprofile())
      .unwrap()
      .then((data) => {
        // if (data.user.data[0]._id === userId) {
        setName(data.user.data[0].firstName);
        // }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div className="bg-light">
      <UserNavbar />
      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <div className="pt-5 mt-5">
                <div className="bg-white-ptbrl-2 mb-5 mt-1">
                  <div className="col-lg-12 pt-4">
                    <p className="col-black intro-text">
                      {msg}, <span className="intro-text-name">{name}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyProfile;
