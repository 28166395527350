import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "./navbar";
import { login } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import { VscEye } from "react-icons/vsc";
function Signin() {
  // hide/show password start
  const [passwordShown, setPasswordShown] = useState(false);
  // hide/show password end

  const [mobileNo, setmobileNo] = useState("");
  const [alertemail, setAlertemail] = useState("");
  const [password, setPassword] = useState("");
  const [alertpass, setAlertpass] = useState("");

  const navigate = useNavigate("");
  const { loading, error, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  async function signIn(event) {
    event.preventDefault();
    dispatch(login({ mobileNo, password }))
      .unwrap()
      .then(() => {
        navigate("/Myprofile");
      });
  }

  const save = (e) => {
    const regemail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
    if (regemail.test(mobileNo)) {
      setAlertemail("");
    } else if (!regemail.test(mobileNo) && mobileNo === "") {
      setAlertemail("Please enter the email address");
      e.preventDefault();
    } else {
      setAlertemail("Please enter the valid email address");
      e.preventDefault();
    }

    const regPass = /^(?=.*?[A-Za-z]).{6,9}$/;
    if (regPass.test(password)) {
      setAlertpass("");
    } else if (!regPass.test(password) && password === "") {
      setAlertpass("Please enter the Password");
      e.preventDefault();
    } else {
      setAlertpass(
        "Password should not more then 6 characters and not more then 9 characters"
      );
      e.preventDefault();
    }
  };
  return (
    <div id="__next">
      <Navbar />
      <section className="login-bg-image">
        <div className="login-form">
          {/*error && <Alert variant="danger">{error}</Alert>*/}

          <p className="login-main-head text-center">Login</p>
          {/* <p className="login-text-1 text-center">
            Enter your details to Signin to your account
          </p> */}
          <form onSubmit={signIn}>
            <input
type="email"
              value={mobileNo}
              onChange={(e) => setmobileNo(e.target.value)}
              className="form-control text-black-ph"
              placeholder="Enter the email"
              style={{ lineHeight: "2" }}
            />
            <p className="alert-message">{alertemail}</p>

            <br />

            <div className="position-r">
              <input
                type={passwordShown ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control text-black-ph"
                placeholder="Enter password"
                style={{ lineHeight: "2" }}
              />
              <VscEye className="eye_icon_login" onClick={togglePassword} />
            </div>
            <p className="alert-message">{alertpass}</p>

            <div className="col-md-12 d-flex justify-content-center mt-5 mb-2">
              <button
                type="submit"
                className="btn btn-warning login-btn col-white"
                onClick={save}
              >
                Sign in
              </button>
            </div>
            <p className=" text-center login-text-2 pt-1">
              Don't have an account?{" "}
              <Link to="Register" className="heightlight-black">
                Signup
              </Link>
            </p>

            <p className="text-center">
              <a href="#" className="heightlight-black text-center">
                Forgot password
              </a>
            </p>

            <p className="text-center">
             User ?  <Link to="/signin" className="heightlight-black text-center">
                Login
              </Link>
            </p>
          </form>
        </div>
        <div className="bg-overlay-orange" />
      </section>

      <Footer />
    </div>
  );
}
export default Signin;
