import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "./navbar";
import Footer from "./Footer";
const HRA = () => {
  const navigate = useNavigate("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [heightInCms, setheightInCms] = useState("");
  const [weightInKgs, setweightInKgs] = useState("");
  const [waistInCms, setwaistInCms] = useState("");
  const [gender, setGender] = useState("");

  const [alertname, setalertName] = useState("");
  const [alertage, setalertAge] = useState("");
  const [alertmobileNo, setalertmobileNo] = useState("");
  const [alertheightInCms, setalertheightInCms] = useState("");
  const [alertweightInKgs, setalertweightInKgs] = useState("");
  const [alertwaistInCms, setalertwaistInCms] = useState("");
  const [alertgender, setalertGender] = useState("");

  const freeconsultation = (e) => {
    const regxname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxname.test(name)) {
      setalertName("");
    } else if (!regxname.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const regxmobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regxmobile.test(mobileNo)) {
      setalertmobileNo("");
    } else if (!regxmobile.test(mobileNo) && mobileNo === "") {
      setalertmobileNo("Please enter your contact number");
      e.preventDefault();
    } else {
      setalertmobileNo("Please enter a valid contact number");
      e.preventDefault();
    }

    const regxage = /^(1[89]|[2-9][0-9])$/;
    if (regxage.test(age)) {
      setalertAge("");
    } else if (!regxage.test(age) && age === "") {
      setalertAge("Please enter  your age");
      e.preventDefault();
    } else {
      setalertAge("");
      // e.preventDefault();
    }

    const regexwaist = /^(1[89][89]|[2-9]\d)$/;
    if (regexwaist.test(waistInCms)) {
      setalertwaistInCms("");
    } else if (!regexwaist.test(waistInCms) && waistInCms === "") {
      setalertwaistInCms("Please enter your waist size");
      e.preventDefault();
    } else {
      setalertwaistInCms("");
    }

    const regexweight = /^(1[89][89]|[2-9]\d)$/;
    if (regexweight.test(weightInKgs)) {
      setalertweightInKgs("");
    } else if (!regexweight.test(weightInKgs) && weightInKgs === "") {
      setalertweightInKgs("Please enter your weight");
      e.preventDefault();
    } else {
      setalertweightInKgs("");
    }

    const regexheight = /^(1[89][89]|[2-9]\d)$/;
    if (regexheight.test(heightInCms)) {
      setalertheightInCms("");
    } else if (!regexheight.test(heightInCms) && heightInCms === "") {
      setalertheightInCms("Please enter your height");
      e.preventDefault();
    } else {
      setalertheightInCms("");
    }

    if (gender === "") {
      setalertGender("Please select your gender");
      e.preventDefault();
    } else {
      setalertGender("");
    }
  };

  const myData = {
    name: name,
    mobileNo: mobileNo,
    age: age,
    gender: gender,
    heightInCms: heightInCms,
    weightInKgs: weightInKgs,
    waistInCms: waistInCms,
  };
  async function searchTheme(e) {
    e.preventDefault();
    navigate("/Questionnaire/", { state: myData });
  }
  return (
    <div id="__next">
      <Header />
      <section className="container pt-5 mt-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="pt-3 pb-3">Health Risk Assessment</h3>
        </div>
        <p className="mt-5">
          Health Risk Assessment is a questionnaire about a person's medical
          history, demographic characteristics and lifestyle. It is one of the
          most widely used screening tools in the field of health promotion and
          is often the first Step to analyze and evaluate the status and quality
          of one’s health condition. After the completion of questionnaire, you
          will get a detailed Health Risk report based on Predictive Health
          analysis. A health Coach will get in touch with you to explain the
          details of the report and answer queries if any.
        </p>
      </section>
      <section className="container pt-3 mt-3">
        <div className="col-lg-12 d-flex justify-content-center">
          <h3 className="pt-3 pb-3">
            {" "}
            Please fill below details to take Health Risk Assessment
          </h3>
        </div>
        <form onSubmit={searchTheme}>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-2">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ lineHeight: "2 " }}
              />{" "}
              <p className="alert-message"> {alertname}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-2">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                style={{ lineHeight: "2 " }}
              />{" "}
              <p className="alert-message"> {alertage}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-2">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your mobile number"
                value={mobileNo}
                onChange={(e) => setmobileNo(e.target.value)}
                style={{ lineHeight: "2 " }}
              />{" "}
              <p className="alert-message"> {alertmobileNo}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-2">
              <select
                className="form-control"
                onChange={(e) => setGender(e.target.value)}
                value={gender}
                style={{ lineHeight: "2 " }}
              >
                <option value="">Gender</option>
                <option>Male</option>
                <option>Female</option>
              </select>
              <p className="alert-message"> {alertgender}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-2">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your height (in cm)"
                value={heightInCms}
                onChange={(e) => setheightInCms(e.target.value)}
                style={{ lineHeight: "2 " }}
              />{" "}
              <p className="alert-message"> {alertheightInCms}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-2">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your weight (in kg)"
                value={weightInKgs}
                onChange={(e) => setweightInKgs(e.target.value)}
                style={{ lineHeight: "2 " }}
              />{" "}
              <p className="alert-message"> {alertweightInKgs}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-2">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your waist size (in cm)"
                value={waistInCms}
                onChange={(e) => setwaistInCms(e.target.value)}
                style={{ lineHeight: "2 " }}
              />{" "}
              <p className="alert-message"> {alertwaistInCms}</p>
            </div>
            <div className="col-lg-1 col-md-3 col-sm-12 col-xs-12 p-2">
              <button
                className="btn btn-blue col-white"
                style={{ width: "100%", height: "40px" }}
                id="save"
                onClick={freeconsultation}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default HRA;
