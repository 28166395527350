import React, { useState } from "react";
import Footer from "./Footer";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./navbar";
import {
  searchFitnessCenter,
  searchByFitnessCenter,
} from "./../store/slices/fitnesscenter";
const Fitnesscenter = (props) => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [searchResults, setsearchResults] = useState([]);
  const location = useLocation();
  const [type, setType] = useState("");
  const [cities, setCities] = useState("");
  const [localities, setLocalities] = useState("");
  const values = location.state;
  const [datacount, setDataCount] = useState("");
  localStorage.setItem("service", values.fitnessServiceName);
  localStorage.setItem("city", values.cityName);
  localStorage.setItem("locality", values.locationName);

  React.useEffect(() => {
    dispatch(searchFitnessCenter())
      .unwrap()
      .then((data) => {
        setsearchResults(data.user.data);
        setDataCount(data.user.dataCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function searchCenter(e) {
    e.preventDefault();

    let item = { type, localities, cities };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(searchByFitnessCenter())
      .unwrap()
      .then((data) => {
        setsearchResults(data.user.data);
        setDataCount(data.user.dataCount);
        //setQuery("");
        // console.log(countVal);
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  return (
    <div id="__next">
      <Header />

      <section className="container pt-5 mt-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="pt-3 pb-3">Fitness Center</h3>
        </div>
      </section>
      <section className="cover p-5">
        <h3 className="text-center col-white">Search Gym/Fitness Center</h3>
        <form onSubmit={searchCenter}>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    value={cities}
                    onChange={(e) => setCities(e.target.value)}
                    style={{
                      lineHeight: "2.6",
                    }}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <input
                    type="text"
                    value={localities}
                    onChange={(e) => setLocalities(e.target.value)}
                    className="form-control"
                    placeholder="Location"
                    style={{
                      lineHeight: "2.6",
                    }}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <select
                    name="health"
                    id="fit"
                    className="select-opt"
                    style={{
                      lineHeight: "2.6",
                    }}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">Select Fitness Format</option>
                    <option>Gyms</option>
                    <option>Zumba</option>
                    <option>Cross Functional Training</option>
                    <option>Yoga</option>
                    <option>Fitness Studio</option>
                    <option>MMA</option>
                    <option>Kick Boxing</option>
                    <option>Dance</option>
                    <option>Pilates</option>
                  </select>
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 d-flex justify-content-center">
                  <button
                    className="btn btn-warning col-white inside-btn"
                    style={{ lineHeight: "2", border: "none" }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      {datacount === 0 && (
        <section className="pb-5">
          <div className="not-found-section" style={{ position: "relative" }}>
            <p className="wrapper-text">No fitness center found...!</p>
          </div>
        </section>
      )}
      {datacount !== 0 && (
        <section className="img_section container pt-5 pb-5">
          <div className="row">
            {searchResults.map((centerVal, index) => (
              <div
                className="col-lg-3 col-md-3 col-sm-12"
                style={{ padding: "5px" }}
              >
                <Link
                  to={`/FitnessCenterDetails/${
                    centerVal._id ? centerVal._id : null
                  }`}
                >
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 white_card"
                    align="center"
                  >
                    <img src={centerVal.logo} alt="" className="trans_imgs" />

                    <h4 className="card-title slick_text">
                      {centerVal.fcName}
                    </h4>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>
      )}

      <Footer />
    </div>
  );
};

export default Fitnesscenter;
