import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import banner2 from "./../images/banner/physio2.jpg";
import Navbar from "./navbar";

const Slippeddisc = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  return (
    <div id="__next">
      <Navbar />

      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>
      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3>Slipped Disc</h3>
        </div>

        <div className="pt-4">
          <p>
            A disc prolapsed is often referred to as a slipped disc, however
            please do not worry as your disc cannot “slip” anywhere. Think of
            your discs as shock absorbers between the vertebrae of your spine.
            What actually occurs, is the thick gel-like nucleus in the middle of
            the disc bulges out through a tiny fault in the fibrous disc wall.
            This small bulge of nucleus can therefore irritate and compress the
            nerve root causing pain, which may radiate down the leg. There are
            different types and severities of disc prolapse.
          </p>
          <p>
            <b>Causes a disc prolapse</b>
            <br />
            Generally, a disc prolapsed will occur if there is already a
            weakness in the fibrous wall of the disc, which often only requires
            a small amount of pressure to be placed on the spine for a prolapse
            to occur. For example: lifting a heavy object in an awkward way,
            direct injury such as a fall or a whiplash accident , bending
            forward, moving awkwardly, coughing or sneezing.
          </p>
          <p>
            <b> Symptoms/effects of a disc prolapse</b>
            <br />
            Disc prolapses typically present with:
            <br />
            <ul>
              <li>acute low back pain</li>
              <li> leg pain / sciatica</li>
              <li> muscle spasm</li>
              <li> shifting of the pelvis and back</li>
              <li> numbness and/ or pins and needles</li>
              <li> weakness in the lower leg or foot</li>
            </ul>
          </p>
          <p>
            <b>Physiotherapy treatment for a disc prolapse</b>
            <br />
            Physiotherapy treatment for disc prolapses is very effective and
            aims to reduce pain, stiffness and muscle spasm, as well as sciatic
            leg pain. Once your disc prolapsed symptoms have been reduced your
            physiotherapist will help to improve your muscle strength and
            flexibility. Your physiotherapist at Edinburgh Sports & Spinal will
            develop an individually tailored exercise plan, which will help
            relieve pain, keep you strong and flexible and help prevent any
            further problems being caused to your back. In the acute phase,
            physiotherapy treatment will involve being given plenty of advice on
            how to modify activity, how to reduce pain, as well as guidance on
            the use of painkillers. As the acute episode settles, physiotherapy
            treatment for disc prolapse may include:
            <br />
            <ul>
              <li>soft tissue massage </li>
              <li> joint mobilisation </li>
              <li>
                extension exercises to relieve nerve root irritation and pain
              </li>
              <li>
                lower back exercises and techniques to help restore normal
                pain-free mobility and stability of the spine
              </li>
              <li> acupuncture </li>
              <li> electrical stimulation such as TENS to help with pain </li>
              <li> postural advice </li>
              <li>
                strengthening exercises to help reduce the recurrence of another
                disc prolapse
              </li>
            </ul>
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Slippeddisc;
