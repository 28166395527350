import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import UserNavbar from "./UserNavbar";
import { postwalkreminder } from "../store/slices/reminder";
// import Moment from "moment";
const AddWalk = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [remindMeAt, setRemindMeAt] = useState("");


 

  async function upload(e) {
    e.preventDefault();
    let userId = "628e07c3295cbb2a64996d2d";
    let walkReminderEnabled = true ;
    let remindMeEnabled = true ;

    let item = {
        userId,
walkReminderEnabled,
remindMeEnabled,
        remindMeAt,
    
}
    dispatch(postwalkreminder(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");

        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div className="bg-light">
      <UserNavbar />

      <div className="container-fluid response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container">
              <div className="pt-5 mt-5">
            <h3 className="pt-4 pb-2">Add Walk Reminder</h3>

<div className="position-relative">
<div className="reminder-card">
 <div className="form-group">
                          <b>Remind Me At</b>
                          <input
                            type="time"
                            // name="First Name"
                            // placeholder="First Name"
                            className="form-control mt-2"
                            value={remindMeAt}
                            required
                            onChange={(e) => setRemindMeAt(e.target.value)}
                            //  value={agentVal.firstName}
                          />
                        </div>
</div>

</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWalk;
