import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import formsService from "../../services/forms.service";
import AuthService from "../../services/auth.service";



export const fitnessFormData = createAsyncThunk(
    "get/fitnessform",
    async(thunkAPI) => {
        try {
            const data = await formsService.fitnessForms();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const medicalhistoryData = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.medicalHistory();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const lifestyleData = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.lifeStyleForm();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const toolSlice = createSlice({
    name: "forms",
    initialState,
    extraReducers: {
      
        [fitnessFormData.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [fitnessFormData.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [fitnessFormData.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

       
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = toolSlice;
export default reducer;