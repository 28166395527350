import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class fitnesstoolService {
   
   
    static bmi() {
      let api = '';        
      let item = JSON.parse(localStorage.getItem("item"));
      api = API_PATHS.fittoolscalculator + '?weight=' + item.weight + '&height=' + item.height + '&age=' + item.age + '&gender=' + item.gender + '&tool=bmi';
    //   weight=65&height=175&age=25&gender=male&tool=bmi
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static bmr() {
      let api = '';        
      let item = JSON.parse(localStorage.getItem("item"));
      api = API_PATHS.fittoolscalculator + '?weight=' + item.weight + '&height=' + item.height + '&age=' + item.age + '&gender=' + item.gender + '&tool=bmr';
    //   weight=65&height=175&age=25&gender=male&tool=bmr
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static rmr() {
      let api = '';        
      let item = JSON.parse(localStorage.getItem("item"));
      api = API_PATHS.fittoolscalculator + '?weight=' + item.weight + '&height=' + item.height + '&age=' + item.age + '&gender=' + item.gender + '&tool=rmr';
    //   weight=65&height=175&age=25&gender=male&tool=bmr
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static IdealWeight() {
      let api = '';        
      let item = JSON.parse(localStorage.getItem("item"));
      api = API_PATHS.fittoolscalculator + '?weight=' + item.weight + '&height=' + item.height + '&age=' + item.age + '&gender=' + item.gender + '&tool=idealWeight';
    //   weight=65&height=175&age=25&gender=male&tool=bmr
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static leanBodyMass() {
      let api = '';        
      let item = JSON.parse(localStorage.getItem("item"));
      api = API_PATHS.fittoolscalculator + '?weight=' + item.weight + '&height=' + item.height + '&age=' + item.age + '&gender=' + item.gender + '&tool=lbm';
    //   weight=65&height=175&age=25&gender=male&tool=bmr
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static macro() {
      let api = '';        
      let item = JSON.parse(localStorage.getItem("item"));
      api = API_PATHS.fittoolscalculator + '?tool=macroCalculator' + '&id=62e285167c6c7631ec11a398' + '&calories=' + item.calories;
    //   weight=65&height=175&age=25&gender=male&tool=bmr
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }
 
    static bodyfat() {
      let api = '';        
      let item = JSON.parse(localStorage.getItem("item"));
      api = API_PATHS.fittoolscalculator + '?weight=' + item.weight + '&height=' + item.height + '&age=' + item.age + '&gender=' + item.gender + '&neck=' + item.neck + '&waist=' + item.waist + '&hip=' + item.hip + '&tool=bfp';
    
    //   weight=65&height=175&age=25&gender=male&tool=bmr
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static calorieintake() {
      let api = '';        
      let item = JSON.parse(localStorage.getItem("item"));
      api = API_PATHS.fittoolscalculator + '?weight=' + item.weight + '&height=' + item.height + '&age=' + item.age + '&gender=' + item.gender + '&targetWeight=' + item.targetWeight + '&goal=' + item.goal + '&activityLevel=' + item.activityLevel + '&tool=calorieIntake';
    
    //   weight=65&height=175&age=25&gender=male&tool=bmr
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default fitnesstoolService;