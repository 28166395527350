import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TravelEnquiry } from "../store/slices/enquiry";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/banner/physio1.jpg";
import Footer from "./Footer";
import Navbar from "./navbar";

const Rehab = () => {
  let enquiryFor = "Physiotherapy";
  const [name, setName] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [alertName, setalertName] = useState("");
  const [alertEmail, setalertEmail] = useState("");
  const [alertcontactNumber, setalertcontactNumber] = useState("");
  const [alertMessage, setalertMesssage] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function next(e) {
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully");
        setMessage("");
        setName("");
        setEmail("");
        setcontactNumber("");
        document.getElementById("myappointment").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }
  const formSubmit = (e) => {
    // e.preventDefault();
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
    }

    const vMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vMessage.test(message)) {
      setalertMesssage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMesssage("Please enter your issue");
      e.preventDefault();
    } else {
      setalertMesssage("");
    }

    const vMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (vMobile.test(contactNumber)) {
      setalertcontactNumber("");
    } else if (!vMobile.test(contactNumber) && contactNumber === "") {
      setalertcontactNumber("Please enter your mobile  number");
      e.preventDefault();
    } else {
      setalertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const vEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (vEmail.test(email)) {
      setalertEmail("");
    } else if (!vEmail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearall = () => {
    setName("");
    setcontactNumber("");
    setEmail("");
    setMessage("");
    setalertEmail("");
  };

  //enquiry form
  return (
    <div id="__next">
      <Navbar />
      <div className="icon-bar">
        <button
          type="button"
          className="btn  btn-primary enq_btn"
          data-bs-toggle="modal"
          data-bs-target="#myappointment"
        >
          Book Appointment
        </button>
      </div>

      <section>
        <div className="carousel_cover">
          <Carousel className="length">
            <Carousel.Item interval={1500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                alt=""
                src={banner1}
              />
              <Carousel.Caption className="carousel_text_top">
                <h1
                  className="text1"
                  style={{ color: "black", float: "right" }}
                >
                  Trusted Physiotherapy & Rehab Service provider
                  <h2>In your City for Pain Relief & Recovery</h2>
                </h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="container pt-5 pb-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="col-head-color-p">Physio/ Rehab E-com</h3>
        </div>

        <div className="pt-4">
          <p>
            Rehabilitation is a concept that is widely discussed globally. This
            is by no means unexpected since over a billion people live with a
            form of disability, accounting for over 15% of the world population.
            Furthermore, according to a recent report, 2.41 billion individuals
            worldwide live with the conditions that impact their functions in
            daily life and would benefit from rehabilitation services, which
            equates to 1 in 3 individuals requiring rehabilitation services
            throughout the course of their illness or injury.
            <br />
            <br />
            According to the World Health Organisation (WHO), rehabilitation is
            one of the essential components of Universal Health Coverage (UHC),
            which features alongside “promotion of good health, prevention of
            diseases, treatment and palliative care”. Thus, rehabilitation
            focuses on achieving functional independence in activities of daily
            living (ADL), participation in work, recreation and education, with
            individuals being able to achieve meaningful roles in daily life.
            Clearly, rehabilitation is pivotal in achieving not only individual
            health benefits, but an overall universal health goal that permits
            the building of a healthy and functional global population.
            <br />
            <br />
            Fundamentally, rehabilitation is founded on the philosophy that
            every individual has the inherent tendency and right to be an expert
            in their own health care.This, therefore, marks the distinction
            between acute care and rehabilitation, where acute care is concerned
            with an individual’s survival, while rehabilitation is concerned
            with the education and training of individuals to be able to carry
            out activities of daily living by themselves, thus promoting
            self-care and functional independence.
          </p>
          <p>
            <b>Rehabilitation Elements</b>
            <br />
            Dietz described four elements of rehabilitation in relation to
            people with cancer, which has now been utilised and applied across
            rehabilitation for a wide range of conditions: preventive,
            restorative, supportive, palliative:
            <br />
            <ul>
              <li>
                Preventative Rehabilitation-Occurs shortly after a new diagnosis
                or onset of new impairments. The aim is to provide education,
                advice and interventions to prevent or slow onset of further
                impairments and maintain a person’s level of ability. This is a
                common form of rehabilitation in long-term conditions, such as
                Cancer, Chronic Obstructive Pulmonary Disease (COPD), Diabetes
                and many neurological conditions.{" "}
              </li>
              <li>
                Restorative Rehabilitation- Restorative rehabilitation focuses
                on interventions that improve impairments such as muscle
                strength or respiratory function and cognitive impairment to get
                maximal recovery of function. This is a common form of
                rehabilitation after surgery, illness or acute events such as a
                major trauma or a stroke in order to maximise function.{" "}
              </li>
              <li>
                Supportive Rehabilitation- Supportive rehabilitation increases a
                person’s self-care ability and mobility using methods such as
                providing self-help devices and teaching people compensatory
                strategies or alternative ways of doing things. This may include
                the provision of assistive equipment or environmental
                modifications. This is sometimes referred to as adaptive
                rehabilitation.{" "}
              </li>
              <li>
                Palliative Rehabilitation-Palliative rehabilitation enables
                people with life limiting conditions to lead a high quality of
                life physically, psychologically and socially, while respecting
                their wishes. It often focusses on relieving symptoms, such as
                pain, dyspnoea and oedema, preventing contractures, breathing
                assistance, psychological wellbeing, relaxation or the use of
                assistive device, in order to maximise functional independence
                and support comfort, dignity and quality of life.
              </li>
            </ul>
          </p>
        </div>
      </section>

      <Footer />

      <div className="modal fade" id="myappointment">
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Physio Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={next}>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="alert-message">{alertName}</p>

                <br />
                <input
                  type="hidden"
                  placeholder="Name"
                  className="form-control"
                  value={enquiryFor}
                />

                <input
                  type="contactNumber"
                  placeholder="Mobile number"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setcontactNumber(e.target.value)}
                />
                <p className="alert-message">{alertcontactNumber}</p>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="alert-message">{alertEmail}</p>

                <br />

                <textarea
                  col="50"
                  row="50"
                  type="text"
                  placeholder="Describe your issue in brief"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="alert-message">{alertMessage}</p>
                <br />
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning col-white"
                  onClick={formSubmit}
                >
                  Request a Call Back
                </button>
                &nbsp; &nbsp;
                <button
                  type="reset"
                  className="btn btn-blue col-white"
                  data-bs-dismiss="modal"
                  onClick={clearall}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  );
};

export default Rehab;
