import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { otpConfirmation } from "../store/slices/auth";
import Footer from "./Footer";

import { useNavigate } from "react-router-dom";
import TravelHeader from "./travel-navbar";

function Otp() {
  const dispatch = useDispatch();
  const [otp, setotp] = useState("");
  const [mobileNo, setMobile] = useState("");
  const navigate = useNavigate("");

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    setMobile(data.data[0].mobileNo);
  }, [mobileNo]);

  async function confirmOtp(e) {
    e.preventDefault();
    dispatch(otpConfirmation({ mobileNo, otp }))
      .unwrap()
      .then(() => {
        navigate(-3);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div id="__next">
      <TravelHeader />
      <section className="login-bg-image">
        <div className="login-form">
          {/*error && <Alert variant="danger">{error}</Alert>*/}

          <p className="login-main-head text-center">Verify OTP</p>
          <p className="login-text-1 text-center"></p>
          <form onSubmit={confirmOtp}>
            <input
              type="text"
              value={otp}
              onChange={(e) => setotp(e.target.value)}
              className="form-control text-black-ph"
              placeholder="Enter your OTP"
              style={{ lineHeight: "2" }}
            />

            <br />

            <div className="col-md-12 d-flex justify-content-center mt-5 mb-2">
              <button
                type="submit"
                className="btn btn-warning login-btn col-white"
              >
                Verify
              </button>
            </div>
          </form>
        </div>
        <div className="bg-overlay-orange" />
      </section>

      <Footer />
    </div>
  );
}

export default Otp;
