import React from "react";
import logo from "../../src/images/logo-white.png";
import { FaUser, FaUserEdit } from "react-icons/fa";
import {
  MdLogout,
  MdSubscriptions,
  MdGroups,
  MdOutlineFitnessCenter,
} from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import { IoLogoWechat, IoSettings } from "react-icons/io5";
import { GiLightBulb, GiStairsGoal } from "react-icons/gi";
import { TiShoppingCart } from "react-icons/ti";
import { TbReportAnalytics, TbListDetails } from "react-icons/tb";
import { RiUserStarLine } from "react-icons/ri";
import {
  BsJournalBookmarkFill,
  BsTools,
  BsPersonBoundingBox,
  BsJournalMedical,
} from "react-icons/bs";
import { SiClockify } from "react-icons/si";
import { NavLink } from "react-router-dom";

function UserNavbar() {
  const logboard = () => {
    document.getElementById("log").classList.toggle("show");
  };

  const openNav = () => {
    document.getElementById("sidenavb").style.width = "250px";
  };

  const closebtn = () => {
    document.getElementById("sidenavb").style.width = "0px";
  };

  const openNavtop = () => {
    document.getElementById("sidenavbtop").style.height = "355px";
  };

  const closebtntop = () => {
    document.getElementById("sidenavbtop").style.height = "0px";
  };

  const listStyleactive = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#f06900" : "transparent",
      borderRadius: isActive ? "5px 0px 0px 5px" : "5px 0px 0px 5px",
      color: isActive ? "white" : "white",
      paddingLeft: isActive ? "7%" : "0%",
    };
  };

  return (
    <div>
      <nav className="top_bar">
        <div className="brand">
          <NavLink to="#" className="navbar_brand">
            <img src={logo} alt="logo" className="logo_img" />
          </NavLink>
        </div>

        <NavLink to="#">
          <span className="hambergerb" onClick={openNavtop}>
            &#9776;
          </span>
        </NavLink>

        <ul className="nav-list">
          <li className="nav-lists">
            <NavLink to="/" className="col-gray">
              Home
            </NavLink>
          </li>
          <li className="nav-lists">
            <NavLink to="/Weight-loss" className="col-gray">
              Weight Loss
            </NavLink>
          </li>
          <li className="nav-lists">
            <NavLink to="/Fitness" className="col-gray">
              Fitness
            </NavLink>
          </li>
          <li className="nav-lists">
            <NavLink to="/Corporate" className="col-gray">
              Corporate
            </NavLink>
          </li>
          <li className="nav-lists">
            <NavLink to="/Physiotherapy" className="col-gray">
              Physiotherapy
            </NavLink>
          </li>
          <li className="nav-lists">
            <NavLink to="/Travelonify" className="col-gray">
              Travelonify
            </NavLink>
          </li>

          <span className="hamberger" onClick={logboard}>
            <CgMenuGridR className="" />
          </span>
        </ul>

        <span className="hamberger_mids" onClick={openNav}>
          <FaUser className="user_icon" />
        </span>

        <span className="logoin" onClick={logboard}>
          <CgMenuGridR className="" />
        </span>

        <div className="log" id="log">
          <ul className="log-contain">
            <li className="log-items">My profile</li>
            <li className="log-items">Logout</li>
          </ul>
        </div>
      </nav>

      <div className="sidenav" id="sidenavb">
        <div className="logo-wrap d-flex justify-content-center">
          <img src={logo} alt="logo" className="logo_img_side" />
        </div>

        <NavLink to="" className="close_btn" onClick={closebtn}>
          &times;
        </NavLink>

        <NavLink
          to="/MyProfile"
          className="menu top_menu"
          style={listStyleactive}
        >
          <BsPersonBoundingBox className="top_menu_icon" /> My Profile
        </NavLink>

        <NavLink to="/EditProfile" className="menu" style={listStyleactive}>
          <FaUserEdit className="top_menu_icon" /> Edit Profile
        </NavLink>

        <NavLink to="/Booking" className="menu " style={listStyleactive}>
          <TbListDetails className="top_menu_icon" /> Booking
        </NavLink>

        <NavLink to="/MyPayment" className="menu" style={listStyleactive}>
          <BsJournalBookmarkFill className="top_menu_icon" /> My Payments
        </NavLink>

        <NavLink to="/MyConsultation" className="menu" style={listStyleactive}>
          <IoLogoWechat className="top_menu_icon" /> Consultation
        </NavLink>

        <NavLink to="/MyPlan" className="menu" style={listStyleactive}>
          <GiLightBulb className="top_menu_icon" />
          Plans
        </NavLink>

        <NavLink to="/MyAppointment" className="menu" style={listStyleactive}>
          <IoLogoWechat className="top_menu_icon" />
          My Appointments
        </NavLink>

        <NavLink to="/MyTools" className="menu" style={listStyleactive}>
          <BsTools className="top_menu_icon" /> Fitness Tools
        </NavLink>

        <NavLink to="/FitnessForm" className="menu" style={listStyleactive}>
          <BsTools className="top_menu_icon" /> Fitness Form
        </NavLink>

        <NavLink to="/MedicalHistory" className="menu" style={listStyleactive}>
          <BsTools className="top_menu_icon" /> Medical History
        </NavLink>

        <NavLink to="/LifeStyle" className="menu" style={listStyleactive}>
          <BsTools className="top_menu_icon" /> Life Style Q & A
        </NavLink>

        <NavLink to="/MyExpert" className="menu" style={listStyleactive}>
          <RiUserStarLine className="top_menu_icon" /> My Expert
        </NavLink>

        <NavLink to="/MyShop" className="menu" style={listStyleactive}>
          <TiShoppingCart className="top_menu_icon" /> My Orders
        </NavLink>

        <NavLink to="/MyPrescription" className="menu" style={listStyleactive}>
          <BsJournalMedical className="top_menu_icon" /> My Prescription
        </NavLink>

        <NavLink to="/MySubscription" className="menu" style={listStyleactive}>
          <MdSubscriptions className="top_menu_icon" /> My Subscription
        </NavLink>

        <NavLink to="/MyGoals" className="menu" style={listStyleactive}>
          <GiStairsGoal className="top_menu_icon" /> My Goals
        </NavLink>

        <NavLink to="/MyReminders" className="menu" style={listStyleactive}>
          <SiClockify className="top_menu_icon" /> My Reminders
        </NavLink>

        <NavLink to="/Mycommunity" className="menu" style={listStyleactive}>
          <MdGroups className="top_menu_icon" /> My Community
        </NavLink>

        <NavLink to="/MySettings" className="menu" style={listStyleactive}>
          <IoSettings className="top_menu_icon" /> Settings
        </NavLink>

        <hr style={{ color: "white" }} />
        <NavLink
          to="/signin"
          className="menu mb-3"
          style={{ paddingTop: "2%" }}
        >
          <MdLogout className="top_menu_icon" /> Logout
        </NavLink>
      </div>

      <div className="sidenav_top" id="sidenavbtop">
        <a
          href="javascript:void(0)"
          className="close_btn_top"
          onClick={closebtntop}
        >
          &times;
        </a>

        <NavLink to="/" className="col-gray">
          Home
        </NavLink>

        <NavLink to="/Weight-loss" className="col-gray">
          Weight Loss
        </NavLink>

        <NavLink to="/Fitness" className="col-gray">
          Fitness
        </NavLink>

        <NavLink to="/Corporate" className="col-gray">
          Corporate
        </NavLink>

        <NavLink to="/Physiotherapy" className="col-gray">
          Physiotherapy
        </NavLink>

        <NavLink to="/Travelonify" className="col-gray">
          Travelonify
        </NavLink>
      </div>
    </div>
  );
}

export default UserNavbar;
