import axios from "axios";
import { API_BASE_URL, API_PATHS } from "../utils/constants/api.constants";

class AuthService {
    static login(mobileNo, password) {
        return axios
            .post(API_PATHS.login, {
                mobileNo,
                password,

            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static register(item) {
        return axios
            .post(API_PATHS.register,
                item
            )
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static otpVerification({
        mobileNo,
        otp,
    }) {
        return axios
            .post(API_PATHS.verifyOtp, {
                mobileNo,
                otp,
            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}




export default AuthService;